/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

const millisYear2000 = new Date(Date.UTC(2000, 0, 1, 0, 0, 0)).getTime()
const millisYear2100 = new Date(Date.UTC(2100, 0, 1, 0, 0, 0)).getTime()
const secondsYear2000 = millisYear2000 / 1000
const secondsYear2100 = millisYear2100 / 1000

export enum TimeFormat {
  LocalTime,
  UTCTime
}

export function formatDateWithTime(date: Date, timeFormat: TimeFormat = TimeFormat.UTCTime): string {
  return `${formatDateWithoutTime(date, timeFormat)} ${formatTimeOnly(date, timeFormat)}`
}

export function formatDateWithoutTime(date: Date, timeFormat: TimeFormat = TimeFormat.UTCTime): string {
  const year = timeFormat === TimeFormat.LocalTime ? date.getFullYear() : date.getUTCFullYear()
  const month = String((timeFormat === TimeFormat.LocalTime ? date.getMonth() : date.getUTCMonth()) + 1).padStart(
    2,
    "0"
  )
  const day = String(timeFormat === TimeFormat.LocalTime ? date.getDate() : date.getUTCDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export function formatTimeOnly(date: Date, timeFormat: TimeFormat = TimeFormat.UTCTime): string {
  const hours = String(timeFormat === TimeFormat.LocalTime ? date.getHours() : date.getUTCHours()).padStart(2, "0")
  const minutes = String(timeFormat === TimeFormat.LocalTime ? date.getMinutes() : date.getUTCMinutes()).padStart(
    2,
    "0"
  )
  const seconds = String(timeFormat === TimeFormat.LocalTime ? date.getSeconds() : date.getUTCSeconds()).padStart(
    2,
    "0"
  )
  return `${hours}:${minutes}:${seconds}${timeFormat === TimeFormat.LocalTime ? "" : " UTC"}`
}

/**
 * Format a time as a HH:MM:SS string.
 * @param seconds Time in seconds.
 */
export function formatTimeAsDuration(seconds: number): string {
  const days = Math.floor(seconds / 86400)
  const hours = Math.floor((seconds - days * 86400) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.round(seconds % 60)
  const paddedHours = String(hours).padStart(2, "0")
  const paddedMinutes = String(minutes).padStart(2, "0")
  const paddedSeconds = String(secs).padStart(2, "0")
  return `${days ? days + "d+" : ""}${paddedHours}:${paddedMinutes}:${paddedSeconds}`
}

export function convertMillisToDateTime(millisOrSeconds: number): Date | undefined {
  if (secondsYear2000 <= millisOrSeconds && millisOrSeconds < secondsYear2100) {
    return new Date(millisOrSeconds * 1000)
  } else if (millisYear2000 <= millisOrSeconds && millisOrSeconds < millisYear2100) {
    return new Date(millisOrSeconds)
  } else {
    // Date-times outside [2000, 2100] are discarded.
    return undefined
  }
}

export function getDateTimeFromTimestamp(line: string) {
  // This matches any time in ISO format "YYYY-MM-DDTHH:MM:SSZ". Use specified timezone.
  const regexIsoTimestamp = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:Z|[+-]\d+)?)/
  let match = regexIsoTimestamp.exec(line)
  if (match) {
    return new Date(match[1])
  }

  // This matches any time in the format "YYYY-MM-DD HH:MM:SS". Uses UTC.
  const regexYMDHMS = /(\d{4})-(\d{2})-(\d{2})\s+(\d{2}):(\d{2}):(\d{2})/
  match = regexYMDHMS.exec(line)
  if (match) {
    return new Date(
      parseInt(match[1]),
      parseInt(match[2]) - 1,
      parseInt(match[3]),
      parseInt(match[4]),
      parseInt(match[5]),
      parseInt(match[6])
    )
  }
}
