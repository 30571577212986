/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import TtpMessageSensorIncomingLocations from "../../ttp/parser/messages/ttpMessageSensorIncomingLocations"
import TtpMessageSensorLocationEvent from "../../ttp/parser/messages/ttpMessageSensorLocationEvent"
import TtpMessageSensorLocationPrediction from "../../ttp/parser/messages/ttpMessageSensorLocationPrediction"
import TtpMessageSensorMapMatcherResult from "../../ttp/parser/messages/ttpMessageSensorMapMatcherResult"
import TtpMessageSensorMapMatcherInputLocations
  from "../../ttp/parser/messages/ttpMessageSensorMapMatcherInputLocations"
import TtpMessageSensorLaneLevelPrediction from "../../ttp/parser/messages/ttpMessageSensorLaneLevelPrediction"
import TtpParser from "../../ttp/ttpParser"
import TtpMessageBase from "../../ttp/parser/ttpMessageBase"
import {BoundingBox} from "../../common/utils/geoUtils"
import {LayerLineParser, LayerType} from "../parserTypes"
import FileParser from "./fileParser"
import Logger from "../../common/logger"
import {MetadataStore} from "../../global/metadataStore"
import MemoryUtils from "../../common/utils/memoryUtils"
import {LngLat} from "../../common/utils/wgs84Utils"

export class FileParserTtp extends FileParser {
  private static readonly locationCircleColor = "rgba(0,255,0)"
  private static readonly parsers: Partial<Record<LayerType, LayerLineParser>> = {
    [LayerType.TTPLocation]: {
      name: "TTP: GPS/GNSS",
      layerType: LayerType.TTPLocation,
      color: {
        "circle-radius": 10,
        "circle-color": FileParserTtp.locationCircleColor,
        text: "G",
        "text-color": "rgb(0,0,0)"
      },
      regexWithLocation: ""
    },
    [LayerType.TTPSlow]: {
      name: "TTP: slow data",
      layerType: LayerType.TTPSlow,
      color: undefined,
      regexWithLocation: ""
    },
    [LayerType.TTPFast]: {
      name: "TTP: fast data",
      layerType: LayerType.TTPFast,
      color: undefined,
      regexWithLocation: ""
    }
  }

  private readonly colorSensorMapMatcherResult = "rgb(248,178,87)"
  private readonly colorSensorLocationPrediction = "rgb(204,115,218)"
  private readonly colorSensorLocationEvent = "rgb(18,200,232)"
  private readonly colorSensorMapMatcherInputLocations = "rgb(18,86,232)"
  private readonly colorIncomingLocations = FileParserTtp.locationCircleColor
  private readonly colorLaneLevelLocationPrediction = "rgb(214,63,102)"

  constructor(layerType: LayerType) {
    super(
      [".ttp"],
      layerType,
      FileParserTtp.parsers[layerType]!.name,
      FileParserTtp.parsers[layerType]!.color,
      FileParserTtp.parsers[layerType]!.regexWithLocation
    )
  }

  async parseFile(fileId: string, contents: string, onProgress: (percentage: number) => void): Promise<Feature[]> {
    Logger.log.info(`Parse TTP file: ${fileId}`)
    let features: Feature[] = []

    // Function to add a message as a feature.
    const addMessage = (lineNumber: number, lngLat: LngLat, message: TtpMessageBase, color: string) => {
      const metadata = {
        message: message
      }
      const layerType: LayerType =
        message instanceof TtpMessageSensorIncomingLocations
          ? LayerType.TTPLocation
          : message instanceof TtpMessageSensorLocationPrediction
            ? LayerType.TTPFast
            : LayerType.TTPSlow
      const feature = this.createFeature(fileId, lineNumber, lngLat, color, metadata, layerType, message.time)
      const metadataKey = feature.properties?.metadata
      if (metadataKey) {
        const extendedMetadata = {...MetadataStore.retrieve(metadataKey), feature: feature}
        MetadataStore.update(metadataKey, extendedMetadata)
      }
      features.push(feature)
    }

    // Create the TTP parser.
    const parser = new TtpParser()

    // Register callbacks for the messages we want to display.
    parser.registerMessageCallback("SensorMapMatcherResult", (message: TtpMessageSensorMapMatcherResult) =>
      addMessage(
        message.lineNumber,
        {lng: message.longitude, lat: message.latitude},
        message,
        this.colorSensorMapMatcherResult
      )
    )
    parser.registerMessageCallback("SensorLocationPrediction", (message: TtpMessageSensorLocationPrediction) =>
      addMessage(
        message.lineNumber,
        {lng: message.longitude, lat: message.latitude},
        message,
        this.colorSensorLocationPrediction
      )
    )
    parser.registerMessageCallback("SensorLaneLevelPrediction", (message: TtpMessageSensorLaneLevelPrediction) =>
      addMessage(
        message.lineNumber,
        {lng: message.longitude, lat: message.latitude},
        message,
        this.colorLaneLevelLocationPrediction
      )
    )
    parser.registerMessageCallback("SensorLocationEvent", (message: TtpMessageSensorLocationEvent) =>
      addMessage(
        message.lineNumber,
        {lng: message.longitude, lat: message.latitude},
        message,
        this.colorSensorLocationEvent
      )
    )
    parser.registerMessageCallback(
      "SensorMapMatcherInputLocations",
      (message: TtpMessageSensorMapMatcherInputLocations) =>
        addMessage(
          message.lineNumber,
          {lng: message.longitude, lat: message.latitude},
          message,
          this.colorSensorMapMatcherInputLocations
        )
    )
    parser.registerMessageCallback("SensorIncomingLocations", (message: TtpMessageSensorIncomingLocations) =>
      addMessage(
        message.lineNumber,
        {lng: message.longitude, lat: message.latitude},
        message,
        this.colorIncomingLocations
      )
    )

    // Parse the data file. This executes the callbacks defined above.
    parser.parse(contents, 1, onProgress)

    // Push all messages as features.
    MemoryUtils.addAllElements(features, features)
    return features
  }

  private createFeature(
    fileId: string,
    lineNumber: number,
    lngLat: LngLat,
    color: string,
    metadata: any,
    layerType: LayerType,
    time?: Date
  ): Feature {
    const extendedMetadata = {
      ...metadata,
      file: fileId,
      lineNumber: lineNumber,
      layer: layerType,
      ...(time !== undefined && {time: time.getTime()}),
      // No "sizeInBytes" for TTP messages.
      bounds: BoundingBox.fromLngLat(lngLat)
    }
    const metadataKey = MetadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lngLat.lng, lngLat.lat]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        "circle-radius": 3,
        "circle-color": color
      }
    }
  }
}

export default FileParserTtp
