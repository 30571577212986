/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {isParsedPolygonColor, LayerLineParser, LayerType, ParsedItemColor, ParsedPolygonColor} from "../parserTypes"
import {TileXYZ} from "../../common/utils/geoUtils"
import {Feature} from "geojson"
import LineParser from "./lineParser"
import Logger from "../../common/logger"
import {DateTimeFormat, DateTimeUtils} from "../../common/utils/dateTimeUtils"
import {HttpUtils} from "../../common/utils/httpUtils"
import Wgs84Utils from "../../common/utils/wgs84Utils"

export class LineParserMapVis extends LineParser {
  private static readonly parsers: Partial<Record<LayerType, LayerLineParser>> = {
    [LayerType.MapVis3D]: {
      name: "3D",
      layerType: LayerType.MapVis3D,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(30,142,158)",
        "fill-outline-color": "rgb(13,87,104)"
      },
      regexWithLocation: "/+map-display/+tile/+3d/+[A-Za-z_-]+/+{z}/+{x}/+{y}.(glb|gltf)"
    },
    [LayerType.MapVisBasicMap]: {
      name: "Basic map",
      layerType: LayerType.MapVisBasicMap,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(139,0,253)",
        "fill-outline-color": "rgb(188,88,191)"
      },
      regexWithLocation:
        "(?:" +
        /* variant 1 */ "(?:/+maps/+[A-Za-z_-]+/+map-display/+tile)" +
        /* variant 2 */ "|(?:/+map/+[0-9]+/+tile/+basic)" +
        ")/+{z}/+{x}/+{y}.pbf"
    },
    [LayerType.MapVisFlow]: {
      name: "Flow",
      layerType: LayerType.MapVisFlow,
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(0,253,33)",
        "fill-outline-color": "rgb(86,165,126)"
      },
      regexWithLocation: "/+traffic/+tile/+flow/+{z}/+{x}/+{y}.pbf"
    },
    [LayerType.MapVisHillshade]: {
      name: "Hillshade",
      layerType: LayerType.MapVisHillshade,
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(4,83,65)",
        "fill-outline-color": "rgb(6,106,76)"
      },
      regexWithLocation:
        "(?:" +
        /* variant 1 */ "(?:/+map-display/+tile/+hillshade)" +
        /* variant 2 */ "|(?:/+map/+[0-9]+/+tile/+hill/+main)" +
        ")/+{z}/+{x}/+{y}.(png|jpe?g)"
    },
    [LayerType.MapVisIncidents]: {
      name: "Incidents",
      layerType: LayerType.MapVisIncidents,
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(0,90,255)",
        "fill-outline-color": "rgb(13,67,166)"
      },
      regexWithLocation:
        "(?:" +
        /* variant 1 */ "(?:/+traffic/+tile/+incidents)" +
        /* variant 2 */ "|(?:/+traffic/+map/+[0-9]+/+tile/+incidents)" +
        ")/+{z}/+{x}/+{y}.pbf"
    },
    [LayerType.MapVisSatellite]: {
      name: "Satellite",
      layerType: LayerType.MapVisSatellite,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(191,113,71)",
        "fill-outline-color": "rgb(234,98,0)"
      },
      regexWithLocation: "/+map-display/+tile/+satellite/+{z}/+{x}/+{y}[.]((?:png)|(?:jpe?g))"
    },
    [LayerType.MapVisStyle]: {
      name: "Style/assets",
      layerType: LayerType.MapVisStyle,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(20,110,83)",
        "fill-outline-color": "rgb(109,205,125)"
      },
      regexWithoutLocation:
        /* variant 1 */ "(/+style/+[0-9]+/+[A-Za-z_-]+/+)" +
        /* variant 2 */ "|(/+maps/+[A-Za-z_-]+/+assets/+)" +
        /* variant 3 */ "|(/+map-display/+non-tile/+)"
    }
  }

  constructor(layerType: LayerType) {
    super(
      layerType,
      LineParserMapVis.parsers[layerType]!.name,
      LineParserMapVis.parsers[layerType]!.color,
      LineParserMapVis.parsers[layerType]!.regexWithLocation,
      LineParserMapVis.parsers[layerType]!.regexWithoutLocation
    )
  }

  parseLine(fileId: string, lineNumber: number, line: string): Feature[] {
    line = this.decodeIfLineIsURI(line)
    let features: Feature[] = []
    const time = this.getDateTimeFromAnyString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const usesCdn = LineParser.isLineCdnUrl(line)
    const metadata = {
      file: fileId,
      lineNumber: lineNumber,
      line: line,
      ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
      ...(usesCdn && {usesCdn: true})
    }
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation
        .replace("{x}", "([0-9.]+)")
        .replace("{y}", "([0-9.]+)")
        .replace("{z}", "([0-9.]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const indexes = {
          x: this.regexWithLocation.indexOf("{x}"),
          y: this.regexWithLocation.indexOf("{y}"),
          z: this.regexWithLocation.indexOf("{z}")
        }
        const tile: TileXYZ = {x: 0, y: 0, z: 0}
        const sortedKeys = Object.keys(indexes).sort(
          (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
        )
        sortedKeys.forEach((key, i) => {
          const value = parseInt(match[i + 1])
          switch (key) {
            case "x":
            case "y":
            case "z":
              tile[key] = value
              break
          }
        })
        const metadataWithTile = {
          ...metadata,
          tileX: tile.x,
          tileY: tile.y,
          tileLevel: tile.z
        }
        const polygon = this.createFeatureFromMapVisTile(tile, this.color, metadataWithTile, time, sizeInBytes)
        features.push(polygon)
        from = from + match.index + match[0].length
      }
    }
    if (features.length === 0 && this.regexWithoutLocation) {
      const match = RegExp(new RegExp(this.regexWithoutLocation)).exec(line)
      if (match) {
        const featureWithoutTile = this.createFeatureWithSizeWithoutCoordinates(metadata, time, sizeInBytes)
        features.push(featureWithoutTile)
      }
    }
    return features
  }

  private createFeatureFromMapVisTile(
    tile: TileXYZ,
    color: ParsedItemColor,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature {
    if (metadata.httpStatusCode && !HttpUtils.isOk(metadata.httpStatusCode) && isParsedPolygonColor(color)) {
      color = this.modifyPolygonColorToErrorState(
        color as ParsedPolygonColor,
        HttpUtils.isError(metadata.httpStatusCode)
      )
    }
    const resolution = 1 << tile.z
    const leftLon = Wgs84Utils.mercatorToLongitude(tile.x / resolution)
    const rightLon = Wgs84Utils.mercatorToLongitude((tile.x + 1) / resolution)
    const topLat = Wgs84Utils.mercatorToLatitude(tile.y / resolution)
    const bottomLat = Wgs84Utils.mercatorToLatitude((tile.y + 1) / resolution)
    if (
      !Wgs84Utils.isValidLat(topLat) ||
      !Wgs84Utils.isValidLat(bottomLat) ||
      !Wgs84Utils.isValidLng(leftLon) ||
      !Wgs84Utils.isValidLng(rightLon)
    ) {
      Logger.log.error(
        `Invalid lat/lon: ${time ? DateTimeUtils.formatDateWithTime(new Date(time), DateTimeFormat.UTCTime) : ""}, (${leftLon}, ${bottomLat}), (${rightLon}, ${topLat})`
      )
      return this.createFeatureWithSizeWithoutCoordinates(metadata, time, sizeInBytes)
    }
    const southWest = {lng: leftLon, lat: bottomLat}
    const northEast = {lng: rightLon, lat: topLat}
    return this.createFeatureFromRectangle(southWest, northEast, color, metadata, time, sizeInBytes)
  }
}

export default LineParserMapVis
