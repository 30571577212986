/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import TtpMessageParseException from "./ttpMessageParseException"

export enum TtpMessageType {
  Header = "Header",
  Footer = "Footer",
  Comment = "Comment",
  Parameter = "Parameter",
  PositioningXML = "PositioningXML",
  SensorConfiguration = "SensorConfiguration",
  SensorMapMatcherResult = "SensorMapMatcherResult",
  SensorLocationPrediction = "SensorLocationPrediction",
  SensorLaneLevelPrediction = "SensorLaneLevelPrediction",
  SensorLocationEvent = "SensorLocationEvent",
  SensorMapMatcherInputLocations = "SensorMapMatcherInputLocations",
  SensorIncomingLocations = "SensorIncomingLocations",
  SensorUnknown = "SensorUnknown",
  ParseError = "ParseError"
}

export enum TtpMessageId {
  MapMatcherResult = 130,
  LocationPrediction = 136,
  LocationEvent = 138,
  LaneLevelPrediction = 140,
  MapMatcherInputLocations = 237,
  IncomingLocations = 245
}

export function parseNumberMandatory(input: string): number {
  const value = parseNumberOptional(input)
  if (value === undefined) {
    throw new TtpMessageParseException(`Invalid number (was empty)`)
  }
  return value
}

export function parseNumberOptional(input: string): number | undefined {
  // Empty is allowed and produced 'undefined'.
  input = input.trim()
  if (input.length === 0) {
    return undefined
  }

  // Otherwise it needs to be a proper number.
  const match = RegExp(/^[-+]?\d*(?:\.\d+)?.*$/).exec(input)
  if (!match || match.length < 1) {
    throw new TtpMessageParseException(`Invalid number (no digits found)`)
  }
  const number = Number(match[0])
  if (number === undefined || isNaN(number)) {
    throw new TtpMessageParseException(`Invalid number (cannot convert to number)`)
  }
  return number
}

export function parseKeyValues(input: string[]): Record<string, string> {
  return input
    .map((pair) => {
      const parts = splitAndTrim(pair, "=")
      if (parts.length !== 2) {
        throw new TtpMessageParseException(`Invalid key/value pairs: ${pair}`)
      }
      return {key: parts[0], value: parts[1]}
    })
    .reduce((acc: Record<string, string>, {key, value}) => {
      acc[key] = value
      return acc
    }, {})
}

export function splitAndTrim(input: string, delimiter: string): string[] {
  return input.split(delimiter).map(trimAllWhitespace)
}

export function trimAllWhitespace(input: string): string {
  return input
    .trim()
    .replace(/\s*=\s*/g, "=")
    .replace(/\s*,\s*/g, ",")
}
