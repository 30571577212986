/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Logger from "../common/logger"

export class BugReport {
  public static logBugReport() {
    Logger.log.warning("If this problem persists, please issue a bug report")
    Logger.log.warning("or change request for the visualizer here:")
    Logger.log.warning("https://github.com/tomtom-internal/map-data-visualizer/issues")
  }
}
