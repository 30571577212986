/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {APPLICATION_VERSION} from "../common/version"
import HtmlWindow from "./htmlWindow"
import FileUtils from "../common/utils/fileUtils"

export class HelpWindow extends HtmlWindow {
  constructor(title: string) {
    super(title, "help")
  }

  async loadContent() {
    FileUtils.loadMarkdownFileAsync(
      this.htmlWindowContentElement.id,
      "./markdown/help.md",
      `# Help for Map Data Visualizer ${APPLICATION_VERSION}\n`
    ).then(() => {})
  }
}

export default HelpWindow
