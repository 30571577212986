/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import maplibregl, {
  GeoJSONSourceSpecification,
  MapGeoJSONFeature,
  MapLibreEvent,
  MapMouseEvent,
  MapOptions,
  Point as MapLibrePoint,
  RasterDEMSourceSpecification,
  RasterSourceSpecification,
  RasterTileSource,
  StyleSpecification,
  VectorSourceSpecification
} from "maplibre-gl"
import MapDataOverlay from "./mapDataOverlay"
import {BoundingBox} from "../common/geo"
import {requestJsonFile} from "../common/files"
import {LayerSpecification, SourceSpecification} from "@maplibre/maplibre-gl-style-spec"
import {FeatureCollection} from "geojson"
import {LayerWithoutId} from "../common/mapLibreLayer"
import DataSelectorTool from "../tools/dataSelectorTool"
import DistanceCalculatorTool from "../tools/distanceCalculatorTool"
import InvalidApiKey from "../exceptions/invalidApiKey"
import {LngLat} from "../common/wgs84"
import {GenericMenuItem} from "./menuTypes"
import LogWindow from "./logWindow"
import assert from "../common/assert"
import {limitValue} from "../common/objects"
import {getJsonValue} from "../common/json"

type MapSourceElement = {
  name: string
  content: SourceSpecification
}
type MapLayerGroupElement = {
  beforeLayerId?: string
  layers: LayerSpecification[]
}

type MapSourceDefinition = VectorSourceSpecification | RasterSourceSpecification | RasterDEMSourceSpecification
type MapDefinition = MapSourceDefinition & {
  name: string
  style: string
  styleLayers: LayerSpecification[]
  styleLayerType: "raster" | "hillshade"
  tileSize?: number
  hideFromMenu?: boolean
  autoShowGrid?: boolean
}
type MapDefinitions = {[id: string]: MapDefinition}
type MapDefinitionFromConfigurationFile = MapDefinition & {hideFromMenu: boolean}
type MapDefinitionsFromConfigurationFile = {[id: string]: MapDefinitionFromConfigurationFile}

export class MapView {
  private readonly logWindow: LogWindow
  private readonly apiKey: string
  private readonly zoomLevelMin = 0
  private readonly zoomLevelMax = 23
  private readonly skipOverlaysInQueries: Record<string, boolean> = {}
  private readonly referenceLayerMap = "ref_map"
  private readonly referenceLayerLayers = "ref_layers"
  private readonly referenceLayerTools = "ref_tools"
  private readonly placeholderApiKey = "{tomtom_key}"
  private readonly configurationFileName = "./config/maps.json"

  private renderer: maplibregl.Map
  private mapDefinitions: MapDefinitions = {}
  private currentMapId: string | undefined
  private currentLayerIds: string[] = []
  private featureCollectionPerTool: Record<string, FeatureCollection> = {}

  private readonly saved: {
    sources: MapSourceElement[]
    layerGroups: MapLayerGroupElement[]
  } = {
    sources: [],
    layerGroups: []
  }
  private nextLayerId = 0
  private mapLoaded = false

  constructor(
    containerId: string,
    logWindow: LogWindow,
    apiKey: string,
    onClick: (location: LngLat, point: MapLibrePoint) => void
  ) {
    this.logWindow = logWindow
    this.apiKey = apiKey
    this.renderer = new maplibregl.Map({container: containerId}) // Use a default.

    // Load the maps.
    requestJsonFile(
      this.configurationFileName,
      (json) => {
        // Add map definitions.
        const mapDefinitions = getJsonValue(json, "mapDefinitions") as MapDefinitionsFromConfigurationFile
        Object.entries(mapDefinitions).forEach(([key, value]) => this.addMapDefinitionAndSource(key, value))
        this.selectMap(this.defaultMap())

        let mapOptions = getJsonValue(json, "mapOptions") as MapOptions
        const style = mapOptions.style as StyleSpecification
        style.glyphs = this.replaceApiKey(style.glyphs ?? "")
        style.sprite = this.replaceApiKey((style.sprite as string) ?? "")

        // Initialize renderer.
        this.renderer = new maplibregl.Map({
          ...mapOptions,
          container: containerId
        })

        // Make sure the map is initialized after loading.
        this.renderer.on("load", (mapLibreEvent: MapLibreEvent) => {
          this.onLoad(mapLibreEvent)
        })

        // Make sure we stay within zoom range.
        this.renderer.on("zoomend", () => {
          const min = this.mapDefinitions[this.currentMapId!].minzoom ?? 0
          const max = this.mapDefinitions[this.currentMapId!].maxzoom ?? 24
          const newZoom = limitValue(this.renderer.getZoom(), min, max)
          if (newZoom !== this.renderer.getZoom()) {
            this.renderer.setZoom(limitValue(this.renderer.getZoom(), min, max))
          }
        })
      },
      () => {
        this.logWindow.error(`Cannot load maps file (not authorized): ${this.configurationFileName}`)
      },
      (httpStatus) =>
        this.logWindow.error(`Cannot load maps file: ${this.configurationFileName}, HTTP status: ${httpStatus}`)
    )

    // Set up the mouse click handler.
    this.setOnClickListener((mapMouseEvent: MapMouseEvent) => onClick(mapMouseEvent.lngLat, mapMouseEvent.point))
  }

  /**
   * Lambda function to return features on a clicked map point (if any).
   * The following features should be filtered:
   * - generated features, such as the extent box
   * - features created in non data-canvas overlays
   * - features with the same metadata (these can occur as MapLibre partitions the data and sometimes splits features)
   *
   * Important: this must be a lambda, not a function, to avoid binding issues.
   * @param point Point on screen map.
   */
  queryFeatures = (point: MapLibrePoint): MapGeoJSONFeature[] => {
    return this.renderer
      .queryRenderedFeatures(point)
      .filter((feature) => !feature.properties?.generated && !this.skipOverlaysInQueries[feature.source])
      .filter(
        (feature, index, self) =>
          !feature.properties?.metadata ||
          index === self.findIndex((other) => other.properties?.metadata === feature.properties?.metadata)
      )
  }

  /**
   * Lambda function to return the URL of a tile (if any).
   * Important: this must be a lambda, not a function, to avoid binding issues.
   * @param point Point on screen map.
   * @returns URL of the tile.
   */
  queryTileUrl = (point: MapLibrePoint): URL | undefined => {
    const features = this.renderer.queryRenderedFeatures(point)
    for (const feature of features) {
      const source = this.renderer.getSource(feature.source)
      if (
        feature._vectorTileFeature &&
        source &&
        source.type === "vector" &&
        "tiles" in source &&
        Array.isArray(source.tiles)
      ) {
        const pos = features[0]._vectorTileFeature
        if ("_z" in pos && "_x" in pos && "_y" in pos) {
          return new URL(source.tiles[0].replace("{z}", pos._z).replace("{x}", pos._x).replace("{y}", pos._y))
        }
      }
    }
    return undefined
  }

  showTileGrid = () => this.renderer.showTileBoundaries

  toggleShowTileGrid() {
    this.renderer.showTileBoundaries = !this.renderer.showTileBoundaries
    this.logWindow.info(`Show tile grid in map --> ${this.renderer.showTileBoundaries ? "on" : "off"}`)
  }

  getMapsMenu(): GenericMenuItem[] {
    return Object.entries(this.mapDefinitions)
      .filter(([_, value]) => !value.hideFromMenu)
      .map(([key]) => {
        return {
          id: key,
          name: this.mapDefinitions[key].name,
          action: () => this.selectMap(key)
        }
      })
  }

  defaultMap() {
    const maps = this.getMapsMenu()
    return maps.length ? this.getMapsMenu()[0].id! : ""
  }

  /**
   * Select a map. Needs to ne a lambda, not a regular function, to avoid binding issues.
   * @param name Map to select.
   */
  selectMap = (name: string) => {
    if (!(name in this.mapDefinitions) || this.currentMapId === name) {
      return
    }
    this.currentMapId = name
    if (this.mapLoaded) {
      this.loadMap(this.currentMapId)
      if (this.renderer.getZoom() < (this.mapDefinitions[name].minzoom ?? this.zoomLevelMin)) {
        this.renderer.setZoom(this.mapDefinitions[name].minzoom ?? this.zoomLevelMin)
      } else if (this.renderer.getZoom() > (this.mapDefinitions[name].maxzoom ?? this.zoomLevelMax)) {
        this.renderer.setZoom(this.mapDefinitions[name].maxzoom ?? this.zoomLevelMax)
      }
      this.renderer.showTileBoundaries = this.mapDefinitions[name].autoShowGrid ?? false
    }
  }

  addMapDefinitionAndSource(mapId: string, config: MapDefinitionFromConfigurationFile) {
    // Do not select features from the maps.
    this.skipOverlaysInQueries[mapId] = true

    // Fix fields.
    if (!config.minzoom) {
      config.minzoom = this.zoomLevelMin
    }
    if (!config.maxzoom) {
      config.maxzoom = this.zoomLevelMax
    }
    if (config.url) {
      config.url = this.replaceApiKey(config.url)
    }
    if (config.style) {
      config.style = this.replaceApiKey(config.style)
      config.styleLayers = [] // These will be loaded during loadMap.
    } else if (config.type === "raster" || config.type === "raster-dem") {
      config.styleLayers = [
        {
          id: mapId,
          source: mapId,
          type: config.styleLayerType,
          minzoom: this.zoomLevelMin,
          maxzoom: this.zoomLevelMax
        }
      ]
    }

    this.mapDefinitions[mapId] = config
    const source: MapSourceDefinition = {
      type: config.type,
      tiles: [config.url!]
    }
    source.maxzoom = config.maxzoom
    source.minzoom = config.minzoom
    if (config.tileSize) {
      ;(source as RasterTileSource).tileSize = config.tileSize
    }
    this.safeAddSource(mapId, source)
  }

  zoomToBounds(bounds: BoundingBox) {
    if (bounds.isEmpty()) {
      return
    }
    // Zoom a little bit out.
    bounds = bounds.extendWithLngLatDelta({lng: 0.1, lat: 0.1})
    this.renderer.fitBounds([
      [bounds.southWest.lng, bounds.southWest.lat],
      [bounds.northEast.lng, bounds.northEast.lat]
    ])
  }

  zoomToLevel(level: number) {
    this.renderer.zoomTo(level)
  }

  zoomIn() {
    this.renderer.zoomIn()
  }

  zoomOut() {
    this.renderer.zoomOut()
  }

  goToTile(z: any, x?: any, y?: any) {
    if (x === undefined || y === undefined) {
      const values = z.split(/\D/)
      z = parseFloat(values[0])
      x = parseFloat(values[1])
      y = parseFloat(values[2])
    }
    const resolution = Math.pow(2, z)
    const tileWidthDegree = 360 / resolution
    const minLng = -180 + x * tileWidthDegree
    const maxLng = minLng + tileWidthDegree
    const minY = (y + 1) / resolution
    const maxY = y / resolution
    const minLat = ((2.0 * Math.atan(Math.exp(Math.PI - 2.0 * Math.PI * minY)) - Math.PI / 2.0) / Math.PI) * 180
    const maxLat = ((2.0 * Math.atan(Math.exp(Math.PI - 2.0 * Math.PI * maxY)) - Math.PI / 2.0) / Math.PI) * 180
    const bounds = new BoundingBox({lng: minLng, lat: minLat}, {lng: maxLng, lat: maxLat})
    this.renderer.fitBounds([bounds.southWest.lng, bounds.southWest.lat, bounds.northEast.lng, bounds.northEast.lat])
  }

  createOverlayForTool(toolId: string, layers: LayerWithoutId[]) {
    const mapDataOverlay = this.createMapDataOverlay(toolId)
    layers.forEach((layer) => mapDataOverlay.addLayerWithoutId(layer))
    this.skipOverlaysInQueries[mapDataOverlay.source] = [DataSelectorTool.ID, DistanceCalculatorTool.ID].includes(
      toolId
    )
    return mapDataOverlay
  }

  private setOnClickListener(action: (e: MapMouseEvent) => void) {
    this.renderer.on("click", (e: MapMouseEvent) => action(e))
  }

  private replaceApiKey(str: string) {
    return str.replace(this.placeholderApiKey, this.apiKey)
  }

  private createMapDataOverlay(id: string) {
    this.safeAddSource(id, this.createEmptyGeoJsonSource())
    return new MapDataOverlay(
      id,
      this.referenceLayerTools,
      (layer: any, beforeLayerId: string) => this.safeAddLayer(layer, beforeLayerId),
      (id: string) => this.renderer.removeLayer(id),
      (featureCollection: FeatureCollection) => this.setSourceFeatureCollection(id, featureCollection)
    )
  }

  private setSourceFeatureCollection(overlayId: string, featureCollection: FeatureCollection) {
    this.featureCollectionPerTool[overlayId] = featureCollection
    const source = this.renderer.getSource(overlayId) as {setData: (data: FeatureCollection) => void} | undefined
    assert(
      source && "setData" in source && typeof source.setData === "function",
      `Function setData missing for map overlay: ${overlayId}`
    )
    source!.setData(featureCollection)
  }

  private convertStyleLayers(layers: LayerSpecification[], mapName: string) {
    const result: any[] = []
    for (const layer of layers) {
      const layerWithSource = {...layer, source: mapName}
      result.push(layerWithSource)
    }
    return result
  }

  private createEmptyGeoJsonSource(): GeoJSONSourceSpecification {
    return {
      type: "geojson",
      attribution: "TomTom",
      cluster: false,
      maxzoom: this.zoomLevelMax,
      data: {
        type: "FeatureCollection",
        features: []
      }
    }
  }

  private safeAddSource(name: string, content: SourceSpecification) {
    if (this.mapLoaded) {
      this.renderer.addSource(name, content)
    } else {
      const elm: MapSourceElement = {
        name: name,
        content: content
      }
      this.saved.sources.push(elm)
    }
  }

  private safeAddLayer(layer: LayerSpecification, beforeLayerId?: string): string {
    layer = {...layer, id: this.nextLayerId.toString()}
    if (layer.type === "raster") {
      if (layer.source.startsWith("hillshade")) {
        layer.source = "hillshade"
      } else {
        layer.source = "satellite"
      }
    } else if (layer.type === "hillshade") {
      layer.source = "hillshade-dem"
    }
    this.nextLayerId += 1
    if (this.mapLoaded) {
      this.renderer.addLayer(layer, beforeLayerId)
    } else {
      const elm: MapLayerGroupElement = {
        beforeLayerId: beforeLayerId,
        layers: [layer]
      }
      this.saved.layerGroups.push(elm)
    }
    return layer.id
  }

  private useLayersAsMap(name: string | undefined, layers: any) {
    if (!name || name !== this.currentMapId) {
      return
    }
    this.currentLayerIds.forEach((element) => this.renderer.removeLayer(element))
    this.currentLayerIds = layers.map((layer: any) => this.safeAddLayer(layer, this.referenceLayerMap))
  }

  private loadMap(mapId: string) {
    const map = this.mapDefinitions[mapId]
    if (!map.style) {
      this.useLayersAsMap(mapId, map.styleLayers)
      return
    }
    requestJsonFile(
      map.style,
      (json) => {
        map.styleLayers = this.convertStyleLayers(json.layers, mapId)
        this.useLayersAsMap(mapId, map.styleLayers)
      },
      () => {
        this.logWindow.error(`Authorization problem loading map style file for map ${mapId}; clearing API keys.`)
        throw new InvalidApiKey()
      },
      () => this.logWindow.error(`Cannot load map style ${map.style} for map ${mapId}`)
    )
  }

  private onLoad(_: MapLibreEvent) {
    this.mapLoaded = true
    this.renderer.getCanvas().style.cursor = "crosshair"

    // Add initial layer.
    this.renderer.addSource("empty", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: []
      }
    })

    // Add saved sources.
    this.saved.sources.forEach((element) => this.renderer.addSource(element.name, element.content))

    // Add reference layers.
    const references = [this.referenceLayerMap, this.referenceLayerLayers, this.referenceLayerTools]
    references.forEach((layer) =>
      this.renderer.addLayer({
        id: layer,
        type: "line",
        source: "empty"
      })
    )

    // Load the map.
    if (this.currentMapId) {
      this.loadMap(this.currentMapId)
    }

    // Add saved layer groups.
    this.saved.layerGroups.forEach((group) =>
      group.layers.map((layer: any) => this.renderer.addLayer(layer, group.beforeLayerId))
    )

    // Clear saved data.
    this.saved.sources = []
    this.saved.layerGroups = []
  }
}

export default MapView
