/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {formatDateWithTime, formatTimeAsDuration, TimeFormat} from "../common/datetime"
import {distanceInMeters, LngLat} from "../common/wgs84"
import {createTimeBasedFilename, requestJsonFile, triggerDownloadFileInBrowser} from "../common/files"
import {BoundingBox, LongitudeLatitude} from "../common/geo"
import {Feature, LineString, Point as GeoJsonPoint, Position} from "geojson"
import {LayerWithoutId} from "../common/mapLibreLayer"
import {Tool} from "./tool"
import {Point as MapLibrePoint} from "maplibre-gl"
import {MetadataStore} from "../common/metadata"
import InvalidApiKey from "../exceptions/invalidApiKey"
import MapView from "../app/mapView"
import InspectorWindow from "../app/inspectorWindow"
import LogWindow from "../app/logWindow"

export class RouteCreatorGpxTool extends Tool {
  static readonly ID = "gpx"
  private readonly apiKey
  private points: Position[] = []

  constructor(
    map: MapView,
    inspectorWindow: InspectorWindow,
    logWindow: LogWindow,
    metadataStore: MetadataStore,
    apiKey: string
  ) {
    super(map, inspectorWindow, logWindow, metadataStore, RouteCreatorGpxTool.ID, "Create/export route as GPX")
    this.apiKey = apiKey
  }

  onClick(location: LngLat, _: MapLibrePoint) {
    this.points.push([location.lng, location.lat])
    this.refresh()
  }

  undo() {
    if (this.points.length > 0) {
      this.points.pop()
      this.refresh()
    }
  }

  clearToolData() {
    this.points = []
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "rgb(255,255,255)",
          "line-width": 6
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "rgb(75,120,203)",
          "line-width": 3
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": 5,
          "circle-color": "rgb(75,120,203)"
        },
        filter: ["in", "$type", "Point"]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": 3,
          "circle-color": "rgb(255,255,255)"
        },
        filter: ["in", "$type", "Point"]
      }
    ]
  }

  refresh() {
    const features: Feature<GeoJsonPoint | LineString>[] = this.points.map((point) => ({
      type: "Feature",
      geometry: {type: "Point", coordinates: point},
      properties: {}
    }))
    this.draw(features)
    if (this.points.length > 1) {
      this.requestCalculateRoute(this.points, (routeResponse) => {
        if (!routeResponse || routeResponse.routes.length < 1) {
          return
        }
        const route = routeResponse.routes[0]
        this.inspectorWindow.show("Calculate route", [route.summary])
        const gpxContents = this.createGpxFromRoute(this.points, routeResponse)
        if (gpxContents) {
          triggerDownloadFileInBrowser(createTimeBasedFilename("route", "gpx"), gpxContents)
          const points = route.legs.flatMap((leg: any) =>
            leg.points.map((point: any) => [point.longitude, point.latitude] as [number, number])
          )
          features.push({
            type: "Feature",
            geometry: {type: "LineString", coordinates: points},
            properties: {}
          })
          this.draw(features)
        }
      })
    }
  }

  createGpxFromRoutingResponse(fileName: string, contents: string): BoundingBox | undefined {
    try {
      const gpxContents = this.createGpxFromRoute(this.points, JSON.parse(contents))
      if (gpxContents) {
        triggerDownloadFileInBrowser(createTimeBasedFilename("route", "gpx"), gpxContents)
      }
    } catch (e) {
      this.logWindow.error(
        `Error parsing JSON as routing response:
${e}
Are you sure this is a TomTom routing API response?`
      )
    }
    return undefined
  }

  private requestCalculateRoute(points: Position[], onResult: (routeResponse: any) => void) {
    let url =
      "https://api.tomtom.com/routing/1/calculateRoute/" +
      points.map((point) => `${point[1]},${point[0]}`).join(":") +
      `/json?key=${this.apiKey}&sectionType=urban`
    requestJsonFile(
      url,
      (routeResponse) => {
        if (routeResponse.error || !("routes" in routeResponse)) {
          this.logWindow.warning(`Error calculating route: ${JSON.stringify(routeResponse)}`)
          return
        }
        if (routeResponse.routes.length <= 0) {
          this.logWindow.info(`No route found: ${JSON.stringify(routeResponse)}`)
          return
        }
        onResult(routeResponse)
      },
      () => {
        this.logWindow.error(`Authorization problem - clearing API keys.`)
        throw new InvalidApiKey()
      },
      () => this.logWindow.error(`Cannot route file: ${url}`)
    )
  }

  private createGpxFromRoute(points: Position[], routeResponse: any) {
    const formatPoint = (pointType: string, point: LongitudeLatitude, indentLevel: number) => {
      return `
${indent(indentLevel)}<${pointType} lon="${point.longitude}" lat="${point.latitude}" />`
    }

    if (routeResponse.routes.length < 1) {
      return undefined
    }
    const dateTime = `${formatDateWithTime(new Date(), TimeFormat.UTCTime)}`
    const indent = (level: number) => "  ".repeat(level)
    const route = routeResponse.routes[0]
    let routeTime = 0
    const gpxHeader = `<?xml version="1.0" encoding="UTF-8"?>
<gpx version="1.1" creator="TomTom" xmlns="https://www.topografix.com/GPX/1/1">
  <metadata>
      <name>${dateTime}, GPX trace with ${points.length} waypoints</name>
      <desc>Generated by TomTom Map Data Visualizer</desc>
      <extensions>
          <lockito:loop>false</lockito:loop>
      </extensions>
  </metadata>
  <rte>
    <name>Trace with ${points.length} waypoints</name>${points
      .map((point, _) =>
        formatPoint(
          "rtept",
          {
            longitude: point[0],
            latitude: point[1]
          },
          2
        )
      )
      .join("")}
  </rte>
  <trk>
    <trkseg>`
    const gpxFooter = `
    </trkseg>
  </trk>
</gpx>`
    let gpxPointsTotal = 0
    let distanceTotal = 0
    const gpxPoints = route.legs
      .flatMap((leg: any) => {
        const speed = leg.summary.lengthInMeters / leg.summary.travelTimeInSeconds
        return leg.points
          .map((point: LongitudeLatitude, pointIndex: number) => {
            ++gpxPointsTotal
            if (pointIndex > 0) {
              const prev = leg.points[pointIndex - 1]
              const start = {lng: prev.longitude, lat: prev.latitude}
              const end = {lng: point.longitude, lat: point.latitude}
              const distance = distanceInMeters(start, end)
              const time = distance / speed
              distanceTotal += distance
              routeTime += time
            }
            return formatPoint("trkpt", point, 3)
          })
          .join("")
      })
      .join("")
    this.logWindow.info(
      `Created GPX route: ${points.length} waypoints, ${gpxPointsTotal} points, ${Math.round(distanceTotal / 1000)} km, ${formatTimeAsDuration(routeTime)}`
    )
    return gpxHeader + gpxPoints + gpxFooter
  }
}

export default RouteCreatorGpxTool
