/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, FeatureCollection} from "geojson"
import {LayerWithoutId} from "../common/mapLibreLayer"

/**
 * This class defines a map overlay that consists of GeoJSON features. It's used
 * to display anything on the map canvas.
 */
export class MapDataOverlay {
  readonly source: string

  private readonly beforeLayerId: string
  private readonly addLayer: (layer: LayerWithoutId, beforeLayerId: string) => string
  private readonly removeLayer: (id: string) => void
  private readonly setSourceFeatureCollection: (featureCollection: FeatureCollection) => void

  private layers: LayerWithoutId[]
  private layerIds: string[]

  constructor(
    source: string,
    beforeLayerId: string,
    addLayer: (layer: any, beforeLayerId: string) => string,
    removeLayer: (id: string) => void,
    setSourceFeatureCollection: (featureCollection: FeatureCollection) => void
  ) {
    this.source = source
    this.beforeLayerId = beforeLayerId
    this.layers = []
    this.layerIds = []
    this.addLayer = addLayer
    this.removeLayer = removeLayer
    this.setSourceFeatureCollection = setSourceFeatureCollection
  }

  setFeatures(features: Feature[]) {
    this.setSourceFeatureCollection({
      type: "FeatureCollection",
      features: features
    })
  }

  replaceAllLayers(newLayers: LayerWithoutId[]) {
    this.layerIds.forEach((layerId) => this.removeLayer(layerId))
    this.layerIds = []
    this.layers = []
    newLayers.forEach((layer) => {
      this.addLayerWithoutId(layer)
    })
  }

  addLayerWithoutId(layer: LayerWithoutId) {
    const layerWithSource = {...layer, source: this.source}
    this.layers.push(layerWithSource)
    this.layerIds.push(this.addLayer(layerWithSource, this.beforeLayerId))
  }
}

export default MapDataOverlay
