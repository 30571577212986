/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, Point, Polygon} from "geojson"
import {BoundingBox, GeoUtils, PointXY} from "../common/utils/geoUtils"
import {isParsedPointColor, LayerType, ParsedItemColor, ParsedPointColor, ParsedPolygonColor} from "./parserTypes"
import {TIMESTAMP_ALWAYS_HIDE} from "../global/dataStore"
import {LogLevel} from "../common/utils/logcatUtils"
import {MetadataStore} from "../global/metadataStore"
import {DateTimeUtils} from "../common/utils/dateTimeUtils"
import {HttpUtils} from "../common/utils/httpUtils"
import {LngLat, Wgs84Utils} from "../common/utils/wgs84Utils"

/**
 * Parser is the base class for all parsers. It contains some utility functions used for parsers.
 */
export abstract class Parser {
  public static readonly SIZE_EXPECTED_BUT_NOT_FOUND = -1 // Return in field `size` if size was expected but not found.
  public static readonly voidCoordinate = [179.84, 84.179] // The `voidCoordinate` coordinate is used for non-shown coordinates.

  readonly name: string
  readonly layerType: LayerType
  color: ParsedItemColor
  regexWithLocation?: string
  regexWithoutLocation?: string

  private readonly regexUrl = /\s*[a-z0-9-]+:\/{2}[^;]*/
  private readonly regexInteger = /\s*[+-]?\s*\d+\s*/
  private readonly regexIsoTimestamp = /\s*\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:Z|(?:[+-](?:\d)+))\s*/

  constructor(
    layerType: LayerType,
    name: string,
    color?: ParsedItemColor,
    regexWithLocation?: string,
    regexWithoutLocation?: string
  ) {
    this.name = name
    this.layerType = layerType
    this.color = color
    this.regexWithLocation = regexWithLocation
    this.regexWithoutLocation = regexWithoutLocation
  }

  /**
   * This function checks if a parse line should be omitted from parsing.
   * @param line The line to check.
   * @param skipComments If true, comments are discarded.
   * @returns True if the line should be omitted, false otherwise.
   */
  static toBeDiscardBeforeParsing(line: string, skipComments = true) {
    const trimmedLine = line.trim()
    if (trimmedLine.length === 0 || (skipComments && trimmedLine.startsWith("#"))) {
      return true
    }
    const discardBeforeParsingRegexes = [
      // This list should contain regexes that match lines that should not be parsed. Make the regexes as specific as
      // possible. These lines may pop-up in logcat files, for example.
      /^[A-Za-z0-9()? ;]{4,}$/,
      /^route_id: .*, route_length: .*, num_arcs: /,
      /^[A-Za-z][A-Za-z0-9]+=[A-Za-z0-9_.@]+$/, // Assignment type of line with "=".
      /^[A-Za-z][A-Za-z0-9 ]+:\s+[A-Za-z0-9 :/_-]+$/, // And with ":".
      /^Route#\d+ summary:$/,
      /^Leg#\d+ summary:$/,
      /^Summary\((\w+=[A-Za-z0-9_.:()[\] -]+,?\s*)+\)$/,
      /\/dih\/\d+\/enc\/session-code/,
      /\/auth\/token(\?.*)?$/,
      /\/dcas\/\d+\/device\/registration/,
      /^TomTom-Api-Version: \d+$/,
      /^Guidance type: regular mappedReason: /
    ]
    return discardBeforeParsingRegexes.reduce((acc, regex) => acc || regex.test(trimmedLine), false)
  }

  /**
   * This function checks is a line is understood, but discarded from the results.
   * @param line The line to check.
   * @returns True if the line should be discarded, false otherwise.
   */
  static toDiscardAfterParsing(line: string) {
    const discardAfterParsingRegexes = [
      // Typical log file line: these should be parsed, but not lead to errors.
      // Normal timestamps of NavSDK.
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}((\+\d{4})|Z) [avdiwe] /,
      // Incomplete timestamps of NavApp.
      /^(\d{4}-)?\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}\s([+-]\d{4} )?\d{1,5} \d{1,5} [AVDIWE] /,
      // Logged lines that overflow to next line:
      /^\s*((Origin)|(Destination))[(]s[)] with points: \d+,\d+[(][0-9,.]+[)]$/,
      /^\s*betterProposalAcceptanceMode=[A-Za-z0-9()=]*$/,
      /^\s*deviationReplanningMode=[A-Za-z0-9()=]*$/,
      // Marker of NavApp for start, crash, etc.
      /^--------- beginning of [a-z]+$/,

      // Discard these URLs.
      /maps\/orbis\/places\/additionalData\.json/
    ]
    return discardAfterParsingRegexes.reduce((acc, regex) => acc || regex.test(line), false)
  }

  /**
   * Get a date/time from a line, in various formats.
   * @param line Input line.
   * @returns Date object or undefined if no date was found.
   */
  protected getDateTimeFromAnyString(line: string) {
    // Try regular formats.
    const dateTime =
      DateTimeUtils.getDateTimeFromISOTimestamp(line) ??
      DateTimeUtils.getDateTimeFromLogcatTimestamp(line) ??
      DateTimeUtils.getDateTimeFromGoLoggerTimestamp(line)
    if (dateTime) {
      return dateTime
    }

    // This matches, for example, Scalyr exported TXT and CSV files.
    const regexTimestamp = /["']timestamp[a-z"': ]*?:.*?([0-9.]+)/
    let match = regexTimestamp.exec(line)
    if (match) {
      const millisOrSeconds = parseFloat(match[1])
      return DateTimeUtils.convertMillisToDateTime(millisOrSeconds)
    }

    // This matches any time in seconds after epoch in a bit more fuzzy way. It assumes a number
    // which can be seconds or milliseconds after epoch. It auto-detects which one it is.
    const regexTimeAsMillisOrSeconds = /time[a-z"': ]*?:.*?([0-9.]+)/
    match = regexTimeAsMillisOrSeconds.exec(line)
    if (match) {
      const millisOrSeconds = parseFloat(match[1])
      return DateTimeUtils.convertMillisToDateTime(millisOrSeconds)
    }
    return undefined
  }

  /**
   * Get the size in bytes from a line, in various formats.
   * @param line Input line.
   * @returns Size in bytes or undefined if no size was found.
   */
  protected getSizeInBytesFromLine(line: string): number | undefined {
    // Format 1: timestamp;httpStatus;responseSizeInBytes;durationInMilliseconds;url <-- old format
    // Format 2: timestamp;httpStatus;responseSizeInBytes;requestSizeInBytes;durationInMilliseconds;url
    const regexHttpLogWithResponseSizeOnly = new RegExp(
      `${this.regexIsoTimestamp.source};${this.regexInteger.source};(${this.regexInteger.source});${this.regexInteger.source};${this.regexUrl.source}`
    )
    let match = regexHttpLogWithResponseSizeOnly.exec(line)
    if (match) {
      const responseSizeInBytes = parseInt(match[1].trim())
      return responseSizeInBytes >= 0 ? responseSizeInBytes : undefined
    }

    const regexHttpLogWithResponseAndRequestSize = new RegExp(
      `${this.regexIsoTimestamp.source};${this.regexInteger.source};(${this.regexInteger.source});(${this.regexInteger.source});${this.regexInteger.source};${this.regexUrl.source}`
    )
    match = regexHttpLogWithResponseAndRequestSize.exec(line)
    if (match) {
      const responseSizeInBytes = parseInt(match[1].trim())
      const requestSizeInBytes = parseInt(match[2].trim())
      return responseSizeInBytes >= 0 || requestSizeInBytes >= 0
        ? Math.max(0, responseSizeInBytes) + Math.max(0, requestSizeInBytes)
        : undefined
    }

    const regexMitmLog = /;(\d+);[^;]*;[^;]*$/
    match = regexMitmLog.exec(line)
    if (match) {
      return parseInt(match[1])
    }

    const regexScalyrLog = /body_bytes_sent[^\d]*(\d+)/
    match = regexScalyrLog.exec(line)
    if (match) {
      return parseInt(match[1])
    }

    const regexOtherSizes = /(?:(?:size(?:InBytes)?)|(?:bytes))\s*[:=]\s*(\d+)/
    match = regexOtherSizes.exec(line)
    if (match) {
      return parseInt(match[1])
    }
    return undefined
  }

  /**
   * Get the HTTP result code from a line, in various formats.
   * @param line Input line.
   * @returns HTTP result code or undefined if no code was found.
   */
  protected getHttpStatusCodeString(line: string) {
    const validCodeOrUndefined = (code: number) => (100 <= code && code <= 599 ? code : undefined)

    const regexHttpLog = new RegExp(`${this.regexIsoTimestamp.source}\\s*;\\s*-?(\\d+)\\s*;`)
    let match = regexHttpLog.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }

    const regexMitmLog = /;\s*(\d+)\s*;[^;]*;[^;]*;[^;]*;[^;]*;[^;]*$/
    match = regexMitmLog.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }

    const regexStatusField = /status[A-Za-z-_]*\s*["']?\s*[:=]\s*['"]?(\d+)/
    match = regexStatusField.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }
    return undefined
  }

  protected createFeatureFromRectangle(
    southWest: LngLat,
    northEast: LngLat,
    color: ParsedItemColor,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature<Polygon> {
    const bounds = new BoundingBox(southWest, northEast)
    const extendedMetadata = {
      ...metadata,
      ...(time && {time: time.getTime()}),
      ...{sizeInBytes: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: this.layerType,
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = MetadataStore.store(extendedMetadata)
    const northWest: LngLat = {lng: southWest.lng, lat: northEast.lat}
    const southEast: LngLat = {lng: northEast.lng, lat: southWest.lat}
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            Wgs84Utils.LngLatToArray(southWest),
            Wgs84Utils.LngLatToArray(northWest),
            Wgs84Utils.LngLatToArray(northEast),
            Wgs84Utils.LngLatToArray(southEast),
            Wgs84Utils.LngLatToArray(southWest)
          ]
        ]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: this.layerType,
        geoHash: bounds.toGeoHash(),
        ...color
      }
    }
  }

  protected createFeatureFromPointXY(
    coordinate: PointXY,
    color: ParsedItemColor,
    metadata: any,
    time?: Date,
    logLevel?: LogLevel
  ): Feature<Point> {
    if (
      ((logLevel && [LogLevel.Warning, LogLevel.Error, LogLevel.Fatal].includes(logLevel)) ||
        (metadata.httpStatusCode && !HttpUtils.isOk(metadata.httpStatusCode))) &&
      isParsedPointColor(color)
    ) {
      color = this.modifyPointColorToErrorState(color as ParsedPointColor)
    }
    const bounds = BoundingBox.fromLngLat(GeoUtils.pointXYToLngLat(coordinate))
    const extendedMetadata = {
      ...metadata,
      layer: this.layerType,
      ...(time && {time: time.getTime()}),
      ...(logLevel && {logLevel: logLevel}),
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = MetadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [coordinate.x, coordinate.y]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: this.layerType,
        geoHash: bounds.toGeoHash(),
        ...color
      }
    }
  }

  protected createFeatureWithSizeWithoutCoordinates(metadata: any, time?: Date, sizeInBytes?: number): Feature {
    const extendedMetadata = {
      ...metadata,
      ...{time: time?.getTime() ?? TIMESTAMP_ALWAYS_HIDE},
      ...{sizeInBytes: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: this.layerType
    }
    const metadataKey = MetadataStore.store(extendedMetadata)
    return this.createFeaturePoint(metadataKey, time)
  }

  protected createFeatureWithLogLevel(metadata: any, time: Date, logLevel?: LogLevel): Feature {
    const extendedMetadata = {
      ...metadata,
      time: time.getTime(),
      layer: this.layerType,
      ...(logLevel && {logLevel: logLevel})
    }
    const metadataKey = MetadataStore.store(extendedMetadata)
    return this.createFeaturePoint(metadataKey, time)
  }

  protected modifyPointColorToErrorState(color: ParsedPointColor): ParsedPointColor {
    const radius = color["circle-radius"] ? color["circle-radius"] + 3 : 6
    return {
      ...color,
      "circle-radius": radius,
      "circle-color": "rgb(255,0,0)",
      text: "!",
      "text-color": "rgb(255,255,255)"
    }
  }

  protected modifyPolygonColorToErrorState(color: ParsedPolygonColor, error: boolean): ParsedPolygonColor {
    if (error) {
      return {
        ...color,
        "fill-opacity": 1,
        "fill-color": "rgb(255,0,0)",
        "fill-outline-color": "rgb(255,0,0)"
      }
    } else {
      return color
    }
  }

  private createFeaturePoint(metadataKey: string, time?: Date): Feature {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: Parser.voidCoordinate
      },
      properties: {
        metadata: metadataKey,
        ...{time: time?.getTime() ?? TIMESTAMP_ALWAYS_HIDE},
        layer: this.layerType,
        generated: true,
        "circle-color": "red",
        "circle-radius": 0 // Hide this point.
      }
    }
  }
}

export default Parser
