/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, Point as GeoJsonPoint} from "geojson"
import {Tool} from "./tool"
import {Point as MapLibrePoint} from "maplibre-gl"
import MapView from "../app/mapView"
import {LayerWithoutId} from "../common/utils/mapLibreUtils"
import {LngLat} from "../common/utils/wgs84Utils"
import RoutingUtils from "../common/utils/routingUtils"
import FileParserJson from "../parsers/file/fileParserJson"
import FileUtils from "../common/utils/fileUtils"
import Storage, {Settings} from "../common/storage"

export class RouteCreatorJsonTool extends Tool {
  private readonly fileParserJsons = new FileParserJson()

  private points: [number, number][] = [] // Clicked points on map.
  private jsonRoutingResponses: string[] = [] // JSON routing responses (last one is currently edited one).

  constructor(map: MapView) {
    super("routeCreatorJsonTool", "Create/export route as JSON", map)
  }

  /**
   * Clicking a point adds 1 point to the current route.
   * @param location The location of the click.
   * @param _ Unused.
   */
  onClick(location: LngLat, _: MapLibrePoint) {
    this.points.push([location.lng, location.lat])
    this.requestRouteAndRefresh()
  }

  onDropFile(contents: string) {}

  select() {
    // reset points, but keep old routes.
    this.points = []
    this.refresh()
  }

  undo() {
    if (this.points.length > 0) {
      this.points.pop()
      if (this.points.length > 1) {
        this.requestRouteAndRefresh()
      } else {
        this.jsonRoutingResponses.pop()
        this.refresh()
      }
    }
  }

  clear() {
    this.points = []
    this.jsonRoutingResponses = []
    this.refresh()
  }

  finishCurrentRoute() {
    this.points = []
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "line", // Outer line, white.
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": 6,
          "line-color": "rgb(255,255,255)"
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(75,120,203)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 3]
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "circle", // Outer circle, white.
        paint: {
          "circle-radius": 5,
          "circle-color": "rgb(255,255,255)"
        },
        filter: ["in", "$type", "Point"]
      },
      {
        type: "circle", // Inner circle.
        paint: {
          "circle-radius": ["case", ["has", "circle-radius"], ["get", "circle-radius"], 3],
          "circle-color": ["case", ["has", "circle-color"], ["get", "circle-color"], "rgb(0,0,0)"]
        },
        filter: ["in", "$type", "Point"]
      },
      {
        type: "symbol",
        layout: {
          "text-field": ["get", "text"],
          "text-font": ["Noto-Bold"],
          "text-size": 10,
          "text-offset": [0, 1],
          "text-anchor": "center",
          "text-rotate": ["get", "text-rotate"]
        },
        paint: {
          "text-color": ["get", "text-color"]
        },
        filter: ["all", ["has", "text"], ["==", ["geometry-type"], "Point"]]
      }
    ]
  }

  async refresh() {
    // Get the (future) features, reparse, so the correct coloring is used.
    const featureFutures: Promise<Feature[]>[] = []
    this.jsonRoutingResponses.forEach((jsonRoutingResponse) => {
      // Use the JSON parser tp re-parse the Routing API JSON response.
      const moreFeatureFutures = this.fileParserJsons.parseFile("", jsonRoutingResponse, () => {})
      featureFutures.push(moreFeatureFutures)
    })
    const features = (await Promise.all(featureFutures)).flatMap((feature) => feature)

    // Add all clicked points to the map.
    const points: Feature<GeoJsonPoint>[] = this.points.map((point) => ({
      type: "Feature",
      geometry: {type: "Point", coordinates: point},
      properties: {
        "circle-radius": 5
      }
    }))
    features.push(...points)
    this.draw(features)
  }

  public requestRouteAndRefresh() {
    if (this.points.length < 2) {
      // If there are no points, add a new route.
      this.jsonRoutingResponses.push(JSON.stringify({}))
      this.refresh()
    } else {
      // If more than 1 point, calculate a route an display its lines.
      RoutingUtils.apiRequestCalculateRouteForCarWithDefaults(
        this.points,
        (jsonRoutingResponse) => {
          // Store the route response.
          const contents = JSON.stringify(jsonRoutingResponse)

          // Replace the last route with the new one.
          this.jsonRoutingResponses[this.jsonRoutingResponses.length - 1] = contents
          this.refresh()

          if (Storage.get(Settings.DownloadRoutingApiResponse)) {
            FileUtils.triggerRateLimitedDownloadFile(FileUtils.createTimeBasedFilename("route", "json"), contents)
          }
        },
        Storage.get(Settings.UseOrbisRoutingApi)
      )
    }
  }
}

export default RouteCreatorJsonTool
