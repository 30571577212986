/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * This is a utility class with static functions only.
 */
export class AsyncUtils {
  /**
   * The yield delay allows the UI to update itself while processing batches of items.
   */
  private static readonly yieldDelayInMillis = 100

  /**
   * Execute a process on a list of items, with progress reporting. Processing is divided into batches.
   *
   * @param items List of items to process.
   * @param processOneItem Function to process an item.
   * @param reportProgressPercentage Function to report progress (takes a percentage between 0 and 1).
   * @param nrOfBatches Number of batches to divide the processing into.
   * @param minBatchSize Minimum number of items in a batch.
   */
  static async processAsync<T>(
    items: T[],
    processOneItem: (item: T, index: number) => Promise<void>,
    reportProgressPercentage: (percentage: number) => void,
    nrOfBatches: number = 10,
    minBatchSize: number = 1000
  ): Promise<void> {
    const batchSize = Math.max(Math.round(items.length / nrOfBatches + 0.5), minBatchSize)
    const totalItems = items.length
    const processOneBatch = async (startIndex: number) => {
      const endIndex = Math.min(startIndex + batchSize, totalItems)
      for (let i = startIndex; i < endIndex; ++i) {
        await processOneItem(items[i], i)
        reportProgressPercentage((i + 1) / totalItems)
      }
      if (endIndex < totalItems) {
        await new Promise((resolve) => setTimeout(resolve, AsyncUtils.yieldDelayInMillis))
        await processOneBatch(endIndex)
      }
    }
    reportProgressPercentage(0)
    await processOneBatch(0)
  }
}

export default AsyncUtils
