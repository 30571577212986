/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Wgs84Utils, {LngLat} from "./utils/wgs84Utils"

export abstract class Tile {
  public readonly level: number
  public readonly x: number
  public readonly y: number
  public readonly northEast: LngLat
  public readonly southWest: LngLat

  /**
   * Create a new tile.
   * @param level The zoom level, >= 0.
   * @param x The x coordinate.
   * @param y The y coordinate.
   * @param northEast The north-east corner of the tile.
   * @param southWest The south-west corner of the tile.
   */
  protected constructor(level: number, x: number, y: number, northEast: LngLat, southWest: LngLat) {
    this.level = level
    this.x = x
    this.y = y
    this.northEast = northEast
    this.southWest = southWest
  }

  public readonly isValid = () =>
    Wgs84Utils.isValidLat(this.northEast.lat) &&
    Wgs84Utils.isValidLat(this.southWest.lat) &&
    Wgs84Utils.isValidLng(this.northEast.lng) &&
    Wgs84Utils.isValidLng(this.southWest.lng)
}

export default Tile
