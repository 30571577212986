/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {MenuItem, separatorMenuItem} from "../../menu/menuTypes"
import {
  HTML_MENU_ITEM_GPX_TIMESTAMPS,
  HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS,
  HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES,
  HTML_MENU_ITEM_SHOW_MOUSE_POSITION,
  HTML_MENU_ITEM_TILE_GRID,
  HTML_MENU_ITEM_USE_LOCAL_TIME,
  HTML_WINDOW_CONSOLE,
  HTML_WINDOW_INSPECTOR,
  HTML_WINDOW_SOURCE
} from "../../html/htmlElementId"
import MenuDefinition from "./menuDefinition"
import App from "../app"
import MapView from "../mapView"
import LayersPanel from "../../panels/layersPanel"
import InspectorWindow from "../../windows/inspectorWindow"
import ConsoleWindow from "../../windows/consoleWindow"
import SourceWindow from "../../windows/sourceWindow"
import {Mutex} from "async-mutex"
import Storage, {Settings} from "../../common/storage"
import MenuBar from "../../menu/menuBar"
import Toolbox from "../../tools/toolbox"
import Timeline from "../timeline"

export class MenuDefinitionView extends MenuDefinition {
  private readonly app: App
  private readonly toolbox: Toolbox
  private readonly timeline: Timeline
  private readonly mapView: MapView
  private readonly layersPanel: LayersPanel
  private readonly inspectorWindow: InspectorWindow
  private readonly consoleWindow: ConsoleWindow
  private readonly sourceWindow: SourceWindow

  constructor(
    app: App,
    toolbox: Toolbox,
    timeline: Timeline,
    mapView: MapView,
    layersPanel: LayersPanel,
    inspectorWindow: InspectorWindow,
    consoleWindow: ConsoleWindow,
    sourceWindow: SourceWindow
  ) {
    super()
    this.app = app
    this.toolbox = toolbox
    this.timeline = timeline
    this.mapView = mapView
    this.layersPanel = layersPanel
    this.inspectorWindow = inspectorWindow
    this.consoleWindow = consoleWindow
    this.sourceWindow = sourceWindow
  }

  public getMenuItems(): MenuItem[] {
    const viewMenuItems: MenuItem[] = [
      {
        key: "Backspace",
        shortcut: "Backspace",
        name: "Undo last point",
        action: () => this.app.undo()
      },
      {
        key: "Escape",
        shortcut: "Esc",
        name: "Reset action",
        action: () => this.app.resetToolsAndKeepData()
      },
      {
        shortcut: "C",
        name: "Clear map",
        action: () => this.app.clearMap()
      },
      {
        key: "",
        shortcut: "+",
        name: "Zoom in (use Shift+ for more)",
        action: () => this.mapView.zoomIn()
      },
      {
        key: "",
        shortcut: "-",
        name: "Zoom out (use Shift- for more)",
        action: () => this.mapView.zoomOut()
      }
    ]

    const toggleMenuItems: MenuItem[] = [
      {
        name: "Route API: show average speeds",
        id: "route-show-section-speeds",
        action: () => this.setOptionRouteShowSectionTypes(false)
      },
      {
        name: "Route API: show road section types",
        id: "route-show-section-types",
        action: () => this.setOptionRouteShowSectionTypes(true)
      },
      separatorMenuItem,
      {
        shortcut: "Z",
        name: "Auto-zoom to extents of data",
        id: "auto-zoom",
        action: () => this.layersPanel.toggleAutoZoom()
      },
      {
        shortcut: "S",
        name: "Auto-show source lines",
        id: "auto-show-source",
        action: () => this.layersPanel.toggleAutoShowSource()
      },
      {
        name: "Show mouse position",
        id: "show-mouse-position",
        action: () => this.toggleOptionShowMousePosition()
      },
      {
        key: "\\",
        shortcut: "\\",
        name: "Toggle all layers on/off",
        id: "toggle-all-layers",
        action: () => this.layersPanel.toggleAllLayers()
      },
      {
        name: "Show MapVis tile grid",
        id: "tile-grid",
        action: () => this.toggleShowTileGrid()
      },
      {
        name: "Write timestamps to GPX files",
        id: "gpx-timestamps",
        action: () => this.toggleOptionGpxTimestamps()
      },
      {
        name: "Use UTC (instead of local time)",
        id: "use-local-time",
        action: () => this.toggleOptionUseLocalTime()
      }
    ]

    const windowMenuItems: MenuItem[] = [
      {
        key: "KeyM",
        shortcut: "M",
        name: "Show inspector window...",
        id: HTML_WINDOW_INSPECTOR,
        action: () => this.inspectorWindow.setVisible(!this.inspectorWindow.isVisible())
      },
      {
        key: "KeyO",
        shortcut: "O",
        name: "Show console window...",
        id: HTML_WINDOW_CONSOLE,
        action: () => this.consoleWindow.setVisible(!this.consoleWindow.isVisible())
      },
      {
        key: "KeyU",
        shortcut: "U",
        name: "Show source window...",
        id: HTML_WINDOW_SOURCE,
        action: () => this.sourceWindow.setVisible(!this.sourceWindow.isVisible())
      }
    ]
    return [viewMenuItems, separatorMenuItem, toggleMenuItems, separatorMenuItem, windowMenuItems].flat()
  }

  private readonly mutexShowSectionTypes = new Mutex()
  private readonly setOptionRouteShowSectionTypes = (showSectionTypes: boolean) => {
    Storage.set(Settings.ShowRouteSectionTypes, showSectionTypes)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS, !showSectionTypes)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES, showSectionTypes)
    // Refresh routes, but make sure we don't re-enter the critical section.
    this.mutexShowSectionTypes.runExclusive(() => {
      this.toolbox.tools.routeCreatorJsonTool.requestRouteAndRefresh()
    })
  }

  private readonly toggleOptionShowMousePosition = () => {
    Storage.toggle(Settings.ShowMousePosition)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SHOW_MOUSE_POSITION, Storage.get(Settings.ShowMousePosition))
    this.timeline.draw()
  }

  private readonly toggleShowTileGrid = () => {
    this.mapView.toggleShowTileGrid()
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_TILE_GRID, this.mapView.showTileGrid())
  }

  private readonly toggleOptionGpxTimestamps = () => {
    Storage.toggle(Settings.AddGpxTimestamps)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_GPX_TIMESTAMPS, Storage.get(Settings.AddGpxTimestamps))
  }

  public readonly toggleOptionUseLocalTime = () => {
    Storage.toggle(Settings.UseTimeFormatUTC)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_LOCAL_TIME, !Storage.get(Settings.UseTimeFormatUTC))
    this.app.draw(true)
  }
}

export default MenuDefinitionView
