/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Html} from "./html/html"
import App from "./app/app"
import Storage from "./common/storage"

/**
 * Toggle visibility of app elements, used to clear the screen for the API key dialog.
 */
function setAppElementsVisibility(show: boolean) {
  ;[
    "version-marker",
    "bug-report",
    "toolbox",
    "panel-filters",
    "panel-layers",
    "panel-data-files",
    "timeline",
    "timeline-controls"
  ].forEach((id) => {
    const elm = Html.getDefinedHtmlElementById(id)
    elm.style.display = show ? "block" : "none"
  })
}

async function startApp() {
  // Show all UI relevant elements.
  setAppElementsVisibility(true)

  // Create app and wait for all content files to be loaded.
  let app = new App()
  await app.initialize()
}

// Pop-up the API key entry dialog if needed.
if (Storage.getTomTomApiKey().length === 0) {
  // Re-initialize the persistent storage to clear all settings.
  Storage.clear()
  Storage.initialize()

  // Ask for API key ans start app.
  setAppElementsVisibility(false)
  Html.getDefinedHtmlElementById("api-key-form").style.display = "block"
  Html.getDefinedHtmlElementById("keys-form").addEventListener("submit", (submitEvent: SubmitEvent) => {
    submitEvent.preventDefault()
    const apiKeyTomTom = (Html.getDefinedHtmlElementById("api-key-tomtom") as HTMLInputElement).value.trim()
    Storage.setTomTomApiKey(apiKeyTomTom)
    Html.getDefinedHtmlElementById("api-key-form").style.display = "none"
    startApp()
  })
} else {
  // API key is there; start app.
  startApp()
}
