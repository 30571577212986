/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LngLat, mapToLon} from "./wgs84"
import GeoHash from "./geoHash"
import assert from "./assert"

// Some functions require a different format for the LngLat type:
export type LongitudeLatitude = {
  longitude: number
  latitude: number
}

export type PointXY = {
  x: number
  y: number
}

export type TileXY = {
  x: number
  y: number
}

export type TileXYZ = {
  x: number
  y: number
  z: number
}

export function pointXYToLngLat(point: PointXY): LngLat {
  return {lng: point.x, lat: point.y}
}

export class BoundingBox {
  readonly southWest: LngLat
  readonly northEast: LngLat

  constructor(min: LngLat, max: LngLat) {
    this.southWest = min
    this.northEast = max
  }

  /**
   * Create a bounding box from a single point
   * @param lngLat The point.
   * @returns A bounding box with the point as both the south-west and north-east corner.
   */
  static fromLngLat(lngLat: LngLat): BoundingBox {
    return new BoundingBox(lngLat, lngLat)
  }

  /**
   * Create an empty bounding box. Can be used as a starting point when extending a bounding box to
   * include other boxes or points.
   * @returns An empty bounding box.
   */
  static empty(): BoundingBox {
    return new BoundingBox({lng: 180.0, lat: 90.0}, {lng: -180.0, lat: -90.0})
  }

  /**
   * Check if the bounding box is empty.
   * @returns True if the bounding box is empty.
   */
  isEmpty() {
    return this.southWest.lng > this.northEast.lng || this.southWest.lat > this.northEast.lat
  }

  /**
   * Extend the bounding box to include another bounding box.
   * @param other The other box to include.
   * @returns A new bounding box that includes the box.
   */
  extendWithOther(other: BoundingBox | undefined): BoundingBox {
    if (!other) {
      return this
    }
    return new BoundingBox(
      {lng: Math.min(this.southWest.lng, other.southWest.lng), lat: Math.min(this.southWest.lat, other.southWest.lat)},
      {lng: Math.max(this.northEast.lng, other.northEast.lng), lat: Math.max(this.northEast.lat, other.northEast.lat)}
    )
  }

  /**
   * Check if two bounding boxes are equal.
   * @param other The other bounding box to compare with.
   * @returns True if the bounding boxes are equal.
   */
  equals(other: BoundingBox): boolean {
    return (
      this.southWest.lng === other.southWest.lng &&
      this.southWest.lat === other.southWest.lat &&
      this.northEast.lng === other.northEast.lng &&
      this.northEast.lat === other.northEast.lat
    )
  }

  /**
   * Extend the bounding box to include a point.
   * @param lngLat The point to include.
   * @returns A new bounding box that includes the point.
   */
  extendToLngLat(lngLat: LngLat): BoundingBox {
    const lon = mapToLon(lngLat.lng)
    const lat = mapToLon(lngLat.lat)
    return new BoundingBox(
      {
        lng: lon < this.southWest.lng ? lon : this.southWest.lng,
        lat: lat < this.southWest.lat ? lat : this.southWest.lat
      },
      {
        lng: lon > this.northEast.lng ? lon : this.northEast.lng,
        lat: lat > this.northEast.lat ? lat : this.northEast.lat
      }
    )
  }

  /**
   * Extend the bounding box with a delta longitude and latitude.
   * @param deltaLng The delta longitude to extend the bounding box with.
   * @returns A new bounding box that includes the delta longitude and latitude. The box can never exceed full range.
   */
  extendWithLngLatDelta(deltaLng: LngLat): BoundingBox {
    assert(deltaLng.lng >= 0 && deltaLng.lat >= 0, "deltaLng and deltaLat must be positive")
    const maxDeltaLng = Math.min(
      deltaLng.lng,
      180 - Math.max(Math.abs(this.southWest.lng), Math.abs(this.northEast.lng))
    )
    const maxDeltaLat = Math.min(
      deltaLng.lat,
      90 - Math.max(Math.abs(this.southWest.lat), Math.abs(this.northEast.lat))
    )
    const minLng = this.southWest.lng - maxDeltaLng
    const minLat = this.southWest.lat - maxDeltaLat
    const maxLng = this.northEast.lng + maxDeltaLng
    const maxLat = this.northEast.lat + maxDeltaLat
    return new BoundingBox({lng: minLng, lat: minLat}, {lng: maxLng, lat: maxLat})
  }

  center(): LngLat {
    return {
      lng: (this.southWest.lng + this.northEast.lng) / 2,
      lat: (this.southWest.lat + this.northEast.lat) / 2
    }
  }

  /**
   * Get a hash for the bounding box.
   * @returns A hash string.
   */
  toGeoHash(): string {
    const hashCenter = new GeoHash(this.center())
    const hashSW = new GeoHash(this.southWest)
    return `${hashCenter.getHash()}:${hashSW.getHash()}`
  }
}
