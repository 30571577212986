/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export type LngLat = {
  lng: number
  lat: number
}

export function LngLatToArray(lngLat: LngLat): [number, number] {
  return [lngLat.lng, lngLat.lat]
}

export function mapToLon(value: number) {
  return ((((value >= 0 ? value : -value) + 180) % 360) - 180) * (value >= 0 ? 1.0 : -1.0)
}

export function mapToLat(value: number) {
  return Math.max(-90, Math.min(90, value))
}

export function isValidLng(lon: number) {
  return -180 <= lon && lon <= 180
}

export function isValidLat(lat: number) {
  return -90 <= lat && lat <= 90
}

// Mercator values for latitude are in the range of 0 (north) to 1 (south).
export function mercatorToLatitude(y: number): number {
  if (Number.isNaN(y) || y === Infinity || y === -Infinity) {
    return NaN
  }
  return (180 / Math.PI) * (2.0 * Math.atan(Math.exp(Math.PI - 2.0 * Math.PI * y)) - Math.PI / 2.0)
}

// Mercator values for longitude are in the range of 0 (west) to 1 (east).
export function mercatorToLongitude(x: number): number {
  return x * 360 - 180
}

export function toRadians(degree: number): number {
  return (degree / 180.0) * Math.PI
}

export function toDegrees(radians: number): number {
  return (radians / Math.PI) * 180.0
}

export function distanceInMeters(start: LngLat, end: LngLat): number {
  const deltaLat = toRadians(end.lat - start.lat)
  const deltaLon = toRadians(end.lng - start.lng)
  const a =
    Math.sin(deltaLat / 2.0) * Math.sin(deltaLat / 2.0) +
    Math.cos(toRadians(start.lat)) * Math.cos(toRadians(end.lat)) * Math.sin(deltaLon / 2.0) * Math.sin(deltaLon / 2.0)
  const c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a))
  const kEarthRadiusMeters = 6378137
  return kEarthRadiusMeters * c
}
