/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"

import {HTML_WINDOW_CONSOLE, HTML_WINDOW_CONTENT_CONSOLE} from "../html/htmlElementId"
import {Logger} from "../common/logger"
import {Html} from "../html/html"

enum LogLevel {
  Ok,
  Info,
  Warning,
  Error
}

export class ConsoleWindow extends HtmlWindow {
  public static readonly colorOK = "color: green"
  public static readonly colorInfo = "color: black"
  public static readonly colorWarning = "color: darkorange"
  public static readonly colorError = "color: red"

  private logMessages: {text: string; style: string}[] = []
  private logMessageRepeatCount = 1
  private readonly minRepeatCount = 3
  private readonly maxLogLines = 250

  constructor(title: string) {
    super(title, "console")
    Logger.setGlobalLogger(new Logger(this.ok, this.info, this.warning, this.error, this.show, this.hide))
  }

  public static readonly color = (color: string) => `color: ${color}`
  public static readonly colorBlack = ConsoleWindow.color("black")
  public static readonly colorWhite = ConsoleWindow.color("white")

  clear() {
    this.logMessages = []
    this.logMessageRepeatCount = 1
    const consoleWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_CONSOLE)
    consoleWindowContent.innerHTML = ""
  }

  private readonly show = () => this.setVisible(true)

  private readonly hide = () => this.setVisible(false)

  setVisible(show: boolean) {
    super.setVisible(show)
    if (show) {
      const consoleWindow = Html.getDefinedHtmlElementById(HTML_WINDOW_CONSOLE)
      const consoleWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_CONSOLE)
      consoleWindow.scrollTo({top: consoleWindowContent.scrollHeight})
    }
  }

  private readonly ok = (text: string) => this.log(text, LogLevel.Ok)

  private readonly info = (text: string) => this.log(text, LogLevel.Info)

  private readonly warning = (text: string) => this.log(text, LogLevel.Warning)

  private readonly error = (text: string) => this.log(text, LogLevel.Error)

  private log(text: string, logLevel: LogLevel) {
    let style = ""
    switch (logLevel) {
      case LogLevel.Ok:
        style = ConsoleWindow.colorOK
        console.info(text)
        break
      case LogLevel.Info:
        style = ConsoleWindow.colorInfo
        console.info(text)
        break
      case LogLevel.Warning:
        style = ConsoleWindow.colorWarning
        console.warn(text)
        break
      case LogLevel.Error:
        style = ConsoleWindow.colorError
        console.error(text)
        break
    }

    if (this.logMessages.length > 0 && text === this.logMessages[this.logMessages.length - 1].text) {
      this.logMessageRepeatCount++
      if (this.logMessageRepeatCount > this.minRepeatCount) {
        this.logMessages.pop()
        text += ` [<-- repeated ${this.logMessageRepeatCount - this.minRepeatCount + 1}x]`
      }
    } else {
      this.logMessageRepeatCount = 1
    }
    this.logMessages.push({text, style})
    if (this.logMessages.length > this.maxLogLines) {
      this.logMessages.shift()
    }

    const consoleWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_CONSOLE)
    consoleWindowContent.innerHTML = this.logMessages
      .map((msg) => `<span style="${msg.style}">${this.escapeHtml(msg.text)}</span><br/>`)
      .join("")
    const consoleWindow = Html.getDefinedHtmlElementById(HTML_WINDOW_CONSOLE)
    consoleWindow.scrollTo({top: consoleWindowContent.scrollHeight})
  }

  private escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
  }
}

export default ConsoleWindow
