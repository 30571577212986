/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * All layer types that are recognized by the application.
 *
 * IMPORTANT: The layers that start with an underscore mark sections
 * of the enum values. They are not real layers.
 */
export enum LayerType {
  _Total = "_Total", // A section marker, not a real layer.

  // Mapvis:
  _MapVis = "_MapVis", // A section marker, not a real layer.
  MapVis3D = "MapVis3D",
  MapVisBasicMap = "MapVisBasicMap",
  MapVisFlow = "MapVisFlow",
  MapVisHillshade = "MapVisHillshade",
  MapVisIncidents = "MapVisIncidents",
  MapVisSatellite = "MapVisSatellite",
  MapVisStyle = "MapVisStyle",
  MapVisCustom = "MapVisCustom",

  // Navigation:
  _Navigation = "_Navigation", // A section marker, not a real layer.
  NdsLive = "NdsLive",
  NdsClassicRegion = "NdsClassicRegion",
  NdsClassicOther = "NdsClassicOther",
  Nk2NavTiles = "Nk2NavTiles",
  Nk2LaneTiles = "Nk2LaneTiles",

  // API:
  _Api = "_Api", // A section marker, not a real layer.
  ApiAutoComplete = "ApiAutoComplete",
  ApiEv = "ApiEv",
  ApiParking = "ApiParking",
  ApiRevGeocode = "ApiRevGeocode",
  ApiRouting = "ApiRouting",
  ApiSearch = "ApiSearch",
  ApiTpeg = "ApiTpeg",

  // Other (none of the below can contains size or API calls information):
  _Other = "_Other", // A section marker, not a real layer.
  LogLines = "LogLines",
  JSON = "JSON",
  GeoJSON = "GeoJSON",
  GPX = "GPX",
  TTPOther = "TTPOther",
  TTPPrediction = "TTPPrediction",
  TTPLocation = "TTPLocation"
}

/**
 * This function determines if duplicates in a layer may lead to red errors ot not.
 * @param layerType The layer type to check.
 */
export function duplicatesCanBeErrors(layerType?: LayerType): boolean {
  return layerType !== undefined && ![LayerType.NdsClassicRegion, LayerType.NdsClassicOther].includes(layerType)
}

/**
 * This function determines if duplicates in a layer are allowed if the are some time apart.
 * @param layerType The layer type to check.
 */
export function duplicatesAreOkAfterSomeTime(layerType?: LayerType): boolean {
  return (
    layerType !== undefined &&
    [
      LayerType.MapVisFlow,
      LayerType.MapVisIncidents,
      LayerType.ApiAutoComplete,
      LayerType.ApiEv,
      LayerType.ApiParking,
      LayerType.ApiRevGeocode,
      LayerType.ApiRouting,
      LayerType.ApiSearch,
      LayerType.ApiTpeg
    ].includes(layerType)
  )
}

/**
 * This function determines if a layer should be shown automatically when data is available, or it should stay off
 * to avoid cluttering the screen.
 * @param layerType The layer type to check.
 */
export function autoShowWhenDataAvailable(layerType?: LayerType): boolean {
  return (
    layerType !== undefined &&
    ![LayerType.NdsClassicRegion, LayerType.NdsClassicOther, LayerType.TTPOther, LayerType.TTPPrediction].includes(
      layerType
    )
  )
}

export function isRealLayer(layerType: LayerType): boolean {
  return !layerType.startsWith("_")
}

export function isFileParserLayer(layerType: LayerType): boolean {
  return [
    LayerType.JSON,
    LayerType.GeoJSON,
    LayerType.GPX,
    LayerType.TTPPrediction,
    LayerType.TTPOther,
    LayerType.TTPLocation
  ].includes(layerType)
}

export function layerSectionName(layerType: LayerType): string {
  return isRealLayer(layerType) ? "" : layerType.slice(1).toUpperCase()
}

/**
 * Only these layers support tile levels.
 */
export const layerTypeSupportsLevel: LayerType[] = [
  LayerType.MapVis3D,
  LayerType.MapVisBasicMap,
  LayerType.MapVisFlow,
  LayerType.MapVisHillshade,
  LayerType.MapVisIncidents,
  LayerType.MapVisSatellite,
  LayerType.MapVisStyle,
  LayerType.MapVisCustom,
  LayerType.Nk2NavTiles,
  LayerType.NdsLive
]

/**
 * These layers do not support size or API calls information.
 */
export const layerTypeSupportsItemCountOnly: LayerType[] = [
  LayerType._Other,
  LayerType.LogLines,
  LayerType.JSON,
  LayerType.GeoJSON,
  LayerType.GPX,
  LayerType.TTPOther,
  LayerType.TTPPrediction,
  LayerType.TTPLocation
]

/**
 * These types define the color of parsed entities.
 */
export type ParsedPointColor = {
  "circle-radius": number
  "circle-color": string
  "text-color": string
  text: string
}

export type ParsedPolygonColor = {
  "fill-opacity": number
  "fill-color": string
  "fill-outline-color": string
}

export type ParsedItemColor = ParsedPointColor | ParsedPolygonColor | undefined

export function isParsedPointColor(color: ParsedItemColor): boolean {
  return color !== undefined && (color as ParsedPointColor)["circle-radius"] !== undefined
}

export function isParsedPolygonColor(color: ParsedItemColor): boolean {
  return color !== undefined && (color as ParsedPolygonColor)["fill-opacity"] !== undefined
}

/**
 * This type describes the regex used to determine which parser is needed to
 * parse a single line of a layer type.
 */
export type LayerLineParser = {
  name: string
  layerType: LayerType
  color: ParsedItemColor
  regexWithLocation?: string // The regex to use when the line contains location information.
  regexWithoutLocation?: string // The regex to use when the line does not contain location information.
}
