/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import {LngLat} from "../common/wgs84"
import TtpMessageSensorIncomingLocations from "../ttp/parser/ttpMessageSensorIncomingLocations"
import TtpMessageSensorLocationEvent from "../ttp/parser/ttpMessageSensorLocationEvent"
import TtpMessageSensorLocationPrediction from "../ttp/parser/ttpMessageSensorLocationPrediction"
import TtpMessageSensorMapMatcherResult from "../ttp/parser/ttpMessageSensorMapMatcherResult"
import TtpMessageSensorMapMatcherInputLocations from "../ttp/parser/ttpMessageSensorMapMatcherInputLocations"
import TtpMessageSensorLaneLevelPrediction from "../ttp/parser/ttpMessageSensorLaneLevelPrediction"
import TtpParser from "../ttp/ttpParser"
import {MetadataStore} from "../common/metadata"
import TtpMessageBase from "../ttp/parser/ttpMessageBase"
import {addAllElements} from "../common/objects"
import {GlobalSettings} from "../app/globalSettings"
import {BoundingBox} from "../common/geo"
import {LayerLineParser, LayerType} from "./parserTypes"
import LogWindow from "../app/logWindow"
import FileParser from "./fileParser"

export class ParserTtp extends FileParser {
  private static readonly locationCircleColor = "rgba(0,255,0)"
  private static readonly parsers: Partial<Record<LayerType, LayerLineParser>> = {
    [LayerType.TTPLocation]: {
      name: "TTP: GPS/GNSS",
      layerType: LayerType.TTPLocation,
      color: {
        "circle-radius": 10,
        "circle-color": ParserTtp.locationCircleColor,
        text: "G",
        "text-color": "rgb(0,0,0)"
      },
      regexWithLocation: ""
    },
    [LayerType.TTP1s]: {
      name: "TTP: 1s data",
      layerType: LayerType.TTP1s,
      color: undefined,
      regexWithLocation: ""
    },
    [LayerType.TTP100ms]: {
      name: "TTP: 1/10s data",
      layerType: LayerType.TTP100ms,
      color: undefined,
      regexWithLocation: ""
    }
  }

  private readonly colorSensorMapMatcherResult = "rgb(248,178,87)"
  private readonly colorSensorLocationPrediction = "rgb(204,115,218)"
  private readonly colorSensorLocationEvent = "rgb(18,200,232)"
  private readonly colorSensorMapMatcherInputLocations = "rgb(18,86,232)"
  private readonly colorIncomingLocations = ParserTtp.locationCircleColor
  private readonly colorLaneLevelLocationPrediction = "rgb(214,63,102)"

  constructor(
    logWindow: LogWindow,
    globalSettings: GlobalSettings,
    metadataStore: MetadataStore,
    layerType: LayerType
  ) {
    super(
      logWindow,
      globalSettings,
      metadataStore,
      layerType,
      ParserTtp.parsers[layerType]!.name,
      ParserTtp.parsers[layerType]!.color,
      ParserTtp.parsers[layerType]!.regexWithLocation
    )
  }

  async parseFile(fileName: string, contents: string, onProgress: (percentage: number) => void): Promise<Feature[]> {
    this.logWindow.info(`Parse TTP file: ${fileName}`)
    let features: Feature[] = []

    // Function to add a message as a feature.
    const addMessage = (lngLat: LngLat, message: TtpMessageBase, color: string) => {
      const metadata = {
        message: message
      }

      const layerType: LayerType =
        message instanceof TtpMessageSensorIncomingLocations
          ? LayerType.TTPLocation
          : message instanceof TtpMessageSensorLocationPrediction
            ? LayerType.TTP100ms
            : LayerType.TTP1s
      const feature = this.createFeature(lngLat, color, metadata, layerType, message.time)
      features.push(feature)
    }

    // Create the TTP parser.
    const parser = new TtpParser()

    // Register callbacks for the messages we want to display/
    parser.registerMessageCallback("SensorMapMatcherResult", (message: TtpMessageSensorMapMatcherResult) =>
      addMessage({lng: message.longitude, lat: message.latitude}, message, this.colorSensorMapMatcherResult)
    )
    parser.registerMessageCallback("SensorLocationPrediction", (message: TtpMessageSensorLocationPrediction) =>
      addMessage({lng: message.longitude, lat: message.latitude}, message, this.colorSensorLocationPrediction)
    )
    parser.registerMessageCallback("SensorLaneLevelPrediction", (message: TtpMessageSensorLaneLevelPrediction) =>
      addMessage({lng: message.longitude, lat: message.latitude}, message, this.colorLaneLevelLocationPrediction)
    )
    parser.registerMessageCallback("SensorLocationEvent", (message: TtpMessageSensorLocationEvent) =>
      addMessage({lng: message.longitude, lat: message.latitude}, message, this.colorSensorLocationEvent)
    )
    parser.registerMessageCallback(
      "SensorMapMatcherInputLocations",
      (message: TtpMessageSensorMapMatcherInputLocations) =>
        addMessage({lng: message.longitude, lat: message.latitude}, message, this.colorSensorMapMatcherInputLocations)
    )
    parser.registerMessageCallback("SensorIncomingLocations", (message: TtpMessageSensorIncomingLocations) =>
      addMessage({lng: message.longitude, lat: message.latitude}, message, this.colorIncomingLocations)
    )

    // Parse the data file. This executes the callbacks defined above.
    parser.parse(contents, 1, onProgress)

    // Push all messages as features.
    addAllElements(features, features)
    return features
  }

  private createFeature(lngLat: LngLat, color: string, metadata: any, layerType: LayerType, time?: Date): Feature {
    const extendedMetadata = {
      ...metadata,
      layer: layerType,
      ...(time !== undefined && {time: time.getTime()}),
      // No "sizeInBytes" for TTP messages.
      bounds: BoundingBox.fromLngLat(lngLat)
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lngLat.lng, lngLat.lat]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        "circle-radius": 3,
        "circle-color": color
      }
    }
  }
}

export default ParserTtp
