/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import TtpMessageBase from "./ttpMessageBase"
import {TtpMessageType} from "./ttpMessage"

export class TtpMessageParseError extends TtpMessageBase {
  type: TtpMessageType = TtpMessageType.ParseError
  reason: string
  line: string
  lineNumber: number

  constructor(lineNumber: number, reason: string, line: string) {
    super(lineNumber)
    this.reason = reason
    this.line = line
    this.lineNumber = lineNumber
  }
}

export default TtpMessageParseError
