/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {DateTimeUtils} from "./dateTimeUtils"

/**
 * Log levels used by logcat.
 */
export enum LogLevel {
  Verbose = "V",
  Debug = "D",
  Info = "I",
  Warning = "W",
  Error = "E",
  Fatal = "F",
  Silent = "S"
}

/**
 * This is a utility class with static functions only.
 */
export class LogcatUtils {
  /**
   * Returns a human-readable string for the given log level.
   * @param logLevel - The log level to convert to a human-readable string.
   * @returns A human-readable string representing the log level.
   */
  static getHumanReadableLogLevel(logLevel: LogLevel): string {
    switch (logLevel) {
      case LogLevel.Verbose:
        return "Verbose"
      case LogLevel.Debug:
        return "Debug"
      case LogLevel.Info:
        return "Info"
      case LogLevel.Warning:
        return "Warning"
      case LogLevel.Error:
        return "Error"
      case LogLevel.Fatal:
        return "Fatal"
      case LogLevel.Silent:
        return "Silent"
      default:
        return "Unknown"
    }
  }

  static isLogLevelWarning(logLevel: LogLevel) {
    return logLevel === LogLevel.Warning
  }

  static isLogLevelError(logLevel: LogLevel) {
    return [LogLevel.Error, LogLevel.Fatal].includes(logLevel)
  }

  static getLogLevelFromLogcatString(line: string): LogLevel | undefined {
    let logLevel = undefined

    // First match the standard logcat format, which is ISO time + log level. Timestamp must be at start, "#" comment allowed.
    const regex1 = new RegExp(
      `^\\s*#?\\s*${DateTimeUtils.regexISODateTime.source.replace("(", "(?:") + /\s+([vVdDiIwWeEfFsS])\s/.source}`
    )
    const match1 = regex1.exec(line)
    if (match1) {
      logLevel = match1[3].toUpperCase()
    } else {
      // Then match an alternative logcat format, which is incomplete US time + 2 numbers + log level
      const regex2 = new RegExp(
        `^\\s*#?\\s*${DateTimeUtils.regexLogcatDateTime.source.replace("(", "(?:") + /\s*\d+\s+\d+\s+([vVdDiIwWeEfFsS])\s/.source}`
      )
      const match2 = regex2.exec(line)
      if (match2) {
        logLevel = match2[5].toUpperCase()
      }
    }
    return (logLevel as LogLevel) ?? undefined
  }
}

export default LogcatUtils
