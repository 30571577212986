/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {GeoJSON, Position} from "geojson"
import {BoundingBox} from "./geoUtils"

/**
 * This is a utility class with static functions only.
 */
export class GeoJsonUtils {
  public static calculateBoundingBox(geojson: GeoJSON): BoundingBox {
    let minLng = Infinity
    let minLat = Infinity
    let maxLng = -Infinity
    let maxLat = -Infinity

    const updateBounds = (coords: Position[]) => {
      coords.forEach(([lng, lat]) => {
        if (lng < minLng) {
          minLng = lng
        }
        if (lat < minLat) {
          minLat = lat
        }
        if (lng > maxLng) {
          maxLng = lng
        }
        if (lat > maxLat) {
          maxLat = lat
        }
      })
    }

    const traverseGeometry = (geometry: GeoJSON) => {
      switch (geometry.type) {
        case "Point":
          updateBounds([geometry.coordinates])
          break
        case "MultiPoint":
        case "LineString":
          updateBounds(geometry.coordinates)
          break
        case "MultiLineString":
        case "Polygon":
          geometry.coordinates.forEach(updateBounds)
          break
        case "MultiPolygon":
          geometry.coordinates.forEach((polygon) => polygon.forEach(updateBounds))
          break
        case "GeometryCollection":
          geometry.geometries.forEach(traverseGeometry)
          break
        case "Feature":
          traverseGeometry(geometry.geometry)
          break
        case "FeatureCollection":
          geometry.features.forEach((feature) => traverseGeometry(feature.geometry))
          break
      }
    }

    traverseGeometry(geojson)
    return new BoundingBox({lng: minLng, lat: minLat}, {lng: maxLng, lat: maxLat})
  }
}

export default GeoJsonUtils
