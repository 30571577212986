/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"

import {HTML_WINDOW_CONTENT_SOURCE, HTML_WINDOW_SOURCE} from "../html/htmlElementId"
import {Html} from "../html/html"
import {HTML_CLASS_CURRENT, HTML_CLASS_SOURCE_LINE, HTML_CLASS_SOURCE_LINE_NUMBER} from "../html/htmlClassId"
import DataStore from "../global/dataStore"
import DataCanvas from "../app/dataCanvas"

export class SourceWindow extends HtmlWindow {
  private readonly dataCanvas: DataCanvas
  private readonly defaultNrOfContextLines = 25 // Number of lines above or below the current line.

  private fileId: string
  private sourceLines: string[]
  private currentLineNumber: number
  private nrOfContextLines: number

  constructor(title: string, htmlWindowElementId: string, htmlWindowTitleElementId: string, dataCanvas: DataCanvas) {
    super(title, htmlWindowElementId, htmlWindowTitleElementId)
    this.dataCanvas = dataCanvas
    this.fileId = ""
    this.sourceLines = []
    this.currentLineNumber = 0
    this.nrOfContextLines = 0
  }

  public reset() {
    this.fileId = ""
    this.sourceLines = []
    this.currentLineNumber = 0
    this.nrOfContextLines = 0
    this.setVisible(false)
  }

  public isEmpty = () => this.sourceLines.length === 0

  public setSourceLines(fileId: string, sourceLines: string[], currentLineNumber?: number, nrOfContextLines?: number) {
    const filename = DataStore.getFilename(fileId)
    this.fileId = fileId
    this.sourceLines = sourceLines
    this.currentLineNumber = currentLineNumber ?? 0
    this.nrOfContextLines = nrOfContextLines ?? this.defaultNrOfContextLines
    this.setTitle(filename)
    this.showSourceLines()

    // Schedule a redraw after a little bit, to fill the window.
    setTimeout(() => {
      this.scrollToCurrentLine()
    }, 100)
  }

  public setVisible(show: boolean = true) {
    super.setVisible(show)
  }

  public update() {
    if (!this.dataCanvas.isFileShown(this.fileId)) {
      this.setVisible(false)
    }
  }

  private scrollToCurrentLine() {
    if (this.sourceLines.length === 0) {
      return
    }
    const sourceWindow = Html.getDefinedHtmlElementById(HTML_WINDOW_SOURCE)
    const sourceWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_SOURCE)
    const before = this.currentLineNumber - this.getStartLineNumber()
    const after = this.getEndLineNumber() - this.currentLineNumber
    const ratio = before < after ? before / after / 2 : 1 - after / before / 2
    const scrollTo = sourceWindowContent.scrollHeight * ratio - 65
    sourceWindow.scrollTo({
      top: Math.min(sourceWindowContent.scrollHeight, scrollTo)
    })
  }

  private getStartLineNumber = () => Math.max(0, this.currentLineNumber - this.nrOfContextLines)

  private getEndLineNumber = () => Math.min(this.sourceLines.length - 1, this.currentLineNumber + this.nrOfContextLines)

  private showSourceLines() {
    const startLine = this.getStartLineNumber()
    const endLine = this.getEndLineNumber()
    const sourceWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_SOURCE)
    sourceWindowContent.innerHTML = this.sourceLines
      .map((sourceLine, lineNumber) => {
        const lineNumberPadded =
          `${lineNumber === this.currentLineNumber ? "-> " : "   "}` + `${lineNumber}`.padStart(7, " ") + " |"
        const classLineNumber =
          HTML_CLASS_SOURCE_LINE_NUMBER + (lineNumber === this.currentLineNumber ? ` ${HTML_CLASS_CURRENT}` : "")
        const classLine =
          HTML_CLASS_SOURCE_LINE + (lineNumber === this.currentLineNumber ? ` ${HTML_CLASS_CURRENT}` : "")
        if (startLine <= lineNumber && lineNumber <= endLine) {
          return `<span class="${classLineNumber}">${lineNumberPadded}</span><span class="${classLine}">${sourceLine}</span><br/>`
        } else {
          return ""
        }
      })
      .join("")
  }
}

export default SourceWindow
