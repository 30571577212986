/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {PointXY} from "../common/utils/geoUtils"
import Parser from "../parsers/parser"
import InspectorWindow from "../windows/inspectorWindow"
import {MetadataStore} from "../global/metadataStore"
import Logger from "../common/logger"
import Storage, {Settings} from "../common/storage"
import {Html} from "../html/html"
import {Feature} from "geojson"
import {DateTimeFormat, DateTimeUtils} from "../common/utils/dateTimeUtils"
import MathUtils from "../common/utils/mathUtils"
import StringUtils from "../common/utils/stringUtils"
import {LngLat} from "../common/utils/wgs84Utils"
import DataStore, {DataFileContent, TIMESTAMP_ALWAYS_HIDE, TIMESTAMP_ALWAYS_SHOW} from "../global/dataStore"
import InfoDialog from "../dialogs/infoDialog"
import YesNoDialog from "../dialogs/yesNoDialog"
import FileUtils from "../common/utils/fileUtils"
import MapLibreTile from "../common/mapLibreTile"

// Range of time in millis; used for the timeline.
export type TimeRange = {
  minMillis: number
  maxMillis: number
}

export function isEmptyTimeRange(timeRange: TimeRange): boolean {
  return timeRange.minMillis === 0 && timeRange.maxMillis === 0
}

export function inTimeRange(timeMillis: number, timeRange: TimeRange): boolean {
  return timeRange.minMillis <= timeMillis && timeMillis <= timeRange.maxMillis
}

// Range of time, used for selection.
type SelectionTimeRange = TimeRange & {
  startDragMillis: number // Used while extending the selection time range.
  endDragMillis: number
}

// Data for any single event.
export type Event = {
  timeMillis: number
  sizeInBytes?: number
  duplicates?: number
  hasLocation?: boolean
  hasWarning?: boolean
  hasError?: boolean
  metadata?: string // Metadata key, not metadata itself.
  feature?: Feature
}

// Bucket containing multiple events; the timeline is divided into buckets.
type Bucket = {
  timeRange: TimeRange
  nrOfEventsTotal: number
  nrOfEventsWithLocation: number
  nrOfEventsWithWarning: number
  nrOfEventsWithError: number
  sizeInBytes: number
}

// Statistics for events; used for all events and the selected events.
type EventStats = {
  nrOfEventsTotal: number
  nrOfEventsWithSize: number
  sizeInBytes: number
  duplicatesSizeInBytes: number
}

type Rectangle = {
  x: number
  y: number
  width: number
  height: number
}

/**
 * This class is a cache for the buckets calculation, to avoid recalculating them every time,
 * which is an expensive operation.
 */
class BucketCache {
  static readonly bucketSizeInPixels = 5 // More pixels implies less buckets and more events in a bucket.
  private static previousTimelineRange = {minMillis: 0, maxMillis: 0}
  private static previousEventsLength = 0
  private static bucketsCache: Bucket[] = []

  static invalidateCache() {
    BucketCache.previousTimelineRange = {minMillis: 0, maxMillis: 0}
    BucketCache.previousEventsLength = 0
  }

  static createBucketsCached(events: Event[], timelineRange: TimeRange, graphWidth: number): Bucket[] {
    // Cache the previous buckets (expensive to recalculate a lot).
    if (
      events.length === this.previousEventsLength &&
      this.previousTimelineRange.minMillis !== 0 &&
      this.previousTimelineRange.maxMillis !== 0 &&
      timelineRange.minMillis === BucketCache.previousTimelineRange.minMillis &&
      timelineRange.maxMillis === BucketCache.previousTimelineRange.maxMillis
    ) {
      return this.bucketsCache
    }
    this.previousTimelineRange = {minMillis: timelineRange.minMillis, maxMillis: timelineRange.maxMillis}
    this.previousEventsLength = events.length

    const totalNrOfBuckets = Math.floor(graphWidth / BucketCache.bucketSizeInPixels)
    this.bucketsCache = []

    // Prepare the buckets.
    const rangeMillis = timelineRange.maxMillis - timelineRange.minMillis
    for (let i = 0; i < totalNrOfBuckets; ++i) {
      const minMillis = timelineRange.minMillis + i * (rangeMillis / totalNrOfBuckets)
      const maxMillis = minMillis + rangeMillis / totalNrOfBuckets
      const bucket: Bucket = {
        timeRange: {
          minMillis: minMillis,
          maxMillis: maxMillis
        },
        nrOfEventsTotal: 0,
        nrOfEventsWithLocation: 0,
        nrOfEventsWithWarning: 0,
        nrOfEventsWithError: 0,
        sizeInBytes: 0
      }
      this.bucketsCache.push(bucket)
    }

    // Fill the buckets with events.
    for (const event of events) {
      if (inTimeRange(event.timeMillis, timelineRange)) {
        let bucketIndex = MathUtils.limitValue(
          Math.floor(
            timelineRange.maxMillis === timelineRange.minMillis
              ? 0
              : ((event.timeMillis - timelineRange.minMillis) / (timelineRange.maxMillis - timelineRange.minMillis)) *
                  totalNrOfBuckets
          ),
          0,
          totalNrOfBuckets - 1
        )
        this.bucketsCache[bucketIndex].nrOfEventsTotal++
        this.bucketsCache[bucketIndex].nrOfEventsWithLocation += event.hasLocation ? 1 : 0
        this.bucketsCache[bucketIndex].nrOfEventsWithWarning += event.hasWarning ? 1 : 0
        this.bucketsCache[bucketIndex].nrOfEventsWithError += event.hasError ? 1 : 0
        this.bucketsCache[bucketIndex].sizeInBytes += event.sizeInBytes ?? 0
      }
    }
    return this.bucketsCache
  }
}

/**
 * This is the timeline class, which is responsible for drawing the timeline.
 */
export class Timeline {
  private readonly container: HTMLElement
  private readonly buttonZoomIn: HTMLButtonElement
  private readonly buttonZoomOut: HTMLButtonElement
  private readonly buttonSave: HTMLButtonElement

  private readonly inspectorWindow: InspectorWindow
  private readonly onUpdateTimeRange: (timeRange: TimeRange, forceUpdate: boolean, inhibitAutoZoom: boolean) => void

  private readonly canvas = document.createElement("canvas")
  private readonly context = this.canvas.getContext("2d")!
  private readonly style = {
    font: "12px Roboto, Arial, Helvetica, sans-serif",
    fontAxis: "10px Roboto, Arial, Helvetica, sans-serif",
    fontSize: "11px Roboto, Arial, Helvetica, sans-serif",
    fontCoordinate: "11px Roboto, Arial, Helvetica, sans-serif",
    fontArrowLarge: "24px Roboto, Arial, Helvetica, sans-serif",
    fontArrowSmall: "10px Roboto, Arial, Helvetica, sans-serif",

    arrowSize: 12,

    paddingLeftX: 5,
    paddingRightX: 35,
    paddingTopY: 26,
    paddingBottomY: 3,
    paddingInfoY: 3,
    paddingAxisX: 5,

    axisWidthY: 30,
    axisHeightX: 20,
    infoHeight: 25,

    coordinateWidth: 400,
    coordinateHeight: 15,

    backgroundColor: "rgb(31,49,71)", // Backdrop of timeline.
    backgroundColorCoordinate: "rgb(0,0,0)", // Backdrop of timeline.
    axisColor: "rgb(169,195,243)", // Color of X/Y axis.
    selectionColor: "rgba(255,109,126,0.40)", // Color of selection rectangle.
    pointerColor: "rgb(255,255,255)", // Mouse hover pointer.

    textColorStats: "rgb(255,255,255)", // Stats text color.
    textColorHelp: "rgb(230,222,187)", // Subtitle help line.
    textColorAxis: "rgb(255,242,148)", // Color for X/Y axis text.
    textColorSize: "rgb(255,236,217)", // Color for size indicator in selection.
    textColorArrow: "rgb(195,195,195)", // Color of selection arrows.
    textColorArrowSelect: "rgb(255,220,78)", // Color of selection arrows.
    textColorCoordinate: "rgb(255,255,255)", // Color coordinate.

    bucketColorEventsTotal: "rgb(87,114,170)", // Color of buckets, for events with locations.
    bucketColorEventsWithLocation: "rgb(99,174,48)", // Color of buckets, for events without locations.
    bucketColorEventsWithWarning: "rgb(202,150,37)", // Color of buckets, for events without errors.
    bucketColorEventsWithWarningDot: "rgb(184,145,59)", // Color of buckets, for events without errors.
    bucketColorEventsWithError: "rgb(202,37,37)", // Color of buckets, for events without errors.
    bucketColorEventsWithErrorDot: "rgb(200,53,53)" // Color of buckets, for events without errors.
  }
  private readonly textHeight = this.getTextHeight()
  private readonly minBucketHeight = 4 // Minimum height of buckets with >0 elements.

  private readonly minMouseMoveX = 5 // Minimum mouse move to create/drag a selection.

  private readonly minSetSelectedTimeRangeIntervalMillis = 250 // Avoid to many redraws while dragging.
  private previousSetSelectedTimeRangeMillis = 0 // Avoid redrawing the selection too often while dragging.

  private allEventsTimeRange: TimeRange = {minMillis: 0, maxMillis: 0} // Range of all events.
  private visibleEventsTimeRange: TimeRange = {minMillis: 0, maxMillis: 0} // Range of visible timeline.
  private selectedEventsTimeRange: SelectionTimeRange = {
    minMillis: 0,
    maxMillis: 0,
    startDragMillis: 0,
    endDragMillis: 0
  } // Range of selection in timeline.

  private cursorMillis = 0 // Current mouse position in millis.
  private isMouseDown = false // True while dragging/extending the selection.
  private mouseDownPoint?: PointXY // Mouse down point.
  private selectLeftTimeRangeBorder = true // True if next right-click selects left time range border.

  private previousTimeRange: TimeRange = {minMillis: 0, maxMillis: 0} // Avoid redrawing the selection when range wasn't changed.

  private events: Event[] = [] // All events.
  private buckets: Bucket[] = [] // Bucketized events for displaying in timeline.

  // Used for displaying statistics.
  private timelineStats: EventStats = {
    nrOfEventsTotal: 0,
    nrOfEventsWithSize: 0,
    sizeInBytes: 0,
    duplicatesSizeInBytes: 0
  }
  private selectionStats: EventStats = {
    nrOfEventsTotal: 0,
    nrOfEventsWithSize: 0,
    sizeInBytes: 0,
    duplicatesSizeInBytes: 0
  }

  constructor(
    container: string,
    buttonZoomIn: string,
    buttonZoomOut: string,
    buttonSave: string,
    inspectorWindow: InspectorWindow,
    onUpdateTimeRange: (timeRange: TimeRange, forceUpdate: boolean, inhibitAutoZoom: boolean) => void
  ) {
    this.container = Html.getDefinedHtmlElementById(container)
    this.buttonZoomIn = Html.getDefinedHtmlElementById(buttonZoomIn) as HTMLButtonElement
    this.buttonZoomOut = Html.getDefinedHtmlElementById(buttonZoomOut) as HTMLButtonElement
    this.buttonSave = Html.getDefinedHtmlElementById(buttonSave) as HTMLButtonElement
    this.inspectorWindow = inspectorWindow
    this.container.appendChild(this.canvas)
    this.onUpdateTimeRange = onUpdateTimeRange

    this.canvas.style.cursor = "pointer"
    this.onResize()

    // Install event listeners (mouse up needs to be window-global).
    this.canvas.addEventListener("mousedown", (event: MouseEvent) => this.onMouseDown(event), true)
    window.addEventListener("mouseup", (event: MouseEvent) => this.onMouseUp(event), false)
    this.canvas.addEventListener("mousemove", (event: MouseEvent) => this.onMouseMove(event), true)
    this.canvas.addEventListener("mouseleave", (event: MouseEvent) => this.onMouseLeave(), true)
    this.canvas.addEventListener("contextmenu", (event: MouseEvent) => this.onContextMenu(event), true)
    window.addEventListener("resize", this.onResize)

    this.buttonZoomIn.addEventListener("click", () => this.onButtonZoomIn())
    this.buttonZoomOut.addEventListener("click", () => this.onButtonZoomOut())
    this.buttonSave.addEventListener("click", () => this.onButtonSave())
  }

  /**
   * Clear all event data and reset the timeline.
   */
  clear() {
    this.allEventsTimeRange = {minMillis: 0, maxMillis: 0}
    this.previousSetSelectedTimeRangeMillis = 0
    this.previousTimeRange = {minMillis: 0, maxMillis: 0}
    this.events = []
    this.cursorMillis = 0
    this.timelineStats = {
      nrOfEventsTotal: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }
    this.selectionStats = {
      nrOfEventsTotal: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }
    this.canvas.style.cursor = "pointer"
    this.resetTimelineTimeRange()
  }

  /**
   * Reset the timeline to its full extent.
   */
  resetTimelineTimeRange() {
    BucketCache.invalidateCache()
    this.visibleEventsTimeRange = {
      minMillis: this.allEventsTimeRange.minMillis,
      maxMillis: this.allEventsTimeRange.maxMillis
    }
    this.selectedEventsTimeRange = {minMillis: 0, maxMillis: 0, startDragMillis: 0, endDragMillis: 0}
    this.isMouseDown = false
    this.selectLeftTimeRangeBorder = true
    this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), false)
    this.draw()
  }

  /**
   * Shrink the selected time range to the given time. The border that snaps to the time is given by
   * this.selectLeftTimeRangeBorder.
   * @param dateTime The time to shrink the time range to.
   */
  shrinkTimeRange = (dateTime: Date) => {
    let redraw = true
    const millis = dateTime.getTime()
    if (isEmptyTimeRange(this.selectedEventsTimeRange)) {
      // If no selection yet, create a new selection.
      if (this.selectLeftTimeRangeBorder) {
        this.selectedEventsTimeRange.minMillis = millis
        this.selectedEventsTimeRange.maxMillis = this.visibleEventsTimeRange.maxMillis
      } else {
        this.selectedEventsTimeRange.minMillis = this.visibleEventsTimeRange.minMillis
        this.selectedEventsTimeRange.maxMillis = millis
      }
      this.selectLeftTimeRangeBorder = false
    } else if (inTimeRange(millis, this.selectedEventsTimeRange)) {
      // if a selection exists, shrink it to the given time.
      if (this.selectLeftTimeRangeBorder) {
        this.selectedEventsTimeRange.minMillis = millis
      } else {
        this.selectedEventsTimeRange.maxMillis = millis
      }
      this.selectLeftTimeRangeBorder = !this.selectLeftTimeRangeBorder
    } else {
      redraw = false
    }
    if (redraw) {
      this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), true)
      this.draw()
    }
  }

  /**
   * Extend the selected time range to the given time. The border that snaps to the time is the one closest
   * to the given time.
   * @param dateTime The time to extend the time range to.
   */
  extendTimeRange = (dateTime: Date) => {
    const millis = dateTime.getTime()
    if (isEmptyTimeRange(this.selectedEventsTimeRange)) {
      const mid = (this.visibleEventsTimeRange.minMillis + this.visibleEventsTimeRange.maxMillis) / 2
      if (millis > mid) {
        this.selectedEventsTimeRange.minMillis = millis
        this.selectedEventsTimeRange.maxMillis = this.visibleEventsTimeRange.maxMillis
      } else {
        this.selectedEventsTimeRange.minMillis = this.visibleEventsTimeRange.minMillis
        this.selectedEventsTimeRange.maxMillis = millis
      }
    } else if (!inTimeRange(millis, this.selectedEventsTimeRange)) {
      this.selectedEventsTimeRange = {
        minMillis: Math.min(millis, this.selectedEventsTimeRange.minMillis),
        maxMillis: Math.max(millis, this.selectedEventsTimeRange.maxMillis),
        startDragMillis: 0,
        endDragMillis: 0
      }
      this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), true)
      this.draw()
    }
  }

  /**
   * Get the select time range, or return the full range if no selection is made.
   */
  getSelectedOrFullTimeRange(): TimeRange {
    // This return the effective time range, which is the selection time range if it is not empty,
    // otherwise it returns the full timeline time range.
    const selectionRange = this.getCurrentSelectionTimeRange()
    const minMills = isEmptyTimeRange(selectionRange) ? this.visibleEventsTimeRange.minMillis : selectionRange.minMillis
    const maxMillis = isEmptyTimeRange(selectionRange)
      ? this.visibleEventsTimeRange.maxMillis
      : selectionRange.maxMillis
    return {minMillis: minMills, maxMillis: maxMillis}
  }

  /**
   * Set the selected time range.
   * @param timeRange The time range to set.
   * @param forceUpdate If true, the time range is updated even if it is the same as the previous one.
   * @param inhibitAutoZoom If true, the auto-zoom is inhibited.
   */
  setSelectedTimeRangeIfChanged(timeRange: TimeRange, forceUpdate: boolean, inhibitAutoZoom: boolean = false) {
    const nowMillis = new Date().getTime()
    if (
      forceUpdate ||
      isEmptyTimeRange(timeRange) ||
      (nowMillis - this.previousSetSelectedTimeRangeMillis >= this.minSetSelectedTimeRangeIntervalMillis &&
        (this.previousTimeRange.minMillis !== timeRange.minMillis ||
          this.previousTimeRange.maxMillis !== timeRange.maxMillis))
    ) {
      this.previousSetSelectedTimeRangeMillis = nowMillis
      this.previousTimeRange = {minMillis: timeRange.minMillis, maxMillis: timeRange.maxMillis}

      const stats = this.calculateEventStats(
        this.getCurrentSelectionTimeRange(),
        this.visibleEventsTimeRange,
        Storage.getHttpOverheadSizeInBytes()
      )
      this.selectionStats = stats.stats1
      this.timelineStats = stats.stats2
      this.onUpdateTimeRange(timeRange, forceUpdate, inhibitAutoZoom)
    }
  }

  /**
   * Replace all events in the timeline.
   * @param allEvents The new events to replace the current events with.
   * @param inhibitAutoZoom If true, the auto-zoom is inhibited.
   */
  replaceAllEvents(allEvents: Event[], inhibitAutoZoom = false) {
    BucketCache.invalidateCache()
    this.events = []
    this.cursorMillis = 0
    this.allEventsTimeRange = {minMillis: 0, maxMillis: 0}
    this.previousTimeRange = {minMillis: 0, maxMillis: 0}
    allEvents.forEach((event) => this.addEventWithoutRedrawAndUpdateFullTimeRange(event))
    if (!inhibitAutoZoom) {
      this.visibleEventsTimeRange = {
        minMillis: this.allEventsTimeRange.minMillis,
        maxMillis: this.allEventsTimeRange.maxMillis
      }
    }
    this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), true, inhibitAutoZoom)
    this.draw()
  }

  /**
   * Recalculate the sizes that are shown in the into panel (for example after changing the HTTP overhead) and redraw the timeline.
   */
  public recalculateSizesAndDraw() {
    this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), true, true)
    this.draw()
  }

  public drawCoordinate(coordinate: LngLat) {
    const level = Storage.getNumber(Settings.ShowMousePositionTileLevel, 13)
    const mapLibreTile = MapLibreTile.fromCoordinate(level, coordinate)
    const mapLibreTileText = `${mapLibreTile.level}/${mapLibreTile.x}/${mapLibreTile.y}`

    this.context.fillStyle = this.style.backgroundColorCoordinate
    this.context.fillRect(1, 1, this.style.coordinateWidth, this.style.coordinateHeight)
    this.context.fillStyle = this.style.textColorCoordinate
    this.context.font = this.style.fontCoordinate
    this.context.textAlign = "left"
    this.context.textBaseline = "top"
    this.context.fillText(
      `lng,lat: ${coordinate.lng.toFixed(6)},${coordinate.lat.toFixed(6)}   tile: ${mapLibreTileText}   level: ${level} "["=down "]""=up`,
      this.style.paddingLeftX + 1,
      this.style.paddingInfoY + 1
    )
  }

  /**
   * Redraw the timeline.
   */
  public draw() {
    const graphRect = this.getGraphRect()
    const axisRectX = this.getAxisRectX()
    const axisRectY = this.getAxisRectY()
    const infoRect = this.getInfoRect()
    this.buckets = BucketCache.createBucketsCached(this.events, this.visibleEventsTimeRange, graphRect.width)

    // Clear graph area.
    this.context.fillStyle = this.style.backgroundColor
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)

    //Redraw graph area.
    this.drawAxisX(axisRectX)
    this.drawAxisY(axisRectY, this.buckets)
    this.drawBars(graphRect, this.buckets)

    // Draw the selected area and the cursor.
    this.drawSelection()
    this.drawCursor()

    // Draw the text info.
    this.drawInfo(infoRect)

    // Set the state of the buttons.
    this.buttonZoomIn.disabled = isEmptyTimeRange(this.selectedEventsTimeRange)
    this.buttonZoomOut.disabled =
      isEmptyTimeRange(this.allEventsTimeRange) ||
      (this.visibleEventsTimeRange.minMillis <= this.allEventsTimeRange.minMillis &&
        this.visibleEventsTimeRange.maxMillis >= this.allEventsTimeRange.maxMillis)
    this.buttonSave.disabled = isEmptyTimeRange(this.selectedEventsTimeRange)
  }

  /**
   * Mouse down: see if we need to selection something.
   * @param event The mouse event.
   */
  private readonly onMouseDown = (event: MouseEvent) => {
    event.preventDefault()
    if (event.button !== 0 || isEmptyTimeRange(this.visibleEventsTimeRange)) {
      return
    }

    // Remember the mouse state for mouse move and mouse up events.
    this.isMouseDown = true

    // Get the point and time of the mouse position.
    const rect = this.getGraphRect()
    const point = this.convertMouseXYToGraphXY(event)
    this.mouseDownPoint = {x: point.x, y: point.y}

    const pointInMillis =
      this.visibleEventsTimeRange.minMillis +
      (point.x / rect.width) * (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)

    // Convert millis to pixels.
    const selectionTimeRange = this.getCurrentSelectionTimeRange()

    // Snap to left/right edge when close to edge.
    const edgeInPixels = 10
    const minEdgeX =
      selectionTimeRange.minMillis === 0
        ? 0
        : ((selectionTimeRange.minMillis - this.visibleEventsTimeRange.minMillis) /
            (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)) *
          rect.width
    const maxEdgeX =
      selectionTimeRange.maxMillis === 0
        ? 0
        : ((selectionTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis) /
            (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)) *
          rect.width
    const isNearMinEdge = Math.abs(point.x - minEdgeX) < edgeInPixels
    const isNearMaxEdge = Math.abs(point.x - maxEdgeX) < edgeInPixels

    if (isNearMinEdge || isNearMaxEdge) {
      this.selectedEventsTimeRange.startDragMillis = 0
      this.selectedEventsTimeRange.endDragMillis = 0
      // Extend selection if near existing time range edges.
      if (isNearMinEdge) {
        this.selectedEventsTimeRange.minMillis = pointInMillis
      } else {
        this.selectedEventsTimeRange.maxMillis = pointInMillis
      }
    }

    // Move time range if clicked inside the selection.
    else if (inTimeRange(pointInMillis, selectionTimeRange)) {
      this.selectedEventsTimeRange.startDragMillis = pointInMillis
      this.selectedEventsTimeRange.endDragMillis = pointInMillis
    } else {
      // Remove time range if clicked outside.
      this.selectedEventsTimeRange.startDragMillis = 0
      this.selectedEventsTimeRange.endDragMillis = 0
      this.selectedEventsTimeRange.minMillis = 0
      this.selectedEventsTimeRange.maxMillis = 0
    }
    this.draw()
  }

  /**
   * Mouse up: see if we need to stop dragging/expanding the selection.
   * @param event The mouse event.
   */
  private readonly onMouseUp = (event: MouseEvent) => {
    event.preventDefault()

    // Only react to left mouse button and if the mouse was down.
    if (event.button !== 0 || !this.isMouseDown) {
      return
    }

    // Reset mouse down state.
    this.isMouseDown = false
    const dateTimeFormat = Storage.get(Settings.UseTimeFormatUTC) ? DateTimeFormat.UTCTime : DateTimeFormat.LocalTime

    // Get the point and time of the mouse.
    const point = this.convertMouseXYToGraphXY(event)
    let shiftMillis = this.selectedEventsTimeRange.endDragMillis - this.selectedEventsTimeRange.startDragMillis

    if (!isEmptyTimeRange(this.selectedEventsTimeRange) && shiftMillis !== 0) {
      this.selectedEventsTimeRange.minMillis = Math.max(
        this.visibleEventsTimeRange.minMillis,
        this.selectedEventsTimeRange.minMillis + shiftMillis
      )
      this.selectedEventsTimeRange.maxMillis = Math.min(
        this.visibleEventsTimeRange.maxMillis,
        this.selectedEventsTimeRange.maxMillis + shiftMillis
      )
    }
    this.selectedEventsTimeRange.startDragMillis = 0
    this.selectedEventsTimeRange.endDragMillis = 0

    // If shift was pressed, the display range of the timeline needs to zoom in/out.
    const selectionRange = this.getCurrentSelectionTimeRange()
    if (event.shiftKey) {
      // Check if the mouse actually selected a range, or it was a single click.
      if (selectionRange.minMillis === selectionRange.maxMillis) {
        // Single click; reset timeline range.
        this.visibleEventsTimeRange = {
          minMillis: this.allEventsTimeRange.minMillis,
          maxMillis: this.allEventsTimeRange.maxMillis
        }
      } else {
        // Selected a range; set new timeline range.
        this.visibleEventsTimeRange = {minMillis: selectionRange.minMillis, maxMillis: selectionRange.maxMillis}
      }

      // Reset selection.
      this.selectedEventsTimeRange = {minMillis: 0, maxMillis: 0, startDragMillis: 0, endDragMillis: 0}
      this.inspectorWindow.setVisible(false)
    } else if (this.mouseDownPoint?.x === point.x && this.mouseDownPoint?.y === point.y) {
      // Show the inspector for a select bucket, only if the user wasn't dragging the selection.
      const rect = this.getGraphRect()

      // Determine time of mouse pointer.
      const pointInMillis =
        this.visibleEventsTimeRange.minMillis +
        (point.x / rect.width) * (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)

      // Determine bucket index under mouse pointer.
      const totalNrOfBuckets = Math.floor(rect.width / BucketCache.bucketSizeInPixels)
      const bucketIndex =
        this.visibleEventsTimeRange.maxMillis === this.visibleEventsTimeRange.minMillis
          ? 0
          : MathUtils.limitValue(
              Math.floor(
                ((pointInMillis - this.visibleEventsTimeRange.minMillis) /
                  (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)) *
                  totalNrOfBuckets
              ),
              0,
              totalNrOfBuckets - 1
            )

      // Show the events in the buckets.
      const bucket = this.buckets[bucketIndex]
      const eventsInBucket = bucket.nrOfEventsTotal
      if (eventsInBucket) {
        const title =
          `Time: ${DateTimeUtils.formatTimeOnly(new Date(bucket.timeRange.minMillis), dateTimeFormat)}` +
          ` to ${DateTimeUtils.formatTimeOnly(new Date(bucket.timeRange.maxMillis), dateTimeFormat)}`
        const selectedEvents: Event[] = []
        for (const event of this.events) {
          if (inTimeRange(event.timeMillis, bucket.timeRange)) {
            // Get the metadata.
            const metadata = event.metadata ? MetadataStore.retrieve(event.metadata) : undefined
            if (metadata) {
              if (metadata.feature) {
                // If a feature is attached, show it, in the inspector and on the map.
                selectedEvents.push(metadata.feature)
              } else {
                // Otherwise, just show the metadata.
                selectedEvents.push(metadata)
              }
            }
          }
        }
        this.inspectorWindow.show(title, selectedEvents)
      }
    } else {
      this.inspectorWindow.setVisible(false)
    }
    this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), true)
    this.draw()
  }

  /**
   * See if we need to expand/move the selection.
   * @param event The mouse event.
   */
  private readonly onMouseMove = (event: MouseEvent) => {
    event.preventDefault()

    // Get the mouse location and wait with dragging until a threshold is passed. Without this threshold,
    // a selection is created too often, which prevents selecting buckets.
    const rect = this.getGraphRect()
    const point = this.convertMouseXYToGraphXY(event)

    const selectionTimeRange = this.getCurrentSelectionTimeRange()

    // Determine the time of the mouse pointer.
    const pointInMillis =
      this.visibleEventsTimeRange.minMillis +
      (point.x / rect.width) * (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)
    const extendLeft = pointInMillis < (selectionTimeRange.maxMillis + selectionTimeRange.minMillis) / 2

    if (this.isMouseDown) {
      if (this.mouseDownPoint && isEmptyTimeRange(this.selectedEventsTimeRange)) {
        if (Math.abs(point.x - this.mouseDownPoint.x) < this.minMouseMoveX) {
          // Threshold not reached, don't create a selection yet.
          return
        } else {
          // Once we're past the threshold, set the selection to the mouse down point.
          const mouseDownPointInMillis =
            this.visibleEventsTimeRange.minMillis +
            (this.mouseDownPoint.x / rect.width) *
              (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)
          this.selectedEventsTimeRange.minMillis = mouseDownPointInMillis
          this.selectedEventsTimeRange.maxMillis = mouseDownPointInMillis
          this.mouseDownPoint = undefined
        }
      }

      // If the mouse is down, the selection is being dragged or extended.
      if (this.selectedEventsTimeRange.startDragMillis !== 0) {
        this.selectedEventsTimeRange.endDragMillis = pointInMillis
      } else if (extendLeft) {
        this.selectedEventsTimeRange.minMillis = pointInMillis
      } else {
        this.selectedEventsTimeRange.maxMillis = pointInMillis
      }
      // Update the time range.
      if (!isEmptyTimeRange(this.selectedEventsTimeRange)) {
        this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), false)
      }
      this.cursorMillis = 0
    } else {
      // If the mouse is not down, the cursor is just hovering. Need to redraw the information area.
      this.cursorMillis = pointInMillis
    }
    this.draw()
  }

  /**
   * Mouse leave: stop tracking the mouse for a bit.
   */
  private readonly onMouseLeave = () => {
    // Don't reset the mouse down state - the user may return the mouse and continue dragging.
    this.cursorMillis = 0
    this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), false, true)
    this.draw()
  }

  /**
   * Right click: extend the selection to the time of the mouse pointer.
   * @param event The mouse event.
   */
  private readonly onContextMenu = (event: MouseEvent) => {
    event.preventDefault()

    // The mouse right-click extends the selection to the time of the mouse pointer.
    this.selectLeftTimeRangeBorder = true
    const rect = this.getGraphRect()
    const point = this.convertMouseXYToGraphXY(event)

    // Determine the time of the mouse pointer.
    const pointInMillis =
      this.visibleEventsTimeRange.minMillis +
      (point.x / rect.width) * (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)

    const dateTime = new Date(pointInMillis)

    // Extend the selection to the time of the mouse pointer.
    Logger.log.info(`Extend selection to time: ${DateTimeUtils.formatDateWithTime(dateTime)}`)
    this.extendTimeRange(dateTime)
  }

  /**
   * Zoom in on X-axis.
   */
  private readonly onButtonZoomIn = () => {
    if (!isEmptyTimeRange(this.selectedEventsTimeRange)) {
      this.visibleEventsTimeRange = {
        minMillis: this.selectedEventsTimeRange.minMillis,
        maxMillis: this.selectedEventsTimeRange.maxMillis
      }
      this.selectedEventsTimeRange = {minMillis: 0, maxMillis: 0, startDragMillis: 0, endDragMillis: 0}
      this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), true)
      this.draw()
    }
  }

  /**
   * Zoom out on X-axis.
   */
  private readonly onButtonZoomOut = () => {
    this.visibleEventsTimeRange = {
      minMillis: this.allEventsTimeRange.minMillis,
      maxMillis: this.allEventsTimeRange.maxMillis
    }
    this.selectedEventsTimeRange = {minMillis: 0, maxMillis: 0, startDragMillis: 0, endDragMillis: 0}
    this.setSelectedTimeRangeIfChanged(this.getSelectedOrFullTimeRange(), true)
    this.draw()
  }

  /**
   * Save the data in data files within a time range.
   */
  private readonly onButtonSave = () => {
    const dateTimeFormat = Storage.get(Settings.UseTimeFormatUTC) ? DateTimeFormat.UTCTime : DateTimeFormat.LocalTime
    const allDataFileContentIds = DataStore.getDataFileContentIds()
    const selectedDataFileContents = allDataFileContentIds
      .map((id) => DataStore.getDataFileContent(id))
      .filter((dataFileContent) => dataFileContent.show)

    if (selectedDataFileContents.length === 0) {
      InfoDialog.show("No files are selected for saving.")
      return
    }

    const message = `<h2>SAVE DATA IN TIME RANGE</h2>\
<h3>Selected time range:</h3>\
${DateTimeUtils.formatTimeOnly(new Date(this.selectedEventsTimeRange.minMillis), dateTimeFormat)}\
 - ${DateTimeUtils.formatTimeOnly(new Date(this.selectedEventsTimeRange.maxMillis), dateTimeFormat)}\
<h3>Selected files:</h3><lu>\
 ${selectedDataFileContents.map((dataFileContent) => "<li>" + dataFileContent.name + "</li>").join("")}</lu>\
 <br>Save the data within this time range for these files?`

    YesNoDialog.show(message, () =>
      this.saveDataFromSelectedTimeRange(this.selectedEventsTimeRange, selectedDataFileContents)
    )
  }

  /**
   * Save data from the selected files, within the given time range.
   * @param timeRange The time range to save.
   * @param selectedDataFileContents The selected data files.
   */
  private saveDataFromSelectedTimeRange(timeRange: TimeRange, selectedDataFileContents: DataFileContent[]) {
    // If a TTP header is found, it replaces the default header.
    let ttpHeader = "BEGIN:ApplicationVersion=TomTom Positioning 0.7"
    const ttpFooter = "END"

    const dataToSave = selectedDataFileContents
      .map((dataFileContent) => {
        // Find original header line in TTP file.
        const isTtpFile = FileUtils.hasFileNameExtension(dataFileContent.name, ["ttp"])
        if (isTtpFile) {
          for (const sourceLineWithTime of dataFileContent.sourceLinesWithTime) {
            if (/^\s*BEGIN\s*:\s*ApplicationVersion\s*=/.exec(sourceLineWithTime.line)) {
              ttpHeader = sourceLineWithTime.line
              break
            }
          }
        }
        return {
          fileName: `partial_${dataFileContent.name}`,
          lines: dataFileContent.sourceLinesWithTime

            // Filter: outside of range, TIMESTAMP_ALWAYS_HIDE and END; keep: inside range and TIMESTAMP_ALWAYS_SHOW.
            .filter((sourceLineWithTime) => {
              const time = sourceLineWithTime.time
              const line = sourceLineWithTime.line.trim()
              return (
                line.length > 0 &&
                line !== "END" &&
                time !== TIMESTAMP_ALWAYS_HIDE &&
                (time === TIMESTAMP_ALWAYS_SHOW || (timeRange.minMillis <= time && time <= timeRange.maxMillis))
              )
            })
            .map((sourceLineWithTime) => sourceLineWithTime.line)
        }
      })
      .filter((data) => data.lines.length > 0)

    if (dataToSave.length === 0) {
      InfoDialog.show("No data found to save in the selected time range.")
      return
    }
    dataToSave.forEach((data) => {
      const isTtpFile = FileUtils.hasFileNameExtension(data.fileName, ["ttp"])

      Logger.log.info(`Saving time range to ${isTtpFile ? "TTP " : ""}file: ${data.fileName}...`)
      const contents = `${isTtpFile ? ttpHeader + "\n" : ""}${data.lines.join("\n")}\n${isTtpFile ? ttpFooter + "\n" : ""}`
      FileUtils.triggerRateLimitedDownloadFile(data.fileName, contents)
    })
  }

  /**
   * Caclulate the statistics for the given ranges.
   * @param range1 The first range (e.g. current selection).
   * @param range2 The second range (e.g. visible events).
   * @param httpOverheadSizeInBytes Overhead to be used.
   */
  private calculateEventStats(
    range1: TimeRange,
    range2: TimeRange,
    httpOverheadSizeInBytes: number
  ): {
    stats1: EventStats
    stats2: EventStats
  } {
    let stats1: EventStats = {
      nrOfEventsTotal: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }
    let stats2: EventStats = {
      nrOfEventsTotal: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }

    /**
     * Calculate the total number of items and the total size in bytes for the given ranges.
     * If 'size' is not defined for an event, the event does not have a size in its definition,
     * so it does not mark the range as incomplete. If it is '-1', it was missing where it
     * was supposed to be found, so it marks the range as incomplete.
     */
    for (const event of this.events) {
      if (inTimeRange(event.timeMillis, range1)) {
        ++stats1.nrOfEventsTotal
        if (event.sizeInBytes !== Parser.SIZE_EXPECTED_BUT_NOT_FOUND) {
          // Size was found (value) or not expected at all (undefined).
          ++stats1.nrOfEventsWithSize
          if (event.sizeInBytes !== undefined) {
            stats1.sizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            if (event.duplicates && event.duplicates > 1) {
              stats1.duplicatesSizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            }
          }
        }
      }
      if (inTimeRange(event.timeMillis, range2)) {
        ++stats2.nrOfEventsTotal
        if (event.sizeInBytes !== Parser.SIZE_EXPECTED_BUT_NOT_FOUND) {
          // Size was found (value) or not expected at all (undefined).
          ++stats2.nrOfEventsWithSize
          if (event.sizeInBytes !== undefined) {
            stats2.sizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            if (event.duplicates && event.duplicates > 1) {
              stats2.duplicatesSizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            }
          }
        }
      }
    }
    return {
      stats1: stats1,
      stats2: stats2
    }
  }

  /**
   * Add a single event and adjust time range.
   * @param event The event to add.
   */
  private addEventWithoutRedrawAndUpdateFullTimeRange(event: Event) {
    this.allEventsTimeRange.minMillis =
      this.allEventsTimeRange.minMillis === 0
        ? event.timeMillis
        : Math.min(this.allEventsTimeRange.minMillis, event.timeMillis)

    this.allEventsTimeRange.maxMillis =
      this.allEventsTimeRange.maxMillis === 0
        ? event.timeMillis
        : Math.max(this.allEventsTimeRange.maxMillis, event.timeMillis)
    this.events.push(event)
  }

  /**
   * Convert mouse XY coordinate to relative coordinate in graph.
   * @param event The mouse event.
   * @returns The relative coordinate in the graph.
   */
  private convertMouseXYToGraphXY(event: MouseEvent): PointXY {
    const graphRect = this.getGraphRect()
    const clientRect = this.canvas.getBoundingClientRect()
    const x = event.clientX - clientRect.left - graphRect.x
    const y = event.clientY - clientRect.top - graphRect.y
    return {x: x, y: y}
  }

  /**
   * On resize: adjust the canvas size.
   */
  private readonly onResize = () => {
    this.canvas.width = this.container.clientWidth
    this.canvas.height = this.container.clientHeight
    this.draw()
  }

  private getTextHeight() {
    let element = document.createElement("div")
    element.style.font = this.style.font
    element.textContent = "a"
    document.body.appendChild(element)
    let height = element.offsetHeight
    document.body.removeChild(element)
    return height
  }

  private getTextWidth(text: string) {
    this.context.font = this.style.font
    let metrics = this.context.measureText(text)
    return metrics.width
  }

  private getGraphRect(): Rectangle {
    return {
      x: this.style.paddingLeftX + this.style.axisWidthY,
      y: this.style.paddingTopY,
      width: this.canvas.width - this.style.paddingLeftX - this.style.paddingRightX - this.style.axisWidthY,
      height: this.canvas.height - this.style.paddingTopY - this.style.paddingBottomY - this.style.axisHeightX
    }
  }

  private getAxisRectX(): Rectangle {
    return {
      x: this.style.paddingLeftX + this.style.axisWidthY,
      y: this.canvas.height - this.style.paddingBottomY - this.style.axisHeightX,
      width: this.canvas.width - this.style.paddingLeftX - this.style.paddingRightX - this.style.axisWidthY,
      height: this.style.axisHeightX
    }
  }

  private getAxisRectY(): Rectangle {
    return {
      x: this.style.paddingLeftX,
      y: this.style.paddingTopY,
      width: this.style.axisWidthY,
      height: this.canvas.height - this.style.paddingTopY - this.style.paddingBottomY - this.style.axisHeightX
    }
  }

  private getInfoRect(): Rectangle {
    return {
      x: this.style.paddingLeftX + this.style.axisWidthY,
      y: this.style.paddingInfoY,
      width: this.canvas.width - this.style.paddingLeftX - this.style.paddingRightX - this.style.axisWidthY,
      height: this.style.infoHeight
    }
  }

  private getCurrentSelectionTimeRange(): TimeRange {
    // This return the selected time, updated while dragging the selection.
    if (isEmptyTimeRange(this.selectedEventsTimeRange)) {
      return {minMillis: 0, maxMillis: 0}
    } else {
      const shift = this.selectedEventsTimeRange.endDragMillis - this.selectedEventsTimeRange.startDragMillis
      return {
        minMillis: Math.min(this.selectedEventsTimeRange.minMillis, this.selectedEventsTimeRange.maxMillis) + shift,
        maxMillis: Math.max(this.selectedEventsTimeRange.minMillis, this.selectedEventsTimeRange.maxMillis) + shift
      }
    }
  }

  private isTimelineRangeInSingleDay(): boolean {
    const date1 = new Date(this.visibleEventsTimeRange.minMillis)
    const date2 = new Date(this.visibleEventsTimeRange.maxMillis)
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  private formatTimeMillis(timeMillis: number) {
    const dateTimeFormat = Storage.get(Settings.UseTimeFormatUTC) ? DateTimeFormat.UTCTime : DateTimeFormat.LocalTime
    if (this.isTimelineRangeInSingleDay()) {
      return DateTimeUtils.formatTimeOnly(new Date(timeMillis), dateTimeFormat)
    } else {
      return DateTimeUtils.formatDateWithTime(new Date(timeMillis), dateTimeFormat)
    }
  }

  private drawAxisX(rect: Rectangle) {
    this.drawLine({x: rect.x, y: rect.y}, {x: rect.x + rect.width, y: rect.y}, this.style.axisColor, 1)
    const range = this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis
    if (range < 0) {
      return
    }
    const textWidth = this.getTextWidth(this.formatTimeMillis(this.visibleEventsTimeRange.maxMillis))
    const count = Math.max(1, Math.min(range / 1000, Math.floor(rect.width / (2 * textWidth))))

    for (let i = 0; i <= count; ++i) {
      const label = this.formatTimeMillis(this.visibleEventsTimeRange.minMillis + (range * i) / count)
      const midX = rect.x + (i * rect.width) / count
      this.drawLine({x: midX, y: rect.y}, {x: midX, y: rect.y + 7}, this.style.axisColor, 1)
      this.drawTextAxis(label, {x: midX, y: rect.y + 10}, "center", "top")
    }
  }

  private drawAxisY(rect: Rectangle, buckets: Bucket[]) {
    this.drawLine(
      {x: rect.x + rect.width, y: rect.y},
      {x: rect.x + rect.width, y: rect.y + rect.height},
      this.style.axisColor,
      1
    )
    let maxValue = 0
    for (const element of buckets) {
      maxValue = Math.max(maxValue, element.nrOfEventsTotal)
    }
    let count = Math.max(1, Math.min(maxValue, Math.floor(rect.height / this.textHeight)))
    let verticalSpacing = rect.height / count

    for (let i = 0; i <= count; ++i) {
      const midY = rect.y + i * verticalSpacing
      this.drawLine({x: rect.x + rect.width - 5, y: midY}, {x: rect.x + rect.width, y: midY}, this.style.axisColor, 1)
      const value = maxValue * (1 - i / count)
      this.drawTextAxis(
        `${i === count || value ? value.toFixed(0) : ""}`,
        {x: rect.x + rect.width - 8, y: midY},
        "right",
        "middle"
      )
    }
  }

  private drawInfo(rect: Rectangle) {
    const dateTimeFormat = Storage.get(Settings.UseTimeFormatUTC) ? DateTimeFormat.UTCTime : DateTimeFormat.LocalTime
    if (isEmptyTimeRange(this.visibleEventsTimeRange)) {
      return
    }

    let line = ""
    if (this.cursorMillis !== 0) {
      line += `CURSOR:   ${this.formatTimeMillis(this.cursorMillis)}   |   `
    }

    if (this.isTimelineRangeInSingleDay()) {
      line += `DAY:  ${DateTimeUtils.formatDateWithoutTime(new Date(this.visibleEventsTimeRange.minMillis), dateTimeFormat)}   |   `
    }

    if (!isEmptyTimeRange(this.selectedEventsTimeRange)) {
      const selectionRange = this.getCurrentSelectionTimeRange()
      line += `SELECTION:   ${this.formatTimeMillis(selectionRange.minMillis)} to ${this.formatTimeMillis(
        selectionRange.maxMillis
      )}${this.selectionStats.sizeInBytes ? "   " + StringUtils.formatSizeInBytes(this.selectionStats.sizeInBytes) : ""}${
        this.selectionStats.duplicatesSizeInBytes
          ? " (" + StringUtils.formatSizeInBytes(this.selectionStats.duplicatesSizeInBytes) + " in duplicates)"
          : ""
      }   ${this.selectionStats.nrOfEventsTotal} items${
        this.selectionStats.nrOfEventsTotal === this.selectionStats.nrOfEventsWithSize
          ? ""
          : " (" + (this.selectionStats.nrOfEventsTotal - this.selectionStats.nrOfEventsWithSize) + " w/o size)"
      }   |   `
    }

    line += `TIMELINE:   ${this.formatTimeMillis(this.visibleEventsTimeRange.minMillis)} to ${this.formatTimeMillis(
      this.visibleEventsTimeRange.maxMillis
    )}${this.timelineStats.sizeInBytes ? "   " + StringUtils.formatSizeInBytes(this.timelineStats.sizeInBytes) : ""}${
      this.timelineStats.duplicatesSizeInBytes
        ? " (" + StringUtils.formatSizeInBytes(this.timelineStats.duplicatesSizeInBytes) + " in duplicates)"
        : ""
    }   ${this.timelineStats.nrOfEventsTotal} items${
      this.timelineStats.nrOfEventsTotal === this.timelineStats.nrOfEventsWithSize
        ? ""
        : " (" + (this.timelineStats.nrOfEventsTotal - this.timelineStats.nrOfEventsWithSize) + " w/o size)"
    }`
    let point = {x: rect.x + rect.width, y: rect.y}
    this.drawText(line, point, "right", "top")

    line = "Left-click = select range  |  Right-click = extend/shrink range  |  Red = ERROR  |  Yellow = WARNING"
    point = {x: rect.x + rect.width, y: rect.y + this.textHeight}
    this.drawText(line, point, "right", "top", this.style.textColorHelp)
  }

  private drawSelection() {
    if (isEmptyTimeRange(this.selectedEventsTimeRange)) {
      return
    }
    const selectionRange = this.getCurrentSelectionTimeRange()
    this.context.fillStyle = this.style.selectionColor
    const rect = this.getGraphRect()
    let startX =
      rect.x +
      ((selectionRange.minMillis - this.visibleEventsTimeRange.minMillis) /
        (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)) *
        rect.width
    let endX =
      rect.x +
      ((selectionRange.maxMillis - this.visibleEventsTimeRange.minMillis) /
        (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)) *
        rect.width
    startX = Math.min(Math.max(startX, rect.x), rect.x + rect.width)
    endX = Math.min(Math.max(endX, rect.x), rect.x + rect.width)
    this.context.fillRect(startX, rect.y, Math.max(BucketCache.bucketSizeInPixels, endX - startX), rect.height)
    const graphRect = this.getGraphRect()
    const sizeText = this.selectionStats.sizeInBytes
      ? StringUtils.formatSizeInBytes(this.selectionStats.sizeInBytes) +
        (this.selectionStats.nrOfEventsTotal === this.selectionStats.nrOfEventsWithSize
          ? ""
          : " (" + (this.selectionStats.nrOfEventsTotal - this.selectionStats.nrOfEventsWithSize) + " incomplete)")
      : ""
    this.drawTextSize(sizeText, {x: (startX + endX) / 2, y: graphRect.y + graphRect.height / 1.5}, "center", "top")
    if (endX - startX > this.style.arrowSize) {
      this.drawTextArrow(
        "<",
        {x: startX + 2, y: graphRect.y + graphRect.height / 2},
        "left",
        "middle",
        this.selectLeftTimeRangeBorder,
        Math.abs(endX - startX) <= this.style.arrowSize * 2
      )
      this.drawTextArrow(
        ">",
        {x: endX - 2, y: graphRect.y + graphRect.height / 2},
        "right",
        "middle",
        !this.selectLeftTimeRangeBorder,
        Math.abs(endX - startX) <= this.style.arrowSize * 2
      )
    }
  }

  private drawCursor() {
    if (this.cursorMillis !== 0) {
      this.context.fillStyle = this.style.axisColor
      const rect = this.getGraphRect()
      let x =
        rect.x +
        ((this.cursorMillis - this.visibleEventsTimeRange.minMillis) /
          (this.visibleEventsTimeRange.maxMillis - this.visibleEventsTimeRange.minMillis)) *
          rect.width
      if (rect.x - this.style.paddingAxisX <= x && x <= rect.x + rect.width + this.style.paddingAxisX) {
        this.drawLine({x: x, y: rect.y}, {x: x, y: rect.y + rect.height}, this.style.pointerColor, 1)
      }
    }
  }

  private drawBars(rect: Rectangle, buckets: Bucket[]) {
    let maxValue = 0
    for (const element of buckets) {
      maxValue = Math.max(maxValue, element.nrOfEventsTotal)
    }

    const barWidth = rect.width / buckets.length
    for (let i = 0; i < buckets.length; ++i) {
      const bucket = buckets[i]
      if (bucket.nrOfEventsTotal === 0) {
        continue
      }

      const posX = rect.x + i * barWidth + 0.5
      const posY = rect.y + rect.height

      const heightEventsTotal = Math.max(
        bucket.nrOfEventsTotal ? this.minBucketHeight : 0,
        (rect.height * bucket.nrOfEventsTotal) / maxValue
      )
      const heightEventsWithLocation = Math.max(
        bucket.nrOfEventsWithLocation ? this.minBucketHeight : 0,
        (rect.height * bucket.nrOfEventsWithLocation) / maxValue
      )
      const heightEventsWithWarning = Math.max(
        bucket.nrOfEventsWithWarning ? this.minBucketHeight : 0,
        (rect.height * bucket.nrOfEventsWithWarning) / maxValue
      )
      const heightEventsWithError = Math.max(
        bucket.nrOfEventsWithError ? this.minBucketHeight : 0,
        (rect.height * bucket.nrOfEventsWithError) / maxValue
      )

      this.context.fillStyle = this.style.bucketColorEventsTotal
      this.context.fillRect(posX, posY, barWidth - 1, -heightEventsTotal)

      this.context.fillStyle = this.style.bucketColorEventsWithLocation
      this.context.fillRect(posX, posY, barWidth - 1, -heightEventsWithLocation)

      this.context.fillStyle = this.style.bucketColorEventsWithError
      this.context.fillRect(posX, posY, barWidth - 1, -heightEventsWithError)

      this.context.fillStyle = this.style.bucketColorEventsWithWarning
      this.context.fillRect(posX, posY - heightEventsWithError, barWidth - 1, -heightEventsWithWarning)

      // If there were events with a locations, make sure we see a little bit of that in the bars.c
      this.context.fillStyle = this.style.bucketColorEventsWithLocation
      this.context.fillRect(
        posX,
        posY - heightEventsWithError - heightEventsWithWarning,
        barWidth - 1,
        heightEventsWithLocation ? -(this.minBucketHeight / 2) : 0
      )

      // Place a small dot below the timeline where the warnings and errors are found.
      if (heightEventsWithError > 0) {
        this.context.fillStyle = this.style.bucketColorEventsWithErrorDot
        this.context.fillRect(posX + 1, posY + 3, barWidth - 3, 3)
      } else if (heightEventsWithWarning > 0) {
        this.context.fillStyle = this.style.bucketColorEventsWithWarningDot
        this.context.fillRect(posX + 1, posY + 3, barWidth - 3, 3)
      }
    }
  }

  private drawLine(from: PointXY, to: PointXY, color: string, width: number) {
    this.context.strokeStyle = color
    this.context.lineWidth = width
    this.context.beginPath()
    this.context.moveTo(from.x, from.y)
    this.context.lineTo(to.x, to.y)
    this.context.stroke()
  }

  private drawText(
    text: string,
    point: PointXY,
    textAlign: CanvasTextAlign,
    textBaseline: CanvasTextBaseline,
    color: string = this.style.textColorStats
  ) {
    this.context.fillStyle = color
    this.context.font = this.style.font
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextAxis(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorAxis
    this.context.font = this.style.fontAxis
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextSize(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorSize
    this.context.font = this.style.fontSize
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextArrow(
    text: string,
    point: PointXY,
    textAlign: CanvasTextAlign,
    textBaseline: CanvasTextBaseline,
    select: boolean,
    small: boolean
  ) {
    this.context.fillStyle = select ? this.style.textColorArrowSelect : this.style.textColorArrow
    this.context.font = small ? this.style.fontArrowSmall : this.style.fontArrowLarge
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }
}

export default Timeline
