/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {MenuAction, MenuItem} from "./menuTypes"
import assert from "../common/assert"
import {HTML_MENUBAR} from "../html/htmlElementId"
import {
  HTML_CLASS_DROPDOWN,
  HTML_CLASS_DROPDOWN_BUTTON,
  HTML_CLASS_DROPDOWN_CONTENT,
  HTML_CLASS_MENU_ITEM_SHORTCUT
} from "../html/htmlClassId"
import {Html} from "../html/html"

export class MenuBar {
  private readonly onSelectMap: (key: string) => void

  constructor(
    mapsMenu: MenuItem[],
    viewMenu: MenuItem[],
    layersMenu: MenuItem[],
    othersMenu: MenuItem[],
    onSelectMap: (key: string) => void
  ) {
    this.onSelectMap = onSelectMap

    let menuBarDiv = Html.getDefinedHtmlElementById(HTML_MENUBAR)
    assert(!menuBarDiv.hasChildNodes(), "MenuBar: menubar is already initialized")

    // Maps menu.
    menuBarDiv.appendChild(
      this.createDropdownGroup(`Map`, mapsMenu, false, (name, button, action, id) => {
        button.textContent = name
        this.onSelectMap(id!)
      })
    )

    // View menu.
    menuBarDiv.appendChild(
      this.createDropdownGroup("View", viewMenu, true, (name, button, action) => action && action())
    )

    // Layers menu.
    menuBarDiv.appendChild(
      this.createDropdownGroup("Layers", layersMenu, true, (name, button, action) => action && action())
    )

    // Other actions.
    menuBarDiv.appendChild(
      this.createDropdownGroup("Others", othersMenu, true, (name, button, action) => action && action())
    )
  }

  static updateMenuItemToggle(menuItemId: string, value: boolean) {
    const menuItem = Html.getDefinedHtmlElementById(menuItemId)
    const posSpace = menuItem.innerHTML.lastIndexOf(">") + 1 // Find the position of the last '>', 0 if not found.
    const text = menuItem.innerHTML.slice(posSpace)
    menuItem.innerHTML = `<code>${value ? '<span style="color: green;">&check;</span>' : '<span style="color: red;">&cross;</span>'}</span></code> ${text}`
  }

  public onWindowClick = (event: MouseEvent) => {
    const target = event.target as Element
    if (!target.matches(`.${HTML_CLASS_DROPDOWN_BUTTON}`)) {
      this.hideDropDowns()
    }
  }

  private createDropdownGroup(title: string, items: MenuItem[], showShortcut: boolean, menuAction?: MenuAction) {
    const div = document.createElement("div")
    div.classList.add(HTML_CLASS_DROPDOWN)

    // Menu bar item button.
    const button = document.createElement("button")
    button.classList.add(HTML_CLASS_DROPDOWN_BUTTON)
    button.textContent = title
    button.addEventListener("click", () => this.toggleButton(title))
    div.appendChild(button)

    // Menu bar list item button.
    const list = document.createElement("div")
    list.classList.add(HTML_CLASS_DROPDOWN_CONTENT)
    list.id = Html.htmlElementIdForDropdown(title)
    for (const item of items) {
      if ("separator" in item) {
        let hrElement = document.createElement("hr")
        list.appendChild(hrElement)
      } else {
        let aElement = document.createElement("a")
        aElement.href = "#"

        let menuItem = document.createElement("div")
        menuItem.classList.add("menu-item")

        let itemName = document.createElement("span")
        if (item.id !== undefined) {
          itemName.id = Html.htmlElementIdForMenuItem(item.id)
        }
        itemName.innerText = item.name
        menuItem.appendChild(itemName)

        if (showShortcut && item.shortcut) {
          let shortcut = document.createElement("span")
          shortcut.classList.add(HTML_CLASS_MENU_ITEM_SHORTCUT)
          shortcut.innerText = `${item.shortcut}`
          menuItem.appendChild(shortcut)
        }

        menuItem.addEventListener("click", () =>
          menuAction ? menuAction(item.name, button, item.action, item.id) : {}
        )
        aElement.appendChild(menuItem)
        list.appendChild(aElement)
      }
    }

    div.appendChild(list)
    return div
  }

  private hideDropDowns() {
    const list = document.getElementsByClassName(HTML_CLASS_DROPDOWN_CONTENT)
    for (let element of Array.from(list)) {
      element.classList.remove("show")
    }
  }

  private readonly toggleButton = (id: string) => {
    this.hideDropDowns()
    Html.getDefinedHtmlElementById(Html.htmlElementIdForDropdown(id)).classList.toggle("show")
  }
}

export default MenuBar
