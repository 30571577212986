/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageParseException} from "./ttpMessageParseException"
import TtpMessageBase from "./ttpMessageBase"
import {parseKeyValues, parseNumberMandatory, TtpMessageType} from "./ttpMessage"

const REGEX_PARAMETER = "^([^,]*),\\s*17\\s*,(.*)$"

export class TtpMessageParameter extends TtpMessageBase {
  type: TtpMessageType = TtpMessageType.Parameter
  data: Record<string, string>

  constructor(lineNumber: number, monotonicTime: number, data: Record<string, string>) {
    super(lineNumber, monotonicTime)
    this.data = data
  }

  static parse(lineNumber: number, input: string): TtpMessageParameter | undefined {
    const match = RegExp(REGEX_PARAMETER).exec(input)
    if (!match) {
      return undefined
    }
    if (match.length < 3) {
      throw new TtpMessageParseException(`Invalid parameter (needs at least 3 fields)`)
    }

    const monotonicTime = parseNumberMandatory(match[1].trim())
    const data = parseKeyValues(match[2].split(","))
    return new TtpMessageParameter(lineNumber, monotonicTime, data)
  }
}

export default TtpMessageParameter
