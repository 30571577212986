/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Parser from "./parser"
import {isParsedPointColor, LayerLineParser, LayerType, ParsedPointColor} from "./parserTypes"
import {Feature} from "geojson"
import {LngLat} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {GlobalSettings} from "../app/globalSettings"
import {isHttpCodeNoError} from "../common/httpCodes"
import LogWindow from "../app/logWindow"
import GeoHash from "../common/geoHash"
import {BoundingBox} from "../common/geo"
import LineParser from "./lineParser"
import {TIMESTAMP_ALWAYS_HIDE} from "../app/dataStore"

export class ParserApiService extends LineParser {
  private static readonly parsers: Partial<Record<LayerType, LayerLineParser>> = {
    [LayerType.ApiRouting]: {
      name: "Routing",
      layerType: LayerType.ApiRouting,
      color: {
        "circle-radius": 9,
        "circle-color": "rgba(255,100,168,0.7)",
        text: "R",
        "text-color": "rgb(255,204,0)"
      },
      regexWithLocation:
        "(?:(?:/maps/[a-z]+/routing/calculate(?:LongDistanceEV)?Route)|(?:/routing/[0-9]+/calculate(?:LongDistanceEV)?Route))/{lat1}(?:(?:%2C)|,){lon1}(?:(?:%3A)|[:;]){lat2}(?:(?:%2C)|,){lon2}/"
    },
    [LayerType.ApiAutoComplete]: {
      name: "Auto-complete",
      layerType: LayerType.ApiAutoComplete,
      color: {
        "circle-radius": 9,
        "circle-color": "rgba(100,149,255,0.7)",
        text: "A",
        "text-color": "rgb(0,255,225)"
      },
      regexWithLocation: "/maps/[a-z]+/places/autocomplete/.*[&?]lat={lat1}&lon={lon1}&",
      regexWithoutLocation: "/maps/[a-z]+/places/autocomplete/"
    },
    [LayerType.ApiSearch]: {
      name: "Search",
      layerType: LayerType.ApiSearch,
      color: {
        "circle-radius": 9,
        "circle-color": "rgb(255,229,100)",
        text: "S",
        "text-color": "rgb(12,24,85)"
      },
      regexWithLocation:
        "(?:(?:/maps/[a-z]+/places/(?:event)?)|(?:/search/[0-9]+/event))/search[/.].*[&?]lat={lat1}&lon={lon1}",
      regexWithoutLocation: "(?:(?:/maps/[a-z]+/places(?:/event)?)|(?:/search/[0-9]+/event))/search[/.]"
    },
    [LayerType.ApiRevGeocode]: {
      name: "Reverse geocode",
      layerType: LayerType.ApiRevGeocode,
      color: {
        "circle-radius": 9,
        "circle-color": "rgb(165,241,113)",
        text: "V",
        "text-color": "rgb(61,20,140)"
      },
      regexWithLocation: "/maps/[a-z]+/places/reverseGeocode/{lat1}(?:(?:%2C)|,){lon1}[.]json"
    },
    [LayerType.ApiTpeg]: {
      name: "TPEG",
      layerType: LayerType.ApiTpeg,
      color: undefined,
      regexWithoutLocation: "(?:/[a-z0-9.]+.tomtom.com/.*/GetMessages)|(?:/tpeg/InitSession)"
    }
  }

  constructor(
    logWindow: LogWindow,
    globalSettings: GlobalSettings,
    metadataStore: MetadataStore,
    layerType: LayerType
  ) {
    super(
      logWindow,
      globalSettings,
      metadataStore,
      layerType,
      ParserApiService.parsers[layerType]!.name,
      ParserApiService.parsers[layerType]!.color,
      ParserApiService.parsers[layerType]!.regexWithLocation,
      ParserApiService.parsers[layerType]!.regexWithoutLocation
    )
  }

  parseLine(lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const metadata = {
      lineNumber: lineNumber,
      line: line,
      ...(httpStatusCode && {httpStatusCode: httpStatusCode})
    }
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation
        .replace("{lon1}", "([+-]?[0-9.]+)")
        .replace("{lat1}", "([+-]?[0-9.]+)")
        .replace("{lon2}", "([+-]?[0-9.]+)")
        .replace("{lat2}", "([+-]?[0-9.]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const indexLon1 = this.regexWithLocation.indexOf("{lon1}")
        const indexLat1 = this.regexWithLocation.indexOf("{lat1}")
        const indexLon2 = this.regexWithLocation.indexOf("{lon2}")
        const indexLat2 = this.regexWithLocation.indexOf("{lat2}")

        const indexes = {
          lon1: indexLon1,
          lat1: indexLat1,
          ...(indexLon2 >= 0 && {lon2: indexLon2}),
          ...(indexLat2 >= 0 && {lat2: indexLat2})
        }
        let origin: LngLat = {lng: 0, lat: 0}
        let destination: {lng: number | undefined; lat: number | undefined} = {lng: undefined, lat: undefined}
        const sortedKeys = Object.keys(indexes).sort(
          (a, b) => indexes[a as keyof typeof indexes]! - indexes[b as keyof typeof indexes]!
        )
        sortedKeys.forEach((key, i) => {
          if (i >= 0) {
            const value = parseFloat(match[i + 1])
            switch (key) {
              case "lon1":
                origin.lng = value
                break
              case "lat1":
                origin.lat = value
                break
              case "lon2":
                destination.lng = value
                break
              case "lat2":
                destination.lat = value
                break
            }
          }
        })
        const featureFromRequest = this.createFeatureFromRequest(
          origin,
          destination.lng !== undefined && destination.lat !== undefined ? (destination as LngLat) : undefined,
          metadata,
          time,
          sizeInBytes
        )
        featureFromRequest.forEach((feature) => features.push(feature))
        from = from + match.index + match[0].length
      }
    }
    if (features.length === 0 && this.regexWithoutLocation) {
      const match = RegExp(new RegExp(this.regexWithoutLocation)).exec(line)
      if (match) {
        const featureFromRequest = this.createFeatureFromRequest(undefined, undefined, metadata, time, sizeInBytes)
        features.push(...featureFromRequest)
      }
    }
    return features
  }

  private createFeatureFromRequest(
    origin: LngLat | undefined,
    destination: LngLat | undefined,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature[] {
    let color = this.color
    if (metadata.httpStatusCode && !isHttpCodeNoError(metadata.httpStatusCode) && isParsedPointColor(color)) {
      color = this.modifyPointColorToErrorState(color as ParsedPointColor)
    }
    let geoHash = undefined
    let bounds = undefined
    if (origin) {
      const realDestination = destination ?? origin
      geoHash = `${new GeoHash(origin).getHash()}:${new GeoHash(realDestination).getHash()}`
      bounds = BoundingBox.empty().extendToLngLat(origin).extendToLngLat(realDestination)
    }
    const extendedMetadata = {
      ...metadata,
      ...(time ? {time: time.getTime()} : {time: TIMESTAMP_ALWAYS_HIDE}),
      ...{sizeInBytes: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: this.layerType,
      ...(bounds && {bounds: bounds}),
      ...(origin && {origin: origin}),
      ...(destination && {destination: destination}),
      ...(geoHash && {geoHash: geoHash})
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    const originPoint: Feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: origin ? [origin.lng, origin.lat] : [0, 0]
      },
      properties: {
        metadata: metadataKey,
        ...(time ? {time: time.getTime()} : {time: TIMESTAMP_ALWAYS_HIDE}),
        layer: this.layerType,
        ...(geoHash && {geoHash: geoHash}),
        ...color
      }
    }
    return (
      origin && destination
        ? [
            originPoint,
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  [origin.lng, origin.lat],
                  [destination.lng, destination.lat]
                ]
              },
              properties: {
                metadata: metadataKey,
                ...(time !== undefined && {time: time.getTime()}),
                layer: this.layerType,
                "line-width": 1,
                "line-stroke-width": 0,
                "line-dasharray": [10, 5],
                "line-color": "rgb(255,0,115,0.4)"
              }
            },
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [destination.lng, destination.lat]
              },
              properties: {
                metadata: metadataKey,
                ...(time !== undefined && {time: time.getTime()}),
                layer: this.layerType,
                "circle-radius": 3,
                "circle-color": "rgb(255,0,76)"
              }
            }
          ]
        : [originPoint]
    ) as Feature[]
  }
}

export default ParserApiService
