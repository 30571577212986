/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import Parser from "./parser"

// Utility callback to skip progress reporting.
export const showNoProgress = (percentage: number) => {}

/**
 * This class is the base class for all file parsers.
 */
export abstract class FileParser extends Parser {
  /**
   * Parse the contents of a file. The contents of the file are passed as a string,
   * so the file is not read piecewise.
   *
   * @param fileName Filename of file to parse (used for messages).
   * @param contents Full contents of file to parse.
   * @param onProgress Callback function to report progress.
   * @returns Array of GeoJSON features, undefined if empty if no features were detected or parsing failed.
   */
  abstract parseFile(fileName: string, contents: string, onProgress: (percentage: number) => void): Promise<Feature[]>
}

export default FileParser
