/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {marked} from "marked"
import {Html} from "../../html/html"
import {HttpUtils} from "./httpUtils"
import Logger from "../logger"

/**
 * This is a utility class with static functions only.
 */
export class FileUtils {
  private static readonly minDownloadWaitTimeInMillis = 500 // Minimum time to wait between downloads in milliseconds.
  private static earliestNextDownloadTime = 0 // Last time a download was triggered.

  /**
   * Read a JSON file from the server and parse it. This is asynchronous. Uses callbacks for success and failure.
   * @param fileUrl URL of file to download.
   * @param onSuccess Called after successfully reading the file.
   * @param onFailureNotAuthorized Called if the server returns a (401/403) Unauthorized status.
   * @param onFailureOther Called if the server returns any other status.
   */
  static requestJsonFile(
    fileUrl: string,
    onSuccess: (topLevelObject: any) => void,
    onFailureNotAuthorized: () => void,
    onFailureOther: (httpStatus: number) => void
  ): void {
    const rawFile = new XMLHttpRequest()
    rawFile.overrideMimeType("application/json")
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (HttpUtils.isOk(rawFile.status)) {
          try {
            onSuccess(JSON.parse(rawFile.responseText))
          } catch (e) {
            console.warn(`Failed to parse JSON file: ${fileUrl}, error: ${e}`)
          }
        } else {
          console.warn(
            `Failed to load JSON file: ${fileUrl}, status: ${rawFile.status}, readyState: ${rawFile.readyState}`
          )
          if (HttpUtils.isNotAuthorized(rawFile.status)) {
            onFailureNotAuthorized()
          } else {
            onFailureOther(rawFile.status)
          }
        }
      }
    }
    // Send the request for the file.
    rawFile.open("GET", fileUrl, false)
    rawFile.send(null)
  }

  /**
   * Triggers the download of a file to local machine in the browser. These are typically stored in a "Downloads"
   * folder. The method makes sure it schedules subsequent downloads a bit apart to avoid browser
   * overload. Safari is particularly sensitive to this.
   * @param downloadAsFileName Filename to save the file as.
   * @param contents Contents of the file.
   */
  static triggerRateLimitedDownloadFile(downloadAsFileName: string, contents: string): void {
    const fileContents = new Blob([contents], {type: "text/plain"})
    const htmlLink = document.createElement("a")
    htmlLink.href = URL.createObjectURL(fileContents)
    htmlLink.download = downloadAsFileName
    document.body.appendChild(htmlLink)

    // Determine the earliest possible time to trigger the download.
    // Safari is known to be sensitive to too many download clicks in a very short time.
    // It will just ignore the later clicks if they are too close together.
    const now = Date.now()

    // Determine the earliest possible time to trigger the download, after 'now'.
    const downloadTime = Math.max(now, FileUtils.earliestNextDownloadTime)

    // Determine time distance to next download time.
    const waitTimeInMillis = Math.max(0, downloadTime - now)

    // Advance the next download time by a delta. This can happen multiple times for quick clicks.
    FileUtils.earliestNextDownloadTime = downloadTime + FileUtils.minDownloadWaitTimeInMillis

    // Schedule trigger.
    setTimeout(() => {
      Logger.log.info(`Downloading file: ${downloadAsFileName}...`)
      htmlLink.click()
      URL.revokeObjectURL(htmlLink.href)
      document.body.removeChild(htmlLink)
    }, waitTimeInMillis)
  }

  /**
   * Create a filename based on the current time. The filename will be created using local time, not UTC.
   * @param filePrefix Fixed prefix for filename, will be followed by "_".
   * @param fileExt Fixed postfix for filename, will be preceded by ".".
   */
  static createTimeBasedFilename(filePrefix: string, fileExt: string): string {
    const padToTwoDigits = (num: number) => num.toString().padStart(2, "0")
    const now = new Date()
    return (
      `${filePrefix}_${now.getFullYear()}${padToTwoDigits(now.getMonth() + 1)}${padToTwoDigits(now.getDate())}_` +
      `${padToTwoDigits(now.getHours())}${padToTwoDigits(now.getMinutes())}${padToTwoDigits(now.getSeconds())}` +
      `.${fileExt}`
    )
  }

  /**
   * Load a markdown file from the server and store it it in an HTML element.
   * @param htmlElementId HTML element to store the markdown content in.
   * @param fileName Name of the markdown file to load.
   * @param title Optional title to prepend to the markdown content.
   */
  static async loadMarkdownFileAsync(htmlElementId: string, fileName: string, title: string = "") {
    try {
      const response = await fetch(fileName)
      if (response.ok) {
        const fileContent = await response.text()
        const elmContent = Html.getDefinedHtmlElementById(`${htmlElementId}`)
        elmContent.innerHTML = marked(`${title}${fileContent}`) as string
      } else {
        Logger.log.error(`Failed to load file ${fileName}: ${response.statusText}`)
      }
    } catch (error) {
      Logger.log.error(JSON.stringify(error))
      throw error
    }
  }

  /**
   * Check if a filename has one of the specified extensions.
   * @param fileName Filename to check.
   * @param extensions Array of extensions to check for (case-insensitive)
   */
  static hasFileNameExtension(fileName: string, extensions: string[]): boolean {
    const fileNameLower = fileName.toLowerCase()
    return extensions.some((ext) => fileNameLower.endsWith(ext.toLowerCase()))
  }
}

export default FileUtils
