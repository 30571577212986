/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LngLat} from "../common/wgs84"
import {LayerWithoutId} from "../common/mapLibreLayer"
import MapDataOverlay from "../app/mapDataOverlay"
import {Point as MapLibrePoint} from "maplibre-gl"
import {Feature} from "geojson"
import {MetadataStore} from "../common/metadata"
import MapView from "../app/mapView"
import InspectorWindow from "../app/inspectorWindow"
import LogWindow from "../app/logWindow"

/**
 * This class represents a selectable tool from the menu, like measuring distances.
 */
export abstract class Tool {
  readonly id: string
  readonly name: string

  protected readonly map: MapView
  protected readonly inspectorWindow: InspectorWindow
  protected readonly logWindow: LogWindow
  protected readonly metadataStore: MetadataStore
  protected readonly mapDataOverlay: MapDataOverlay

  protected constructor(
    map: MapView,
    inspectorWindow: InspectorWindow,
    logWindow: LogWindow,
    metadataStore: MetadataStore,
    id: string,
    name: string
  ) {
    this.id = id
    this.name = name
    this.map = map
    this.inspectorWindow = inspectorWindow
    this.logWindow = logWindow
    this.metadataStore = metadataStore
    this.mapDataOverlay = map.createOverlayForTool(id, this.getLayers())
  }

  /**
   * These methods can be implemented by the tool class, or they do nothing when the tool is selected.
   */

  // Called when user clicks on the map (provides both a map and screen coordinate).
  onClick(location: LngLat, _: MapLibrePoint) {}

  // Called when the user selected "undo".
  undo() {
    // Do nothing.
  }

  // Called when the map is requested to be cleared. This just clears the data for this tool.
  clearToolData() {
    // Do nothing.
  }

  draw(features: Feature[]) {
    this.mapDataOverlay.setFeatures(features)
  }

  // Redraws the screen with updated information of the tool.
  refresh() {
    // Do nothing.
  }

  // Returns the map layers required for this tool.
  getLayers(): LayerWithoutId[] {
    return []
  }
}
