/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {MenuItem, separatorMenuItem} from "../../menu/menuTypes"
import {
  HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW,
  HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE,
  HTML_MENU_ITEM_SKIP_COMMENTS,
  HTML_MENU_ITEM_USE_ORBIS_ROUTING_API,
  HTML_WINDOW_HELP,
  HTML_WINDOW_RELEASE_NOTES
} from "../../html/htmlElementId"
import MenuDefinition from "./menuDefinition"
import App from "../app"
import ConsoleWindow from "../../windows/consoleWindow"
import YesNoDialog from "../../dialogs/yesNoDialog"
import Storage, {Settings} from "../../common/storage"
import Logger from "../../common/logger"
import ReleaseNotesWindow from "../../windows/releaseNotesWindow"
import Toolbox from "../../tools/toolbox"
import Html from "../../html/html"
import MenuBar from "../../menu/menuBar"
import {Mutex} from "async-mutex"

export class MenuDefinitionOthers extends MenuDefinition {
  private readonly app: App
  private readonly toolbox: Toolbox
  private readonly releaseNotesWindow: ReleaseNotesWindow
  private readonly consoleWindow: ConsoleWindow

  constructor(app: App, toolbox: Toolbox, releaseNotesWindow: ReleaseNotesWindow, consoleWindow: ConsoleWindow) {
    super()
    this.app = app
    this.toolbox = toolbox
    this.releaseNotesWindow = releaseNotesWindow
    this.consoleWindow = consoleWindow
  }

  public getMenuItems(): MenuItem[] {
    return [
      {
        name: "Show help...",
        id: HTML_WINDOW_HELP,
        action: () => this.app.toggleHelpWindow()
      },
      {
        name: "What's new (release notes)...",
        id: HTML_WINDOW_RELEASE_NOTES,
        action: () => this.releaseNotesWindow.setVisible(!this.releaseNotesWindow.isVisible())
      },
      separatorMenuItem,
      {
        name: "Skip '#' comment lines while importing",
        id: "skip-comments",
        action: () => this.toggleOptionSkipComments()
      },
      {
        shortcut: "E",
        name: "Show errors/unprocessed lines during import",
        id: "auto-show-console-window",
        action: () => this.toggleOptionAutoShowConsole()
      },
      separatorMenuItem,
      {
        name: "Use Orbis Routing API...",
        id: "use-orbis-routing-api",
        action: () => this.toggleOptionUseOrbisRoutingApi()
      },
      {
        name: "Always download Routing API responses as JSON...",
        id: "download-routing-api-response",
        action: () => this.toggleOptionDownloadRoutingApiResponse()
      },
      {
        name: "Convert JSON Routing API response to GPX...",
        action: () => this.app.createGpxFromRoutingResponseFile()
      },
      separatorMenuItem,
      {
        name: "Enter default HTTP overhead size...",
        action: () => this.app.enterDefaultHttpOverheadSize()
      },
      {
        name: "Reset settings (incl. API key)...",
        action: () =>
          YesNoDialog.show(
            "<strong>Reset settings</strong><br/><br/>This will reload the page, ask for a new API key and reset the default overhead used for calculating the size of HTTP calls...<br/><br/>Are you sure?",
            () => {
              Storage.clear()
              window.location.reload()
            },
            () => {}
          )
      },
      separatorMenuItem,
      {
        name: "Activate data selector tool...",
        shortcut: "V",
        action: () => this.toolbox.resetCurrentTool()
      },
      {
        name: "Enter regular expression for custom MapVis tiles...",
        action: () => this.app.enterMapVisCustomRegex()
      },
      {
        name: "Copy HTML ids to clipboard for development...",
        action: () => {
          this.consoleWindow.setVisible(true)
          const output = Html.copyAllHtmlIdsFromDocumentToClipboard()
          Logger.log.info(`HTML ids for development:\n\n${output}`)
          navigator.clipboard
            .writeText(output)
            .then(() => {
              alert("HTML id's from this application are copied to the clipboard as TypeScript declarations...")
            })
            .catch((err) => {
              Logger.log.error(`Failed to copy text to clipboard: ${JSON.stringify(err)}`)
            })
        }
      }
    ]
  }

  private readonly toggleOptionSkipComments = () => {
    Storage.toggle(Settings.SkipComments)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SKIP_COMMENTS, Storage.get(Settings.SkipComments))
  }

  private readonly toggleOptionDownloadRoutingApiResponse = () => {
    Storage.toggle(Settings.DownloadRoutingApiResponse)
    MenuBar.updateMenuItemToggle(
      HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE,
      Storage.get(Settings.DownloadRoutingApiResponse)
    )
  }

  private readonly toggleOptionAutoShowConsole = () => {
    Storage.toggle(Settings.AutoShowConsole)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW, Storage.get(Settings.AutoShowConsole))
    if (Storage.get(Settings.AutoShowConsole)) {
      Logger.log.show()
    }
  }

  private readonly mutexUseOrbisRoutingApi = new Mutex()
  private readonly toggleOptionUseOrbisRoutingApi = () => {
    Storage.toggle(Settings.UseOrbisRoutingApi)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_ORBIS_ROUTING_API, Storage.get(Settings.UseOrbisRoutingApi))
    this.mutexUseOrbisRoutingApi.runExclusive(() => {
      this.toolbox.tools.routeCreatorJsonTool.requestRouteAndRefresh()
    })
  }
}

export default MenuDefinitionOthers
