/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageSensorBase} from "./ttpMessageSensorBase"
import {TtpMessageParseException} from "./ttpMessageParseException"
import {parseNumberMandatory, TtpMessageType} from "./ttpMessage"

export enum LocationEventIndex {
  LongitudeIndex = 2,
  LatitudeIndex = 3,
  EventIndex = 4
}

export class TtpMessageSensorLocationEvent extends TtpMessageSensorBase {
  longitude: number
  latitude: number
  event: string

  // Note: 'time' is already captured in base class!

  constructor(
    lineNumber: number,
    monotonicTime: number,
    message: number,
    longitude: number,
    latitude: number,
    event: string
  ) {
    super(lineNumber, TtpMessageType.SensorLocationEvent, monotonicTime, message)
    this.longitude = longitude
    this.latitude = latitude
    this.event = event
  }

  static parse(
    lineNumber: number,
    parts: string[],
    monotonicTime: number,
    message: number
  ): TtpMessageSensorLocationEvent {
    const {LongitudeIndex, LatitudeIndex, EventIndex} = LocationEventIndex

    if (parts.length <= LatitudeIndex) {
      throw new TtpMessageParseException(`Invalid location line (need at least lon, lat)`)
    }
    const longitude = parseNumberMandatory(parts[LongitudeIndex])
    const latitude = parseNumberMandatory(parts[LatitudeIndex])
    const event = parts.length > EventIndex ? parts[EventIndex] : ""
    return new TtpMessageSensorLocationEvent(lineNumber, monotonicTime, message, longitude, latitude, event)
  }
}

export default TtpMessageSensorLocationEvent
