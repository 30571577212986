/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {BoundingBox} from "../common/geo"
import {Event, TimeRange} from "./timeline"
import {LayerWithoutId} from "../common/mapLibreLayer"
import {Feature} from "geojson"
import MapDataOverlay from "./mapDataOverlay"
import {LayerType, layerTypeSupportsLevel} from "../parsers/parserTypes"
import ParserGeoJson from "../parsers/parserGeoJson"
import ParserGpx from "../parsers/parserGpx"
import ParserJson from "../parsers/parserJson"
import ParserMapVis from "../parsers/parserMapVis"
import ParserNdsLive from "../parsers/parserNdsLive"
import ParserNavTiles from "../parsers/parserNavTiles"
import ParserLogLines from "../parsers/parserLogLines"
import ParserTtp from "../parsers/parserTtp"
import {addAllElements} from "../common/objects"
import DataStore, {
  DataFileState,
  FilterType,
  LayerSize,
  LayerState,
  TileLevelFilterState,
  TIMESTAMP_ALWAYS_HIDE,
  TIMESTAMP_ALWAYS_SHOW
} from "./dataStore"
import {MetadataStore} from "../common/metadata"
import {GlobalSettings} from "./globalSettings"
import MapView from "./mapView"
import LogWindow from "./logWindow"
import ParserApiService from "../parsers/parserApiService"
import LineParser from "../parsers/lineParser"
import FileParser from "../parsers/fileParser"
import Parser from "../parsers/parser"
import ProgressWindow from "./progressWindow"
import {processItemsAsync} from "../common/async"
import {isHttpCodeNoError} from "../common/httpCodes"

export type LayerStates = Record<LayerType, LayerState>
export type LayerStateAndSize = LayerState & LayerSize
export type LayerStatesAndSizes = Record<LayerType, LayerStateAndSize>

/**
 * The DataCanvas class is the overlay of the map that accepts dropped data and display it using parsers.
 * It relies heavily on on all parser and the map overlay to display the data.
 */
export class DataCanvas {
  // Stores per file whether the file should be shown or not.
  dataFileStates: Record<string, DataFileState>

  // Stores per layer whether the layer should be shown or not, its size and whether it supports tile levels.
  layerStates: LayerStates

  // Store the tile level filter state.
  tileLevelFilterState: TileLevelFilterState

  // Stores the line parsers for each layer type.
  readonly lineParsers: Partial<Record<LayerType, LineParser>>

  // Stores the file parsers for each layer type.
  readonly fileParsers: Partial<Record<LayerType, FileParser>>

  private readonly map: MapView
  private readonly mapDataOverlay: MapDataOverlay

  private readonly globalSettings: GlobalSettings
  private readonly dataStore: DataStore
  private readonly metadataStore: MetadataStore
  private readonly progressWindow: ProgressWindow
  private readonly logWindow: LogWindow

  // The volatile layers are allowed to request the same tile when more than <n> seconds apart.
  private readonly volatileLayers = ["MapVisIncidents", "MapVisFlow"]
  private readonly minSecondsBetweenVolatileFeatures = 1.5 * 60
  private readonly delayYieldUpdate = 100

  // The initial layer states, used to reset settings.
  private readonly initialLayerStates: LayerStatesAndSizes

  constructor(
    map: MapView,
    globalSettings: GlobalSettings,
    progressWindow: ProgressWindow,
    logWindow: LogWindow,
    metadataStore: MetadataStore,
    dataStore: DataStore
  ) {
    this.dataFileStates = {}
    this.initialLayerStates = {
      _Total: this.initialLayerStateAndSize(LayerType._Total),
      _MapVis: this.initialLayerStateAndSize(LayerType._MapVis),
      MapVis3D: this.initialLayerStateAndSize(LayerType.MapVis3D),
      MapVisBasicMap: this.initialLayerStateAndSize(LayerType.MapVisBasicMap),
      MapVisFlow: this.initialLayerStateAndSize(LayerType.MapVisFlow),
      MapVisHillshade: this.initialLayerStateAndSize(LayerType.MapVisHillshade),
      MapVisIncidents: this.initialLayerStateAndSize(LayerType.MapVisIncidents),
      MapVisSatellite: this.initialLayerStateAndSize(LayerType.MapVisSatellite),
      MapVisStyle: this.initialLayerStateAndSize(LayerType.MapVisStyle),

      _Navigation: this.initialLayerStateAndSize(LayerType._Navigation),
      Nk2NavTiles: this.initialLayerStateAndSize(LayerType.Nk2NavTiles),
      Nk2LaneTiles: this.initialLayerStateAndSize(LayerType.Nk2LaneTiles),
      NdsLive: this.initialLayerStateAndSize(LayerType.NdsLive),

      _Api: this.initialLayerStateAndSize(LayerType._Api),
      ApiAutoComplete: this.initialLayerStateAndSize(LayerType.ApiAutoComplete),
      ApiRevGeocode: this.initialLayerStateAndSize(LayerType.ApiRevGeocode),
      ApiRouting: this.initialLayerStateAndSize(LayerType.ApiRouting),
      ApiSearch: this.initialLayerStateAndSize(LayerType.ApiSearch),
      ApiTpeg: this.initialLayerStateAndSize(LayerType.ApiTpeg),

      _Other: this.initialLayerStateAndSize(LayerType._Other),
      LogLines: this.initialLayerStateAndSize(LayerType.LogLines),
      GeoJSON: this.initialLayerStateAndSize(LayerType.GeoJSON),
      GPX: this.initialLayerStateAndSize(LayerType.GPX),
      JSON: this.initialLayerStateAndSize(LayerType.JSON),
      TTP1s: this.initialLayerStateAndSize(LayerType.TTP1s),
      TTP100ms: this.initialLayerStateAndSize(LayerType.TTP100ms),
      TTPLocation: this.initialLayerStateAndSize(LayerType.TTPLocation)
    }
    this.layerStates = structuredClone(this.initialLayerStates)
    this.tileLevelFilterState = {
      level: 10,
      filter: FilterType.GreaterEqual
    }
    this.map = map
    this.mapDataOverlay = map.createOverlayForTool("canvas", this.getLayers())
    this.globalSettings = globalSettings
    this.progressWindow = progressWindow
    this.logWindow = logWindow
    this.dataStore = dataStore
    this.metadataStore = metadataStore

    this.lineParsers = {
      MapVis3D: new ParserMapVis(logWindow, globalSettings, metadataStore, LayerType.MapVis3D),
      MapVisBasicMap: new ParserMapVis(logWindow, globalSettings, metadataStore, LayerType.MapVisBasicMap),
      MapVisFlow: new ParserMapVis(logWindow, globalSettings, metadataStore, LayerType.MapVisFlow),
      MapVisHillshade: new ParserMapVis(logWindow, globalSettings, metadataStore, LayerType.MapVisHillshade),
      MapVisIncidents: new ParserMapVis(logWindow, globalSettings, metadataStore, LayerType.MapVisIncidents),
      MapVisSatellite: new ParserMapVis(logWindow, globalSettings, metadataStore, LayerType.MapVisSatellite),
      MapVisStyle: new ParserMapVis(logWindow, globalSettings, metadataStore, LayerType.MapVisStyle),
      Nk2NavTiles: new ParserNavTiles(logWindow, globalSettings, metadataStore, LayerType.Nk2NavTiles),
      Nk2LaneTiles: new ParserNavTiles(logWindow, globalSettings, metadataStore, LayerType.Nk2LaneTiles),
      NdsLive: new ParserNdsLive(logWindow, globalSettings, metadataStore),
      ApiAutoComplete: new ParserApiService(logWindow, globalSettings, metadataStore, LayerType.ApiAutoComplete),
      ApiRevGeocode: new ParserApiService(logWindow, globalSettings, metadataStore, LayerType.ApiRevGeocode),
      ApiRouting: new ParserApiService(logWindow, globalSettings, metadataStore, LayerType.ApiRouting),
      ApiSearch: new ParserApiService(logWindow, globalSettings, metadataStore, LayerType.ApiSearch),
      ApiTpeg: new ParserApiService(logWindow, globalSettings, metadataStore, LayerType.ApiTpeg),
      LogLines: new ParserLogLines(logWindow, globalSettings, metadataStore)
    }
    this.fileParsers = {
      JSON: new ParserJson(logWindow, globalSettings, metadataStore),
      GeoJSON: new ParserGeoJson(logWindow, globalSettings, metadataStore),
      GPX: new ParserGpx(logWindow, globalSettings, metadataStore),
      TTP1s: new ParserTtp(logWindow, globalSettings, metadataStore, LayerType.TTP1s),
      TTP100ms: new ParserTtp(logWindow, globalSettings, metadataStore, LayerType.TTP100ms),
      TTPLocation: new ParserTtp(logWindow, globalSettings, metadataStore, LayerType.TTPLocation)
    }
  }

  removeAll() {
    this.dataStore.removeAll()
    this.layerStates = structuredClone(this.initialLayerStates)
    this.dataFileStates = {}
  }

  /**
   * This effectively clears the conditional layers and resets the time range.
   */
  resetTimeRange() {
    this.mapDataOverlay.replaceAllLayers(this.getLayers())
  }

  /**
   * Sets the time range for the data to be displayed. Times are in milliseconds after epoch
   * as returned by Date.getTime() and property "time" in the GeoJSON features.
   * Important: This must be a lambda and not a method to avoid binding issues.
   * @param timeRange Time range.
   */
  setTimeRange = (timeRange: TimeRange) => {
    // Configure the layers to filter on time.
    const layers = this.getLayers()
    if (timeRange.minMillis < timeRange.maxMillis) {
      layers.forEach((layer) => {
        layer.filter = [
          "all",
          [
            "any",
            ["!", ["has", "time"]],
            ["==", ["get", "time"], TIMESTAMP_ALWAYS_SHOW], // Include items with time === TIMESTAMP_ALWAYS_SHOW.
            [
              "all",
              ["has", "time"],
              [">=", ["get", "time"], timeRange.minMillis],
              ["<=", ["get", "time"], timeRange.maxMillis],
              ["!=", ["get", "time"], TIMESTAMP_ALWAYS_HIDE] // Exclude items with time === TIMESTAMP_ALWAYS_HIDE.
            ]
          ],
          ...(layer.filter !== undefined ? [layer.filter] : [])
        ]
      })
    }
    this.mapDataOverlay.replaceAllLayers(layers)

    // Calculate bounding box to zoom in (pretty fast operation).
    let bounds = BoundingBox.empty()
    const features = this.dataStore.getFeaturesFromDataFiles(
      this.layerStates,
      this.dataFileStates,
      this.tileLevelFilterState
    )
    features.forEach((feature) => {
      const time = feature.properties?.time
      const metadataKey = feature.properties?.metadata
      if (metadataKey) {
        const metadata = this.metadataStore.retrieve(metadataKey)
        if (metadata?.bounds && (!time || (timeRange.minMillis <= time && time <= timeRange.maxMillis))) {
          bounds = bounds.extendWithOther(metadata.bounds)
        }
      }
    })

    // Zoom map.
    if (this.globalSettings.optionAutoZoom) {
      this.map.zoomToBounds(bounds)
    }
  }

  updateMapDataOverlayFeatures(features: Feature[]) {
    this.mapDataOverlay.setFeatures(features)
  }

  getActiveFeaturesFromDataStore() {
    return this.dataStore.getFeaturesFromDataFiles(this.layerStates, this.dataFileStates, this.tileLevelFilterState)
  }

  getEventsFromFeatures(features: Feature[]) {
    let events: Event[] = []
    features.forEach((feature) => {
      const time = feature.properties?.time as number
      const metadataKey = feature.properties?.metadata
      if (time && metadataKey) {
        const metadata = this.metadataStore.retrieve(metadataKey)
        const sizeInBytes = metadata?.sizeInBytes as number
        const duplicates = metadata?.duplicates as number
        const location = metadata?.geoHash
        const httpStatusCode = metadata?.httpStatusCode
        events.push({
          timeMillis: time,
          metadata: metadataKey,
          ...(sizeInBytes !== undefined && {sizeInBytes: sizeInBytes}),
          ...(duplicates !== undefined && {duplicates: duplicates}),
          ...(location !== undefined && {hasLocation: true}),
          ...(httpStatusCode !== undefined && !isHttpCodeNoError(httpStatusCode) && {hasError: true})
        })
      }
    })
    return events
  }

  zoomToBounds() {
    const bounds = this.dataStore.getBoundsOfDataFiles(this.dataFileStates)
    this.map.zoomToBounds(bounds)
  }

  getLayers(): LayerWithoutId[] {
    const minZoom = 1
    const maxZoom = 24
    return [
      {
        type: "fill",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "fill-outline-color": [
            "case",
            ["has", "fill-outline-color"],
            ["get", "fill-outline-color"],
            "rgb(255,255,255)"
          ],
          "fill-color": ["case", ["has", "fill-color"], ["get", "fill-color"], "rgb(255,198,0)"],
          "fill-opacity": ["case", ["has", "fill-opacity"], ["get", "fill-opacity"], 0.15]
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "line-color": ["case", ["has", "fill-outline-color"], ["get", "fill-outline-color"], "rgb(255,255,255)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 0.66]
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "rgb(93,93,93)",
          "line-width": ["+", 1, ["case", ["has", "line-width"], ["get", "line-width"], 4]],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["==", ["get", "line-outline"], true]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(133,133,133)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 4],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0],
          "line-dasharray": [3, 3]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["has", "line-dasharray"]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(133,133,133)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 4],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["!", ["has", "line-dasharray"]]]
      },
      // Circle outline.
      {
        type: "circle",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "circle-radius": ["+", 1, ["case", ["has", "circle-radius"], ["get", "circle-radius"], 4]],
          "circle-color": "rgb(0,0,0)"
        },
        filter: ["all", ["==", ["geometry-type"], "Point"]]
      },
      // Circle.
      {
        type: "circle",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "circle-radius": ["case", ["has", "circle-radius"], ["get", "circle-radius"], 6],
          "circle-color": ["case", ["has", "circle-color"], ["get", "circle-color"], "rgb(77,143,75)"]
        },
        filter: ["all", ["==", ["geometry-type"], "Point"]]
      },
      // Text.
      {
        type: "symbol",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "text-allow-overlap": true,
          "text-max-width": 99,
          "text-justify": "left",
          "text-field": ["get", "text"],
          "text-font": ["Noto-Bold"],
          "text-size": ["case", ["has", "text-size"], ["get", "text-size"], 12],
          "text-offset": [0, 0],
          "text-anchor": ["case", ["has", "text-anchor"], ["get", "text-anchor"], "center"],
          "text-rotate": ["get", "text-rotate"]
        },
        paint: {
          "text-color": ["get", "text-color"]
        },
        filter: ["all", ["has", "text"], ["==", ["geometry-type"], "Point"]]
      }
    ]
  }

  /**
   * Handles the drop event.
   * @param dragEvent The event.
   * @param onSuccess To be called once after all files were processed.
   * @param onFailure To be called if no files were processed, or no features were found.
   * @param autoShowLogWindow If true, the log window will be shown automatically on errors.
   */
  async handleDrop(dragEvent: DragEvent, onSuccess: () => void, onFailure: () => void, autoShowLogWindow = false) {
    const files = dragEvent.dataTransfer!.files
    if (files.length > 0) {
      let bounds = BoundingBox.empty()
      let currentFileNr = 0
      for (const file of Array.from(files)) {
        currentFileNr++
        try {
          this.progressWindow.show(`Importing file ${currentFileNr} of ${files.length}: ${file.name}...`)
          const features = await this.processSingleFile(file, autoShowLogWindow)
          if (features.length > 0) {
            bounds = bounds.extendWithOther(this.dataStore.getBoundsOfDataFile(file))
          }
          if (currentFileNr === files.length) {
            if (this.globalSettings.optionAutoZoom) {
              this.map.zoomToBounds(bounds)
            }
            this.progressWindow.hide()
            onSuccess()
          }
        } catch (error) {
          console.error(`Error reading file ${file.name}: ${error}`)
          this.progressWindow.hide()
          onFailure()
        }
      }
    } else {
      this.progressWindow.show(`Importing data, please wait...`)
      let data = dragEvent.dataTransfer!.getData("Text")
      if (data?.trim().length > 0) {
        const features = await this.processDroppedText(data, autoShowLogWindow)
        if (features) {
          if (this.globalSettings.optionAutoZoom) {
            this.map.zoomToBounds(this.dataStore.calculateBoundsFromFeatures(features))
          }
          this.progressWindow.hide()
          onSuccess()
        } else {
          this.logWindow.info(`No features found, nothing to visualize`)
          this.progressWindow.hide()
          onFailure()
        }
      } else {
        this.logWindow.info(`No data found, nothing to visualize`)
        this.progressWindow.hide()
        onFailure()
      }
    }
  }

  private initialLayerStateAndSize(layerType: LayerType): LayerStateAndSize {
    return {
      show: false,
      supportsLevel: layerTypeSupportsLevel.includes(layerType),
      totalCount: 0,
      apiCount: 0,
      sizeInBytes: 0
    }
  }

  private setDataFileStateShow(fileId: string) {
    this.dataFileStates[fileId] = {show: true}
  }

  private readonly onProgressHandler = (percentage: number) => this.progressWindow.updatePercentage(percentage)

  private async processSingleFile(file: File, autoShowLogWindow: boolean): Promise<Feature[]> {
    const reader = new FileReader()
    const readFile = (): Promise<string> => {
      return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = () => reject(reader.error)
        reader.readAsText(file)
      })
    }

    try {
      const contents = await readFile()
      return await this.processSingleFileContents(file, contents, autoShowLogWindow)
    } catch (error) {
      this.logWindow.error(`Error reading file ${file.name}: ${error}`)
      return []
    }
  }

  private async processSingleFileContents(file: File, contents: string, autoShowLogWindow = false): Promise<Feature[]> {
    const fileName = file.name
    let checkDuplicates = true
    let features: Feature[] = []

    this.logWindow.info("")
    if (this.dataStore.exists(file)) {
      this.logWindow.info(`File ${fileName} was already parsed; data cleared.`)
      this.dataStore.remove(file)
    }
    this.logWindow.info(`Parsing file ${fileName}...`)

    // Try all file parsers.
    if (fileName.endsWith(".ttp")) {
      features = await (this.fileParsers[LayerType.TTPLocation] as ParserTtp).parseFile(
        fileName,
        contents,
        this.onProgressHandler
      )
      if (features.length > 0) {
        this.layerStates.TTPLocation.show = true
        this.layerStates.TTP1s.show = false
        this.layerStates.TTP100ms.show = false
        checkDuplicates = false
      }
    } else if (fileName.endsWith(".gpx")) {
      features = await (this.fileParsers[LayerType.GPX] as ParserGpx).parseFile(
        fileName,
        contents,
        this.onProgressHandler
      )
      if (features.length > 0) {
        this.layerStates.GPX.show = true
        checkDuplicates = false
      }
    } else if (fileName.endsWith(".geo.json") || fileName.endsWith(".geojson")) {
      features = await (this.fileParsers[LayerType.GeoJSON] as ParserGeoJson).parseFile(
        fileName,
        contents,
        this.onProgressHandler
      )
      if (features.length > 0) {
        this.layerStates.GeoJSON.show = true
        checkDuplicates = false
      }
    } else if (fileName.endsWith(".json")) {
      features = await (this.fileParsers[LayerType.JSON] as ParserJson).parseFile(
        fileName,
        contents,
        this.onProgressHandler
      )
      if (features.length > 0) {
        this.layerStates.JSON.show = true
        checkDuplicates = false
      }
    } else {
      // This may be a file with different layer types per line.
      features = await this.handleDropFileContents(contents, autoShowLogWindow)
    }
    if (features.length === 0) {
      this.logWindow.info(`No features found in file ${fileName}; nothing imported.`)
      this.dataStore.remove(file)
      delete this.dataFileStates[fileName]
      return []
    }

    // Add all features to the feature store; this is expensive. Only do this if needed.
    if (checkDuplicates) {
      await this.handleDuplicates(features)
    }
    const fileId = this.dataStore.addFeaturesFromDataFile(features, file)
    this.setDataFileStateShow(fileId)
    return features
  }

  private async processDroppedText(text: string, autoShowLogWindow = false): Promise<Feature[]> {
    // Try text parser.
    text = text.trim()
    let geoJsonfeatures = (this.fileParsers[LayerType.GeoJSON] as ParserGeoJson).parseString(text)
    let featuresPromise: Promise<Feature[]> = geoJsonfeatures
      ? Promise.resolve(geoJsonfeatures)
      : this.handleDropFileContents(text, autoShowLogWindow)

    // Get all features.
    const features = await featuresPromise

    // Add all features to the feature store.
    await this.handleDuplicates(features)
    const fileId = this.dataStore.addFeaturesFromDataFile(features)
    this.setDataFileStateShow(fileId)
    return features
  }

  private parseSingleTextLine(lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []

    // Execute the line parsers on the line.
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      const layerState = this.layerStates[layerType]
      const layerLineParser = this.lineParsers[layerType]
      if (layerLineParser) {
        features = layerLineParser.parseLine(lineNumber, line)
        if (features && features.length > 0) {
          layerState.show = true

          // Return when parser found something on this line, otherwise try next parser.
          return features
        }
      }
    }
    return features
  }

  private async handleDropFileContents(contents: string, autoShowLogWindow = false): Promise<Feature[]> {
    const linesToParse = contents
      .replace(/\r/g, "")
      .split("\n")
      .filter(
        (line) =>
          line.trim().length > 0 &&
          Parser.lineIsCandidateForParsing(line.trim(), this.globalSettings.optionSkipComments)
      )
    let allFeatures: Feature[] = []
    this.logWindow.info("Parsing contents line by line...")
    let unprocessedLines: {line: string; lineNumber: number}[] = []

    const processLine = async (line: string, index: number) => {
      this.onProgressHandler(index / linesToParse.length)
      const featurePerLine = this.parseSingleTextLine(index + 1, line.trim())
      if (featurePerLine.length > 0) {
        addAllElements(allFeatures, featurePerLine)
      } else {
        unprocessedLines.push({line: line, lineNumber: index + 1})
      }
    }
    await processItemsAsync(linesToParse, processLine, this.onProgressHandler)

    if (unprocessedLines.length > 0) {
      const filteredUnprocessedLines = unprocessedLines.filter((elm) => Parser.lineShouldBeRecognized(elm.line))
      const outputFilteredUnprocessedLines = filteredUnprocessedLines.reduce(
        (acc, elm) => `${acc}\nline ${(elm.lineNumber + 1).toString().padStart(7)}: ${elm.line}`,
        ""
      )

      if (filteredUnprocessedLines.length > 0) {
        if (autoShowLogWindow) {
          this.logWindow.setVisible(true)
        }
        this.logWindow.warning("Unprocessed lines:")
        this.logWindow.error(outputFilteredUnprocessedLines)
        this.logWindow.error(`\nWARNING: ${filteredUnprocessedLines.length} lines were not recognized or processed`)
        this.logWindow.info("| If this is a HTTP log from NavSDK, please issue a change request for the visualizer.")
        this.logWindow.info("| You can reach out on Slack to rijn.buve@tomtom.com for that.")
      }
    } else {
      this.logWindow.ok("OK: all lines were correctly processed")
    }
    return allFeatures
  }

  private async handleDuplicates(features: Feature[]): Promise<void> {
    this.progressWindow.updateMessage(`Analyzing ${features.length} requests for potential duplicates...`)
    this.progressWindow.updatePercentage(0)

    const processFeatureGroup = async (group: Feature[], index: number) => {
      const duplicates = group.length
      if (duplicates > 1) {
        for (const feature of group) {
          const time = feature.properties?.time
          let duplicates = 1
          if (time) {
            duplicates = group.filter(
              (otherFeature) =>
                otherFeature.properties &&
                otherFeature.properties.time &&
                Math.abs(otherFeature.properties.time - time) < this.minSecondsBetweenVolatileFeatures * 1000
            ).length
          }
          if (duplicates > 1) {
            const metadataKey = feature.properties?.metadata
            if (metadataKey) {
              const metadata = {
                duplicates: duplicates,
                ...this.metadataStore.retrieve(metadataKey)
              }
              this.metadataStore.update(metadataKey, metadata)
            }
            switch (feature.geometry?.type) {
              case "LineString":
              case "Polygon":
                feature.properties = {
                  ...feature.properties,
                  "fill-outline-color": "rgb(255,47,0)",
                  "line-width": Math.min(6, duplicates * 1.5)
                }
                break
              case "Point":
                feature.properties = {
                  ...feature.properties,
                  text: `${duplicates}x`,
                  "text-color": "rgb(255,255,255)",
                  "circle-color": "rgb(255,47,0)"
                }
                break
            }
          }
        }
      }
      this.onProgressHandler(index / featureMap.size)
    }

    // First create feature groups that have the same geoHash and layer.
    const featureMap = new Map<string, Feature[]>()
    for (const feature of features) {
      const geoHash = feature.properties?.geoHash
      const layer = feature.properties?.layer
      if (geoHash && layer) {
        const key = `${layer}-${geoHash}`
        if (!featureMap.has(key)) {
          featureMap.set(key, [])
        }
        featureMap.get(key)!.push(feature)
      }
    }
    const featureGroups = Array.from(featureMap.values())

    // Then process each group to find overlapping features withing a time range.
    await processItemsAsync(featureGroups, processFeatureGroup, this.onProgressHandler, 10)
  }
}

export default DataCanvas
