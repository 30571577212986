/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import {Tool} from "./tool"
import {MapGeoJSONFeature, Point as MapLibrePoint} from "maplibre-gl"
import MapView from "../app/mapView"
import InspectorWindow from "../windows/inspectorWindow"
import Logger from "../common/logger"
import SourceWindow from "../windows/sourceWindow"
import {DateTimeUtils} from "../common/utils/dateTimeUtils"
import {LayerWithoutId} from "../common/utils/mapLibreUtils"
import {LngLat} from "../common/utils/wgs84Utils"

/**
 * This tool allows the user to select map data on the map and get metadata info in the
 * metadata window.
 */
export class DataSelectorTool extends Tool {
  private readonly inspectorWindow: InspectorWindow
  private readonly sourceWindow: SourceWindow
  private readonly queryFeatures: (point: MapLibrePoint) => MapGeoJSONFeature[]
  private readonly shrinkTimeRage: (dateTime: Date) => void
  private selectedFeatures: Feature[] = []

  constructor(
    map: MapView,
    inspectorWindow: InspectorWindow,
    sourceWindow: SourceWindow,
    queryFeatures: (point: MapLibrePoint) => MapGeoJSONFeature[],
    shrinkTimeRange: (dateTime: Date) => void
  ) {
    super("dataSelectorTool", "Select data on map", map)
    this.inspectorWindow = inspectorWindow
    this.sourceWindow = sourceWindow
    this.queryFeatures = queryFeatures
    this.shrinkTimeRage = shrinkTimeRange
  }

  onClick(location: LngLat, point: MapLibrePoint) {
    this.selectedFeatures = this.queryFeatures(point) ?? []
    this.refresh()
  }

  onContextMenu(location: LngLat, point: MapLibrePoint) {
    const features = this.queryFeatures(point) ?? []
    if (features.length > 0) {
      const firstTime = features[0].properties?.time
      const earliestTime = new Date(
        features
          .map((feature) => new Date(feature.properties?.time).getTime())
          .reduce((minTime, currentTime) => Math.max(minTime, currentTime), firstTime)
      )
      Logger.log.info(`Shrink selection to time: ${DateTimeUtils.formatDateWithTime(earliestTime)}`)
      this.shrinkTimeRage(earliestTime)
    }
  }

  clear() {
    this.selectedFeatures = []
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "symbol",
        paint: {
          "text-color": "white"
        },
        filter: ["all", ["has", "text"], ["==", ["geometry-type"], "Point"]]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": 7,
          "circle-color": ["case", ["has", "circle-color"], ["get", "circle-color"], "rgb(255,0,0)"]
        },
        filter: ["==", ["geometry-type"], "Point"]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(42,69,158)"],
          "line-width": 6,
          "line-dasharray": [0.2, 1]
        },
        filter: ["any", ["==", ["geometry-type"], "Polygon"], ["==", ["geometry-type"], "LineString"]]
      },
      {
        type: "fill",
        paint: {
          "fill-color": ["case", ["has", "fill-color"], ["get", "fill-color"], "rgb(255,0,0)"],
          "fill-opacity": ["case", ["has", "fill-opacity"], ["get", "fill-opacity"], 0.2]
        },
        filter: ["==", ["geometry-type"], "Polygon"]
      }
    ]
  }

  refresh() {
    if (this.selectedFeatures.length < 1) {
      // Hide the inspector if no features are selected, make sure they are removed from the canvas.
      this.inspectorWindow.setVisible(false)
      this.sourceWindow.setVisible(false)
      this.draw([])
    } else {
      // Show the inspector if features are selected.
      this.inspectorWindow.show("", this.selectedFeatures)
    }
  }
}

export default DataSelectorTool
