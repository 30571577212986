/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * This is a utility class with static functions only.
 */
export class MemoryUtils {
  /**
   * Get the size in bytes of an object. This is an approximation.
   * @param object Any object/variable.
   */
  static approximateMemorySizeInBytesOfObject(object: any): number {
    if (!object) {
      return 0
    }
    const objectList = new Set<any>()
    const stack = [object]
    let bytes = 0

    while (stack.length) {
      const value = stack.pop()

      if (typeof value === "boolean") {
        bytes += 4
      } else if (typeof value === "string") {
        bytes += value.length * 2
      } else if (typeof value === "number") {
        bytes += 8
      } else if (typeof value === "object" && value !== null && !objectList.has(value)) {
        objectList.add(value)

        for (const key in value) {
          if (value.hasOwnProperty(key)) {
            // Keys are strings and add their size
            bytes += key.length * 2
            stack.push(value[key])
          }
        }
      }
    }
    return bytes
  }

  /**
   *
   * Add all elements from a source array to the target array, with limited stack usage.
   * This avoids using the Array.push(...source) method, which can cause a stack overflow.
   * Note that Array.prototype.push.apply(target, source) may also produce a stack overflow.
   * @param target Destination, source will be added at end.
   * @param source Source, possible empty.
   */
  static addAllElements<T>(target: T[], source: T[]) {
    const CHUNK_SIZE = 10000
    const length = source.length
    for (let i = 0; i < length; i += CHUNK_SIZE) {
      Array.prototype.push.apply(target, source.slice(i, i + CHUNK_SIZE))
    }
  }
}

export default MemoryUtils
