/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"

import {HTML_WINDOW_CONSOLE, HTML_WINDOW_CONTENT_CONSOLE} from "../html/htmlElementId"
import {Logger} from "../common/logger"
import {Html} from "../html/html"

export class ConsoleWindow extends HtmlWindow {
  public static readonly colorOK = "color: green"
  public static readonly colorInfo = "color: black"
  public static readonly colorWarning = "color: darkorange"
  public static readonly colorError = "color: red"

  private logMessages: {text: string; style: string}[] = []
  private logMessageRepeatCount = 1
  private readonly minRepeatCount = 3
  private readonly maxLogLines = 250

  constructor(title: string, htmlWindowElementId: string, htmlWindowTitleElementId: string) {
    super(title, htmlWindowElementId, htmlWindowTitleElementId)
    Logger.setGlobalLogger(new Logger(this.ok, this.info, this.warning, this.error, this.show, this.hide))
  }

  public static readonly color = (color: string) => `color: ${color}`
  public static readonly colorBlack = ConsoleWindow.color("black")
  public static readonly colorWhite = ConsoleWindow.color("white")

  clear() {
    this.logMessages = []
    this.logMessageRepeatCount = 1
    const consoleWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_CONSOLE)
    consoleWindowContent.innerHTML = ""
  }

  setVisible(show: boolean) {
    super.setVisible(show)
    if (show) {
      const consoleWindow = Html.getDefinedHtmlElementById(HTML_WINDOW_CONSOLE)
      const consoleWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_CONSOLE)
      consoleWindow.scrollTo({top: consoleWindowContent.scrollHeight})
    }
  }

  private readonly ok = (text: string) => this.log(text, ConsoleWindow.colorOK)

  private readonly info = (text: string) => this.log(text, ConsoleWindow.colorInfo)

  private readonly warning = (text: string) => this.log(text, ConsoleWindow.colorWarning)

  private readonly error = (text: string) => this.log(text, ConsoleWindow.colorError)

  private readonly show = () => this.setVisible(true)

  private readonly hide = () => this.setVisible(false)

  private log(text: string, style: string) {
    if (this.logMessages.length > 0 && text === this.logMessages[this.logMessages.length - 1].text) {
      this.logMessageRepeatCount++
      if (this.logMessageRepeatCount > this.minRepeatCount) {
        this.logMessages.pop()
        text += ` [<-- repeated ${this.logMessageRepeatCount - this.minRepeatCount + 1}x]`
      }
    } else {
      this.logMessageRepeatCount = 1
    }
    this.logMessages.push({text, style})
    if (this.logMessages.length > this.maxLogLines) {
      this.logMessages.shift()
    }

    const consoleWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_CONSOLE)
    consoleWindowContent.innerHTML = this.logMessages
      .map((msg) => `<span style="${msg.style}">${msg.text}</span><br/>`)
      .join("")
    const consoleWindow = Html.getDefinedHtmlElementById(HTML_WINDOW_CONSOLE)
    consoleWindow.scrollTo({top: consoleWindowContent.scrollHeight})
  }
}

export default ConsoleWindow
