/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export class Logger {
  static readonly log = new Logger()

  ok: (text: string) => void
  info: (text: string) => void
  warning: (text: string) => void
  error: (text: string) => void
  show: () => void
  hide: () => void

  constructor(
    ok: (text: string) => void = () => {},
    info: (text: string) => void = () => {},
    warning: (text: string) => void = () => {},
    error: (text: string) => void = () => {},
    show: () => void = () => {},
    hide: () => void = () => {}
  ) {
    this.ok = ok
    this.info = info
    this.warning = warning
    this.error = error
    this.show = show
    this.hide = hide
  }

  static setGlobalLogger(logger: Logger) {
    Logger.log.ok = logger.ok
    Logger.log.info = logger.info
    Logger.log.warning = logger.warning
    Logger.log.error = logger.error
    Logger.log.show = logger.show
    Logger.log.hide = logger.hide
  }
}

export default Logger
