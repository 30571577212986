/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * Fixed overhead for HTTP calls, to be used as a default if real overhead is unknown.
 */
export const KEY_HTTP_OVERHEAD_SIZE_IN_BYTES = "http-overhead-size-in-bytes"

export function getDefaultHttpOverheadSizeInBytes() {
  let overhead = localStorage.getItem(KEY_HTTP_OVERHEAD_SIZE_IN_BYTES)
  return overhead !== null ? parseInt(overhead) : 1024
}

export function setDefaultHttpOverheadSizeInBytes(sizeInBytes: number) {
  localStorage.setItem(KEY_HTTP_OVERHEAD_SIZE_IN_BYTES, sizeInBytes.toString())
}

/**
 * Returns whether the HTTP result code indicated success.
 * @param code The HTTP result code.
 */
type HttpCodeName = {
  code: number
  name: string
}

const httpCodeNames: HttpCodeName[] = [
  {code: 200, name: "OK"},
  {code: 201, name: "Created"},
  {code: 202, name: "Accepted"},
  {code: 203, name: "Non-Authoritative Information"},
  {code: 204, name: "No Content"},
  {code: 205, name: "Reset Content"},
  {code: 206, name: "Partial Content"},
  {code: 207, name: "Multi-Status"},
  {code: 208, name: "Already Reported"},
  {code: 226, name: "IM Used"},
  {code: 300, name: "Multiple Choices"},
  {code: 301, name: "Moved Permanently"},
  {code: 302, name: "Found"},
  {code: 303, name: "See Other"},
  {code: 304, name: "Not Modified"},
  {code: 305, name: "Use Proxy"},
  {code: 306, name: "Switch Proxy"},
  {code: 307, name: "Temporary Redirect"},
  {code: 308, name: "Permanent Redirect"}
]

export function getHttpCodeName(code: number): string | undefined {
  const httpCode = httpCodeNames.find((httpCode) => httpCode.code === code)
  return httpCode ? httpCode.name : undefined
}

export function isHttpCodeNoError(code: number): boolean {
  return httpCodeNames.some((httpCode) => httpCode.code === code)
}

export function isHttpCodeNoContent(code: number): boolean {
  return code === 204
}

/**
 * Returns whether the HTTP result code indicated an authorization failure.
 * @param code The HTTP result code.
 */
export function isHttpCodeNotAuthorized(code: number): boolean {
  return code === 401 || code === 403
}
