/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import {BoundingBox} from "../common/utils/geoUtils"
import {MetadataStore} from "./metadataStore"
import {LayerType} from "../parsers/parserTypes"
import {DateTimeFormat, DateTimeUtils} from "../common/utils/dateTimeUtils"
import MemoryUtils from "../common/utils/memoryUtils"
import StringUtils from "../common/utils/stringUtils"

/**
 * This file defines data structures for "global data".
 *
 * WARNING: Be aware that globally updating data in 'async' functions can lead to race conditions.
 * In such cases critical sections and guards may need to be used to make sure no two async functions
 * enter a critical section at the same time.
 */

export const DEFAULT_FILE_ID = "_file"
export const DEFAULT_LAYER_ID = LayerType._Total

export const TIMESTAMP_ALWAYS_SHOW = -1
export const TIMESTAMP_ALWAYS_HIDE = -2

const DEFAULT_FILE_NAME = "[ Dropped text items ]"

/**
 * All tile level filters.
 */
export enum TileLevelFilter {
  All,
  LessThan,
  LessEqual,
  Equal,
  GreaterEqual,
  GreaterThan
}

export type TileLevelFilterState = {
  level: number // Tile level to filter on.
  tileLevelFilter: TileLevelFilter // Filter type (<=, >=, ...).
}

/**
 * This type describes the state of a layer.
 */
export type LayerState = {
  show: boolean // True if the layer is shown.
  supportsLevel: boolean // True if the layer supports tile levels.
}

/**
 * This is the state of all layers, per layer type.
 */
export type LayerStates = Record<LayerType, LayerState>

/**
 * This type describes the size of a layer, in counts and bytes.
 */
export type LayerSize = {
  totalCount: number // Total number of calls/events.
  apiCount: number // Number of (non-CDN) API calls.
  sizeInBytes: number // Total size in bytes.
}

/**
 * This type describes the size of all layers, per layer type.
 */
export type LayerSizes = Record<LayerType, LayerSize>

/**
 * This type describes the state of a data file.
 */
export type DataFileState = {
  show: boolean // True if the data file is shown.
}

/**
 * This is the state of all data files, per file ID.
 */
export type DataFileStates = Record<string, DataFileState>

/**
 * This type describes all features per layer.
 */
export type LayerFeatures = Record<LayerType, Feature[]>

/**
 * This type describes a data file.
 */
export type DataFileInfo = {
  id: string // Unique ID of the data file.
  name: string // Name of the data file.
  memorySizeInBytes: number // Size of the data file in memory.
  layerSizes: LayerSizes // Size of the data file per layer.
}

/**
 * This type describes the content of a data file, as features per layer.
 */
export type DataFileContent = Omit<DataFileInfo, "id"> & {
  layerFeatures: LayerFeatures // Features per layer.
  bounds: BoundingBox // Bounding box of the data file.
  sourceLines: string[] // All source lines of the data files.
}

/**
 * This type describes the content of all data files, per file ID.
 */
type DataFileContentPerFile = Record<string, DataFileContent>

/**
 * This class stores all data files and their content.
 */
export class DataStore {
  private static dataFileContentPerFile: DataFileContentPerFile = {}

  static getDataFileContentIds() {
    return Object.entries(DataStore.dataFileContentPerFile)
  }

  static addFeaturesAndSourceLines(features: Feature[], sourceLines: string[], sourceFile?: File) {
    const fileName = DataStore.getFileNameFromFile(sourceFile)
    const layerFeatures: LayerFeatures = {} as LayerFeatures
    let bounds = BoundingBox.empty()

    const layerSizes: LayerSizes = {} as LayerSizes
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      layerFeatures[layerType] = []
      layerSizes[layerType] = {totalCount: 0, apiCount: 0, sizeInBytes: 0}
    }

    // Calculate count and size of features per layer.
    features.forEach((feature) => {
      const metadata = MetadataStore.retrieve(feature.properties?.metadata)
      const layerType = (metadata?.layer ? metadata.layer : DEFAULT_LAYER_ID) as LayerType
      layerFeatures[layerType].push(feature)
      bounds = bounds.extendWithOther(metadata?.bounds)

      // Increase total number of calls.
      layerSizes[layerType].totalCount++

      // If the "cdn" property is true, we don't count it as an API call.
      if (!metadata?.usesCdn) {
        layerSizes[layerType].apiCount++
      }

      if (metadata?.sizeInBytes) {
        layerSizes[layerType].sizeInBytes += metadata.sizeInBytes
      }
    })

    const fileId = DataStore.getFileIdFromFile(sourceFile)
    DataStore.dataFileContentPerFile[fileId] = {
      name: fileName,
      bounds: bounds,
      memorySizeInBytes: MemoryUtils.approximateMemorySizeInBytesOfObject(features),
      layerFeatures: layerFeatures,
      layerSizes: layerSizes,
      sourceLines: sourceLines
    }

    // Create a box around the file extents, if there are bounds.
    if (!bounds.isEmpty()) {
      if (!layerFeatures[DEFAULT_LAYER_ID]) {
        layerFeatures[DEFAULT_LAYER_ID] = []
      }
      layerFeatures[DEFAULT_LAYER_ID].push(...DataStore.createExtentsBoxWithFilename(bounds, fileName))
    }
  }

  static getSourceLines(fileId: string): string[] {
    const dataFileContent = DataStore.dataFileContentPerFile[fileId]
    return dataFileContent ? dataFileContent.sourceLines : ["No source found..."]
  }

  static getFilename(fileId: string): string {
    const dataFileContent = DataStore.dataFileContentPerFile[fileId]
    return dataFileContent ? dataFileContent.name : ""
  }

  static getFeatures(
    layerStates: LayerStates,
    dataFileStates: DataFileStates,
    filterStates: TileLevelFilterState
  ): Feature[] {
    const features: Feature[] = []
    for (const dataFile in DataStore.dataFileContentPerFile) {
      // Skip data files that are not shown.
      if (!dataFileStates[dataFile].show) {
        continue
      }

      // Get all features per layer.
      const featuresPerLayer = DataStore.dataFileContentPerFile[dataFile].layerFeatures
      for (const layerTypeKey in featuresPerLayer) {
        const layerType = layerTypeKey as LayerType
        // Copy all elements from the default layer (where generated data, like bounding boxes, lives).
        if (layerType === DEFAULT_LAYER_ID) {
          MemoryUtils.addAllElements(features, featuresPerLayer[layerType])
          continue
        }

        // Skip all layers that are not shown.
        const layerState = layerStates[layerType]
        if (layerState && !layerState.show) {
          continue
        }

        if (!layerState?.supportsLevel || filterStates.tileLevelFilter === TileLevelFilter.All) {
          // Super-fast block copy for non-tiled data, or non-filtered data.
          MemoryUtils.addAllElements(features, featuresPerLayer[layerType])
        } else {
          // Need to copy feature by feature.
          for (const feature of featuresPerLayer[layerType]) {
            const metadata = MetadataStore.retrieve(feature.properties?.metadata)
            if (
              metadata &&
              (metadata.tileLevel === undefined ||
                (TileLevelFilter.LessThan === filterStates.tileLevelFilter &&
                  metadata.tileLevel < filterStates.level) ||
                (TileLevelFilter.LessEqual === filterStates.tileLevelFilter &&
                  metadata.tileLevel <= filterStates.level) ||
                (TileLevelFilter.Equal === filterStates.tileLevelFilter && metadata.tileLevel === filterStates.level) ||
                (TileLevelFilter.GreaterEqual === filterStates.tileLevelFilter &&
                  metadata.tileLevel >= filterStates.level) ||
                (TileLevelFilter.GreaterThan === filterStates.tileLevelFilter &&
                  metadata.tileLevel > filterStates.level))
            ) {
              features.push(feature)
            }
          }
        }
      }
    }
    return features
  }

  static getDataFileInfoList(): DataFileInfo[] {
    return Object.keys(DataStore.dataFileContentPerFile).map((id) => {
      const dataFileContent = DataStore.dataFileContentPerFile[id]
      return {
        id: id,
        name: dataFileContent.name,
        memorySizeInBytes: dataFileContent.memorySizeInBytes,
        layerSizes: dataFileContent.layerSizes
      }
    })
  }

  static getBoundsOfDataFile(file: File): BoundingBox {
    const fileId = DataStore.getFileIdFromFile(file)
    return DataStore.dataFileContentPerFile[fileId].bounds
  }

  static isFileLoaded(file: File): boolean {
    return DataStore.dataFileContentPerFile[DataStore.getFileIdFromFile(file)] !== undefined
  }

  /**
   * Clears all features in the data store for a specific data file.
   */
  static removeFile(file: File) {
    const fileId = DataStore.getFileIdFromFile(file)
    if (DataStore.dataFileContentPerFile[fileId]) {
      Object.entries(DataStore.dataFileContentPerFile[fileId].layerFeatures).forEach(([_, features]) => {
        features.forEach((feature) => {
          if (feature.properties?.metadata) {
            MetadataStore.remove(feature.properties.metadata)
          }
        })
      })
      delete DataStore.dataFileContentPerFile[fileId]
    }
  }

  /**
   * Initializes and clear the data store.
   */
  static removeAll() {
    DataStore.dataFileContentPerFile = {}
    MetadataStore.removeAll()
  }

  /**
   * Returns the approximate size in bytes of the data store.
   */
  static totalSizeInBytes(): number {
    return MemoryUtils.approximateMemorySizeInBytesOfObject(DataStore.dataFileContentPerFile)
  }

  static getFileIdFromFile(file?: File) {
    return file ? DataStore.createDataFileIdFromFile(file) : DEFAULT_FILE_ID
  }

  static getFileNameFromFile(file?: File) {
    return file ? DataStore.createDataFileNameFromFile(file) : DEFAULT_FILE_NAME
  }

  private static createExtentsBoxWithFilename(bounds: BoundingBox, fileName: string): Feature[] {
    return [
      // Dashed box.
      {
        type: "Feature",
        properties: {
          time: TIMESTAMP_ALWAYS_SHOW,
          "line-width": 1,
          "line-color": "rgba(0,0,0,0.3)",
          "line-dasharray": [10, 5],
          generated: true
        },
        geometry: {
          type: "LineString",
          coordinates: [
            [bounds.southWest.lng, bounds.southWest.lat],
            [bounds.southWest.lng, bounds.northEast.lat],
            [bounds.northEast.lng, bounds.northEast.lat],
            [bounds.northEast.lng, bounds.southWest.lat],
            [bounds.southWest.lng, bounds.southWest.lat]
          ]
        }
      },
      // Filename.
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [bounds.southWest.lng, bounds.southWest.lat]
        },
        properties: {
          text: `\n${fileName}`,
          time: TIMESTAMP_ALWAYS_SHOW,
          generated: true,
          "text-size": 12,
          "text-anchor": "left",
          "text-color": "rgb(80,17,75)",
          "circle-radius": 0
        }
      }
    ]
  }

  private static createDataFileIdFromFile(file: File): string {
    return `${file.name}_${file.lastModified}`
  }

  private static createDataFileNameFromFile(file: File): string {
    let name = file.name
    const fileId = DataStore.createDataFileIdFromFile(file)
    for (const id in DataStore.dataFileContentPerFile) {
      if (DataStore.dataFileContentPerFile[id].name === name && id !== fileId) {
        name = `${file.name} (${DateTimeUtils.formatDateWithTime(new Date(file.lastModified), DateTimeFormat.LocalTime)})`
        break
      }
    }
    return StringUtils.truncateString(name, 64)
  }
}

export default DataStore
