/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {isParsedPointColor, isParsedPolygonColor, LayerType, ParsedPointColor, ParsedPolygonColor} from "./parserTypes"
import {BoundingBox} from "../common/geo"
import {Feature} from "geojson"
import {packedTileIdToLevel, packedTileIdToWgs} from "../nds/nds"
import {LngLatToArray} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {GlobalSettings} from "../app/globalSettings"
import {isHttpCodeNoError} from "../common/httpCodes"
import LogWindow from "../app/logWindow"
import LineParser from "./lineParser"
import {TIMESTAMP_ALWAYS_HIDE} from "../app/dataStore"
import Parser from "./parser"

export class ParserNdsLive extends LineParser {
  constructor(logWindow: LogWindow, globalSettings: GlobalSettings, metadataStore: MetadataStore) {
    super(
      logWindow,
      globalSettings,
      metadataStore,
      LayerType.NdsLive,
      "NDS.Live",
      {
        "fill-opacity": 0.2,
        "fill-color": "rgb(255,77,0)",
        "fill-outline-color": "rgb(239,74,13)"
      },
      "(?:(?:/+nds-[A-Za-z_-]+-tiles/+[0-9]+/+tiles/+)|(?:/+umd-tiles/+[A-Za-z0-9_.-]+)/+(?:(?:tiles)|(?:headers))/+){n}",
      "(?:/+ndslive/+smart-layer-service/+(?:[a-z]+/+)?[0-9]+/+get.*ByTileId)|(?:/+umd-tiles/+[A-Za-z0-9_.-]+)/+(?:metadata)/+"
    )
  }

  parseLine(lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const usesCdn = Parser.lineUsesCdn(line)
    const metadata = {
      lineNumber: lineNumber,
      line: line,
      ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
      ...(usesCdn && {usesCdn: true})
    }
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation.replace("{n}", "([0-9.]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const packedTileId = parseInt(match[1])
        const tileLevel = packedTileIdToLevel(packedTileId)
        const metadataWithTile = {
          ...metadata,
          tileId: packedTileId,
          tileLevel: tileLevel
        }
        const polygon = this.createPolygonFromNdsLiveTile(packedTileId, metadataWithTile, time, sizeInBytes)
        if (!polygon) {
          return features
        }
        features.push(polygon)
        from = from + match.index + match[0].length
      }
    }
    if (features.length === 0 && this.regexWithoutLocation) {
      const match = RegExp(new RegExp(this.regexWithoutLocation)).exec(line)
      if (match) {
        const featureWithoutTile = this.createFeatureWithoutTile(metadata, time, sizeInBytes)
        features.push(featureWithoutTile)
      }
    }
    return features
  }

  private createFeatureWithoutTile(metadata: any, time?: Date, sizeInBytes?: number): Feature {
    let color = this.color
    if (metadata.httpStatusCode && !isHttpCodeNoError(metadata.httpStatusCode) && isParsedPointColor(color)) {
      color = this.modifyPointColorToErrorState(color as ParsedPointColor)
    }
    const extendedMetadata = {
      ...metadata,
      ...{time: time?.getTime() ?? TIMESTAMP_ALWAYS_HIDE},
      ...{sizeInBytes: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: this.layerType
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [0, 0]
      },
      properties: {
        metadata: metadataKey,
        ...{time: time?.getTime() ?? TIMESTAMP_ALWAYS_HIDE},
        layer: this.layerType,
        ...color
      }
    }
  }

  private createPolygonFromNdsLiveTile(
    packedTileId: number,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature {
    let color = this.color
    if (metadata.httpStatusCode && !isHttpCodeNoError(metadata.httpStatusCode) && isParsedPolygonColor(color)) {
      color = this.modifyPolygonColorToErrorState(color as ParsedPolygonColor)
    }
    const coordinates = packedTileIdToWgs(packedTileId)
    const bounds = BoundingBox.empty().extendToLngLat(coordinates.southWest).extendToLngLat(coordinates.northEast)
    const extendedMetadata = {
      ...metadata,
      ...(time !== undefined && {time: time.getTime()}),
      ...(sizeInBytes !== undefined && {sizeInBytes: sizeInBytes}),
      layer: this.layerType,
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            LngLatToArray(coordinates.southWest),
            LngLatToArray(coordinates.northWest),
            LngLatToArray(coordinates.northEast),
            LngLatToArray(coordinates.southEast),
            LngLatToArray(coordinates.southWest)
          ]
        ]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: this.layerType,
        geoHash: bounds.toGeoHash(),
        ...color
      }
    }
  }
}

export default ParserNdsLive
