/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export const HTML_PROPERTY_TOOLBOX_TOP = "--toolbox-top"
export const HTML_PROPERTY_PANEL_FILTERS_TOP = "--panel-filters-top"
export const HTML_PROPERTY_PANEL_LAYERS_TOP = "--panel-layers-top"
export const HTML_PROPERTY_PANEL_LAYERS_MAX_HEIGHT = "--panel-layers-max-height"
export const HTML_PROPERTY_PANEL_DATA_FILES_TOP = "--panel-data-files-top"
export const HTML_PROPERTY_PANEL_DATA_FILES_MAX_HEIGHT = "--panel-data-files-max-height"
