/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Point as MapLibrePoint} from "maplibre-gl"
import DataSelectorTool from "../tools/dataSelectorTool"
import RouteCreatorJsonTool from "../tools/routeCreatorJsonTool"
import RouteCreatorGpxTool from "../tools/routeCreatorGpxTool"
import DistanceCalculatorTool from "../tools/distanceCalculatorTool"
import TileEditorTool from "../tools/tileEditorTool"
import {Tool} from "../tools/tool"
import {LngLat} from "../common/wgs84"
import MapView from "./mapView"
import DataCanvas from "./dataCanvas"
import Timeline from "./timeline"
import MenuBar from "./menuBar"
import {GlobalSettings} from "./globalSettings"
import {formatNumberAsPowers, formatSizeInBytes} from "../common/objects"
import {DataFileInfo, DataStore, FilterType, LayerSizes} from "./dataStore"
import {MetadataStore} from "../common/metadata"
import InvalidApiKey from "../exceptions/invalidApiKey"
import {GenericMenuItem, LayerMenuItem, MenuItem, separatorMenuItem} from "./menuTypes"
import {
  isParsedPointColor,
  isParsedPolygonColor,
  isRealLayer,
  layerSectionName,
  LayerType,
  layerTypeSupportsItemCountOnly,
  ParsedPointColor,
  ParsedPolygonColor
} from "../parsers/parserTypes"
import {HelpWindow} from "./helpWindow"
import {APPLICATION_VERSION} from "../common/version"
import {
  getDefaultHttpOverheadSizeInBytes,
  KEY_HTTP_OVERHEAD_SIZE_IN_BYTES,
  setDefaultHttpOverheadSizeInBytes
} from "../common/httpCodes"
import ReleaseNotesWindow from "./ReleaseNotesWindow"
import InspectorWindow from "./inspectorWindow"
import LogWindow from "./logWindow"
import {Toolbox, Tools} from "./toolbox"
import {
  getDefinedHtmlElementById,
  getHtmlElementPosition,
  HtmlElementPosition,
  makeHtmlClassDraggable,
  makeHtmlElementDraggable,
  setHtmlElementPosition
} from "../common/html"
import assert from "../common/assert"
import ProgressWindow from "./progressWindow"
import {TimeFormat} from "../common/datetime"

/**
 * This class defines the application. It supports an exception handler to catch exceptions during
 * execution.
 */
export class App {
  public static readonly KEY_API_KEY_TOMTOM = "api-key-tomtom"
  private readonly KEY_PREVIOUS_APPLICATION_VERSION = "previous-application-version"

  /**
   * Below are some HTML element IDs used in the application.
   * The application uses some conventions that modules can rely
   * on, such as:
   *
   * "some-id" - this is a HTML element ID.
   * "some-id-menu-item" - this is the ID of the menu item related to "some-id", often used to show a on/off toggle
   * "some-id-dropdown" - this is the ID of the drop down menu item related to "some-id".
   * "some-id-content" - this is the ID of an HTML text placeholder for "some-id".
   * "some-id-color/text/status" - these are the IDs of layer HTML elements in the layers list.
   */
  private readonly htmlElementIdLogWindow = "log-window"
  private readonly htmlElementIdInspectorWindow = "inspector-window"
  private readonly htmlElementIdInspectorWindowTitleBar = "inspector-window-title-bar"
  private readonly htmlElementIdInspectorContent = "inspector-content"
  private readonly htmlElementIdProgressWindow = "progress-window"
  private readonly htmlElementIdProgressContent = "progress-content"
  private readonly htmlElementIdProgressBar = "progress-bar"
  private readonly htmlElementIdHelpWindow = "help-window"
  private readonly htmlElementIdHelpContent = "help-content"
  private readonly htmlElementIdReleaseNotesWindow = "release-notes-window"
  private readonly htmlElementIdReleaseNotesContent = "release-notes-content"

  private readonly htmlElementIdToolbox = "toolbox"
  private readonly htmlElementIdMenuBar = "menubar"
  private readonly htmlElementIdMap = "map"
  private readonly htmlElementIdTimeline = "timeline"

  private readonly htmlClassIdPanel = "panel"
  private readonly htmlClassIdWindow = "window"
  private readonly htmlClassIdWindowTitleBar = "window-title-bar"

  private readonly textAutoZoomOn = "Auto-zoom"
  private readonly textAutoZoomOff = "No zoom"
  private readonly showLayerSizeInBytesOn = "Data usage"
  private readonly showLayerSizeInBytesOff = "API calls"

  private readonly globalSettings: GlobalSettings
  private readonly canvas: DataCanvas
  private readonly mapView: MapView
  private readonly timeline: Timeline
  private readonly metadataStore: MetadataStore
  private readonly dataStore: DataStore
  private readonly tools: Tools
  private readonly toolbox: Toolbox
  private readonly menuBar: MenuBar
  private readonly helpWindow: HelpWindow
  private readonly releaseNotesWindow: ReleaseNotesWindow
  private readonly inspectorWindow: InspectorWindow
  private readonly logWindow: LogWindow
  private readonly progressWindow: ProgressWindow

  private readonly excludeLayersFromToggleAll: LayerType[] = [LayerType.TTP100ms, LayerType.TTP1s]

  private currentTool: Tool
  private keyboardShortcutsEnabled = true
  private showLayerSizeInBytes = true
  private htmlElementPositions: Record<string, HtmlElementPosition> = {}

  constructor(applicationVersion: string, apiKey: string) {
    window.onerror = this.handleException

    this.globalSettings = new GlobalSettings()
    this.metadataStore = new MetadataStore()

    this.helpWindow = new HelpWindow(this.htmlElementIdHelpWindow, this.htmlElementIdHelpContent)
    this.helpWindow.loadContent()

    this.releaseNotesWindow = new ReleaseNotesWindow(
      this.htmlElementIdReleaseNotesWindow,
      this.htmlElementIdReleaseNotesContent
    )
    this.releaseNotesWindow.loadContent()

    this.progressWindow = new ProgressWindow(
      this.htmlElementIdProgressWindow,
      this.htmlElementIdProgressContent,
      this.htmlElementIdProgressBar
    )
    this.inspectorWindow = new InspectorWindow(
      this.htmlElementIdInspectorWindow,
      this.htmlElementIdInspectorContent,
      this.htmlElementIdInspectorWindowTitleBar,
      this.globalSettings
    )
    this.logWindow = new LogWindow(this.htmlElementIdLogWindow)

    this.mapView = new MapView("map", this.logWindow, apiKey, this.onClick)
    this.dataStore = new DataStore(this.metadataStore, this.globalSettings)
    this.canvas = new DataCanvas(
      this.mapView,
      this.globalSettings,
      this.progressWindow,
      this.logWindow,
      this.metadataStore,
      this.dataStore
    )
    this.tools = {
      dataSelector: new DataSelectorTool(
        this.mapView,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        this.mapView.queryFeatures
      ),
      distanceCalculator: new DistanceCalculatorTool(
        this.mapView,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore
      ),
      routeCreatorJson: new RouteCreatorJsonTool(
        this.mapView,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        apiKey
      ),
      routeCreatorGpx: new RouteCreatorGpxTool(
        this.mapView,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        apiKey
      ),
      tileEditor: new TileEditorTool(
        this.mapView,
        this.inspectorWindow,
        this.logWindow,
        this.metadataStore,
        this.mapView.queryTileUrl
      )
    }
    this.currentTool = this.tools.dataSelector
    const timeLineWindow = getDefinedHtmlElementById(this.htmlElementIdTimeline)
    this.timeline = new Timeline(
      timeLineWindow,
      this.globalSettings,
      this.metadataStore,
      this.inspectorWindow,
      this.canvas.setTimeRange
    )

    const viewMenuDefinition = this.setupViewMenu()
    const othersMenuDefinition = this.setupOthersMenu()

    const clearButton = getDefinedHtmlElementById("button-clear-map")
    clearButton.addEventListener("click", () => this.clearMap())

    this.createDataFilesList()
    this.createLayersList()
    this.updateOptionTileLevels()

    this.toolbox = new Toolbox(this.selectTool, this.toggleHelpWindow)
    this.toolbox.selectInitialTool()

    const layersMenuDefinition = this.setupLayersMenu()
    this.menuBar = new MenuBar(
      this.mapView.getMapsMenu(),
      viewMenuDefinition,
      layersMenuDefinition,
      othersMenuDefinition,
      this.mapView.selectMap
    )
    window.onclick = (e) => this.menuBar.onWindowClick(e)

    layersMenuDefinition.forEach((item) => {
      if (item.shortcut) {
        const layerType = item.id as LayerType
        const elm = getDefinedHtmlElementById(`${layerType}-color`)
        elm.innerText = item.shortcut
        if (item.textColor) {
          elm.style.color = item.textColor
        }
      }
    })
    this.setupKeyboardListeners([viewMenuDefinition, layersMenuDefinition, othersMenuDefinition].flat())
    this.setupOtherListeners()

    this.updateOptionsStatus(
      "auto-zoom",
      this.globalSettings.optionAutoZoom,
      true,
      this.textAutoZoomOn,
      this.textAutoZoomOff
    )
    this.updateOptionsStatus(
      "toggle-size",
      this.showLayerSizeInBytes,
      true,
      this.showLayerSizeInBytesOn,
      this.showLayerSizeInBytesOff
    )
    MenuBar.updateMenuItemToggle("tile-grid-menu-item", this.mapView.showTileGrid())
    MenuBar.updateMenuItemToggle("urban-sections-menu-item", this.globalSettings.optionUrbanSections)
    MenuBar.updateMenuItemToggle(
      "use-local-time-menu-item",
      this.globalSettings.optionTimeFormat === TimeFormat.UTCTime
    )
    MenuBar.updateMenuItemToggle("skip-comments-menu-item", this.globalSettings.optionSkipComments)
    MenuBar.updateMenuItemToggle("auto-show-log-window-menu-item", this.globalSettings.optionAutoShowLogWindow)

    Object.entries(this.canvas.layerStates).forEach(([layerType, item]) => {
      if (isRealLayer(layerType as LayerType)) {
        this.updateOptionsStatus(layerType, item.show)
      }
    })

    this.makeHtmlElementDroppable(this.htmlElementIdMap)
    this.makeHtmlElementDroppable(this.htmlElementIdMenuBar)
    this.makeHtmlElementDroppable(this.htmlElementIdTimeline)
    this.makeHtmlElementDroppable(this.htmlElementIdToolbox)

    this.makeHtmlClassDroppable(this.htmlClassIdPanel)
    this.makeHtmlClassDroppable(this.htmlClassIdWindow)

    const selectFilesButton = getDefinedHtmlElementById("button-select-files")
    selectFilesButton.addEventListener("click", () => this.selectFiles())

    // These require the menu options to be available:
    this.updateLayersList()
    this.helpWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
    this.logWindow.setVisible(false)

    // Automatically show help/what's new if this is a new version.
    let previousApplicationVersion = localStorage.getItem(this.KEY_PREVIOUS_APPLICATION_VERSION)
    if (previousApplicationVersion !== APPLICATION_VERSION) {
      localStorage.setItem(this.KEY_PREVIOUS_APPLICATION_VERSION, APPLICATION_VERSION)
      this.releaseNotesWindow.setVisible(true)
    } else {
      this.releaseNotesWindow.setVisible(false)
    }

    this.htmlElementPositions[this.htmlElementIdToolbox] = getHtmlElementPosition(this.htmlElementIdToolbox)
    this.storeHtmlElementPositions(this.htmlClassIdPanel)
    this.storeHtmlElementPositions(this.htmlClassIdWindow)

    makeHtmlElementDraggable(this.htmlElementIdToolbox)
    makeHtmlClassDraggable(this.htmlClassIdPanel)
    makeHtmlClassDraggable(this.htmlClassIdWindowTitleBar, this.htmlClassIdWindow)

    const versionMarker = getDefinedHtmlElementById("version-marker")
    versionMarker.addEventListener("click", (event: MouseEvent) => {
      event.preventDefault()
      this.releaseNotesWindow.setVisible(true)
    })

    this.resizePanels()
    window.addEventListener("resize", this.resizePanels)
  }

  private readonly resizePanels = () => {
    const menubar = getDefinedHtmlElementById("menubar")
    const toolbox = getDefinedHtmlElementById("toolbox")
    const filtersList = getDefinedHtmlElementById("filters-list")
    const layersList = getDefinedHtmlElementById("layers-list")
    const dataFilesList = getDefinedHtmlElementById("data-files-list")
    const timeline = getDefinedHtmlElementById("timeline")

    const gap = 5
    const toolboxTop = menubar.offsetTop + menubar.offsetHeight + gap
    toolbox.style.setProperty("--toolbox-top", `${toolboxTop}px`)

    const filtersListTop = toolbox.offsetTop + toolbox.offsetHeight + gap
    filtersList.style.setProperty("--filters-list-top", `${filtersListTop}px`)

    const layersListTop = filtersList.offsetTop + filtersList.offsetHeight + gap
    const layersListMaxHeight = timeline.offsetTop - layersListTop - dataFilesList.offsetHeight - gap
    layersList.style.setProperty("--layers-list-top", `${layersListTop}px`)
    layersList.style.setProperty("--layers-list-max-height", `${layersListMaxHeight}px`)

    const dataFilesListTop = layersList.offsetTop + layersList.offsetHeight + gap
    dataFilesList.style.setProperty("--data-files-list-top", `${dataFilesListTop}px`)
  }

  private makeHtmlClassDroppable(className: string) {
    let elements = document.getElementsByClassName(className)
    Array.from(elements).forEach((elm) => this.makeHtmlElementDroppable(elm.id))
  }

  private async handleDropOnCanvas(dragEvent: DragEvent) {
    await this.canvas.handleDrop(
      dragEvent,
      () => {
        this.clearAllToolDataExceptDistanceCalculator()
        this.updateLayersList()
        this.createDataFilesList()
        this.draw()
      },
      () => {
        this.updateLayersList()
        this.createDataFilesList()
        this.draw()
      },
      this.globalSettings.optionAutoShowLogWindow
    )
  }

  private makeHtmlElementDroppable(id: string) {
    const dropArea = getDefinedHtmlElementById(id)
    dropArea.addEventListener("dragover", (dragEvent) => {
      dragEvent.preventDefault()
      dropArea.classList.add("drag-and-drop-border")
    })

    dropArea.addEventListener("dragleave", (dragEvent) => {
      dragEvent.preventDefault()
      dropArea.classList.remove("drag-and-drop-border")
    })

    dropArea.addEventListener("drop", (dragEvent) => {
      dragEvent.preventDefault()
      dropArea.classList.remove("drag-and-drop-border")
      this.handleDropOnCanvas(dragEvent)
    })
  }

  private storeHtmlElementPositions(className: string) {
    const elements = document.getElementsByClassName(className)
    Array.from(elements).forEach((elm) => (this.htmlElementPositions[elm.id] = getHtmlElementPosition(elm.id)))
  }

  private resetHTMLElementPositions() {
    Object.entries(this.htmlElementPositions).forEach(([id, position]) => {
      setHtmlElementPosition(id, position)
    })
  }

  private toggleShowTileGrid() {
    this.mapView.toggleShowTileGrid()
    MenuBar.updateMenuItemToggle("tile-grid-menu-item", this.mapView.showTileGrid())
  }

  private selectFiles() {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = true
    input.accept = ".ttp,.gpx,.json,.geojson,.csv,.txt,.log,.http,.xml,.output,.diff"
    input.addEventListener("change", (event) => {
      const files = (event.target as HTMLInputElement).files
      if (files) {
        const dataTransfer = new DataTransfer()
        for (const element of files) {
          dataTransfer.items.add(element)
        }
        const dragEvent = new DragEvent("drop", {
          dataTransfer: dataTransfer,
          bubbles: true,
          cancelable: true
        })
        // Simulate drop event for the selected files.
        this.handleDropOnCanvas(dragEvent)
      }
    })
    // Open file selector dialog.
    input.click()
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param location Location on map.
   * @param point Screen map location.
   */
  private readonly onClick = (location: LngLat, point: MapLibrePoint) => {
    this.currentTool.onClick(location, point)
  }

  /**
   * Select a tool. NNeeds to be a lambda, not a regular function, to avoid binding issues.
   * @param id Tool to select.
   */
  private readonly selectTool = (id: keyof Tools) => {
    this.currentTool = this.tools[id]
  }

  /**
   * Toggle help window. NNeeds to be a lambda, not a regular function, to avoid binding issues.
   */
  private readonly toggleHelpWindow = () => {
    this.helpWindow.setVisible(!this.helpWindow.isVisible())
    if (this.helpWindow.isVisible()) {
      this.releaseNotesWindow.setVisible(false)
    }
  }

  private toggleLayer(layerType: LayerType) {
    const layerStateAndSize = this.canvas.layerStates[layerType]
    layerStateAndSize.show = !layerStateAndSize.show
    this.updateOptionsStatus(layerType as string, layerStateAndSize.show)
    this.draw()
  }

  private readonly toggleAllLayers = () => {
    const elm = getDefinedHtmlElementById("toggle-all-status")
    elm.classList.add("highlight")
    setTimeout(() => {
      elm.classList.remove("highlight")
    }, 250)
    const show = !Object.entries(this.canvas.layerStates)
      .filter(([key, _]) => !this.excludeLayersFromToggleAll.includes(key as LayerType))
      .map(([_, layerState]) => layerState.show)
      .reduce((acc, show) => acc || show, false)
    this.showAllLayersExceptExcluded(show)
    if (show) {
      this.excludeLayersFromToggleAll.forEach((layerType) => {
        if (!this.canvas.layerStates[layerType].show) {
          const columnStatus = getDefinedHtmlElementById(`${layerType}-status`)
          columnStatus.classList.add("highlight")
          setTimeout(() => {
            columnStatus.classList.remove("highlight")
          }, 250)
        }
      })
    }
  }

  private showAllLayersExceptExcluded(show: boolean) {
    Object.entries(this.canvas.layerStates)
      .filter(([key, _]) => !show || !this.excludeLayersFromToggleAll.includes(key as LayerType))
      .forEach(([_, layerState]) => {
        layerState.show = show
      })
    this.updateLayersList()
    this.draw()
  }

  private readonly toggleDataFile = (dataFileInfo: DataFileInfo) => {
    const newValue = !this.canvas.dataFileStates[dataFileInfo.id].show
    this.canvas.dataFileStates[dataFileInfo.id].show = newValue
    this.updateOptionsStatus(dataFileInfo.id, newValue)
    this.updateLayersList()
    if (newValue && this.globalSettings.optionAutoZoom) {
      this.canvas.zoomToBounds()
    }
    this.draw()
  }

  private toggleOptionTileLevel(tileLevel: number, useKeyboard = true) {
    if (useKeyboard) {
      switch (this.canvas.tileLevelFilterState.filter) {
        case FilterType.All:
          if (this.canvas.tileLevelFilterState.level === tileLevel) {
            this.canvas.tileLevelFilterState.filter = FilterType.Equal
          } else {
            this.canvas.tileLevelFilterState.level = tileLevel
          }
          break
        case FilterType.Equal:
          if (this.canvas.tileLevelFilterState.level === tileLevel) {
            this.canvas.tileLevelFilterState.filter = FilterType.GreaterEqual
          } else {
            this.canvas.tileLevelFilterState.level = tileLevel
          }
          break
        case FilterType.GreaterEqual:
          if (this.canvas.tileLevelFilterState.level === tileLevel) {
            this.canvas.tileLevelFilterState.filter = FilterType.LessEqual
          } else {
            this.canvas.tileLevelFilterState.level = tileLevel
          }
          break
        case FilterType.LessEqual:
          if (this.canvas.tileLevelFilterState.level === tileLevel) {
            this.canvas.tileLevelFilterState.filter = FilterType.All
          } else {
            this.canvas.tileLevelFilterState.level = tileLevel
          }
          break
      }
    } else {
      if (this.canvas.tileLevelFilterState.filter === FilterType.All) {
        this.canvas.tileLevelFilterState.filter = FilterType.GreaterEqual
      }
      this.canvas.tileLevelFilterState.level = tileLevel
    }
    this.updateOptionTileLevels()
    this.draw()
  }

  private toggleOptionSkipComments() {
    this.globalSettings.optionSkipComments = !this.globalSettings.optionSkipComments
    MenuBar.updateMenuItemToggle("skip-comments-menu-item", this.globalSettings.optionSkipComments)
  }

  private toggleOptionUrbanSections() {
    this.globalSettings.optionUrbanSections = !this.globalSettings.optionUrbanSections
    MenuBar.updateMenuItemToggle("urban-sections-menu-item", this.globalSettings.optionUrbanSections)
  }

  private toggleOptionUseLocalTime() {
    this.globalSettings.optionTimeFormat =
      this.globalSettings.optionTimeFormat === TimeFormat.UTCTime ? TimeFormat.LocalTime : TimeFormat.UTCTime
    MenuBar.updateMenuItemToggle(
      "use-local-time-menu-item",
      this.globalSettings.optionTimeFormat === TimeFormat.UTCTime
    )
    this.draw()
  }

  private toggleOptionAutoShowLogWindow() {
    this.globalSettings.optionAutoShowLogWindow = !this.globalSettings.optionAutoShowLogWindow
    MenuBar.updateMenuItemToggle("auto-show-log-window-menu-item", this.globalSettings.optionAutoShowLogWindow)
    if (this.globalSettings.optionAutoShowLogWindow) {
      this.logWindow.setVisible(true)
    }
  }

  private updateOptionTileLevels() {
    const filterStates = this.canvas.tileLevelFilterState
    const cellFilterAll = getDefinedHtmlElementById("filter-all")
    cellFilterAll.classList.remove("off", "on")

    const cellFilterEq = getDefinedHtmlElementById("filter-eq")
    cellFilterEq.classList.remove("off", "on")

    const cellFilterLe = getDefinedHtmlElementById("filter-le")
    cellFilterLe.classList.remove("off", "on")

    const cellFilterGe = getDefinedHtmlElementById("filter-ge")
    cellFilterGe.classList.remove("off", "on")

    for (let i = 8; i <= 17; i++) {
      const cell = getDefinedHtmlElementById(`filter-level-${i}`)
      cell.classList.remove("off", "on")
    }
    if (filterStates.filter !== FilterType.All) {
      const cellLevel = getDefinedHtmlElementById(`filter-level-${filterStates.level}`)
      cellLevel.classList.remove("off", "on")
      cellLevel.classList.add("on")
    }

    let cell: HTMLElement = cellFilterAll
    switch (filterStates.filter) {
      case FilterType.LessEqual:
        cell = cellFilterLe
        break
      case FilterType.Equal:
        cell = cellFilterEq
        break

      case FilterType.GreaterEqual:
        cell = cellFilterGe
        break
    }
    cell.classList.add("on")
  }

  private updateOptionsStatus(
    id: string,
    value: boolean,
    changeOnOffText: boolean = true,
    textOn?: string,
    textOff?: string
  ) {
    // Some options have a menu equivalent, some don't.
    const menuItem = `${id}-menu-item`
    if (document.getElementById(menuItem)) {
      MenuBar.updateMenuItemToggle(`${id}-menu-item`, value)
    }

    const cell = getDefinedHtmlElementById(`${id}-status`)
    if (changeOnOffText) {
      cell.innerText = value ? textOn ?? "ON" : textOff ?? "OFF"
    }
    cell.classList.remove("off", "on")
    cell.classList.add(value ? "on" : "off")
  }

  private formatLayerCount(count: number) {
    return count <= 0 ? "-" : formatNumberAsPowers(count, 2, 7)
  }

  private formatLayerSize(sizeInBytes: number) {
    return sizeInBytes <= 0 ? "-" : formatSizeInBytes(sizeInBytes, 1, 1)
  }

  private createLayersList() {
    const layersList = getDefinedHtmlElementById("layers-list-content")
    layersList.innerHTML = ""

    const buttons = document.createElement("div")
    buttons.style.display = "flex"
    buttons.style.justifyContent = "space-between"
    buttons.style.gap = "10px"

    // Create buttons
    let button = document.createElement("span")
    button.id = "auto-zoom-status"
    button.textContent = ""
    button.className = "panel-status-button wider"
    buttons.appendChild(button)

    button = document.createElement("span")
    button.id = "toggle-size-status"
    button.textContent = ""
    button.className = "panel-status-button wider"
    buttons.appendChild(button)

    button = document.createElement("span")
    button.id = "toggle-all-status"
    button.textContent = "Toggle all"
    button.className = "panel-status-button wider"
    buttons.appendChild(button)

    layersList.appendChild(buttons)

    const table = document.createElement("table")
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType

      let row = document.createElement("tr")
      let column = document.createElement("td")

      // Create section header.
      if (isRealLayer(layerType)) {
        // Create layer row.
        const layerState = this.canvas.layerStates[layerType]
        const parser = this.canvas.lineParsers[layerType] ?? this.canvas.fileParsers[layerType]!

        row = document.createElement("tr")
        column = document.createElement("td")

        column.id = `${layerType}-color`
        column.className = "panel-color-swatch"
        if (!parser.color) {
          column.innerText = "-"
        }
        // Properties for backgroundColor and border will be added later.
        row.appendChild(column)

        column = document.createElement("td")
        column.id = `${layerType}-text`
        column.className = "panel-text"
        column.textContent = parser.name
        row.appendChild(column)

        column = document.createElement("td")
        column.id = `${layerType}-size`
        column.className = "panel-text-size"
        column.textContent = this.formatLayerSize(0)
        row.appendChild(column)

        column = document.createElement("td")
        column.id = `${layerType}-status`
        column.className = "panel-status-button"
        // Properties for innerText and on/off will be added later.

        column.innerText = layerState.show ? "ON" : "OFF"
        column.classList.remove("off", "on")
        column.classList.add(layerState.show ? "on" : "off")
      } else {
        column.className = "panel-color-swatch"
        column.innerText = ""
        column.classList.add("none")
        row.appendChild(column)

        column = document.createElement("td")
        column.className = "panel-text"
        column.textContent = `${layerSectionName(layerType)}:`
        column.classList.add(layerType === LayerType._Total ? "total" : "subtotal")
        row.appendChild(column)

        column = document.createElement("td")
        column.textContent = ""
        column.id = `${layerSectionName(layerType)}-total-size`
        column.className = "panel-text-size"
        column.classList.add(layerType === LayerType._Total ? "total" : "subtotal")
        row.appendChild(column)

        column = document.createElement("td")
        column.className = "panel-status-button"

        column.innerText = ""
        column.classList.add("none")
      }
      row.appendChild(column)
      table.appendChild(row)
    }
    layersList.appendChild(table)
  }

  private updateLayersList() {
    let sectionLayerSizes = {} as LayerSizes
    let sectionTotalCount = 0
    let sectionApiCount = 0
    let sectionSizeInBytes = 0
    let currentSection: LayerType | undefined = undefined

    // Accumulate total size in bytes for each layer type in active files.
    const totalLayerSizes = {} as LayerSizes
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      totalLayerSizes[layerType] = {totalCount: 0, apiCount: 0, sizeInBytes: 0}
    }
    Object.entries(this.dataStore.dataFileContentPerFile)
      .filter(([id, _]) => this.canvas.dataFileStates[id].show)
      .forEach(([_, dataFileContent]) => {
        Object.entries(dataFileContent.layerSizes).forEach(([layerType, layerSize]) => {
          totalLayerSizes[layerType as LayerType].totalCount += layerSize.totalCount
          totalLayerSizes[layerType as LayerType].apiCount += layerSize.apiCount
          totalLayerSizes[layerType as LayerType].sizeInBytes += layerSize.sizeInBytes
        })
      })

    let allSectionsTotalCount = 0
    let allSectionsApiCount = 0
    let allSectionsSizeInBytes = 0
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      if (isRealLayer(layerType)) {
        // Update layer row.
        const layerStates = this.canvas.layerStates[layerType]
        MenuBar.updateMenuItemToggle(`${layerType}-menu-item`, layerStates.show)
        const parser = this.canvas.lineParsers[layerType] ?? this.canvas.fileParsers[layerType]!
        const columnColor = getDefinedHtmlElementById(`${layerType}-color`)
        if (isParsedPointColor(parser.color)) {
          const color = parser.color as ParsedPointColor
          columnColor.style.backgroundColor = color["circle-color"]
          columnColor.style.border = `1px solid ${color["text-color"]}`
        } else if (isParsedPolygonColor(parser.color)) {
          const color = parser.color as ParsedPolygonColor
          columnColor.style.backgroundColor = color["fill-color"]
          columnColor.style.border = `1px solid ${color["fill-outline-color"]}`
        }

        const totalCount = totalLayerSizes[layerType].totalCount
        sectionTotalCount += totalCount
        allSectionsTotalCount += totalCount

        const apiCount = totalLayerSizes[layerType].apiCount
        if (!layerTypeSupportsItemCountOnly.includes(layerType)) {
          sectionApiCount += apiCount
          allSectionsApiCount += apiCount
        }

        const sizeInBytesWithOverhead = totalLayerSizes[layerType].sizeInBytes
          ? totalLayerSizes[layerType].sizeInBytes +
            totalLayerSizes[layerType].totalCount * getDefaultHttpOverheadSizeInBytes()
          : 0
        sectionSizeInBytes += sizeInBytesWithOverhead
        allSectionsSizeInBytes += sizeInBytesWithOverhead

        const columnSize = getDefinedHtmlElementById(`${layerType}-size`)
        columnSize.innerText =
          layerTypeSupportsItemCountOnly.includes(layerType) || !this.showLayerSizeInBytes
            ? this.formatLayerCount(apiCount)
            : this.formatLayerSize(sizeInBytesWithOverhead)

        const columnStatus = getDefinedHtmlElementById(`${layerType}-status`)
        columnStatus.innerText = layerStates.show ? "ON" : "OFF"
        columnStatus.classList.remove("off", "on")
        columnStatus.classList.add(layerStates.show ? "on" : "off")
      } else {
        if (currentSection) {
          sectionLayerSizes[currentSection] = {
            totalCount: sectionTotalCount,
            apiCount: sectionApiCount,
            sizeInBytes: sectionSizeInBytes
          }
        }
        currentSection = layerType

        // Reset section counters.
        sectionTotalCount = 0
        sectionApiCount = 0
        sectionSizeInBytes = 0
      }
    }
    sectionLayerSizes[LayerType._Other] = {totalCount: 0, apiCount: 0, sizeInBytes: 0}
    sectionLayerSizes[LayerType._Total] = {
      totalCount: allSectionsTotalCount,
      apiCount: allSectionsApiCount,
      sizeInBytes: allSectionsSizeInBytes
    }

    // Update layer sizes.
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      if (!isRealLayer(layerType)) {
        // Update section header.
        const totalCount = sectionLayerSizes[layerType].totalCount
        const apiCount = sectionLayerSizes[layerType].apiCount
        const sizeInBytes = sectionLayerSizes[layerType].sizeInBytes
        getDefinedHtmlElementById(`${layerSectionName(layerType)}-total-size`).innerText =
          layerTypeSupportsItemCountOnly.includes(layerType)
            ? "items:"
            : this.showLayerSizeInBytes
              ? sizeInBytes
                ? formatSizeInBytes(sizeInBytes, 1, 1)
                : layerType === LayerType._Total
                  ? "-"
                  : "size:"
              : apiCount
                ? `${formatNumberAsPowers(apiCount, 2, 7)}`
                : layerType === LayerType._Total
                  ? "-"
                  : "calls:"
      }
    }
  }

  private createDataFilesList() {
    const dataFilesListHeader = getDefinedHtmlElementById("data-files-list-header")
    const title = document.createElement("tr")
    const sizeInBytes = this.totalSizeInBytes()
    title.textContent = `Data files${sizeInBytes > 999 ? " (uses " + formatSizeInBytes(sizeInBytes) + " of memory)" : ""}:`
    title.style.lineHeight = "20px"
    title.style.fontWeight = "bold"
    dataFilesListHeader.innerHTML = ""
    dataFilesListHeader.appendChild(title)

    const dataFilesListContent = getDefinedHtmlElementById("data-files-list-content")
    dataFilesListContent.innerHTML = ""

    const dataFileInfoList: DataFileInfo[] = this.dataStore.getDataFileInfoList()
    const table = document.createElement("table")

    dataFileInfoList.forEach((dataFileInfo) => {
      const row = document.createElement("tr")
      const columnStatus = document.createElement("td")
      columnStatus.className = "panel-status-button"
      columnStatus.id = `${dataFileInfo.id}-status`
      columnStatus.onclick = () => this.toggleDataFile(dataFileInfo)
      row.appendChild(columnStatus)
      table.appendChild(row)

      const columnName = document.createElement("td")
      columnName.className = "panel-text longer"
      columnName.textContent = dataFileInfo.name
      columnName.onclick = () => this.toggleDataFile(dataFileInfo)
      row.appendChild(columnName)
    })

    if (dataFileInfoList.length > 0) {
      const row = document.createElement("tr")
      const empty = document.createElement("td")
      empty.innerText = ""
      row.appendChild(empty)

      const overhead = document.createElement("td")
      overhead.id = "layers-overhead"
      overhead.textContent = `HTTP overhead: ${formatSizeInBytes(getDefaultHttpOverheadSizeInBytes(), 1)}/request`
      overhead.style.lineHeight = "20px"
      overhead.style.fontWeight = "regular"
      row.appendChild(overhead)
      table.appendChild(row)
    }
    dataFilesListContent.appendChild(table)
    dataFileInfoList.forEach((layerInfo) =>
      this.updateOptionsStatus(layerInfo.id, this.canvas.dataFileStates[layerInfo.id].show)
    )
  }

  private toggleAutoZoom() {
    this.globalSettings.optionAutoZoom = !this.globalSettings.optionAutoZoom
    this.updateOptionsStatus(
      "auto-zoom",
      this.globalSettings.optionAutoZoom,
      true,
      this.textAutoZoomOn,
      this.textAutoZoomOff
    )
    if (this.globalSettings.optionAutoZoom) {
      this.canvas.zoomToBounds()
    }
  }

  private toggleShowLayerSizeInBytes() {
    this.showLayerSizeInBytes = !this.showLayerSizeInBytes
    this.updateOptionsStatus(
      "toggle-size",
      this.showLayerSizeInBytes,
      true,
      this.showLayerSizeInBytesOn,
      this.showLayerSizeInBytesOff
    )
    this.updateLayersList()
    this.draw()
  }

  private handleKeyDigit(key: KeyboardEvent) {
    if (key.key < "0" || key.key > "9") {
      return false
    }
    if (key.key >= "0" && key.key <= "7") {
      this.toggleOptionTileLevel(parseInt(key.key) + 10)
    } else if (key.key >= "8" && key.key <= "9") {
      this.toggleOptionTileLevel(parseInt(key.key))
    }
    return true
  }

  private setupKeyboardListeners(menuDefinitions: MenuItem[]) {
    document.body.addEventListener("keydown", (keyboardEvent) => {
      // Avoid listening to keyboard shortcuts when typing in an input field, for example for the API key.
      if (!this.keyboardShortcutsEnabled) {
        return
      }
      if (keyboardEvent.altKey || keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
        return
      }
      // Digits 0-9 are handled separately.
      if (!this.handleKeyDigit(keyboardEvent)) {
        const menuItem = menuDefinitions.find(
          (item) =>
            ("key" in item && keyboardEvent.key === `${item.key}`) ||
            ("shortcut" in item && keyboardEvent.code === `Key${item.shortcut}`)
        )
        if (menuItem && "action" in menuItem) {
          menuItem.action()
        }
      }
    })
  }

  private setupOtherListeners() {
    getDefinedHtmlElementById("auto-zoom-status").addEventListener("click", (_) => {
      this.toggleAutoZoom()
    })
    getDefinedHtmlElementById("toggle-size-status").addEventListener("click", (_) => {
      this.toggleShowLayerSizeInBytes()
    })
    getDefinedHtmlElementById("toggle-all-status").addEventListener("click", () => {
      this.toggleAllLayers()
    })
    ;["filter-all", "filter-eq", "filter-le", "filter-ge"].forEach((id) => {
      getDefinedHtmlElementById(id).addEventListener("click", () => {
        switch (id) {
          case "filter-all":
            this.canvas.tileLevelFilterState.filter = FilterType.All
            break
          case "filter-eq":
            this.canvas.tileLevelFilterState.filter = FilterType.Equal
            break
          case "filter-le":
            this.canvas.tileLevelFilterState.filter = FilterType.LessEqual
            break
          case "filter-ge":
            this.canvas.tileLevelFilterState.filter = FilterType.GreaterEqual
            break
        }
        this.updateOptionTileLevels()
        this.draw()
      })
    })
    for (let i = 8; i <= 17; i++) {
      getDefinedHtmlElementById(`filter-level-${i}`).addEventListener("click", () => {
        this.toggleOptionTileLevel(i, false)
      })
    }

    const closeButtons = document.querySelectorAll(".close-button")
    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        const windowElement = closeButton.closest(".window") as HTMLElement
        windowElement.style.display = "none"
      })
    })
  }

  private totalSizeInBytes() {
    return this.dataStore.totalSizeInBytes()
  }

  /**
   * Remove local storage, incl. API key. Needs to be a lambda, not a regular function, to avoid
   * binding issues for 'this'.
   */
  private readonly clearLocalStorage = () => {
    localStorage.removeItem(App.KEY_API_KEY_TOMTOM)
    localStorage.removeItem(this.KEY_PREVIOUS_APPLICATION_VERSION)
    localStorage.removeItem(KEY_HTTP_OVERHEAD_SIZE_IN_BYTES)
    window.location.reload()
  }

  private enterDefaultHttpOverheadSize() {
    const submitDialog = () => {
      const input = getDefinedHtmlElementById("data-input-value") as HTMLInputElement
      const number = parseInt(input.value)
      if (number !== undefined && !isNaN(number) && number >= 0 && number <= 16 * 1024) {
        setDefaultHttpOverheadSizeInBytes(number)
        document.body.removeEventListener("keydown", handleKeydown)
        dialog.style.display = "none"
        this.keyboardShortcutsEnabled = true
        this.timeline.recalculateSizesAndDraw()
        this.updateLayersList()
        this.createDataFilesList()
      } else {
        alert("Please enter a valid number between 0-16384.")
      }
    }

    const cancelDialog = () => {
      document.body.removeEventListener("keydown", handleKeydown)
      const dialog = getDefinedHtmlElementById("data-input")
      dialog.style.display = "none"
      this.keyboardShortcutsEnabled = true
    }

    const handleKeydown = (keyboardEvent: KeyboardEvent) => {
      if (keyboardEvent.key === "Enter") {
        submitDialog()
      } else if (keyboardEvent.key === "Escape") {
        cancelDialog()
      }
    }

    this.keyboardShortcutsEnabled = false
    const dialog = getDefinedHtmlElementById("data-input")
    dialog.style.display = "block"
    const value = getDefinedHtmlElementById("data-input-value") as HTMLInputElement
    value.value = getDefaultHttpOverheadSizeInBytes().toString()
    value.focus()
    value.select()
    document.body.addEventListener("keydown", handleKeydown)
    getDefinedHtmlElementById("data-input-submit").addEventListener("click", () => submitDialog())
    getDefinedHtmlElementById("data-input-cancel").addEventListener("click", () => cancelDialog())
  }

  private yesNoDialog(htmlText: string, onYes: () => void, onNo: () => void) {
    this.keyboardShortcutsEnabled = false
    const text = getDefinedHtmlElementById("yes-no-dialog-text")
    text.innerHTML = htmlText
    const dialog = getDefinedHtmlElementById("yes-no-dialog")
    dialog.style.display = "block"
    getDefinedHtmlElementById("yes-no-dialog-yes").addEventListener("click", () => {
      this.keyboardShortcutsEnabled = true
      dialog.style.display = "none"
      onYes()
    })
    getDefinedHtmlElementById("yes-no-dialog-no").addEventListener("click", () => {
      this.keyboardShortcutsEnabled = true
      dialog.style.display = "none"
      onNo()
    })
  }

  private createGpxFromRoutingResponseFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json, .txt"
    input.addEventListener("change", (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.tools.routeCreatorGpx.createGpxFromRoutingResponse(file.name, content)
        }
        reader.readAsText(file)
      }
    })
    input.click()
  }

  private createRoutesFromJsonFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json"
    input.addEventListener("change", (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.tools.routeCreatorJson.createRoutesFromJson(file.name, content)
        }
        reader.readAsText(file)
      }
    })
    input.click()
  }

  private clearAllToolDataExceptDistanceCalculator() {
    this.timeline.resetTimelineTimeRange()
    for (let toolKey in this.tools) {
      const key = toolKey as keyof Tools
      if (key !== "distanceCalculator") {
        this.tools[key].clearToolData()
      }
    }
    this.tools["distanceCalculator"].prepareNextMeasurement()
    this.helpWindow.setVisible(false)
    this.releaseNotesWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
  }

  private clearMap() {
    this.resetHTMLElementPositions()
    this.canvas.removeAll()
    this.tools["distanceCalculator"].clearToolData()
    this.toolbox.selectInitialTool()
    this.clearAllToolDataExceptDistanceCalculator()
    this.createDataFilesList()
    this.updateLayersList()
    this.logWindow.setVisible(false)
    this.logWindow.clear()
    this.draw()
  }

  private undo() {
    this.logWindow.info("Undo last action")
    this.currentTool.undo && this.currentTool.undo()
    this.draw()
  }

  private draw() {
    const timeRange = this.timeline.getEffectiveEventsTimeRange()
    const features = this.canvas.getActiveFeaturesFromDataStore()
    this.canvas.updateMapDataOverlayFeatures(features)
    this.timeline.replaceAllEvents(this.canvas.getEventsFromFeatures(features))
    this.timeline.setEventsTimeRangeIfChanged(timeRange, true)
    this.inspectorWindow.draw()
  }

  /**
   * Exception handler. Important: this needs to be a lambda and not a regular function to avoid
   * binding issues.
   * @param message Message.
   * @param source Source.
   * @param line Line.
   * @param column Column.
   * @param error Error.
   */
  private readonly handleException = (
    message: string | Event,
    source: string | undefined,
    line: number | undefined,
    column: number | undefined,
    error: Error | undefined
  ): boolean => {
    console.error(
      `Exception: ${JSON.stringify(message)} in: ${source}, line ${line}, column ${column}\nerror: ${JSON.stringify(error)}`
    )
    if (error instanceof InvalidApiKey) {
      this.clearLocalStorage()
      return true
    }
    // Return a boolean that indicates whether or not the browser should still handle this exception.
    return false
  }

  private setupViewMenu(): MenuItem[] {
    const viewMenuItems: GenericMenuItem[] = [
      {
        key: "Backspace",
        shortcut: "Backspace",
        name: "Undo last point",
        action: () => this.undo()
      },
      {
        key: "Escape",
        shortcut: "Esc",
        name: "Reset action",
        action: () => this.clearAllToolDataExceptDistanceCalculator()
      },
      {
        shortcut: "C",
        name: "Clear map",
        action: () => this.clearMap()
      },
      {
        key: "",
        shortcut: "+",
        name: "Zoom in (use Shift+ for more)",
        action: () => this.mapView.zoomIn()
      },
      {
        key: "",
        shortcut: "-",
        name: "Zoom out (use Shift- for more)",
        action: () => this.mapView.zoomOut()
      }
    ]

    const toggleMenuItems: GenericMenuItem[] = [
      {
        shortcut: "Z",
        name: "Auto-zoom to extents of data",
        id: "auto-zoom",
        action: () => this.toggleAutoZoom()
      },
      {
        key: "\\",
        shortcut: "\\",
        name: "Toggle all layers on/off",
        id: "toggle-all-layers",
        action: () => this.toggleAllLayers()
      },
      {
        name: "Show MapVis tile grid",
        id: "tile-grid",
        action: () => this.toggleShowTileGrid()
      },
      {
        name: "Show urban sections in routes",
        id: "urban-sections",
        action: () => this.toggleOptionUrbanSections()
      },
      {
        name: "Use UTC (instead of local time)",
        id: "use-local-time",
        action: () => this.toggleOptionUseLocalTime()
      }
    ]

    const windowMenuItems: GenericMenuItem[] = [
      {
        key: "KeyM",
        shortcut: "M",
        name: "Show inspector window...",
        id: this.htmlElementIdInspectorWindow,
        action: () => this.inspectorWindow.setVisible(!this.inspectorWindow.isVisible())
      },
      {
        key: "KeyO",
        shortcut: "O",
        name: "Show logging window...",
        id: this.htmlElementIdLogWindow,
        action: () => this.logWindow.setVisible(!this.logWindow.isVisible())
      }
    ]
    return [viewMenuItems, separatorMenuItem, toggleMenuItems, separatorMenuItem, windowMenuItems].flat()
  }

  private setupLayersMenu(): LayerMenuItem[] {
    // Note: the IDs of these menu items must be the keys of LayerType.
    const layersMenuItems: LayerMenuItem[] = [
      {
        id: "MapVis3D",
        name: "MapVis: 3D",
        shortcut: "D",
        action: () => this.toggleLayer(LayerType.MapVis3D)
      },
      {
        id: "MapVisBasicMap",
        name: "MapVis: basic map",
        shortcut: "B",
        action: () => this.toggleLayer(LayerType.MapVisBasicMap)
      },
      {
        id: "MapVisFlow",
        name: "MapVis: flow",
        shortcut: "F",
        textColor: "black",
        action: () => this.toggleLayer(LayerType.MapVisFlow)
      },
      {
        id: "MapVisHillshade",
        name: "MapVis: hillshade",
        shortcut: "H",
        action: () => this.toggleLayer(LayerType.MapVisHillshade)
      },
      {
        id: "MapVisIncidents",
        name: "MapVis: incidents",
        shortcut: "I",
        action: () => this.toggleLayer(LayerType.MapVisIncidents)
      },
      {
        id: "MapVisSatellite",
        name: "MapVis: satellite",
        shortcut: "L",
        action: () => this.toggleLayer(LayerType.MapVisSatellite)
      },
      {
        id: "MapVisStyle",
        name: "MapVis: style",
        shortcut: "Y",
        action: () => this.toggleLayer(LayerType.MapVisStyle)
      },
      {
        id: "NdsLive",
        name: "NDS.Live",
        shortcut: "N",
        action: () => this.toggleLayer(LayerType.NdsLive)
      },
      {
        id: "Nk2NavTiles",
        name: "NK2 NavTiles",
        shortcut: "T",
        action: () => this.toggleLayer(LayerType.Nk2NavTiles)
      },
      {
        id: "Nk2LaneTiles",
        name: "NK2 LaneTiles",
        textColor: "black",
        action: () => this.toggleLayer(LayerType.Nk2LaneTiles)
      },
      {
        id: "ApiAutoComplete",
        name: "API: Auto-complete",
        shortcut: "A",
        action: () => this.toggleLayer(LayerType.ApiAutoComplete)
      },
      {
        id: "ApiRevGeocode",
        name: "API: Rev.geocode",
        shortcut: "V",
        textColor: "black",
        action: () => this.toggleLayer(LayerType.ApiRevGeocode)
      },
      {
        id: "ApiRouting",
        name: "API: Routing",
        shortcut: "R",
        action: () => this.toggleLayer(LayerType.ApiRouting)
      },
      {
        id: "ApiSearch",
        name: "API: Search",
        shortcut: "S",
        textColor: "black",
        action: () => this.toggleLayer(LayerType.ApiSearch)
      },
      {
        id: "ApiTpeg",
        name: "API: TPEG",
        textColor: "black",
        action: () => this.toggleLayer(LayerType.ApiTpeg)
      },
      {
        id: "LogLines",
        name: "Log lines",
        shortcut: "O",
        action: () => this.toggleLayer(LayerType.LogLines)
      },
      {
        id: "JSON",
        name: "JSON",
        shortcut: "J",
        action: () => this.toggleLayer(LayerType.JSON)
      },
      {
        id: "GeoJSON",
        name: "GeoJSON",
        action: () => this.toggleLayer(LayerType.GeoJSON)
      },
      {
        id: "GPX",
        name: "GPX",
        shortcut: "X",
        action: () => this.toggleLayer(LayerType.GPX)
      },
      {
        id: "TTP1s",
        name: "TTP: 1s data",
        action: () => this.toggleLayer(LayerType.TTP1s)
      },
      {
        id: "TTP100ms",
        name: "TTP: 100ms data",
        action: () => this.toggleLayer(LayerType.TTP100ms)
      },
      {
        id: "TTPLocation",
        name: "TTP: GPS/GNSS",
        shortcut: "G",
        textColor: "black",
        action: () => this.toggleLayer(LayerType.TTPLocation)
      }
    ]
    for (const layerType in LayerType) {
      assert(
        !isRealLayer(layerType as LayerType) || layersMenuItems.find((item) => item.id === layerType),
        `Layer type ${layerType} not found in layersMenuItems list`
      )
    }
    layersMenuItems.forEach((menuItem) => {
      ;[`${menuItem.id}-color`, `${menuItem.id}-text`, `${menuItem.id}-status`].forEach((id) => {
        getDefinedHtmlElementById(id).addEventListener("click", () => {
          menuItem.action?.()
        })
      })
    })
    return layersMenuItems
  }

  private setupOthersMenu(): MenuItem[] {
    const othersMenuItems: MenuItem[] = [
      {
        name: "What's new...",
        id: this.htmlElementIdReleaseNotesWindow,
        action: () => this.releaseNotesWindow.setVisible(!this.releaseNotesWindow.isVisible())
      },
      {
        name: "Show help...",
        id: this.htmlElementIdHelpWindow,
        action: () => this.toggleHelpWindow()
      },
      separatorMenuItem,
      {
        name: "Skip '#' comment lines while importing",
        id: "skip-comments",
        action: () => this.toggleOptionSkipComments()
      },
      {
        shortcut: "E",
        name: "Show errors/unprocessed lines during import",
        id: "auto-show-log-window",
        action: () => this.toggleOptionAutoShowLogWindow()
      },
      separatorMenuItem,
      {
        name: "Enter default HTTP overhead size...",
        action: () => this.enterDefaultHttpOverheadSize()
      },
      {
        name: "Clear local storage (incl. API key)...",
        action: () =>
          this.yesNoDialog(
            "<strong>Clear local storage</strong><br/><br/>This will reload the page, ask for a new API key and reset the default overhead used for calculating the size of HTTP calls...<br/><br/>Are you sure?",
            this.clearLocalStorage,
            () => {}
          )
      },
      {
        name: "Convert routing API response from JSON to GPX...",
        action: () => this.createGpxFromRoutingResponseFile()
      },
      {
        name: "Create routes from JSON file...",
        action: () => this.createRoutesFromJsonFile()
      }
    ]
    return othersMenuItems
  }
}

export default App
