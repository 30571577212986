/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"
import {formatDateWithTime, formatTimeAsDuration} from "../common/datetime"
import {getDefaultHttpOverheadSizeInBytes, getHttpCodeName} from "../common/httpCodes"
import {capitalizeFirstLetter, formatSizeInBytes, redactedString} from "../common/objects"
import {BoundingBox} from "../common/geo"
import {getDefinedHtmlElementById} from "../common/html"
import {GlobalSettings} from "./globalSettings"

/**
 * This class defines the inspector window that displays detailed information about objects.
 * It tries to interpret the object and format it in a human readable way. If interpretation
 * fails, a simple JSON notation of the object is used.
 */
export class InspectorWindow extends HtmlWindow {
  private readonly globalSettings: GlobalSettings
  private readonly htmlElementIdContent: string
  private readonly htmlElementIdButtons: string

  private currentTitle: string = ""
  private objects: any[] = []
  private getMetadata?: (object: any) => any
  private displaySelected?: (object: any) => void
  private currentIndex = 0
  private currentMetadata: any

  constructor(
    htmlElementIdWindow: string,
    htmlElementIdContent: string,
    htmlElementIdButtons: string,
    globalSettings: GlobalSettings
  ) {
    super(htmlElementIdWindow)
    this.globalSettings = globalSettings
    this.htmlElementIdContent = htmlElementIdContent
    this.htmlElementIdButtons = htmlElementIdButtons

    const buttons = document.createElement("span")
    buttons.id = "inspector-buttons"
    buttons.className = "inspector-buttons"
    buttons.innerHTML =
      '<span class="inspector-button" id="inspector-previous" style="margin-left: 5px; margin-right: 5px"><--</span>' +
      '<span class="inspector-button" id="inspector-next">--></span>'
    const content = getDefinedHtmlElementById(htmlElementIdButtons)
    content.appendChild(buttons)

    const previous = getDefinedHtmlElementById("inspector-previous")
    previous.addEventListener("click", () => {
      this.select((this.currentIndex - 1 + this.objects.length) % this.objects.length)
    })
    const next = getDefinedHtmlElementById("inspector-next")
    next.addEventListener("click", () => {
      this.select((this.currentIndex + 1) % this.objects.length)
    })
  }

  show(title: string, objects: any[], getMetadata?: (object: any) => any, displaySelected?: (object: any) => void) {
    this.currentTitle = title
    this.objects = objects
    this.getMetadata = getMetadata
    this.displaySelected?.(undefined)
    this.displaySelected = displaySelected
    this.select(0)
    this.setVisible(true)
  }

  draw() {
    const content = getDefinedHtmlElementById(this.htmlElementIdContent)
    content.innerHTML = ""

    // Add previous and next buttons if there is more than one object.
    const buttons = getDefinedHtmlElementById("inspector-buttons")
    if (this.objects.length >= 2) {
      buttons.classList.remove("hide")
      buttons.classList.add("show")
    } else {
      buttons.classList.remove("show")
      buttons.classList.add("hide")
      // buttons.classList.remove("show")
    }

    if (this.objects.length > 0) {
      const element = document.createElement("span")
      const selectedHtml =
        this.objects.length > 1 ? `<strong>Showing: ${this.currentIndex + 1} of ${this.objects.length}</strong>\n` : ""
      const titleHtml = `${this.currentTitle.length > 0 ? "<h2>" + this.currentTitle + "</h2>\n" : "\n"}${selectedHtml}`
      const metadataHtml = `${this.currentMetadata ? this.formatMetadata(this.currentMetadata) + "\n" : ""}`
      const objectHtml = `<span class="inspector-json-object">${this.formatObject(this.objects[this.currentIndex])}</span>`
      element.innerHTML = `${titleHtml}${metadataHtml}${objectHtml}`
      content.appendChild(element)
    }
  }

  private select(index: number) {
    this.currentIndex = index
    this.currentMetadata = this.objects.length > 0 ? this.getMetadata?.(this.objects[this.currentIndex]) : undefined
    this.draw()
    this.displaySelected?.(this.objects.length > 0 ? this.objects[this.currentIndex] : undefined)
    window.scrollTo({top: 0})
  }

  private jsonAsRedactedString(object: any) {
    return object
      ? JSON.stringify(object, undefined, 2)
          .split("\n")
          .map((line) => redactedString(line))
          .join("\n")
      : "undefined"
  }

  private formatObject(object: any): string {
    return `${this.jsonAsRedactedString(object)}`
  }

  private formatMetadata(metadata: any): string {
    const table = (value: string) => `<table style="border-collapse:collapse" >${value}</table>`
    const row = (type: number, col1: any, col2: any) =>
      `<tr><td class="inspector-table-header-${type}">${col1?.toString()}:</td><td class="inspector-table-value-${type}">${col2?.toString()}</td></tr>`
    const duration = (value: number) => `${formatTimeAsDuration(value)} (${value})`
    const remove = (metadata: any, field: string) => {
      delete metadata?.[field]
      return metadata
    }

    // Use a temporary record to remove fields to not duplicate that are represented in a table.
    let remaining
    if (metadata.message) {
      remaining = structuredClone(metadata.message)
    } else {
      remaining = structuredClone(metadata)
    }

    let rows = ""
    if (remaining.duplicates) {
      rows += row(1, "Duplicates", remaining.duplicates)
      remaining = remove(remaining, "duplicates")
    }
    if (remaining.type) {
      rows += row(1, "Type", remaining.type)
      remaining = remove(remaining, "type")
    }
    if (remaining.layer) {
      rows += row(1, "Layer", remaining.layer)
      remaining = remove(remaining, "layer")
    }
    if (remaining.httpStatusCode) {
      rows += row(
        1,
        "HTTP",
        `${remaining.httpStatusCode} (${getHttpCodeName(remaining.httpStatusCode)?.toUpperCase() ?? "(UNKNOWN)"})`
      )
      remaining = remove(remaining, "httpStatusCode")
    }
    if (remaining.sizeInBytes !== undefined) {
      rows += row(
        1,
        "Size",
        remaining.sizeInBytes === -1
          ? "(missing)"
          : `${formatSizeInBytes(remaining.sizeInBytes)}<small> (+${formatSizeInBytes(getDefaultHttpOverheadSizeInBytes())})</small>`
      )
      remaining = remove(remaining, "sizeInBytes")
    }
    if (remaining.usesCdn !== undefined) {
      rows += row(1, "Uses CDN", "true")
      remaining = remove(remaining, "usesCdn")
    }

    rows += row(
      1,
      "Time",
      remaining.time
        ? `${formatDateWithTime(new Date(remaining.time), this.globalSettings.optionTimeFormat)}`
        : "missing"
    )
    remaining = remove(remaining, "time")

    if (remaining.monotonicTime) {
      rows += row(1, "Monotonic", duration(remaining.monotonicTime))
      remaining = remove(remaining, "monotonicTime")
    }
    if (remaining.tileLevel !== undefined) {
      rows += row(1, "Level", remaining.tileLevel)
      remaining = remove(remaining, "tileLevel")
    }
    if (remaining.tileX !== undefined) {
      rows += row(1, "X", remaining.tileX)
      remaining = remove(remaining, "tileX")
    }
    if (remaining.tileY !== undefined) {
      rows += row(1, "Y", remaining.tileY)
      remaining = remove(remaining, "tileY")
    }
    if (remaining.tileId) {
      rows += row(1, "ID", remaining.tileId)
      remaining = remove(remaining, "tileId")
    }
    if (remaining.bounds) {
      const box = remaining.bounds as BoundingBox
      rows += row(
        1,
        "Bounds",
        `<small>(${box.southWest.lng.toFixed(4)}, ${box.southWest.lat.toFixed(4)}), (${box.northEast.lng.toFixed(4)}, ${box.northEast.lat.toFixed(4)})</small>`
      )
      remaining = remove(remaining, "bounds")
    }
    if (remaining.geoHash) {
      rows += row(1, "Geo hash", `<small>${remaining.geoHash}</small>`)
      remaining = remove(remaining, "geoHash")
    }
    if (remaining.lineNumber) {
      rows += row(1, "Line", remaining.lineNumber)
      remaining = remove(remaining, "lineNumber")
    }

    let line = remaining.line
    if (remaining.line) {
      remaining = remove(remaining, "line")
    }
    const tableKnownMetadata = table(rows)

    rows = ""
    if (Object.keys(remaining).length !== 0) {
      rows += row(2, "METADATA", "")
      Object.keys(remaining).forEach((key) => {
        rows += row(2, capitalizeFirstLetter(key), `${this.jsonAsRedactedString(remaining[key])}`)
      })
    }
    const tableOtherMetadata = table(rows)

    return (
      tableKnownMetadata +
      (line ? `<div class="inspector-json-object">${this.formatObject(line)}</div>` : "") +
      tableOtherMetadata
    )
  }
}

export default InspectorWindow
