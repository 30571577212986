/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * This is a utility class with static functions only.
 */
export class JsonUtils {
  /**
   * Get a value from a field. If the field does not exist and there's no default value,
   * present an error.
   *
   * @param json Object, usually read from a JSON file.
   * @param key Key of required field.
   * @param defaultValue Default value if the field does not exist.
   */
  static getJsonValue(json: any, key: string, defaultValue: any = undefined) {
    if (!json.hasOwnProperty(key)) {
      if (defaultValue === undefined) {
        throw new Error(`Field '${key}' not found in JSON object: ${JSON.stringify(json)}`)
      }
    }
    return json[key] || defaultValue
  }
}

export default JsonUtils
