/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import {addAllElements, approximateMemorySizeInBytesOfObject, truncateString} from "../common/objects"
import {BoundingBox} from "../common/geo"
import {MetadataStore} from "../common/metadata"
import {formatDateWithTime, TimeFormat} from "../common/datetime"
import {LayerType} from "../parsers/parserTypes"
import {GlobalSettings} from "./globalSettings"

export const DEFAULT_FILE_ID = "_file"
export const DEFAULT_LAYER_ID = LayerType._Total

export const TIMESTAMP_ALWAYS_SHOW = -1
export const TIMESTAMP_ALWAYS_HIDE = -2

const DEFAULT_FILE_NAME = "[ Dropped text items ]"

export enum FilterType {
  All,
  LessThan,
  LessEqual,
  Equal,
  GreaterEqual,
  GreaterThan
}

export type TileLevelFilterState = {
  level: number
  filter: FilterType
}

export type LayerState = {
  show: boolean
  supportsLevel: boolean // True if the layer supports tile levels.
}

export type LayerStates = Record<LayerType, LayerState>

export type LayerSize = {
  totalCount: number // Total number of calls.
  apiCount: number // Number of (non-CDN) API calls.
  sizeInBytes: number // Total size in bytes.
}

export type LayerSizes = Record<LayerType, LayerSize>

export type DataFileState = {
  show: boolean
}

export type DataFileStates = Record<string, DataFileState>

export type DataFileInfo = {
  id: string
  name: string
  memorySizeInBytes: number
  layerSizes: LayerSizes
}

export type LayerFeatures = Record<LayerType, Feature[]>

export type DataFileContent = Omit<DataFileInfo, "id"> & {
  layerFeatures: LayerFeatures
  bounds: BoundingBox
}

/**
 * This type describes the content of all data files, per file ID.
 */
type DataFileContentPerFile = Record<string, DataFileContent>

export class DataStore {
  private readonly metadataStore: MetadataStore
  private readonly globalSettings: GlobalSettings

  dataFileContentPerFile: DataFileContentPerFile = {}

  constructor(metadataStore: MetadataStore, globalSettings: GlobalSettings) {
    this.metadataStore = metadataStore
    this.globalSettings = globalSettings
    this.removeAll()
  }

  getDataFileInfoList(): DataFileInfo[] {
    return Object.keys(this.dataFileContentPerFile).map((id) => {
      const dataFileContent = this.dataFileContentPerFile[id]
      return {
        id: id,
        name: dataFileContent.name,
        memorySizeInBytes: dataFileContent.memorySizeInBytes,
        layerSizes: dataFileContent.layerSizes
      }
    })
  }

  addFeaturesFromDataFile(features: Feature[], sourceFile?: File): string {
    const id = this.getFileId(sourceFile)
    const fileName = this.getFileName(sourceFile)
    const layerFeatures: LayerFeatures = {} as LayerFeatures
    let bounds = BoundingBox.empty()

    const layerSizes: LayerSizes = {} as LayerSizes
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      layerFeatures[layerType] = []
      layerSizes[layerType] = {totalCount: 0, apiCount: 0, sizeInBytes: 0}
    }

    // Calculate count and size of features per layer.
    features.forEach((feature) => {
      const metadata = this.metadataStore.retrieve(feature.properties?.metadata)
      const layerType = (metadata?.layer ? metadata.layer : DEFAULT_LAYER_ID) as LayerType
      layerFeatures[layerType].push(feature)
      bounds = bounds.extendWithOther(metadata?.bounds)

      // Increase total number of calls.
      layerSizes[layerType].totalCount++

      // If the "cdn" property is true, we don't count it as an API call.
      if (!metadata?.usesCdn) {
        layerSizes[layerType].apiCount++
      }

      if (metadata?.sizeInBytes) {
        layerSizes[layerType].sizeInBytes += metadata.sizeInBytes
      }
    })

    // Create a box around the file extents.
    if (!layerFeatures[DEFAULT_LAYER_ID]) {
      layerFeatures[DEFAULT_LAYER_ID] = []
    }
    layerFeatures[DEFAULT_LAYER_ID].push(...this.createExtentsBoxWithFilename(bounds, fileName))

    this.dataFileContentPerFile[id] = {
      name: fileName,
      bounds: bounds,
      memorySizeInBytes: approximateMemorySizeInBytesOfObject(features),
      layerFeatures: layerFeatures,
      layerSizes: layerSizes
    }
    return id
  }

  getBoundsOfDataFile(file?: File): BoundingBox {
    const fileId = this.getFileId(file)
    return this.dataFileContentPerFile[fileId].bounds
  }

  getBoundsOfDataFiles(dataFileStates: DataFileStates): BoundingBox {
    let bounds = BoundingBox.empty()
    for (const id in this.dataFileContentPerFile) {
      if (dataFileStates[id].show) {
        bounds = bounds.extendWithOther(this.dataFileContentPerFile[id].bounds)
      }
    }
    return bounds
  }

  calculateBoundsFromFeatures(features: Feature[]) {
    return features
      .map((feature) => {
        if (feature.properties?.metadata) {
          const metadata = this.metadataStore.retrieve(feature.properties.metadata)
          if (metadata?.bounds) {
            return metadata.bounds
          }
        }
        return undefined
      })
      .filter((bounds) => bounds !== undefined)
      .reduce((bounds, current) => bounds.extendWithOther(current), BoundingBox.empty())
  }

  getFeaturesFromDataFiles(
    layerStates: LayerStates,
    dataFileStates: DataFileStates,
    filterStates: TileLevelFilterState
  ): Feature[] {
    const features: Feature[] = []
    for (const dataFile in this.dataFileContentPerFile) {
      if (!dataFileStates[dataFile].show) {
        continue
      }
      const featuresPerLayer = this.dataFileContentPerFile[dataFile].layerFeatures
      for (const layerType in featuresPerLayer) {
        // Copy all elements from the default layer.
        if (layerType === DEFAULT_LAYER_ID) {
          addAllElements(features, featuresPerLayer[layerType])
          continue
        }

        // Copy only applicable other layers.
        const layerState = layerStates[layerType as LayerType]
        if (layerState && !layerState.show) {
          continue
        }
        if (!layerState?.supportsLevel || filterStates.filter === FilterType.All) {
          // Super-fast block copy.
          addAllElements(features, featuresPerLayer[layerType as LayerType])
        } else {
          // Need to copy feature by feature.
          for (const feature of featuresPerLayer[layerType as LayerType]) {
            const metadata = this.metadataStore.retrieve(feature.properties?.metadata)
            if (
              metadata &&
              (metadata.tileLevel === undefined ||
                (FilterType.LessThan === filterStates.filter && metadata.tileLevel < filterStates.level) ||
                (FilterType.LessEqual === filterStates.filter && metadata.tileLevel <= filterStates.level) ||
                (FilterType.Equal === filterStates.filter && metadata.tileLevel === filterStates.level) ||
                (FilterType.GreaterEqual === filterStates.filter && metadata.tileLevel >= filterStates.level) ||
                (FilterType.GreaterThan === filterStates.filter && metadata.tileLevel > filterStates.level))
            ) {
              features.push(feature)
            }
          }
        }
      }
    }
    return features
  }

  exists(file: File): boolean {
    return this.dataFileContentPerFile[this.getFileId(file)] !== undefined
  }

  /**
   * Clears all features in the data store for a specific data file.
   */
  remove(file: File) {
    const fileId = this.getFileId(file)
    if (this.dataFileContentPerFile[fileId]) {
      Object.entries(this.dataFileContentPerFile[fileId].layerFeatures).forEach(([_, features]) => {
        features.forEach((feature) => {
          if (feature.properties?.metadata) {
            this.metadataStore.remove(feature.properties.metadata)
          }
        })
      })
      delete this.dataFileContentPerFile[fileId]
    }
  }

  /**
   * Initializes and clear the data store.
   */
  removeAll() {
    this.dataFileContentPerFile = {}
    this.metadataStore.removeAll()
  }

  /**
   * Returns the approximate size in bytes of the data store.
   */
  totalSizeInBytes(): number {
    return approximateMemorySizeInBytesOfObject(this.dataFileContentPerFile)
  }

  private createExtentsBoxWithFilename(bounds: BoundingBox, fileName: string): Feature[] {
    return [
      // Dashed box.
      {
        type: "Feature",
        properties: {
          time: TIMESTAMP_ALWAYS_SHOW,
          "line-width": 1,
          "line-color": "rgb(0,0,0,0.3)",
          "line-dasharray": [10, 5],
          generated: true
        },
        geometry: {
          type: "LineString",
          coordinates: [
            [bounds.southWest.lng, bounds.southWest.lat],
            [bounds.southWest.lng, bounds.northEast.lat],
            [bounds.northEast.lng, bounds.northEast.lat],
            [bounds.northEast.lng, bounds.southWest.lat],
            [bounds.southWest.lng, bounds.southWest.lat]
          ]
        }
      },
      // Filename.
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [bounds.southWest.lng, bounds.southWest.lat]
        },
        properties: {
          text: `\n${fileName}`,
          time: TIMESTAMP_ALWAYS_SHOW,
          generated: true,
          "text-size": 12,
          "text-anchor": "left",
          "text-color": "rgb(80,17,75)",
          "circle-radius": 0
        }
      }
    ]
  }

  private getFileId(file?: File) {
    return file ? this.createDataFileId(file) : DEFAULT_FILE_ID
  }

  private getFileName(file?: File) {
    return file ? this.createDataFileName(file) : DEFAULT_FILE_NAME
  }

  private createDataFileId(file: File): string {
    return `${file.name}_${file.lastModified}`
  }

  private createDataFileName(file: File): string {
    let name = file.name
    const fileId = this.createDataFileId(file)
    for (const id in this.dataFileContentPerFile) {
      if (this.dataFileContentPerFile[id].name === name && id !== fileId) {
        name = `${file.name} (${formatDateWithTime(new Date(file.lastModified), TimeFormat.LocalTime)})`
        break
      }
    }
    return truncateString(name, 32)
  }
}

export default DataStore
