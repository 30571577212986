/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageSensorBase} from "./ttpMessageSensorBase"
import {TtpMessageType} from "../ttpMessage"

export enum LocationIndex {
  ChannelIndex = 2,
  LongitudeIndex = 3,
  LatitudeIndex = 5,
  AccuracyIndex = 6,
  AltitudeIndex = 7,
  BearingIndex = 9,
  SpeedIndex = 11,
  TimeIndex = 17,
  QualityIndex = 18,
  LanesVisibleIndex = 20,
  LaneDrivenIndex = 21
}

export abstract class TtpMessageSensorLocationBase extends TtpMessageSensorBase {
  channel: number
  longitude: number
  latitude: number
  accuracy?: number
  altitude?: number
  bearing?: number
  speed?: number
  // Note: 'time' is already captured in base class!
  quality?: number
  lanesVisible?: number
  laneDriven?: number

  protected constructor(
    lineNumber: number,
    type: TtpMessageType,
    monotonicTime: number,
    message: number,
    channel: number,
    longitude: number,
    latitude: number,
    accuracy?: number,
    altitude?: number,
    bearing?: number,
    speed?: number,
    time?: Date,
    quality?: number,
    lanesVisible?: number,
    laneDriven?: number
  ) {
    super(lineNumber, type, monotonicTime, message)
    this.channel = channel
    this.longitude = longitude
    this.latitude = latitude
    this.accuracy = accuracy
    this.altitude = altitude
    this.bearing = bearing
    this.speed = speed
    this.time = time
    this.quality = quality
    this.lanesVisible = lanesVisible
    this.laneDriven = laneDriven
  }
}

export default TtpMessageSensorLocationBase
