/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {MapMouseEvent} from "maplibre-gl"
import MapView from "./mapView"
import DataCanvas from "./dataCanvas"
import Timeline, {TimeRange} from "./timeline"
import MenuBar from "../menu/menuBar"
import {MenuItem} from "../menu/menuTypes"
import {isRealLayer, LayerType} from "../parsers/parserTypes"
import {HelpWindow} from "../windows/helpWindow"
import ReleaseNotesWindow from "../windows/releaseNotesWindow"
import InspectorWindow from "../windows/inspectorWindow"
import ConsoleWindow from "../windows/consoleWindow"
import {Toolbox} from "../tools/toolbox"
import ProgressWindow from "../windows/progressWindow"
import {
  HTML_BUTTON_CLEAR_MAP,
  HTML_BUTTON_SAVE,
  HTML_BUTTON_SELECT_FILES,
  HTML_BUTTON_ZOOM_IN,
  HTML_BUTTON_ZOOM_OUT,
  HTML_MAP,
  HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW,
  HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE,
  HTML_MENU_ITEM_GPX_TIMESTAMPS,
  HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS,
  HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES,
  HTML_MENU_ITEM_SHOW_MOUSE_POSITION,
  HTML_MENU_ITEM_SKIP_COMMENTS,
  HTML_MENU_ITEM_TILE_GRID,
  HTML_MENU_ITEM_USE_LOCAL_TIME,
  HTML_MENU_ITEM_USE_ORBIS_ROUTING_API,
  HTML_MENUBAR,
  HTML_NUMBER_INPUT,
  HTML_NUMBER_INPUT_CANCEL,
  HTML_NUMBER_INPUT_SUBMIT,
  HTML_NUMBER_INPUT_VALUE,
  HTML_PROGRESS_BAR,
  HTML_STRING_INPUT,
  HTML_STRING_INPUT_CANCEL,
  HTML_STRING_INPUT_SUBMIT,
  HTML_STRING_INPUT_VALUE,
  HTML_TIMELINE,
  HTML_TOOLBOX,
  HTML_VERSION_MARKER,
  HTML_WINDOW_CONTENT_PROGRESS,
  HTML_WINDOW_PROGRESS,
  HTML_WINDOW_TITLE_BAR_INSPECTOR
} from "../html/htmlElementId"
import {
  HTML_CLASS_PANEL,
  HTML_CLASS_PANEL_DRAG_BAR,
  HTML_CLASS_WINDOW,
  HTML_CLASS_WINDOW_TITLE_BAR
} from "../html/htmlClassId"
import Logger from "../common/logger"
import Storage, {Settings} from "../common/storage"
import {ExceptionHandler} from "./exceptionHandler"
import {Html} from "../html/html"
import LayersPanel from "../panels/layersPanel"
import DataFilesPanel from "../panels/dataFilesPanel"
import TileLevelFiltersPanel from "../panels/tileLevelFiltersPanel"
import SourceWindow from "../windows/sourceWindow"
import {Feature} from "geojson"
import {MetadataStore} from "../global/metadataStore"
import DataStore from "../global/dataStore"
import {Mutex} from "async-mutex"
import MenuDefinitionView from "./menu/menuDefinitionView"
import MenuDefinitionOthers from "./menu/menuDefinitionOthers"
import MenuDefinitionLayers from "./menu/menuDefinitionLayers"
import MenuDefinitionMaps from "./menu/menuDefinitionMaps"
import {BugReport} from "../global/bugReport"

/**
 * This class defines the application. It sets up the entire application and supports an exception handler to catch exceptions during
 * execution.
 */
export class App {
  private readonly dataCanvas: DataCanvas // Map overlay with all imported data.
  private readonly mapView: MapView // The map itself.

  private readonly menuBar: MenuBar // Top menu bar.
  private readonly toolbox: Toolbox // Toolbox with all tool icons.
  private readonly timeline: Timeline // Timeline (bottom) with all events.

  private readonly helpWindow: HelpWindow // Window handles.
  private readonly releaseNotesWindow: ReleaseNotesWindow
  private readonly inspectorWindow: InspectorWindow
  private readonly consoleWindow: ConsoleWindow
  private readonly sourceWindow: SourceWindow
  private readonly progressWindow: ProgressWindow

  private readonly tileLevelFiltersPanel: TileLevelFiltersPanel // Panel for tile level filters.
  private readonly layersPanel: LayersPanel // Panel for toggling layers.
  private readonly dataFilesPanel: DataFilesPanel // Panel for selecting data files.

  private readonly mousePositionLoggingIntervalInMillis = 50
  private mousePositionLastLoggedTime = 0

  private keyboardShortcutsEnabled = true // Set to false when expecting keyboard input in a dialog.

  constructor() {
    console.log("Constructing app...")

    // Install the exception handler.
    window.onerror = ExceptionHandler.handleException

    // Create the map view (as soon as possible, so it has some time to load stuff).
    this.mapView = new MapView("map", this.onClick, this.onContextMenu, this.onMouseMove)

    // Create the progress window.
    this.progressWindow = new ProgressWindow(HTML_WINDOW_PROGRESS, HTML_WINDOW_CONTENT_PROGRESS, HTML_PROGRESS_BAR)

    // Create the help window. Load the contents async with "initialize()".
    this.helpWindow = new HelpWindow("Help")

    // Create the release notes window. Load the contents async with "initialize()".
    this.releaseNotesWindow = new ReleaseNotesWindow("Release notes")

    // Create the inspector window.
    this.inspectorWindow = new InspectorWindow(
      "Inspector",
      HTML_WINDOW_TITLE_BAR_INSPECTOR,
      (feature?: Feature) => MetadataStore.retrieve(feature?.properties?.metadata) ?? {},
      (feature?: Feature) => this.toolbox.tools.dataSelectorTool.draw(feature ? [feature] : []),
      (fileId: string, lineNumer: number) => {
        Logger.log.info(`Show source: ${fileId}, line ${lineNumer}`)
        const sourceLinesWithTimes = DataStore.getSourceLinesWithTime(fileId)
        this.sourceWindow.setSourceLinesWithTime(fileId, sourceLinesWithTimes, lineNumer)
        if (Storage.get(Settings.AutoShowSource)) {
          this.sourceWindow.setVisible()
        }
      }
    )

    // Create the inspector window.
    this.consoleWindow = new ConsoleWindow("Console")

    // Create the source window.
    this.sourceWindow = new SourceWindow("Source")

    // Create the timeline region.
    this.timeline = new Timeline(
      HTML_TIMELINE,
      HTML_BUTTON_ZOOM_IN,
      HTML_BUTTON_ZOOM_OUT,
      HTML_BUTTON_SAVE,
      this.inspectorWindow,
      this.setTimeRange
    )

    // Create the toolbox with all tools.
    this.toolbox = new Toolbox(
      this.mapView,
      this.inspectorWindow,
      this.sourceWindow,
      this.timeline.shrinkTimeRange,
      this.toggleHelpWindow
    )
    this.toolbox.resetCurrentTool()

    // TODO [techdebt]: The data canvas can access the map view before it is fully loaded. Race condition.
    // Create the map canvas (which uses the map view). Give it a bit of time to load...
    this.dataCanvas = new DataCanvas(this.mapView, this.progressWindow)

    // Create the panels.
    this.tileLevelFiltersPanel = new TileLevelFiltersPanel(this.dataCanvas, this.timeline, this.draw)
    this.dataFilesPanel = new DataFilesPanel(this.dataCanvas, this.timeline, this.draw)
    this.layersPanel = new LayersPanel(this.dataCanvas, this.timeline, this.sourceWindow, this.draw)

    // Create the menu definitions.
    const menuItemsView = new MenuDefinitionView(
      this,
      this.toolbox,
      this.timeline,
      this.mapView,
      this.layersPanel,
      this.inspectorWindow,
      this.consoleWindow,
      this.sourceWindow
    ).getMenuItems()
    const menuItemsOthers = new MenuDefinitionOthers(
      this,
      this.toolbox,
      this.releaseNotesWindow,
      this.consoleWindow
    ).getMenuItems()
    const menuItemsLayers = new MenuDefinitionLayers(this.layersPanel).getMenuItems()
    const menuItemsMaps = new MenuDefinitionMaps(this.mapView).getMenuItems()

    const clearButton = Html.getDefinedHtmlElementById(HTML_BUTTON_CLEAR_MAP)
    clearButton.addEventListener("click", () => this.clearMap())

    // Create the menu bar.
    this.menuBar = new MenuBar(menuItemsMaps, menuItemsView, menuItemsLayers, menuItemsOthers, this.mapView.selectMap)

    // Add the click handler for the menu. This needs to be "window" to catch all clicks, as the menu has dropdowns.
    window.addEventListener("click", (e) => this.menuBar.onWindowClick(e))

    // Add the keyboard shortcut in the color switches for the layers.
    menuItemsLayers.forEach((item) => {
      if (item.shortcut) {
        const layerType = item.id as LayerType
        const elm = Html.getDefinedHtmlElementById(Html.htmlElementIdForColor(layerType))
        elm.innerText = item.shortcut
        if (item.textColor) {
          elm.style.color = item.textColor
        }
      }
    })
    this.setupKeyboardListeners([menuItemsView, menuItemsLayers, menuItemsOthers].flat())

    // Update the menu items.
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_TILE_GRID, this.mapView.showTileGrid())
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS, !Storage.get(Settings.ShowRouteSectionTypes))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES, Storage.get(Settings.ShowRouteSectionTypes))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_GPX_TIMESTAMPS, Storage.get(Settings.AddGpxTimestamps))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SHOW_MOUSE_POSITION, Storage.get(Settings.ShowMousePosition))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_LOCAL_TIME, !Storage.get(Settings.UseTimeFormatUTC))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SKIP_COMMENTS, Storage.get(Settings.SkipComments))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW, Storage.get(Settings.AutoShowConsole))
    MenuBar.updateMenuItemToggle(
      HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE,
      Storage.get(Settings.DownloadRoutingApiResponse)
    )
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_ORBIS_ROUTING_API, Storage.get(Settings.UseOrbisRoutingApi))

    // Update the layer panel.
    Object.entries(this.dataCanvas.layerStates).forEach(([layerTypeString, item]) => {
      const layerType = layerTypeString as LayerType
      if (isRealLayer(layerType)) {
        this.layersPanel.updateOption(layerType, item.show)
      }
    })

    // Allow dropping files on these elements and classes.
    Html.makeHtmlElementDroppable(HTML_MAP, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_MENUBAR, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_TIMELINE, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_TOOLBOX, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlClassDroppable(HTML_CLASS_PANEL, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlClassDroppable(HTML_CLASS_WINDOW, this.handleDropOnCanvasOrSelectedFile)

    // Add the "select files" button.
    const selectFilesButton = Html.getDefinedHtmlElementById(HTML_BUTTON_SELECT_FILES)
    selectFilesButton.addEventListener("click", () => this.selectFiles())

    // These require the menu options to be available:
    this.layersPanel.update()
    this.helpWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
    this.consoleWindow.setVisible(false)
    this.sourceWindow.setVisible(false)

    // Automatically show help/what's new if this is a new version.
    if (Storage.hasApplicationVersionChanged()) {
      Storage.updateApplicationVersion()
      this.releaseNotesWindow.setVisible()
    } else {
      this.releaseNotesWindow.setVisible(false)
    }

    // Add the clickable version marker in the menu bar (shows release notes).
    const versionMarker = Html.getDefinedHtmlElementById(HTML_VERSION_MARKER)
    versionMarker.addEventListener("click", () =>
      this.releaseNotesWindow.setVisible(!this.releaseNotesWindow.isVisible())
    )

    // Store the panel positions and make them draggable.
    Html.storeHtmlElementPosition(HTML_TOOLBOX)
    Html.storeHtmlClassPositions(HTML_CLASS_PANEL)
    Html.storeHtmlClassPositions(HTML_CLASS_WINDOW)
    Html.makeHtmlElementDraggable(HTML_TOOLBOX)
    Html.makeHtmlClassDraggable(HTML_CLASS_PANEL_DRAG_BAR, HTML_CLASS_PANEL)
    Html.makeHtmlClassDraggable(HTML_CLASS_WINDOW_TITLE_BAR, HTML_CLASS_WINDOW)

    // Make sure all panels are positioned properly.
    this.layersPanel.resizePanels()
  }

  /**
   * Initialize the application. This is an async function, which returns immediately.
   * Most of the initialization is done in the constructor, but some things need to be done async.
   *
   * The reason to not move all initialization here, is that some of the initialized properties
   * are declared as "readonly", so they can **only** be initialized in the constructor.
   */
  async initialize() {
    console.log("Loading content files async...")
    await this.helpWindow.loadContent()
    await this.releaseNotesWindow.loadContent()
  }

  /**
   * Callback function for dropping files on canvas (must be lambda), or a selected file (which is passed
   * as an event).
   * @param dragEvent event.
   */
  private readonly mutexDropOnCanvas = new Mutex()
  private readonly handleDropOnCanvasOrSelectedFile = async (dragEvent: DragEvent) => {
    // Do not allow simultaneous file dropping due to potential race conditions in the loaded data structures.
    await this.mutexDropOnCanvas.runExclusive(async () => {
      await this.dataCanvas.handleDrop(
        dragEvent,
        () => {
          this.resetToolsAndKeepData()
          this.layersPanel.update()
          this.dataFilesPanel.update()
          // Auto-zoom after dropping, if allowed.
          this.draw(false)
          // TODO [techdebt]: This is a workaround for a bug where the timeline does not yet show duplicates after dropping a file.
          setTimeout(() => this.draw(false), 1000)
        },
        () => {
          this.consoleWindow.setVisible(true)
          this.layersPanel.update()
          this.dataFilesPanel.update()
          BugReport.logBugReport()
          // Auto-zoom after dropping, if allowed.
          this.draw(true)
        }
      )
    })
  }

  /**
   * Allow the user to select a data file from the file system.
   */
  private readonly selectFiles = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = true
    input.accept = ".ttp,.gpx,.json,.geojson,.csv,.txt,.log,.http,.xml,.output,.diff"
    input.addEventListener("change", (event) => {
      const files = (event.target as HTMLInputElement).files
      if (files) {
        const dataTransfer = new DataTransfer()
        for (const element of files) {
          dataTransfer.items.add(element)
        }
        const dragEvent = new DragEvent("drop", {
          dataTransfer: dataTransfer,
          bubbles: true,
          cancelable: true
        })
        // Simulate drop event for the selected files.
        this.handleDropOnCanvasOrSelectedFile(dragEvent).then(() => {})
      }
    })
    // Open file selector dialog with a slight delay (helps mobile browsers).
    setTimeout(() => {
      input.click()
    }, 100)
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private readonly onClick = (event: MapMouseEvent) => {
    const location = event.lngLat
    const point = event.point
    this.toolbox.currentTool.onClick(location, point)
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private readonly onContextMenu = (event: MapMouseEvent) => {
    const location = event.lngLat
    const point = event.point
    this.toolbox.currentTool.onContextMenu(location, point)
  }

  /**
   * Mouse move  handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private lastMouseLngLat = {lng: 0, lat: 0}
  private readonly onMouseMove = (event: MapMouseEvent) => {
    this.lastMouseLngLat = event.lngLat
    if (Storage.get(Settings.ShowMousePosition)) {
      const now = Date.now()
      if (now > this.mousePositionLastLoggedTime + this.mousePositionLoggingIntervalInMillis) {
        this.mousePositionLastLoggedTime = now
        this.timeline.drawCoordinate(event.lngLat)
      }
    }
  }

  /**
   * Toggle the visibility of tile levels based on a digit.
   * @param key The digit.
   */
  private handleKeyDigit(key: KeyboardEvent) {
    if (key.key < "0" || key.key > "9") {
      return false
    }
    if (key.key >= "0" && key.key <= "7") {
      this.tileLevelFiltersPanel.toggleOption(parseInt(key.key) + 10)
    } else if (key.key >= "8" && key.key <= "9") {
      this.tileLevelFiltersPanel.toggleOption(parseInt(key.key))
    }
    return true
  }

  /**
   * Install the keyboard handler for menu item shortcuts.
   * @param menuDefinitions List of menu items.
   */
  private setupKeyboardListeners(menuDefinitions: MenuItem[]) {
    document.body.addEventListener("keydown", (keyboardEvent: KeyboardEvent) => {
      // Avoid listening to keyboard shortcuts when typing in an input field, for example for the API key.
      if (!this.keyboardShortcutsEnabled) {
        return
      }
      if (keyboardEvent.altKey || keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
        return
      }

      // Digits 0-9 are handled separately.
      if (!this.handleKeyDigit(keyboardEvent)) {
        // Find a menu schortcut.
        const menuItem = menuDefinitions.find(
          (item) =>
            ("key" in item && keyboardEvent.key === `${item.key}`) ||
            ("shortcut" in item && keyboardEvent.code === `Key${item.shortcut}`)
        )
        if (menuItem && "action" in menuItem) {
          keyboardEvent.preventDefault()
          menuItem.action()
        } else {
          // Handle special cases.
          const level = Storage.getNumber(Settings.ShowMousePositionTileLevel, 13)
          switch (keyboardEvent.key) {
            case "[":
              Storage.set(Settings.ShowMousePositionTileLevel, level > 1 ? level - 1 : 1)
              Logger.log.info("Decrease tile level (for mouse coordinate reporting)")
              this.timeline.drawCoordinate(this.lastMouseLngLat)
              break
            case "]":
              Storage.set(Settings.ShowMousePositionTileLevel, level < 17 ? level + 1 : 17)
              Logger.log.info("Decrease tile level (for mouse coordinate reporting)")
              this.timeline.drawCoordinate(this.lastMouseLngLat)
              break
          }
        }
      }
    })
  }

  private readonly mutexSetTimeRange = new Mutex()
  private readonly setTimeRange = async (timeRange: TimeRange, forceUpdate: boolean, inhibitAutoZoom: boolean) => {
    await this.mutexSetTimeRange.runExclusive(async () => {
      // Set the active time range for the data canvas.
      this.dataCanvas.setActiveTimeRange(timeRange)

      if (forceUpdate) {
        try {
          this.progressWindow.show("Analyzing requests for potential duplicates...")
          await this.dataCanvas.highlightPotentialDuplicates()
        } finally {
          this.progressWindow.hide()
        }
      }

      // Zoom map, if auto-zoom is enabled and zooming is not inhibited.
      if (!inhibitAutoZoom && Storage.get(Settings.AutoZoom)) {
        this.dataCanvas.zoomToBounds()
      }
    })
  }

  /**
   * Allow the user to enter a custom HTTP overhead size.
   */
  public enterDefaultHttpOverheadSize() {
    const addEventListeners = () => {
      document.body.addEventListener("keydown", handleKeydown)
      Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT_SUBMIT).addEventListener("click", submitDialog)
      Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT_CANCEL).addEventListener("click", cancelDialog)
    }

    const removeEventListeners = () => {
      document.body.removeEventListener("keydown", handleKeydown)
      Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT_SUBMIT).removeEventListener("click", submitDialog)
      Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT_CANCEL).removeEventListener("click", cancelDialog)
    }

    const submitDialog = () => {
      const input = Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT_VALUE) as HTMLInputElement
      const number = parseInt(input.value)
      if (number !== undefined && !isNaN(number) && number >= 0 && number <= 16 * 1024) {
        Storage.setHttpOverheadSizeInBytes(number)
        removeEventListeners()
        dialog.style.display = "none"
        this.keyboardShortcutsEnabled = true
        this.timeline.recalculateSizesAndDraw()
        this.layersPanel.update()
        this.layersPanel.update()
      } else {
        alert("Please enter a valid number between 0-16384.")
      }
    }

    const cancelDialog = () => {
      removeEventListeners()
      const dialog = Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT)
      dialog.style.display = "none"
      this.keyboardShortcutsEnabled = true
    }

    const handleKeydown = (keyboardEvent: KeyboardEvent) => {
      // Do not prevent further handling of keys with keyboardEvent.preventDefault().
      if (keyboardEvent.key === "Enter") {
        submitDialog()
      } else if (keyboardEvent.key === "Escape") {
        cancelDialog()
      }
    }

    this.keyboardShortcutsEnabled = false
    const dialog = Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT)
    dialog.style.display = "block"
    const value = Html.getDefinedHtmlElementById(HTML_NUMBER_INPUT_VALUE) as HTMLInputElement
    value.value = Storage.getHttpOverheadSizeInBytes().toString()
    value.focus()
    value.select()

    addEventListeners()
  }

  /**
   * Allow the user to enter a regex for custom MapVis tiles.
   */
  public enterMapVisCustomRegex() {
    const addEventListeners = () => {
      document.body.addEventListener("keydown", handleKeydown)
      Html.getDefinedHtmlElementById(HTML_STRING_INPUT_SUBMIT).addEventListener("click", submitDialog)
      Html.getDefinedHtmlElementById(HTML_STRING_INPUT_CANCEL).addEventListener("click", cancelDialog)
    }

    const removeEventListeners = () => {
      document.body.removeEventListener("keydown", handleKeydown)
      Html.getDefinedHtmlElementById(HTML_STRING_INPUT_SUBMIT).removeEventListener("click", submitDialog)
      Html.getDefinedHtmlElementById(HTML_STRING_INPUT_CANCEL).removeEventListener("click", cancelDialog)
    }

    const submitDialog = () => {
      const input = Html.getDefinedHtmlElementById(HTML_STRING_INPUT_VALUE) as HTMLInputElement
      let isOk = true
      try {
        new RegExp(input.value)
      } catch (e) {
        isOk = false
      }
      if (isOk) {
        this.dataCanvas.lineParsers[LayerType.MapVisCustom]!.regexWithLocation = input.value
        Storage.setMapVisCustomRegex(input.value)
        removeEventListeners()
        dialog.style.display = "none"
        this.keyboardShortcutsEnabled = true
        this.timeline.recalculateSizesAndDraw()
        this.layersPanel.update()
        this.layersPanel.update()
      } else {
        alert("Please enter a valid regular expression, like: '/custom/{z}/{x}/{y}'")
      }
    }

    const cancelDialog = () => {
      removeEventListeners()
      const dialog = Html.getDefinedHtmlElementById(HTML_STRING_INPUT)
      dialog.style.display = "none"
      this.keyboardShortcutsEnabled = true
    }

    const handleKeydown = (keyboardEvent: KeyboardEvent) => {
      // Do not prevent further handling of keys with keyboardEvent.preventDefault().
      if (keyboardEvent.key === "Enter") {
        submitDialog()
      } else if (keyboardEvent.key === "Escape") {
        cancelDialog()
      }
    }

    this.keyboardShortcutsEnabled = false
    const dialog = Html.getDefinedHtmlElementById(HTML_STRING_INPUT)
    dialog.style.display = "block"
    const value = Html.getDefinedHtmlElementById(HTML_STRING_INPUT_VALUE) as HTMLInputElement
    value.value = this.dataCanvas.lineParsers[LayerType.MapVisCustom]!.regexWithLocation ?? ""
    value.focus()
    value.select()

    addEventListeners()
  }

  /**
   * Create a GPX file from a routing response file.
   */
  public createGpxFromRoutingResponseFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json, .txt"
    input.addEventListener("change", (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.toolbox.tools.routeCreatorGpxTool.createGpxFromRoutingResponse(file.name, content)
        }
        reader.readAsText(file)
      }
    })
    input.click()
  }

  /**
   * Reset the state of tools (for example, after pressing Escape).
   */
  public resetToolsAndKeepData(keepRoutes = true) {
    this.toolbox.tools.dataSelectorTool.clear()
    this.toolbox.tools.distanceCalculatorTool.prepareNextMeasurement()
    keepRoutes
      ? this.toolbox.tools.routeCreatorJsonTool.finishCurrentRoute()
      : this.toolbox.tools.routeCreatorJsonTool.clear()
    this.toolbox.tools.routeCreatorGpxTool.clear()
    this.toolbox.tools.tileEditorTool.clear()
    this.helpWindow.setVisible(false)
    this.releaseNotesWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
    this.sourceWindow.setVisible(false)
    this.timeline.resetTimelineTimeRange()
  }

  /**
   * Clear the map and its imported data structures.
   */
  public readonly clearMap = () => {
    Html.resetHTMLElementPositions()
    this.dataCanvas.clear()
    this.toolbox.tools.dataSelectorTool.clear()
    this.toolbox.tools.distanceCalculatorTool.clear()
    this.toolbox.tools.routeCreatorJsonTool.clear()
    this.toolbox.tools.routeCreatorGpxTool.clear()
    this.toolbox.tools.tileEditorTool.clear()
    this.toolbox.resetCurrentTool()
    this.consoleWindow.clear()
    this.consoleWindow.setVisible(false)
    this.sourceWindow.clear()
    this.sourceWindow.setVisible(false)
    this.timeline.clear()
    this.layersPanel.update()
    this.dataFilesPanel.update()
    this.draw()
  }

  /**
   * Toggle help window. NNeeds to be a lambda, not a regular function, to avoid binding issues.
   */
  public readonly toggleHelpWindow = () => {
    this.helpWindow.setVisible(!this.helpWindow.isVisible())
    if (this.helpWindow.isVisible()) {
      this.releaseNotesWindow.setVisible(false)
    }
  }

  /**
   * Undo the last action. This is often a very simplistic undo, really.
   */
  public undo() {
    Logger.log.info("Undo last action")
    this.toolbox.currentTool.undo && this.toolbox.currentTool.undo()
    this.draw(true)
  }

  /**
   * Callback for (re)drawing the screen. This is an async function, which returns immediately.
   * @param inhibitAutoZoom If true, do not auto-zoom.
   * @param onFinished Called when finished drawing.
   */
  public readonly draw = async (inhibitAutoZoom: boolean = false, onFinished: () => void = () => {}) => {
    try {
      this.layersPanel.update() // Update sizes and stats of layers panel.
      this.timeline.replaceAllEvents(this.dataCanvas.getEventsFromFeatures(), inhibitAutoZoom)
      this.inspectorWindow.update()
      this.sourceWindow.update()
    } finally {
      onFinished()
    }
  }
}

export default App
