/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LayerType} from "./parserTypes"
import {PointXY} from "../common/geo"
import {Feature} from "geojson"
import {MetadataStore} from "../common/metadata"
import {GlobalSettings} from "../app/globalSettings"
import {isValidLat, isValidLng} from "../common/wgs84"
import LogWindow from "../app/logWindow"
import LineParser from "./lineParser"

export class ParserLogLines extends LineParser {
  constructor(logWindow: LogWindow, globalSettings: GlobalSettings, metadataStore: MetadataStore) {
    super(
      logWindow,
      globalSettings,
      metadataStore,
      LayerType.LogLines,
      "Log lines",
      {
        "circle-radius": 10,
        "circle-color": "rgba(100,144,255,0.7)",
        text: "C",
        "text-color": "rgb(255,255,255)"
      },
      "(?:latitude|lat|y)[^A-Za-z0-9.+-]+{y}[^0-9.+-]*?(?:longitude|lon|lng|long|x)[^A-Za-z0-9.+-]+{x}"
    )
  }

  parseLine(lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation.replace("{x}", "([+-]?[0-9.]+)").replace("{y}", "([+-]?[0-9.]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const indexes = {
          x: this.regexWithLocation.indexOf("{x}"),
          y: this.regexWithLocation.indexOf("{y}")
        }
        const coordinate: PointXY = {x: 0, y: 0}
        const sortedKeys = Object.keys(indexes).sort(
          (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
        )
        sortedKeys.forEach((key, i) => {
          const value = parseFloat(match[i + 1])
          switch (key) {
            case "x":
            case "y":
              coordinate[key] = value
              break
          }
        })
        if (!isValidLng(coordinate.x) || !isValidLat(coordinate.y)) {
          break
        }
        const metadata = {
          lineNumber: lineNumber,
          line: line,
          ...(httpStatusCode && {httpStatusCode: httpStatusCode})
        }
        const point = this.createPointFromCoordinates(coordinate, this.color, metadata, time, sizeInBytes)
        features.push(point)
        from = from + match.index + match[0].length
      }
    }
    return features
  }
}

export default ParserLogLines
