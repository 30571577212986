/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import assert from "./assert"

export type HtmlElementPosition = {left: number; top: number}

/**
 * Get the HTML element, given an ID, Throw if it does not exist.
 * @param id ID of the HTML element.
 * @returns The HTML element.
 */
export function getDefinedHtmlElementById(id: string): HTMLElement {
  const elm = document.getElementById(id)
  assert(elm, `HTML element '${id}' not found`)
  return elm!
}

export function getHtmlElementPosition(id: string): HtmlElementPosition {
  const elm = getDefinedHtmlElementById(id)
  return {left: parseInt(elm.style.left), top: parseInt(elm.style.top)}
}

export function setHtmlElementPosition(id: string, position: HtmlElementPosition) {
  const elm = getDefinedHtmlElementById(id)
  elm.style.left = isNaN(position.left) ? "" : `${position.left}px`
  elm.style.top = isNaN(position.top) ? "" : `${position.top}px`
}

export function makeHtmlElementDraggable(id: string, classToDrag?: string) {
  let offsetX = 0
  let offsetY = 0
  const elm = getDefinedHtmlElementById(id)
  const parentElm = elm.parentElement
  const parentClass = parentElm?.classList
  const draggableElement = classToDrag && parentElm && parentClass?.contains(classToDrag) ? parentElm : elm

  const mouseDownHandler = (event: MouseEvent) => {
    offsetX = event.clientX - draggableElement.getBoundingClientRect().left
    offsetY = event.clientY - draggableElement.getBoundingClientRect().top
    document.addEventListener("mousemove", mouseMoveHandler)
    document.addEventListener("mouseup", mouseUpHandler)
  }

  const mouseMoveHandler = (e: MouseEvent) => {
    setHtmlElementPosition(draggableElement.id, {left: e.clientX - offsetX, top: e.clientY - offsetY})
  }

  const mouseUpHandler = (_: MouseEvent) => {
    document.removeEventListener("mousemove", mouseMoveHandler)
    document.removeEventListener("mouseup", mouseUpHandler)
  }
  elm.addEventListener("mousedown", mouseDownHandler)
}

export function makeHtmlClassDraggable(className: string, classToDrag?: string) {
  const elements = document.getElementsByClassName(className)
  Array.from(elements).forEach((elm) => makeHtmlElementDraggable(elm.id, classToDrag))
}
