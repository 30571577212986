/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LayerType} from "../parserTypes"
import {PointXY} from "../../common/utils/geoUtils"
import {Feature} from "geojson"
import LineParser from "./lineParser"
import LogcatUtils from "../../common/utils/logcatUtils"
import Wgs84Utils from "../../common/utils/wgs84Utils"

export class LineParserLogLines extends LineParser {
  constructor() {
    super(
      LayerType.LogLines,
      "Log lines",
      {
        "circle-radius": 3,
        "circle-color": "rgba(100,144,255,0.7)",
        text: "",
        "text-color": "rgb(255,255,255)"
      },
      "(?:" +
        /* latitude */ "(?:(?:latitude)|(?:lat)|[Yy])[^A-Za-z0-9.+-]+{y}" +
        /* separator */ "[^0-9.+-]*" +
        /* longitude */ "(?:(?:longitude)|(?:lon)|(?:lng)|(?:long)|[Xx])[^A-Za-z0-9.+-]+{x})"
    )
  }

  parseLine(fileId: string, lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []
    // Log lines must have a log level, so we can abort if there's no log level.
    const logLevel = LogcatUtils.getLogLevelFromLogString(line)
    if (!logLevel) {
      return features
    }
    const time = this.getDateTimeFromAnyString(line)
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation.replace("{x}", "([+-]?[0-9.]+)").replace("{y}", "([+-]?[0-9.]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const indexes = {
          x: this.regexWithLocation.indexOf("{x}"),
          y: this.regexWithLocation.indexOf("{y}")
        }
        const coordinate: PointXY = {x: 0, y: 0}
        const sortedKeys = Object.keys(indexes).sort(
          (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
        )
        sortedKeys.forEach((key, i) => {
          const value = parseFloat(match[i + 1])
          switch (key) {
            case "x":
              coordinate.x = value
              break

            case "y":
              coordinate.y = value
              break
          }
        })
        if (!Wgs84Utils.isValidLng(coordinate.x) || !Wgs84Utils.isValidLat(coordinate.y)) {
          break
        }
        const metadata = {
          file: fileId,
          lineNumber: lineNumber,
          line: line
        }
        const point = this.createFeatureFromPointXY(coordinate, this.color, metadata, time, logLevel)
        features.push(point)
        from = from + match.index + match[0].length
      }
    }

    // Add the lo line to the timeline if the lines was not used at all, so at least it's visible.
    if (features.length === 0) {
      if (time) {
        const metadata = {
          file: fileId,
          lineNumber: lineNumber,
          line: line
        }
        const point = this.createFeatureWithLogLevel(metadata, time, logLevel)
        features.push(point)
      }
    }
    return features
  }
}

export default LineParserLogLines
