/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */
import {getDefinedHtmlElementById} from "../common/html"
import {limitValue} from "../common/objects"

export class ProgressWindow {
  private readonly htmlElementId: HTMLElement
  private readonly htmlElementIdContent: HTMLElement
  private readonly progressBar: HTMLElement
  private lastUpdateTimeMsec: number = 0
  private lastUpdatePercentage: number = 0

  private static readonly minimumUpdateTimeMsec = 500

  private message: string = ""

  constructor(htmlElementId: string, htmlElementIdContent: string, htmlElementIdProgressBar: string) {
    this.htmlElementId = getDefinedHtmlElementById(htmlElementId)
    this.htmlElementIdContent = getDefinedHtmlElementById(htmlElementIdContent)
    this.progressBar = getDefinedHtmlElementById(htmlElementIdProgressBar)
  }

  show(message: string) {
    this.message = message
    this.htmlElementIdContent.innerText = this.message
    this.htmlElementIdContent.classList.add("blink")
    this.htmlElementId.style.display = "block"
    this.lastUpdateTimeMsec = Date.now()
    this.updatePercentage(0)
  }

  updateMessage(message: string) {
    const now = Date.now()
    if (this.lastUpdateTimeMsec + ProgressWindow.minimumUpdateTimeMsec < now || this.message !== message) {
      this.message = message
      this.htmlElementIdContent.innerText = message
      this.lastUpdateTimeMsec = now
    }
  }

  updatePercentage(percentage: number) {
    const now = Date.now()
    const limitedPercentage = limitValue(Math.round(percentage * 100 + 0.5), 0, 100)
    if (
      this.lastUpdateTimeMsec + ProgressWindow.minimumUpdateTimeMsec < now ||
      this.lastUpdatePercentage !== limitedPercentage
    ) {
      this.progressBar.style.width = `${limitedPercentage}%`
      this.lastUpdateTimeMsec = now
      this.lastUpdatePercentage = limitedPercentage
    }
  }

  hide() {
    this.htmlElementId.style.display = "none"
    this.htmlElementIdContent.classList.remove("blink")
  }
}

export default ProgressWindow
