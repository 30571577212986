/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, LineString, Point, Position} from "geojson"
import {Tool} from "./tool"
import {Point as MapLibrePoint} from "maplibre-gl"
import MapView from "../app/mapView"
import MathUtils from "../common/utils/mathUtils"
import {LayerWithoutId} from "../common/utils/mapLibreUtils"
import {LngLat, Wgs84Utils} from "../common/utils/wgs84Utils"

export class DistanceCalculatorTool extends Tool {
  private positions: Position[][] = []
  private currentSet = 0

  constructor(map: MapView) {
    super("distanceCalculatorTool", "Measure distances", map)
    this.positions[0] = []
  }

  onClick(location: LngLat, _: MapLibrePoint): void {
    this.positions[this.currentSet].push([location.lng, location.lat])
    this.refresh()
  }

  undo(): void {
    if (this.positions[this.currentSet].length > 0) {
      this.positions[this.currentSet].pop()
      this.refresh()
    } else {
      if (this.currentSet > 0) {
        this.positions.pop()
        --this.currentSet
      }
    }
  }

  prepareNextMeasurement(): void {
    ++this.currentSet
    this.positions[this.currentSet] = []
    this.refresh()
  }

  clearToolData(): void {
    this.positions = [] as Position[][]
    this.positions[0] = []
    this.currentSet = 0
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "circle",
        paint: {
          "circle-radius": 5,
          "circle-color": ["get", "circle-color"]
        },
        filter: ["==", ["geometry-type"], "Point"]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": 2,
          "line-dasharray": [2, 2],
          "line-color": "rgba(255,0,0,0.5)"
        },
        filter: ["==", ["geometry-type"], "LineString"]
      },
      {
        type: "symbol",
        layout: {
          "text-field": ["get", "text"],
          "text-font": ["Noto-Bold"],
          "text-size": 10,
          "text-offset": [0, 1],
          "text-anchor": "center",
          "text-rotate": ["get", "text-rotate"]
        },
        paint: {
          "text-color": ["get", "text-color"]
        },
        filter: ["all", ["has", "text"], ["==", ["geometry-type"], "Point"]]
      }
    ]
  }

  refresh(): void {
    const maxSafeHaversineDistanceInDegrees = 0.0025
    let features: Feature<LineString | Point>[] = []
    let totalDistanceInMeters = 0
    for (const line of this.positions) {
      // Define the connecting line type.
      const lineStringFeature: Feature<LineString> = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: []
        },
        properties: {
          "line-color": "rgba(0,21,255,0.5)"
        }
      }
      for (let i = 0; i < line.length; ++i) {
        const position = line[i]
        lineStringFeature.geometry.coordinates.push(position)

        // Define point marker.
        const pointFeature: Feature<Point> = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: position
          },
          properties: {
            "circle-color":
              i === 0 ? "rgba(9,128,0,0.5)" : i === line.length - 1 ? "rgba(255,0,0,0.5)" : "rgba(0,32,135,0.5)"
          }
        }
        features.push(pointFeature)

        if (i > 0) {
          const prevPosition = line[i - 1]
          const start: LngLat = {lng: prevPosition[0], lat: prevPosition[1]}
          const end: LngLat = {lng: position[0], lat: position[1]}

          // // Calculate angle of text using Haversine.
          const lat1 = MathUtils.degreesToRadians(start.lat)
          const lat2 = MathUtils.degreesToRadians(end.lat)
          const deltaLng = MathUtils.degreesToRadians(end.lng - start.lng)
          const y = Math.sin(deltaLng)
          const x = Math.log(Math.tan(Math.PI / 4 + lat2 / 2) / Math.tan(Math.PI / 4 + lat1 / 2))
          let angle = MathUtils.radiansToDegrees(Math.atan2(y, x)) + 90
          if (angle > 90) {
            angle -= 180
          } else if (angle <= -90) {
            angle += 180
          }

          // Calculate distance in short steps to increase accuracy for longer distances.
          const stepsLng = Math.ceil(Math.abs(start.lng - end.lng) / maxSafeHaversineDistanceInDegrees)
          const stepsLat = Math.ceil(Math.abs(start.lat - end.lat) / maxSafeHaversineDistanceInDegrees)
          const steps = Math.max(1, Math.max(stepsLng, stepsLat))
          let segmentDistanceInMeters = 0
          let currentLng = start.lng
          let currentLat = start.lat
          for (let step = 0; step < steps; ++step) {
            const nextLng = currentLng + (end.lng - start.lng) / steps
            const nextLat = currentLat + (end.lat - start.lat) / steps

            segmentDistanceInMeters += Wgs84Utils.distanceInMeters(
              {lng: currentLng, lat: currentLat},
              {
                lng: nextLng,
                lat: nextLat
              }
            )
            currentLng = nextLng
            currentLat = nextLat
          }
          totalDistanceInMeters += segmentDistanceInMeters

          const midPoint: LngLat = {lng: (start.lng + end.lng) / 2, lat: (start.lat + end.lat) / 2}
          const segmentDistance = `${i > 1 ? "... " : ""}${this.formatDistance(totalDistanceInMeters)}${i > 1 && i === line.length - 1 ? " (total)" : ""}`
          const textFeature: Feature<Point> = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [midPoint.lng, midPoint.lat]
            },
            properties: {
              text: segmentDistance,
              "text-color": "rgb(192,0,0)",
              "circle-color": "rgba(0,0,0,0)",
              "text-rotate": angle
            }
          }
          features.push(textFeature)
        }
      }
      features.push(lineStringFeature)
    }
    this.draw(features)
  }

  private formatDistance(distanceInMeters: number): string {
    if (distanceInMeters >= 100000) {
      return `${(distanceInMeters / 1000).toFixed(0)} km`
    } else if (distanceInMeters >= 5000) {
      return `${(distanceInMeters / 1000).toFixed(1)} km`
    } else if (distanceInMeters >= 1000) {
      return `${(distanceInMeters / 1000).toFixed(2)} km`
    } else {
      return `${distanceInMeters.toFixed(0)} m`
    }
  }
}

export default DistanceCalculatorTool
