/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LayerType} from "../parsers/parserTypes"

export type MenuAction = (
  name: string,
  button: HTMLButtonElement,
  action: (() => void) | undefined,
  id?: string
) => void

export type LayerMenuItem = {
  id: keyof typeof LayerType
  name: string
  key?: string
  shortcut?: string
  textColor?: string
  action: () => void
}

export type GenericMenuItem = {
  id?: string
  name: string
  key?: string
  shortcut?: string
  action: () => void
}

export type SeparatorMenuItem = {
  separator: boolean
}

export const separatorMenuItem: SeparatorMenuItem = {separator: true}

export type MenuItem = LayerMenuItem | GenericMenuItem | SeparatorMenuItem
