/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Html} from "./html/html"
import App from "./app/app"
import PersistentStorage from "./persistence/persistentStorage"

/**
 * Toggle visibility of app elements, used to clear the screen for the API key dialog.
 */
function setAppElementsVisibility(show: boolean) {
  ;[
    "menubar",
    "version-marker",
    "bug-report",
    "map",
    "toolbox",
    "panel-filters",
    "panel-layers",
    "panel-data-files",
    "timeline",
    "timeline-controls"
  ].forEach((id) => {
    const elm = Html.getDefinedHtmlElementById(id)
    elm.style.display = show ? "block" : "none"
  })
}

function startApp() {
  setAppElementsVisibility(true)
  let app = new App()
  console.assert(app)
}

// Pop-up the API key entry dialog if needed.
if (PersistentStorage.getTomTomApiKey().length === 0) {
  setAppElementsVisibility(false)
  Html.getDefinedHtmlElementById("api-key-form").style.display = "block"
  Html.getDefinedHtmlElementById("keys-form").addEventListener("submit", (submitEvent: SubmitEvent) => {
    submitEvent.preventDefault()
    const apiKeyTomTom = (Html.getDefinedHtmlElementById("api-key-tomtom") as HTMLInputElement).value.trim()
    PersistentStorage.setTomTomApiKey(apiKeyTomTom)
    Html.getDefinedHtmlElementById("api-key-form").style.display = "none"
    startApp()
  })
} else {
  startApp()
}
