/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import App from "./app/app"
import {APPLICATION_VERSION} from "./common/version"

import {getDefinedHtmlElementById} from "./common/html"

/**
 * Toggle visibility of app elements, used to clear the screen for the API key dialog.
 */
let apiKeyTomTom = localStorage.getItem(App.KEY_API_KEY_TOMTOM)

function setAppElementsVisibility(show: boolean) {
  ;["menubar", "map", "filters-list", "layers-list", "data-files-list", "timeline", "toolbox"].forEach((id) => {
    const elm = getDefinedHtmlElementById(id)
    elm.style.display = show ? "block" : "none"
  })
}

function startApp() {
  setAppElementsVisibility(true)
  let app = new App(APPLICATION_VERSION, apiKeyTomTom ?? "")
  console.assert(app)
}

if (!apiKeyTomTom || apiKeyTomTom.length === 0) {
  // Pop-up the API key entry dialog.
  setAppElementsVisibility(false)
  getDefinedHtmlElementById("api-key-form").style.display = "block"
  getDefinedHtmlElementById("keys-form").addEventListener("submit", (e) => {
    e.preventDefault()
    apiKeyTomTom = (getDefinedHtmlElementById("api-key-tomtom") as HTMLInputElement).value.trim()
    localStorage.setItem(App.KEY_API_KEY_TOMTOM, apiKeyTomTom)
    getDefinedHtmlElementById("api-key-form").style.display = "none"
    startApp()
  })
} else {
  startApp()
}
