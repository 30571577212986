/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export const HTML_CLASS_WINDOW = "window"
export const HTML_CLASS_WINDOW_TITLE_BAR = "window-title-bar"

export const HTML_CLASS_PANEL = "panel"
export const HTML_CLASS_PANEL_DRAG_BAR = "panel-drag-bar"
export const HTML_CLASS_PANEL_STATUS_BUTTON = "panel-status-button"
export const HTML_CLASS_PANEL_COLOR_SWATCH = "panel-color-swatch"
export const HTML_CLASS_PANEL_TEXT = "panel-text"
export const HTML_CLASS_PANEL_TEXT_SIZE = "panel-text-size"

export const HTML_CLASS_CLOSE_BUTTON = "close-button"
export const HTML_CLASS_TITLE = "title"
export const HTML_CLASS_WINDOW_CONTENT = "window-content"

export const HTML_CLASS_DROPDOWN = "dropdown"
export const HTML_CLASS_DROPDOWN_BUTTON = "dropdown-button"
export const HTML_CLASS_DROPDOWN_CONTENT = "dropdown-content"

export const HTML_CLASS_MENUBAR = "menubar"
export const HTML_CLASS_MENU_ITEM_SHORTCUT = "menu-item-shortcut"
export const HTML_CLASS_MAP = "map"

export const HTML_CLASS_INSPECTOR_BUTTON = "inspector-button"
export const HTML_CLASS_INSPECTOR_JSON_OBJECT = "inspector-json-object"

export const HTML_CLASS_SOURCE_LINE = "source-line"
export const HTML_CLASS_SOURCE_LINE_NUMBER = "source-line-number"
export const HTML_CLASS_CURRENT = "current"
