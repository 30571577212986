/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"

import {HTML_WINDOW_CONTENT_SOURCE, HTML_WINDOW_SOURCE} from "../html/htmlElementId"
import {Html} from "../html/html"
import {HTML_CLASS_CURRENT, HTML_CLASS_SOURCE_LINE, HTML_CLASS_SOURCE_LINE_NUMBER} from "../html/htmlClassId"
import DataStore, {SourceLineWithTime} from "../global/dataStore"
import {DateTimeFormat, DateTimeUtils} from "../common/utils/dateTimeUtils"
import Storage, {Settings} from "../common/storage"

export class SourceWindow extends HtmlWindow {
  private readonly defaultNrOfContextLines = 100 // Number of lines above or below the current line.

  private fileId?: string
  private sourceLinesWithTime: SourceLineWithTime[]
  private currentLineNumber: number
  private nrOfContextLines: number

  constructor(title: string) {
    super(title, "source")
    this.fileId = undefined
    this.sourceLinesWithTime = []
    this.currentLineNumber = 0
    this.nrOfContextLines = 0
  }

  public clear() {
    this.fileId = undefined
    this.sourceLinesWithTime = []
    this.currentLineNumber = 0
    this.nrOfContextLines = 0
    this.setVisible(false)
  }

  public isEmpty = () => this.sourceLinesWithTime.length === 0

  public setSourceLinesWithTime(
    fileId: string,
    sourceLinesWithTime: SourceLineWithTime[],
    currentLineNumber?: number,
    nrOfContextLines?: number
  ) {
    const filename = DataStore.getFilename(fileId)
    this.fileId = fileId
    this.sourceLinesWithTime = sourceLinesWithTime
    this.currentLineNumber = currentLineNumber ?? 0
    this.nrOfContextLines = nrOfContextLines ?? this.defaultNrOfContextLines
    this.setTitle(filename)
    this.showSourceLinesWithTime()

    // Schedule a redraw after a little bit, to fill the window.
    setTimeout(() => {
      this.scrollToCurrentLine()
    }, 100)
  }

  public setVisible(show: boolean = true) {
    super.setVisible(show)
    if (show) {
      this.showSourceLinesWithTime()
      this.scrollToCurrentLine()
    }
  }

  public update() {
    if (this.fileId) {
      this.showSourceLinesWithTime()
      if (!DataStore.isFileShown(this.fileId)) {
        this.setVisible(false)
      }
    }
  }

  private scrollToCurrentLine() {
    if (this.sourceLinesWithTime.length === 0) {
      return
    }
    const sourceWindow = Html.getDefinedHtmlElementById(HTML_WINDOW_SOURCE)
    const sourceWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_SOURCE)
    const before = this.currentLineNumber - this.getStartLineNumber()
    const after = this.getEndLineNumber() - this.currentLineNumber
    const ratio = before < after ? before / after / 2 : 1 - after / before / 2
    const scrollTo = sourceWindowContent.scrollHeight * ratio - 65
    sourceWindow.scrollTo({
      top: Math.min(sourceWindowContent.scrollHeight, scrollTo)
    })
  }

  private readonly getStartLineNumber = () => Math.max(0, this.currentLineNumber - this.nrOfContextLines)

  private readonly getEndLineNumber = () =>
    Math.min(this.sourceLinesWithTime.length - 1, this.currentLineNumber + this.nrOfContextLines)

  private showSourceLinesWithTime() {
    const dateTimeFormat = Storage.get(Settings.UseTimeFormatUTC) ? DateTimeFormat.UTCTime : DateTimeFormat.LocalTime
    const startLineNumber = this.getStartLineNumber()
    const endLineNumber = this.getEndLineNumber()
    const sourceWindowContent = Html.getDefinedHtmlElementById(HTML_WINDOW_CONTENT_SOURCE)
    sourceWindowContent.innerHTML = this.sourceLinesWithTime
      .map((sourceLineWithTime, index) => {
        const lineNumber = index + 1
        const linePrefix =
          `${lineNumber === this.currentLineNumber ? "-> " : "   "}` +
          `${lineNumber}`.padStart(7, " ") +
          " | " +
          DateTimeUtils.formatTimeOnly(new Date(sourceLineWithTime.time), dateTimeFormat, true)

        const classLineNumber =
          HTML_CLASS_SOURCE_LINE_NUMBER + (lineNumber === this.currentLineNumber ? ` ${HTML_CLASS_CURRENT}` : "")

        const classLine =
          HTML_CLASS_SOURCE_LINE + (lineNumber === this.currentLineNumber ? ` ${HTML_CLASS_CURRENT}` : "")
        if (startLineNumber <= lineNumber && lineNumber <= endLineNumber) {
          return `<span class="${classLineNumber}">${linePrefix}</span><span class="${classLine}">${sourceLineWithTime.line}</span><br/>`
        } else {
          return ""
        }
      })
      .join("")
  }
}

export default SourceWindow
