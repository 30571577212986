/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageParseException} from "../ttpMessageParseException"
import {TtpMessageSensorBase} from "./ttpMessageSensorBase"
import {parseNumberMandatory, parseNumberOptional, TtpMessageType} from "../ttpMessage"

export enum LaneLevelPredictionIndex {
  LongitudeIndex = 4,
  LatitudeIndex = 5,
  AltitudeIndex = 6,
  HeadingIndex = 7,
  TargetSpeedIndex = 9,
  PredictionSpeedIndex = 10,
  CurrentSpeedIndex = 13
}

export class TtpMessageSensorLaneLevelPrediction extends TtpMessageSensorBase {
  longitude: number
  latitude: number
  altitude?: number
  heading?: number
  targetSpeed?: number
  predictionSpeed?: number
  currentSpeed?: number

  constructor(
    lineNumber: number,
    monotonicTime: number,
    message: number,
    longitude: number,
    latitude: number,
    altitude?: number,
    heading?: number,
    targetSpeed?: number,
    predictionSpeed?: number,
    currentSpeed?: number
  ) {
    super(lineNumber, TtpMessageType.SensorLaneLevelPrediction, monotonicTime, message)
    this.longitude = longitude
    this.latitude = latitude
    this.altitude = altitude
    this.heading = heading
    this.targetSpeed = targetSpeed
    this.predictionSpeed = predictionSpeed
    this.currentSpeed = currentSpeed
  }

  static parse(
    lineNumber: number,
    parts: string[],
    monotonicTime: number,
    message: number
  ): TtpMessageSensorLaneLevelPrediction {
    const {
      LongitudeIndex,
      LatitudeIndex,
      AltitudeIndex,
      HeadingIndex,
      TargetSpeedIndex,
      PredictionSpeedIndex,
      CurrentSpeedIndex
    } = LaneLevelPredictionIndex

    if (parts.length < LatitudeIndex) {
      throw new TtpMessageParseException(`Invalid lane level prediction line (need at least lon, lat)`)
    }
    const longitude = parseNumberMandatory(parts[LongitudeIndex])
    const latitude = parseNumberMandatory(parts[LatitudeIndex])
    const altitude = parts.length > AltitudeIndex ? parseNumberOptional(parts[AltitudeIndex]) : undefined
    const bearing = parts.length > HeadingIndex ? parseNumberOptional(parts[HeadingIndex]) : undefined
    const targetSpeed = parts.length > TargetSpeedIndex ? parseNumberOptional(parts[TargetSpeedIndex]) : undefined
    const predictionSpeed =
      parts.length > PredictionSpeedIndex ? parseNumberOptional(parts[PredictionSpeedIndex]) : undefined
    const currentSpeed = parts.length > CurrentSpeedIndex ? parseNumberOptional(parts[CurrentSpeedIndex]) : undefined
    return new TtpMessageSensorLaneLevelPrediction(
      lineNumber,
      monotonicTime,
      message,
      longitude,
      latitude,
      altitude,
      bearing,
      targetSpeed,
      predictionSpeed,
      currentSpeed
    )
  }
}

export default TtpMessageSensorLaneLevelPrediction
