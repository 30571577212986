/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LngLat, LngLatToArray} from "../common/wgs84"
import {Feature, Point, Polygon} from "geojson"
import {MetadataStore} from "../common/metadata"
import {GlobalSettings} from "../app/globalSettings"
import {BoundingBox, PointXY, pointXYToLngLat} from "../common/geo"
import {isParsedPointColor, LayerType, ParsedItemColor, ParsedPointColor, ParsedPolygonColor} from "./parserTypes"
import {isHttpCodeNoError} from "../common/httpCodes"
import {convertMillisToDateTime, getDateTimeFromTimestamp} from "../common/datetime"
import LogWindow from "../app/logWindow"

/**
 * Parser is the base class for all parsers. It contains some utility functions used for parsers.
 */
export abstract class Parser {
  public static readonly SIZE_EXPECTED_BUT_NOT_FOUND = -1 // Return in field `size` if size was expected but not found.

  readonly name: string
  readonly layerType: LayerType
  readonly color: ParsedItemColor
  protected readonly regexWithLocation?: string
  protected readonly regexWithoutLocation?: string

  protected readonly logWindow: LogWindow
  protected readonly globalSettings: GlobalSettings
  protected readonly metadataStore: MetadataStore

  private readonly regexUrl = /\s*[a-z0-9-]+:\/{2}[^;]*/
  private readonly regexInteger = /\s*[+-]?\s*\d+\s*/
  private readonly regexIsoTimestamp = /\s*\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:Z|(?:[+-](?:\d)+))\s*/

  constructor(
    logWindow: LogWindow,
    settings: GlobalSettings,
    metadataStore: MetadataStore,
    layerType: LayerType,
    name: string,
    color?: ParsedItemColor,
    regexWithLocation?: string,
    regexWithoutLocation?: string
  ) {
    this.logWindow = logWindow
    this.globalSettings = settings
    this.metadataStore = metadataStore

    this.name = name
    this.layerType = layerType
    this.color = color
    this.regexWithLocation = regexWithLocation
    this.regexWithoutLocation = regexWithoutLocation
  }

  /**
   * This function checks if a parse line should be omitted from parsing.
   * @param line The line to check.
   * @param discardComments If true, comments are discarded.
   * @returns True if the line should be omitted, false otherwise.
   */
  static lineIsCandidateForParsing(line: string, discardComments = true) {
    if (discardComments && line.trim().startsWith("#")) {
      return false
    }
    const doNotParseRegexes = [
      // This list should contain regexes that match lines that should not be parsed. Make the regexes as specific as
      // possible. These lines may pop-up in logcat files, for examples.
      /^[A-Za-z0-9()? ;]{4,}$/,
      /^route_id: .*, route_length: .*, num_arcs: /,
      /^[A-Za-z0-9_.() ]+[=:][A-Za-z0-9_.:/@() -]*$/, // A single 'assignment' can be skipped.
      /^Route#\d+ summary:$/,
      /^Leg#\d+ summary:$/,
      /^Summary\((\w+=[A-Za-z0-9_.:()[\] -]+,?\s*)+\)$/,
      /dih\/\d+\/enc\/session-code/,
      /auth\/token(\?.*)?$/,
      /^TomTom-Api-Version: \d+$/
    ]
    return doNotParseRegexes.reduce((acc, regex) => acc && !regex.test(line), true)
  }

  /**
   * This function checks is a line is understood, but discarded from the results.
   * @param line The line to check.
   * @returns True if the line should be discarded, false otherwise.
   */
  static lineShouldBeRecognized(line: string) {
    const noNeedToRecognizeRegexes = [
      // Typical log file line: these should be parsed, but not lead to errors.
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}((\+\d{4})|Z)[ \t][iIwWeE][ \t]/,

      // Discard these URLs.
      /maps\/orbis\/places\/additionalData\.json/,

      // Discard NDS.Classic URLs for now, if they were not recognized.
      /nds-updates\.tomtom\.com\/.*\/PGROUPS/,
      /nds-updates\.tomtom\.com\/.*\/PRODUCT.NUC/,
      /nds-test\/updates\/\d+\/fetch/
    ]
    return noNeedToRecognizeRegexes.reduce((acc, regex) => acc && !regex.test(line), true)
  }

  static lineUsesCdn(line: string) {
    const typicalCdnRegexes = [
      // Typical CDN urls:
      /https?:\/\/[a-z0-9.]+\.akamaized\.net\//,
      /https?:\/\/[a-z0-9.]+\.azureedge\.net\//,
      /https?:\/\/[a-z0-9.]+\.cdn\./,
      /https?:\/\/[a-z0-9.]+\.cloudflare\.net\//,
      /https?:\/\/[a-z0-9.]+\.cloudfront\.net\//
    ]
    return typicalCdnRegexes.reduce((acc, regex) => acc || regex.test(line), false)
  }

  protected createRectangle(
    southWest: LngLat,
    northEast: LngLat,
    color: ParsedItemColor,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature<Polygon> {
    const bounds = new BoundingBox(southWest, northEast)
    const extendedMetadata = {
      ...metadata,
      ...(time && {time: time.getTime()}),
      ...{sizeInBytes: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: this.layerType,
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    const northWest: LngLat = {lng: southWest.lng, lat: northEast.lat}
    const southEast: LngLat = {lng: northEast.lng, lat: southWest.lat}
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            LngLatToArray(southWest),
            LngLatToArray(northWest),
            LngLatToArray(northEast),
            LngLatToArray(southEast),
            LngLatToArray(southWest)
          ]
        ]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: this.layerType,
        geoHash: bounds.toGeoHash(),
        ...color
      }
    }
  }

  protected createPointFromCoordinates(
    coordinate: PointXY,
    color: ParsedItemColor,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature<Point> {
    if (metadata.httpStatusCode && !isHttpCodeNoError(metadata.httpStatusCode) && isParsedPointColor(color)) {
      color = this.modifyPointColorToErrorState(color as ParsedPointColor)
    }
    const bounds = BoundingBox.fromLngLat(pointXYToLngLat(coordinate))
    const extendedMetadata = {
      ...metadata,
      layer: this.layerType,
      ...(time && {time: time.getTime()}),
      ...(sizeInBytes && {sizeInBytes: sizeInBytes}),
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [coordinate.x, coordinate.y]
      },
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: this.layerType,
        geoHash: bounds.toGeoHash(),
        ...color
      }
    }
  }

  protected modifyPointColorToErrorState(color: ParsedPointColor): ParsedPointColor {
    return {
      ...color,
      "circle-color": "rgb(255,0,0)",
      text: "!!",
      "text-color": "rgb(255,255,255)"
    }
  }

  protected modifyPolygonColorToErrorState(color: ParsedPolygonColor): ParsedPolygonColor {
    return {
      ...color,
      "fill-opacity": 1,
      "fill-color": "rgb(255,0,0)",
      "fill-outline-color": "rgb(255,0,0)"
    }
  }

  /**
   * Get a date/time from a line, in various formats.
   * @param line Input line.
   * @returns Date object or undefined if no date was found.
   */
  protected getDateTimeFromString(line: string) {
    // Try regular formats.
    const dateTime = getDateTimeFromTimestamp(line)
    if (dateTime) {
      return dateTime
    }

    // This matches, for example, Scalyr exported TXT and CSV files.
    const regexTimestamp = /["']timestamp[a-z"': ]*?:.*?([0-9.]+)/
    let match = regexTimestamp.exec(line)
    if (match) {
      const millisOrSeconds = parseFloat(match[1])
      return convertMillisToDateTime(millisOrSeconds)
    }

    // This matches any time in seconds after epoch in a bit more fuzzy way. It assumes a number
    // which can be seconds or milliseconds after epoch. It auto-detects which one it is.
    const regexTimeAsMillisOrSeconds = /time[a-z"': ]*?:.*?([0-9.]+)/
    match = regexTimeAsMillisOrSeconds.exec(line)
    if (match) {
      const millisOrSeconds = parseFloat(match[1])
      return convertMillisToDateTime(millisOrSeconds)
    }
    return undefined
  }

  protected getSizeInBytesFromLine(line: string): number | undefined {
    // Format 1: timestamp;httpStatus;responseSizeInBytes;durationInMilliseconds;url <-- old format
    // Format 2: timestamp;httpStatus;responseSizeInBytes;requestSizeInBytes;durationInMilliseconds;url
    const regexHttpLogWithResponseSizeOnly = new RegExp(
      `${this.regexIsoTimestamp.source};${this.regexInteger.source};(${this.regexInteger.source});${this.regexInteger.source};${this.regexUrl.source}`
    )
    let match = regexHttpLogWithResponseSizeOnly.exec(line)
    if (match) {
      const responseSizeInBytes = parseInt(match[1].trim())
      return responseSizeInBytes >= 0 ? responseSizeInBytes : undefined
    }

    const regexHttpLogWithResponseAndRequestSize = new RegExp(
      `${this.regexIsoTimestamp.source};${this.regexInteger.source};(${this.regexInteger.source});(${this.regexInteger.source});${this.regexInteger.source};${this.regexUrl.source}`
    )
    match = regexHttpLogWithResponseAndRequestSize.exec(line)
    if (match) {
      const responseSizeInBytes = parseInt(match[1].trim())
      const requestSizeInBytes = parseInt(match[2].trim())
      return responseSizeInBytes >= 0 || requestSizeInBytes >= 0
        ? Math.max(0, responseSizeInBytes) + Math.max(0, requestSizeInBytes)
        : undefined
    }

    const regexMitmLog = /;(\d+);[^;]*;[^;]*$/
    match = regexMitmLog.exec(line)
    if (match) {
      return parseInt(match[1])
    }

    const regexScalyrLog = /body_bytes_sent[^\d]*(\d+)/
    match = regexScalyrLog.exec(line)
    if (match) {
      return parseInt(match[1])
    }

    const regexOtherSizes = /(?:(?:size)|(?:bytes))\s*[:=]\s*(\d+)/
    match = regexOtherSizes.exec(line)
    if (match) {
      return parseInt(match[1])
    }
    return undefined
  }

  protected getHttpStatusCodeString(line: string) {
    const validCodeOrUndefined = (code: number) => (100 <= code && code <= 599 ? code : undefined)

    const regexHttpLog = new RegExp(`${this.regexIsoTimestamp.source}\\s*;\\s*-?(\\d+)\\s*;`)
    let match = regexHttpLog.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }

    const regexMitmLog = /;\s*(\d+)\s*;[^;]*;[^;]*;[^;]*;[^;]*;[^;]*$/
    match = regexMitmLog.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }

    const regexStatusField = /status[A-Za-z-_]*\s*["']?\s*[:=]\s*['"]?(\d+)/
    match = regexStatusField.exec(line)
    if (match) {
      return validCodeOrUndefined(parseInt(match[1]))
    }
    return undefined
  }
}

export default Parser
