/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Position} from "geojson"
import Storage from "../storage"
import FileUtils from "./fileUtils"
import Logger from "../logger"
import InvalidApiKeyException from "../../exceptions/invalidApiKeyException"

/**
 * This is a utility class to handle server routing requests.
 */
export class RoutingUtils {
  private static readonly apiVersionGenesis = 1
  private static readonly baseUrlGenesis = `https://api.tomtom.com/routing/${RoutingUtils.apiVersionGenesis}/calculateRoute`

  private static readonly apiVersionOrbis = 2
  private static readonly baseUrlOrbis = "https://api.tomtom.com/maps/orbis/routing/calculateRoute"

  /**
   * Request a route calculation from the server. The result is passed to the onResult callback.
   *
   * @param points  These points define the route "legs".
   * @param onResult The callback function to handle the routing response.
   * @param useOrbis If true, use the Orbis routing API, otherwise use the Genesis routing API.
   * Genesis routing, these parameters are converted as needed.
   */
  static apiRequestCalculateRouteForCarWithDefaults(
    points: Position[],
    onResult: (jsonRoutingResponse: any) => void,
    useOrbis = true
  ) {
    const parameters = [
      "travelMode=car",
      `routeType=${useOrbis ? "fast" : "fastest"}`,
      `traffic=${useOrbis ? "historical" : "false"}`,
      "extendedRouteRepresentation=distance",
      "extendedRouteRepresentation=travelTime",
      "sectionType=carTrain",
      "sectionType=ferry",
      "sectionType=tunnel",
      "sectionType=motorway",
      "sectionType=pedestrian",
      "sectionType=tollRoad",
      "sectionType=toll",
      "sectionType=tollVignette",
      "sectionType=travelMode",
      "sectionType=carpool",
      "sectionType=urban",
      "sectionType=unpaved",
      "sectionType=lowEmissionZone",
      "sectionType=speedLimit"

      // We may consider adding the following parameters:
      // "sectionType=country",
      // "sectionType=traffic",
      // "avoid=unpavedRoads",
    ]

    // Create routing URL.
    let url =
      `${useOrbis ? RoutingUtils.baseUrlOrbis : RoutingUtils.baseUrlGenesis}/` +
      points.map((point) => `${point[1]},${point[0]}`).join(":") +
      // Add response type.
      "/json" +
      // Add API key.
      `?key=${Storage.getTomTomApiKey()}` +
      // Add API version.
      `${useOrbis ? "&apiVersion=" + RoutingUtils.apiVersionOrbis : ""}` +
      // Add additional parameters.
      `&${parameters.join("&")}`
    Logger.log.info(`Routing request: ${url}`)

    // Request routing response.
    // url =
    //   "https://api.tomtom.com/maps/orbis/routing/calculateRoute/52.3676,4.9041:48.8566,2.3522:48.8207,3.2155:51.1354,5.5062/json?apiVersion=2&key=fA9GcAjsX4W8MAeGbQApW5JJVMq3s8Go&sectionType=carTrain&sectionType=ferry&sectionType=tunnel&sectionType=motorway&sectionType=pedestrian&sectionType=toll&sectionType=tollVignette&sectionType=country&sectionType=travelMode&sectionType=traffic&sectionType=carpool&sectionType=urban&sectionType=unpaved&sectionType=lowEmissionZone&sectionType=speedLimit&sectionType=roadShields&extendedRouteRepresentation=distance&extendedRouteRepresentation=travelTime"
    FileUtils.requestJsonFile(
      url,
      (jsonRoutingResponse) => {
        if (jsonRoutingResponse.error || !("routes" in jsonRoutingResponse)) {
          Logger.log.warning(`Error calculating route: ${JSON.stringify(jsonRoutingResponse)}`)
          return
        }
        if (jsonRoutingResponse.routes.length <= 0) {
          Logger.log.info(`No route found: ${JSON.stringify(jsonRoutingResponse)}`)
          return
        }
        onResult(jsonRoutingResponse)
      },
      () => {
        Logger.log.error(`Authorization problem - clearing API keys.`)
        throw new InvalidApiKeyException()
      },
      () => Logger.log.error(`Error requesting: ${url}`)
    )
  }
}

export default RoutingUtils
