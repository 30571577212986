/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * This is a utility class with static functions only.
 */
export class MathUtils {
  static degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180)
  }

  static radiansToDegrees(radians: number): number {
    return radians * (180 / Math.PI)
  }

  /**
   * Limit a value to a certain range.
   * @param value Value to limit.
   * @param min Minimum value.
   * @param max Maximum value.
   */
  static limitValue(value: number, min: number, max: number): number {
    return Math.min(max, Math.max(min, value))
  }
}

export default MathUtils
