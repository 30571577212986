/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"
import {BoundingBox} from "../common/utils/geoUtils"
import {Settings} from "../global/settings"
import {HTML_INSPECTOR_BUTTONS} from "../html/htmlElementId"
import {HTML_CLASS_INSPECTOR_BUTTON, HTML_CLASS_INSPECTOR_JSON_OBJECT} from "../html/htmlClassId"
import PersistentStorage from "../persistence/persistentStorage"
import Html from "../html/html"
import {Feature} from "geojson"
import Logger from "../common/logger"
import {Event} from "../app/timeline"
import {DateTimeUtils} from "../common/utils/dateTimeUtils"
import {HttpUtils} from "../common/utils/httpUtils"
import LogcatUtils from "../common/utils/logcatUtils"
import StringUtils from "../common/utils/stringUtils"

/**
 * This class defines the inspector window that displays detailed information about objects.
 * It tries to interpret the object and format it in a human readable way. If interpretation
 * fails, a simple JSON notation of the object is used.
 */
export class InspectorWindow extends HtmlWindow {
  private readonly htmlContentElementId: string
  private readonly onGetMetadata: (feature?: Feature) => any
  private readonly onDisplayOnMap: (feature?: Feature) => void
  private readonly onSetSource: (fileId: string, lineNumber: number) => void

  private readonly showFullObject = false

  private currentTitle: string = ""
  private items: any[] = []
  private currentIndex = 0
  private currentMetadata: any

  constructor(
    title: string,
    htmlWindowElementId: string,
    htmlWindowTitleElementId: string,
    htmlContentElementId: string,
    htmlButtonsElementId: string,
    onGetMetadata: (feature?: Feature) => any,
    onDisplayOnMap: (feature?: Feature) => void,
    onSetSource: (fileId: string, lineNumber: number) => void
  ) {
    super(title, htmlWindowElementId, htmlWindowTitleElementId)

    const htmlIdPrevious = "inspector-previous"
    const htmlIdNext = "inspector-next"

    this.htmlContentElementId = htmlContentElementId
    this.onGetMetadata = onGetMetadata
    this.onDisplayOnMap = onDisplayOnMap
    this.onSetSource = onSetSource

    const buttons = document.createElement("span")
    buttons.id = HTML_INSPECTOR_BUTTONS
    buttons.innerHTML =
      `<span class=${HTML_CLASS_INSPECTOR_BUTTON} id=${htmlIdPrevious} style="margin-left: 5px; margin-right: 5px"><--</span>` +
      `<span class=${HTML_CLASS_INSPECTOR_BUTTON} id=${htmlIdNext}>--></span>`
    const content = Html.getDefinedHtmlElementById(htmlButtonsElementId)
    content.appendChild(buttons)

    const previous = Html.getDefinedHtmlElementById(htmlIdPrevious)
    previous.addEventListener("click", () =>
      this.selectAndDraw((this.currentIndex - 1 + this.items.length) % this.items.length)
    )
    const next = Html.getDefinedHtmlElementById(htmlIdNext)
    next.addEventListener("click", () => this.selectAndDraw((this.currentIndex + 1) % this.items.length))
  }

  /**
   * Show the inspector window with the given objects.
   * @param title Title of the inspector window.
   * @param items Features to show in the inspector window.
   */
  show(title: string, items: any[]) {
    this.currentTitle = title
    this.items = items
    this.onDisplayOnMap(undefined) // Remove previous selection from map.
    this.setVisible() // Show the inspector window.
    this.selectAndDraw(0) // Select and draw the first object (if there are multiple).
  }

  public update() {
    const content = Html.getDefinedHtmlElementById(this.htmlContentElementId)
    content.innerHTML = ""

    // Add previous and next buttons if there is more than one object.
    const buttons = Html.getDefinedHtmlElementById(HTML_INSPECTOR_BUTTONS)
    if (this.items.length >= 2) {
      buttons.classList.remove("hide")
      buttons.classList.add("show")
    } else {
      buttons.classList.remove("show")
      buttons.classList.add("hide")
    }

    if (this.items.length > 0) {
      const element = document.createElement("span")
      const selectedHtml =
        this.items.length > 1 ? `<strong>Showing: ${this.currentIndex + 1} of ${this.items.length}</strong>\n` : ""
      const titleHtml = `${this.currentTitle.length > 0 ? "<h2>" + this.currentTitle + "</h2>\n" : "\n"}${selectedHtml}`
      const metadataHtml = `${this.currentMetadata ? this.formatMetadata(this.currentMetadata) + "\n" : ""}`
      const objectHtml = `<span class="${HTML_CLASS_INSPECTOR_JSON_OBJECT}">${this.formatObjectWithoutQuotes(this.items[this.currentIndex])}</span>`
      element.innerHTML = `${titleHtml}${metadataHtml}${this.showFullObject ? objectHtml : ""}`
      content.appendChild(element)
    }
  }

  private readonly isFeature = (item: any): boolean => "type" in item && item.type === "Feature"

  private selectAndDraw(index: number) {
    this.currentIndex = index
    this.currentMetadata =
      this.items.length > 0 && this.isFeature(this.items[this.currentIndex])
        ? this.onGetMetadata(this.items[this.currentIndex] as Feature)
        : undefined

    // Create the contents of the inspector window.
    this.update()
    window.scrollTo({top: 0})

    // Draw the selected object on the map.
    if (this.items.length > 0 && this.isFeature(this.items[this.currentIndex])) {
      // Show a feature that can be on the map.
      const item = this.items[this.currentIndex] as Feature
      Logger.log.info(`\nSelected feature:\n${JSON.stringify(item)}`)
      this.onDisplayOnMap(item)
      const metadata = this.onGetMetadata(item)
      if (metadata.file && metadata.lineNumber !== undefined) {
        this.onSetSource(metadata.file, metadata.lineNumber)
      }
    } else {
      // Show a feature that cannot be on the map.
      const item = this.items[this.currentIndex] as Event
      Logger.log.info(`\nSelected event:\n${JSON.stringify(item)}`)
      const metadata = this.items[this.currentIndex].metadata
      if (metadata.file && metadata.lineNumber !== undefined) {
        this.onSetSource(metadata.file, metadata.lineNumber)
      }
    }
  }

  private jsonAsRedactedString(object: any) {
    return object
      ? JSON.stringify(object, undefined, 2)
          .split("\n")
          .map((line) => StringUtils.redactedString(line))
          .join("\n")
      : "undefined"
  }

  private formatObjectWithoutQuotes(object: any): string {
    return `${object ? this.jsonAsRedactedString(object).slice(1, -1) : "undefined"}`
  }

  private formatMetadata(item: any): string {
    const table = (value: string) => `<table style="border-collapse:collapse" >${value}</table>`
    const row = (type: number, col1: any, col2: any) =>
      `<tr><td class="${Html.htmlClassIdForInspectorTableHeader(type)}">${col1?.toString()}:</td>` +
      `<td class="${Html.htmlClassIdForInspectorTableValue(type)}">${col2?.toString()}</td></tr>`
    const duration = (value: number) => `${DateTimeUtils.formatTimeAsDuration(value)} (${value})`
    const remove = (metadata: any, field: string) => {
      delete metadata?.[field]
      return metadata
    }

    // Use a temporary record to remove fields to not duplicate that are represented in a table.
    let remaining
    if (item.message) {
      remaining = structuredClone(item.message)
    } else {
      remaining = structuredClone(item)
    }

    let rows = ""
    if (remaining.duplicates) {
      rows += row(1, "Duplicates", remaining.duplicates)
      remaining = remove(remaining, "duplicates")
    }
    rows += row(
      1,
      "Time",
      remaining.time
        ? `${DateTimeUtils.formatDateWithTime(new Date(remaining.time), Settings.optionTimeFormat)}`
        : "missing"
    )
    remaining = remove(remaining, "time")

    if (remaining.type) {
      rows += row(1, "Type", remaining.type)
      remaining = remove(remaining, "type")
    }
    if (remaining.layer) {
      rows += row(1, "Layer", remaining.layer)
      remaining = remove(remaining, "layer")
    }
    if (remaining.httpStatusCode) {
      rows += row(
        1,
        "HTTP",
        `${remaining.httpStatusCode} (${HttpUtils.getName(remaining.httpStatusCode)?.toUpperCase() ?? "(UNKNOWN)"})`
      )
      remaining = remove(remaining, "httpStatusCode")
    }
    if (remaining.logLevel) {
      rows += row(1, "Log level", LogcatUtils.getHumanReadableLogLevel(remaining.logLevel))
      remaining = remove(remaining, "logLevel")
    }
    if (remaining.sizeInBytes !== undefined) {
      rows += row(
        1,
        "Size",
        remaining.sizeInBytes === -1
          ? "(missing)"
          : `${StringUtils.formatSizeInBytes(remaining.sizeInBytes)}<small> (+${StringUtils.formatSizeInBytes(PersistentStorage.getHttpOverheadSizeInBytes())})</small>`
      )
      remaining = remove(remaining, "sizeInBytes")
    }
    if (remaining.usesCdn !== undefined) {
      rows += row(1, "Uses CDN", "true")
      remaining = remove(remaining, "usesCdn")
    }

    if (remaining.monotonicTime) {
      rows += row(1, "Monotonic", duration(remaining.monotonicTime))
      remaining = remove(remaining, "monotonicTime")
    }
    if (remaining.tileLevel !== undefined) {
      rows += row(1, "Level", remaining.tileLevel)
      remaining = remove(remaining, "tileLevel")
    }
    if (remaining.tileX !== undefined) {
      rows += row(1, "X", remaining.tileX)
      remaining = remove(remaining, "tileX")
    }
    if (remaining.tileY !== undefined) {
      rows += row(1, "Y", remaining.tileY)
      remaining = remove(remaining, "tileY")
    }
    if (remaining.tileId) {
      rows += row(1, "ID", remaining.tileId)
      remaining = remove(remaining, "tileId")
    }
    if (remaining.bounds) {
      const box = remaining.bounds as BoundingBox
      rows += row(
        1,
        "Bounds",
        `<small>(${box.southWest.lng.toFixed(4)}, ${box.southWest.lat.toFixed(4)}), (${box.northEast.lng.toFixed(4)}, ${box.northEast.lat.toFixed(4)})</small>`
      )
      remaining = remove(remaining, "bounds")
    }
    if (remaining.geoHash) {
      rows += row(1, "Geo hash", `<small>${remaining.geoHash}</small>`)
      remaining = remove(remaining, "geoHash")
    }
    if (remaining.lineNumber) {
      rows += row(1, "Line", remaining.lineNumber)
      remaining = remove(remaining, "lineNumber")
    }

    let line = remaining.line
    if (remaining.line) {
      remaining = remove(remaining, "line")
    }
    const tableKnownMetadata = table(rows)

    rows = ""
    if (Object.keys(remaining).length !== 0) {
      const metadataRows = Object.keys(remaining)
        .filter((key) => key !== "feature")
        .map((key) => row(2, StringUtils.capitalizeFirstLetter(key), `${this.jsonAsRedactedString(remaining[key])}`))
      if (metadataRows.length > 0) {
        rows += row(2, "METADATA", "")
        rows += metadataRows.join("\n")
      }
    }
    const tableOtherMetadata = table(rows)

    return (
      tableKnownMetadata +
      (line ? `<div class="${HTML_CLASS_INSPECTOR_JSON_OBJECT}">${this.formatObjectWithoutQuotes(line)}</div>` : "") +
      tableOtherMetadata
    )
  }
}

export default InspectorWindow
