/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import assert from "./assert"

export class CriticalSection {
  private inCriticalSection = false

  async enterCriticalSection(criticalSection: () => void | Promise<void>) {
    const inCriticalSection = this.inCriticalSection
    this.inCriticalSection = true
    if (inCriticalSection) {
      return
    }
    await criticalSection()
  }

  public leaveCriticalSection() {
    assert(this.inCriticalSection)
    this.inCriticalSection = false
  }
}

export default CriticalSection
