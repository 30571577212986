/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {isParsedPolygonColor, LayerLineParser, LayerType, ParsedPolygonColor} from "../parserTypes"
import {TileXY} from "../../common/utils/geoUtils"
import {Feature} from "geojson"
import LineParser from "./lineParser"
import Logger from "../../common/logger"
import {HttpUtils} from "../../common/utils/httpUtils"
import Wgs84Utils from "../../common/utils/wgs84Utils"

export class LineParserNavTiles extends LineParser {
  private static readonly parsers: Partial<Record<LayerType, LayerLineParser>> = {
    [LayerType.Nk2NavTiles]: {
      name: "NK2 NavTiles",
      layerType: LayerType.Nk2NavTiles,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(158,98,10)",
        "fill-outline-color": "rgb(243,134,9)"
      },
      regexWithLocation: "/+navkit2navigation/+.*?/+navigationtile/+{x}/+{y}"
    },
    [LayerType.Nk2LaneTiles]: {
      name: "NK2 LaneTiles",
      layerType: LayerType.Nk2NavTiles,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(71,136,8)",
        "fill-outline-color": "rgb(126,237,6)"
      },
      regexWithLocation: "/+navkit2navigation/+.*?/+lanetile/+{x}/+{y}"
    }
  }

  constructor(layerType: LayerType) {
    super(
      layerType,
      LineParserNavTiles.parsers[layerType]!.name,
      LineParserNavTiles.parsers[layerType]!.color,
      LineParserNavTiles.parsers[layerType]!.regexWithLocation,
      LineParserNavTiles.parsers[layerType]!.regexWithoutLocation
    )
  }

  parseLine(fileId: string, lineNumber: number, line: string): Feature[] {
    line = this.decodeIfLineIsURI(line)
    let features: Feature[] = []
    const time = this.getDateTimeFromAnyString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const usesCdn = LineParser.isLineCdnUrl(line)
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation.replace("{x}", "([0-9.]+)").replace("{y}", "([0-9.]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const indexes = {
          x: this.regexWithLocation.indexOf("{x}"),
          y: this.regexWithLocation.indexOf("{y}")
        }
        const tile: TileXY = {x: 0, y: 0}
        const sortedKeys = Object.keys(indexes).sort(
          (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
        )
        sortedKeys.forEach((key, i) => {
          const value = parseInt(match[i + 1])
          switch (key) {
            case "x":
            case "y":
              tile[key] = value
              break
          }
        })
        const metadata = {
          file: fileId,
          lineNumber: lineNumber,
          line: line,
          ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
          ...(usesCdn && {usesCdn: true}),
          tileLevel: 13,
          tileX: tile.x,
          tileY: tile.y
        }
        const polygon = this.createFeatureFromNavTilesTile(tile, metadata, time, sizeInBytes)
        features.push(polygon)
        from = from + match.index + match[0].length
      }
    }
    return features
  }

  private createFeatureFromNavTilesTile(tile: TileXY, metadata: any, time?: Date, sizeInBytes?: number): Feature {
    let color = this.color
    if (metadata.httpStatusCode && !HttpUtils.isOk(metadata.httpStatusCode) && isParsedPolygonColor(color)) {
      color = this.modifyPolygonColorToErrorState(
        color as ParsedPolygonColor,
        HttpUtils.isError(metadata.httpStatusCode)
      )
    }
    const tileLevel = 13
    const sizeX = 1 << (tileLevel + 1)
    const sizeY = 1 << tileLevel
    const degreesPerTileUnitX = 360.0 / sizeX
    const degreesPerTileUnitY = 180.0 / sizeY

    const leftLon = -180.0 + tile.x * degreesPerTileUnitX
    const rightLon = -180.0 + (tile.x + 1) * degreesPerTileUnitX
    const topLat = 90.0 - tile.y * degreesPerTileUnitY
    const bottomLat = 90.0 - (tile.y + 1) * degreesPerTileUnitY
    if (
      !Wgs84Utils.isValidLat(topLat) ||
      !Wgs84Utils.isValidLat(bottomLat) ||
      !Wgs84Utils.isValidLng(leftLon) ||
      !Wgs84Utils.isValidLng(rightLon)
    ) {
      Logger.log.error(`Invalid lat/lon: (${leftLon}, ${bottomLat}), (${rightLon}, ${topLat})`)
      return this.createFeatureWithSizeWithoutCoordinates(metadata, time, sizeInBytes)
    }
    const southWest = {lng: leftLon, lat: bottomLat}
    const northEast = {lng: rightLon, lat: topLat}

    return this.createFeatureFromRectangle(southWest, northEast, color, metadata, time, sizeInBytes)
  }
}

export default LineParserNavTiles
