/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {
  isParsedPointColor,
  isParsedPolygonColor,
  LayerLineParser,
  LayerType,
  ParsedItemColor,
  ParsedPointColor,
  ParsedPolygonColor
} from "./parserTypes"
import {TileXYZ} from "../common/geo"
import {Feature} from "geojson"
import {isValidLat, isValidLng, mercatorToLatitude, mercatorToLongitude} from "../common/wgs84"
import {MetadataStore} from "../common/metadata"
import {GlobalSettings} from "../app/globalSettings"
import {isHttpCodeNoError} from "../common/httpCodes"
import LogWindow from "../app/logWindow"
import LineParser from "./lineParser"
import {TIMESTAMP_ALWAYS_HIDE} from "../app/dataStore"
import Parser from "./parser"
import {formatDateWithTime, TimeFormat} from "../common/datetime"

export class ParserMapVis extends LineParser {
  private static readonly parsers: Partial<Record<LayerType, LayerLineParser>> = {
    [LayerType.MapVis3D]: {
      name: "3D",
      layerType: LayerType.MapVis3D,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(20,99,110)",
        "fill-outline-color": "rgb(109,205,176)"
      },
      regexWithLocation: "/+map-display/+tile/+3d/+[A-Za-z_-]+/+{z}/+{x}/+{y}.(glb|gltf)"
    },
    [LayerType.MapVisBasicMap]: {
      name: "Basic map",
      layerType: LayerType.MapVisBasicMap,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(139,0,253)",
        "fill-outline-color": "rgb(188,88,191)"
      },
      regexWithLocation:
        "(?:(?:/+maps/+[A-Za-z_-]+/+map-display/+tile)|(?:/+map/+[0-9]+/+tile/+basic))/+{z}/+{x}/+{y}.pbf"
    },
    [LayerType.MapVisFlow]: {
      name: "Flow",
      layerType: LayerType.MapVisFlow,
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(0,253,33)",
        "fill-outline-color": "rgb(86,165,126)"
      },
      regexWithLocation: "/+traffic/+tile/+flow/+{z}/+{x}/+{y}.pbf"
    },
    [LayerType.MapVisHillshade]: {
      name: "Hillshade",
      layerType: LayerType.MapVisHillshade,
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(4,83,65)",
        "fill-outline-color": "rgb(3,170,120)"
      },
      regexWithLocation:
        "(?:(?:/+map-display/+tile/+hillshade)|(?:/+map/+[0-9]+/+tile/+hill/+main))/+{z}/+{x}/+{y}.(png|jpe?g)"
    },
    [LayerType.MapVisIncidents]: {
      name: "Incidents",
      layerType: LayerType.MapVisIncidents,
      color: {
        "fill-opacity": 0.1,
        "fill-color": "rgb(0,90,255)",
        "fill-outline-color": "rgb(13,67,166)"
      },
      regexWithLocation:
        "(?:(?:/+traffic/+tile/+incidents)|(?:/+traffic/+map/+[0-9]+/+tile/+incidents))/+{z}/+{x}/+{y}.pbf"
    },
    [LayerType.MapVisSatellite]: {
      name: "Satellite",
      layerType: LayerType.MapVisSatellite,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(191,113,71)",
        "fill-outline-color": "rgb(234,98,0)"
      },
      regexWithLocation: "/+map-display/+tile/+satellite/+{z}/+{x}/+{y}.(png|jpe?g)"
    },
    [LayerType.MapVisStyle]: {
      name: "Style/assets",
      layerType: LayerType.MapVisStyle,
      color: {
        "fill-opacity": 0.2,
        "fill-color": "rgb(20,110,83)",
        "fill-outline-color": "rgb(109,205,125)"
      },
      regexWithoutLocation:
        "(?:/+style/+[0-9]+/+[A-Za-z_-]+/+)|(?:/+maps/+[A-Za-z_-]+/+assets/+)|(?:/+map-display/+non-tile/+)"
    }
  }

  constructor(
    logWindow: LogWindow,
    globalSettings: GlobalSettings,
    metadataStore: MetadataStore,
    layerType: LayerType
  ) {
    super(
      logWindow,
      globalSettings,
      metadataStore,
      layerType,
      ParserMapVis.parsers[layerType]!.name,
      ParserMapVis.parsers[layerType]!.color,
      ParserMapVis.parsers[layerType]!.regexWithLocation,
      ParserMapVis.parsers[layerType]!.regexWithoutLocation
    )
  }

  parseLine(lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []
    const time = this.getDateTimeFromString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const usesCdn = Parser.lineUsesCdn(line)
    const metadata = {
      lineNumber: lineNumber,
      line: line,
      ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
      ...(usesCdn && {usesCdn: true})
    }
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation
        .replace("{x}", "([0-9.]+)")
        .replace("{y}", "([0-9.]+)")
        .replace("{z}", "([0-9.]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const indexes = {
          x: this.regexWithLocation.indexOf("{x}"),
          y: this.regexWithLocation.indexOf("{y}"),
          z: this.regexWithLocation.indexOf("{z}")
        }
        const tile: TileXYZ = {x: 0, y: 0, z: 0}
        const sortedKeys = Object.keys(indexes).sort(
          (a, b) => indexes[a as keyof typeof indexes] - indexes[b as keyof typeof indexes]
        )
        sortedKeys.forEach((key, i) => {
          const value = parseInt(match[i + 1])
          switch (key) {
            case "x":
            case "y":
            case "z":
              tile[key] = value
              break
          }
        })
        const metadataWithTile = {
          ...metadata,
          tileX: tile.x,
          tileY: tile.y,
          tileLevel: tile.z
        }
        const polygon = this.createPolygonFromMapVisTile(tile, this.color, metadataWithTile, time, sizeInBytes)
        if (!polygon) {
          return features
        }
        features.push(polygon)
        from = from + match.index + match[0].length
      }
    }
    if (features.length === 0 && this.regexWithoutLocation) {
      const match = RegExp(new RegExp(this.regexWithoutLocation)).exec(line)
      if (match) {
        const featureWithoutTile = this.createFeatureWithoutTile(metadata, time, sizeInBytes)
        features.push(featureWithoutTile)
      }
    }
    return features
  }

  private createFeatureWithoutTile(metadata: any, time?: Date, sizeInBytes?: number): Feature {
    let color = this.color
    if (metadata.httpStatusCode && !isHttpCodeNoError(metadata.httpStatusCode) && isParsedPointColor(color)) {
      color = this.modifyPointColorToErrorState(color as ParsedPointColor)
    }
    const extendedMetadata = {
      ...metadata,
      ...(time ? {time: time.getTime()} : {time: TIMESTAMP_ALWAYS_HIDE}),
      ...{sizeInBytes: sizeInBytes ?? Parser.SIZE_EXPECTED_BUT_NOT_FOUND},
      layer: this.layerType
    }
    const metadataKey = this.metadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [0, 0]
      },
      properties: {
        metadata: metadataKey,
        ...(time ? {time: time.getTime()} : {time: TIMESTAMP_ALWAYS_HIDE}),
        layer: this.layerType,
        ...color
      }
    }
  }

  private createPolygonFromMapVisTile(
    tile: TileXYZ,
    color: ParsedItemColor,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature | undefined {
    if (metadata.httpStatusCode && !isHttpCodeNoError(metadata.httpStatusCode) && isParsedPolygonColor(color)) {
      color = this.modifyPolygonColorToErrorState(color as ParsedPolygonColor)
    }
    const resolution = 1 << tile.z
    const leftLon = mercatorToLongitude(tile.x / resolution)
    const rightLon = mercatorToLongitude((tile.x + 1) / resolution)
    const topLat = mercatorToLatitude(tile.y / resolution)
    const bottomLat = mercatorToLatitude((tile.y + 1) / resolution)
    if (!isValidLat(topLat) || !isValidLat(bottomLat) || !isValidLng(leftLon) || !isValidLng(rightLon)) {
      this.logWindow.error(
        `Invalid lat/lon: ${time ? formatDateWithTime(new Date(time), TimeFormat.UTCTime) : ""}, (${leftLon}, ${bottomLat}), (${rightLon}, ${topLat})`
      )
      return undefined
    }
    const southWest = {lng: leftLon, lat: bottomLat}
    const northEast = {lng: rightLon, lat: topLat}
    return this.createRectangle(southWest, northEast, color, metadata, time, sizeInBytes)
  }
}

export default ParserMapVis
