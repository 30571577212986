/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageParseException} from "./ttpMessageParseException"
import {TtpMessageSensorBase} from "./ttpMessageSensorBase"
import {parseNumberMandatory, parseNumberOptional, TtpMessageType} from "./ttpMessage"

export enum MapMatcherResultIndex {
  LongitudeIndex = 2,
  LatitudeIndex = 3,
  AltitudeIndex = 4,
  HeadingIndex = 5,
  ProbabilityIndex = 8,
  ArcKeyIndex = 11,
  InputLongitudeIndex = 13,
  InputLatitudeIndex = 14,
  LocationSourceIndex = 15
}

export class TtpMessageSensorMapMatcherResult extends TtpMessageSensorBase {
  longitude: number
  latitude: number
  altitude?: number
  heading?: number
  probability?: number
  arcKey?: number
  inputLongitude?: number
  inputLatitude?: number
  locationSource?: number

  constructor(
    lineNumber: number,
    monotonicTime: number,
    message: number,
    longitude: number,
    latitude: number,
    altitude?: number,
    heading?: number,
    probability?: number,
    arcKey?: number,
    inputLongitude?: number,
    inputLatitude?: number,
    locationSource?: number
  ) {
    super(lineNumber, TtpMessageType.SensorMapMatcherResult, monotonicTime, message)
    this.longitude = longitude
    this.latitude = latitude
    this.altitude = altitude
    this.heading = heading
    this.probability = probability
    this.arcKey = arcKey
    this.inputLongitude = inputLongitude
    this.inputLatitude = inputLatitude
    this.locationSource = locationSource
  }

  static parse(
    lineNumber: number,
    parts: string[],
    monotonicTime: number,
    message: number
  ): TtpMessageSensorMapMatcherResult {
    const {
      LongitudeIndex,
      LatitudeIndex,
      AltitudeIndex,
      HeadingIndex,
      ProbabilityIndex,
      ArcKeyIndex,
      InputLongitudeIndex,
      InputLatitudeIndex,
      LocationSourceIndex
    } = MapMatcherResultIndex

    if (parts.length < LatitudeIndex) {
      throw new TtpMessageParseException(`Invalid map matcher result line (need at least lon, lat)`)
    }
    const longitude = parseNumberMandatory(parts[LongitudeIndex])
    const latitude = parseNumberMandatory(parts[LatitudeIndex])
    const altitude = parts.length > AltitudeIndex ? parseNumberOptional(parts[AltitudeIndex]) : undefined
    const bearing = parts.length > HeadingIndex ? parseNumberOptional(parts[HeadingIndex]) : undefined
    const accuracy = parts.length > ProbabilityIndex ? parseNumberOptional(parts[ProbabilityIndex]) : undefined
    const arcKey = parts.length > ArcKeyIndex ? parseNumberOptional(parts[ArcKeyIndex]) : undefined
    const inputLongitude =
      parts.length > InputLongitudeIndex ? parseNumberOptional(parts[InputLongitudeIndex]) : undefined
    const inputLatitude = parts.length > InputLatitudeIndex ? parseNumberOptional(parts[InputLatitudeIndex]) : undefined
    const locationSource =
      parts.length > LocationSourceIndex ? parseNumberOptional(parts[LocationSourceIndex]) : undefined
    return new TtpMessageSensorMapMatcherResult(
      lineNumber,
      monotonicTime,
      message,
      longitude,
      latitude,
      altitude,
      bearing,
      accuracy,
      arcKey,
      inputLongitude,
      inputLatitude,
      locationSource
    )
  }
}

export default TtpMessageSensorMapMatcherResult
