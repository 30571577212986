/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Panel from "./panel"
import DataCanvas from "../app/dataCanvas"
import Timeline from "../app/timeline"
import {HTML_FILTER_ALL, HTML_FILTER_EQ, HTML_FILTER_GE, HTML_FILTER_LE} from "../html/htmlElementId"
import {TileLevelFilter} from "../global/dataStore"
import Html from "../html/html"
import {Mutex} from "async-mutex"

export class TileLevelFiltersPanel extends Panel {
  public readonly update: () => void = this.create

  constructor(
    dataCanvas: DataCanvas,
    timeline: Timeline,
    onDraw: (inhibitAutoZoom?: boolean, onFinished?: () => void) => void
  ) {
    super(dataCanvas, timeline, onDraw)

    this.create()
    this.setupListeners()
  }

  private readonly mutexToggleOption = new Mutex()

  public toggleOption(tileLevel: number, useKeyboard = true) {
    this.mutexToggleOption.runExclusive(() => {
      if (useKeyboard) {
        switch (this.dataCanvas.tileLevelFilterState.tileLevelFilter) {
          case TileLevelFilter.All:
            if (this.dataCanvas.tileLevelFilterState.level === tileLevel) {
              this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.Equal
            } else {
              this.dataCanvas.tileLevelFilterState.level = tileLevel
            }
            break
          case TileLevelFilter.Equal:
            if (this.dataCanvas.tileLevelFilterState.level === tileLevel) {
              this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.GreaterEqual
            } else {
              this.dataCanvas.tileLevelFilterState.level = tileLevel
            }
            break
          case TileLevelFilter.GreaterEqual:
            if (this.dataCanvas.tileLevelFilterState.level === tileLevel) {
              this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.LessEqual
            } else {
              this.dataCanvas.tileLevelFilterState.level = tileLevel
            }
            break
          case TileLevelFilter.LessEqual:
            if (this.dataCanvas.tileLevelFilterState.level === tileLevel) {
              this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.All
            } else {
              this.dataCanvas.tileLevelFilterState.level = tileLevel
            }
            break
        }
      } else {
        if (this.dataCanvas.tileLevelFilterState.tileLevelFilter === TileLevelFilter.All) {
          this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.GreaterEqual
        }
        this.dataCanvas.tileLevelFilterState.level = tileLevel
      }
      this.update()
      this.onDraw(true, () => {})
    })
  }

  private setupListeners() {
    ;[HTML_FILTER_ALL, HTML_FILTER_EQ, HTML_FILTER_LE, HTML_FILTER_GE].forEach((id) => {
      Html.getDefinedHtmlElementById(id).addEventListener("click", () => {
        switch (id) {
          case HTML_FILTER_ALL:
            this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.All
            break
          case HTML_FILTER_EQ:
            this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.Equal
            break
          case HTML_FILTER_LE:
            this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.LessEqual
            break
          case HTML_FILTER_GE:
            this.dataCanvas.tileLevelFilterState.tileLevelFilter = TileLevelFilter.GreaterEqual
            break
        }
        this.update()
        this.onDraw(true)
      })
    })
    for (let i = 8; i <= 17; i++) {
      Html.getDefinedHtmlElementById(Html.htmlElementIdForFilterLevel(i)).addEventListener("click", () =>
        this.toggleOption(i, false)
      )
    }
  }

  private create() {
    const filterStates = this.dataCanvas.tileLevelFilterState
    const cellFilterAll = Html.getDefinedHtmlElementById(HTML_FILTER_ALL)
    cellFilterAll.classList.remove("off", "on")

    const cellFilterEq = Html.getDefinedHtmlElementById(HTML_FILTER_EQ)
    cellFilterEq.classList.remove("off", "on")

    const cellFilterLe = Html.getDefinedHtmlElementById(HTML_FILTER_LE)
    cellFilterLe.classList.remove("off", "on")

    const cellFilterGe = Html.getDefinedHtmlElementById(HTML_FILTER_GE)
    cellFilterGe.classList.remove("off", "on")

    for (let i = 8; i <= 17; i++) {
      const cell = Html.getDefinedHtmlElementById(Html.htmlElementIdForFilterLevel(i))
      cell.classList.remove("off", "on")
    }
    if (filterStates.tileLevelFilter !== TileLevelFilter.All) {
      const cellLevel = Html.getDefinedHtmlElementById(Html.htmlElementIdForFilterLevel(filterStates.level))
      cellLevel.classList.remove("off", "on")
      cellLevel.classList.add("on")
    }

    let cell: HTMLElement = cellFilterAll
    switch (filterStates.tileLevelFilter) {
      case TileLevelFilter.LessEqual:
        cell = cellFilterLe
        break
      case TileLevelFilter.Equal:
        cell = cellFilterEq
        break

      case TileLevelFilter.GreaterEqual:
        cell = cellFilterGe
        break
    }
    cell.classList.add("on")
  }
}

export default TileLevelFiltersPanel
