/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {MapMouseEvent} from "maplibre-gl"
import MapView from "./mapView"
import DataCanvas from "./dataCanvas"
import Timeline from "./timeline"
import MenuBar from "../menu/menuBar"
import {Settings} from "../global/settings"
import {GenericMenuItem, LayerMenuItem, MenuItem, separatorMenuItem} from "../menu/menuTypes"
import {isRealLayer, LayerType} from "../parsers/parserTypes"
import {HelpWindow} from "../windows/helpWindow"
import ReleaseNotesWindow from "../windows/releaseNotesWindow"
import InspectorWindow from "../windows/inspectorWindow"
import ConsoleWindow from "../windows/consoleWindow"
import {Toolbox} from "../tools/toolbox"
import assert from "../common/assert"
import ProgressWindow from "../windows/progressWindow"
import {DateTimeFormat} from "../common/utils/dateTimeUtils"
import {
  HTML_BUTTON_CLEAR_MAP,
  HTML_BUTTON_SAVE,
  HTML_BUTTON_SELECT_FILES,
  HTML_BUTTON_ZOOM_IN,
  HTML_BUTTON_ZOOM_OUT,
  HTML_DATA_INPUT,
  HTML_DATA_INPUT_CANCEL,
  HTML_DATA_INPUT_SUBMIT,
  HTML_DATA_INPUT_VALUE,
  HTML_MAP,
  HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW,
  HTML_MENU_ITEM_GPX_TIMESTAMPS,
  HTML_MENU_ITEM_SKIP_COMMENTS,
  HTML_MENU_ITEM_TILE_GRID,
  HTML_MENU_ITEM_URBAN_SECTIONS,
  HTML_MENU_ITEM_USE_LOCAL_TIME,
  HTML_MENUBAR,
  HTML_PROGRESS_BAR,
  HTML_TIMELINE,
  HTML_TOOLBOX,
  HTML_VERSION_MARKER,
  HTML_WINDOW_CONSOLE,
  HTML_WINDOW_CONSOLE_TITLE,
  HTML_WINDOW_CONTENT_HELP,
  HTML_WINDOW_CONTENT_INSPECTOR,
  HTML_WINDOW_CONTENT_PROGRESS,
  HTML_WINDOW_CONTENT_RELEASE_NOTES,
  HTML_WINDOW_HELP,
  HTML_WINDOW_HELP_TITLE,
  HTML_WINDOW_INSPECTOR,
  HTML_WINDOW_INSPECTOR_TITLE,
  HTML_WINDOW_PROGRESS,
  HTML_WINDOW_RELEASE_NOTES,
  HTML_WINDOW_RELEASE_NOTES_TITLE,
  HTML_WINDOW_SOURCE,
  HTML_WINDOW_SOURCE_TITLE,
  HTML_WINDOW_TITLE_BAR_INSPECTOR,
  HTML_YES_NO_DIALOG,
  HTML_YES_NO_DIALOG_NO,
  HTML_YES_NO_DIALOG_TEXT,
  HTML_YES_NO_DIALOG_YES
} from "../html/htmlElementId"
import {
  HTML_CLASS_PANEL,
  HTML_CLASS_PANEL_DRAG_BAR,
  HTML_CLASS_WINDOW,
  HTML_CLASS_WINDOW_TITLE_BAR
} from "../html/htmlClassId"
import Logger from "../common/logger"
import PersistentStorage from "../persistence/persistentStorage"
import {ExceptionHandler} from "./exceptionHandler"
import {Html} from "../html/html"
import LayersPanel from "../panels/layersPanel"
import DataFilesPanel from "../panels/dataFilesPanel"
import TileLevelFiltersPanel from "../panels/tileLevelFiltersPanel"
import SourceWindow from "../windows/sourceWindow"
import {Feature} from "geojson"
import {MetadataStore} from "../global/metadataStore"
import DataStore from "../global/dataStore"
import {CriticalSection} from "../common/criticalSection"

/**
 * This class defines the application. It sets up the entire application and supports an exception handler to catch exceptions during
 * execution.
 */
export class App {
  private readonly dataCanvas: DataCanvas
  private readonly mapView: MapView

  private readonly menuBar: MenuBar
  private readonly toolbox: Toolbox
  private readonly timeline: Timeline

  private readonly helpWindow: HelpWindow
  private readonly releaseNotesWindow: ReleaseNotesWindow
  private readonly inspectorWindow: InspectorWindow
  private readonly consoleWindow: ConsoleWindow
  private readonly sourceWindow: SourceWindow
  private readonly progressWindow: ProgressWindow

  private readonly tileLevelFiltersPanel: TileLevelFiltersPanel
  private readonly layersPanel: LayersPanel
  private readonly dataFilesPanel: DataFilesPanel

  private keyboardShortcutsEnabled = true
  private criticalSectionGuard = new CriticalSection()

  constructor() {
    // Install the exception handler.
    window.onerror = ExceptionHandler.handleException

    // Create the help window. This call is async but allowed here.
    this.helpWindow = new HelpWindow("Help", HTML_WINDOW_HELP, HTML_WINDOW_HELP_TITLE, HTML_WINDOW_CONTENT_HELP)
    this.helpWindow.loadContent()

    // Create the release notes window. This call is async but allowed here.
    this.releaseNotesWindow = new ReleaseNotesWindow(
      "Release notes",
      HTML_WINDOW_RELEASE_NOTES,
      HTML_WINDOW_RELEASE_NOTES_TITLE,
      HTML_WINDOW_CONTENT_RELEASE_NOTES
    )
    this.releaseNotesWindow.loadContent()

    // Create the progress window.
    this.progressWindow = new ProgressWindow(HTML_WINDOW_PROGRESS, HTML_WINDOW_CONTENT_PROGRESS, HTML_PROGRESS_BAR)

    // Create the map view and the data canvas (which uses the map view).
    this.mapView = new MapView("map", this.onClick, this.onContextMenu)
    this.dataCanvas = new DataCanvas(this.mapView, this.progressWindow)

    // Create the inspector window.
    this.inspectorWindow = new InspectorWindow(
      "Inspector",
      HTML_WINDOW_INSPECTOR,
      HTML_WINDOW_INSPECTOR_TITLE,
      HTML_WINDOW_CONTENT_INSPECTOR,
      HTML_WINDOW_TITLE_BAR_INSPECTOR,
      (feature?: Feature) => {
        const metadata = MetadataStore.retrieve(feature?.properties?.metadata)
        return metadata ?? feature
      },
      (feature?: Feature) => this.toolbox.tools.dataSelectorTool.draw(feature ? [feature] : []),
      (fileId: string, lineNumer: number) => {
        Logger.log.info(`Show source: ${fileId}, line ${lineNumer}`)
        const sourceLines = DataStore.getSourceLines(fileId)
        this.sourceWindow.setSourceLines(fileId, sourceLines, lineNumer)
        if (Settings.optionAutoShowSource) {
          this.sourceWindow.setVisible()
        }
      }
    )

    // Create the inspector window.
    this.consoleWindow = new ConsoleWindow("Console", HTML_WINDOW_CONSOLE, HTML_WINDOW_CONSOLE_TITLE)

    // Create the source window.
    this.sourceWindow = new SourceWindow("Source", HTML_WINDOW_SOURCE, HTML_WINDOW_SOURCE_TITLE, this.dataCanvas)

    // Create the menu definitions.
    const viewMenuDefinition = this.setupViewMenu()
    const othersMenuDefinition = this.setupOthersMenu()

    const clearButton = Html.getDefinedHtmlElementById(HTML_BUTTON_CLEAR_MAP)
    clearButton.addEventListener("click", () => this.clearMap())

    // Create the timeline region.
    this.timeline = new Timeline(
      HTML_TIMELINE,
      HTML_BUTTON_ZOOM_IN,
      HTML_BUTTON_ZOOM_OUT,
      HTML_BUTTON_SAVE,
      this.inspectorWindow,
      this.dataCanvas.setTimeRange
    )

    this.tileLevelFiltersPanel = new TileLevelFiltersPanel(this.dataCanvas, this.timeline, this.draw)
    this.dataFilesPanel = new DataFilesPanel(this.dataCanvas, this.timeline, this.draw)
    this.layersPanel = new LayersPanel(this.dataCanvas, this.timeline, this.sourceWindow, this.draw)

    // Create the toolbox with all tools.
    this.toolbox = new Toolbox(
      this.mapView,
      this.inspectorWindow,
      this.sourceWindow,
      this.timeline.shrinkTimeRange,
      this.toggleHelpWindow
    )
    this.toolbox.resetCurrentTool()

    const layersMenuDefinition = this.setupLayersMenu()
    this.menuBar = new MenuBar(
      this.mapView.getMapsMenu(),
      viewMenuDefinition,
      layersMenuDefinition,
      othersMenuDefinition,
      this.mapView.selectMap
    )

    // Add the click handler for the menu. This needs to be "window" to catch all clicks, as the menu has dropxdowns.
    window.addEventListener("click", (e) => this.menuBar.onWindowClick(e))

    // Add the keyboard shortcut in the color swtches for the layers.
    layersMenuDefinition.forEach((item) => {
      if (item.shortcut) {
        const layerType = item.id as LayerType
        const elm = Html.getDefinedHtmlElementById(Html.htmlElementIdForColor(layerType))
        elm.innerText = item.shortcut
        if (item.textColor) {
          elm.style.color = item.textColor
        }
      }
    })
    this.setupKeyboardListeners([viewMenuDefinition, layersMenuDefinition, othersMenuDefinition].flat())

    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_TILE_GRID, this.mapView.showTileGrid())
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_URBAN_SECTIONS, Settings.optionUrbanSections)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_GPX_TIMESTAMPS, Settings.optionGpxTimestamps)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_LOCAL_TIME, Settings.optionTimeFormat === DateTimeFormat.UTCTime)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SKIP_COMMENTS, Settings.optionSkipComments)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW, Settings.optionAutoShowConsole)

    Object.entries(this.dataCanvas.layerStates).forEach(([layerTypeString, item]) => {
      const layerType = layerTypeString as LayerType
      if (isRealLayer(layerType)) {
        this.layersPanel.updateOption(layerType, item.show)
      }
    })

    // Allow dropping files on these elements and classes.
    Html.makeHtmlElementDroppable(HTML_MAP, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_MENUBAR, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_TIMELINE, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_TOOLBOX, this.handleDropOnCanvasOrSelectedFile)

    Html.makeHtmlClassDroppable(HTML_CLASS_PANEL, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlClassDroppable(HTML_CLASS_WINDOW, this.handleDropOnCanvasOrSelectedFile)

    // Add the "select files" button.
    const selectFilesButton = Html.getDefinedHtmlElementById(HTML_BUTTON_SELECT_FILES)
    selectFilesButton.addEventListener("click", () => this.selectFiles())

    // These require the menu options to be available:
    this.layersPanel.update()
    this.helpWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
    this.consoleWindow.setVisible(false)
    this.sourceWindow.setVisible(false)

    // Automatically show help/what's new if this is a new version.
    if (PersistentStorage.hasApplicationVersionChanged()) {
      PersistentStorage.updateApplicationVersion()
      this.releaseNotesWindow.setVisible()
    } else {
      this.releaseNotesWindow.setVisible(false)
    }

    // Add the clickable version marker in the menu bar (shows release notes).
    const versionMarker = Html.getDefinedHtmlElementById(HTML_VERSION_MARKER)
    versionMarker.addEventListener("click", () =>
      this.releaseNotesWindow.setVisible(!this.releaseNotesWindow.isVisible())
    )

    // Store the panel positions and make them draggable.
    Html.storeHtmlElementPosition(HTML_TOOLBOX)
    Html.storeHtmlClassPositions(HTML_CLASS_PANEL)
    Html.storeHtmlClassPositions(HTML_CLASS_WINDOW)
    Html.makeHtmlElementDraggable(HTML_TOOLBOX)
    Html.makeHtmlClassDraggable(HTML_CLASS_PANEL_DRAG_BAR, HTML_CLASS_PANEL)
    Html.makeHtmlClassDraggable(HTML_CLASS_WINDOW_TITLE_BAR, HTML_CLASS_WINDOW)

    // Make sure all panels are positioned properly.
    this.layersPanel.resizePanels()
  }

  /**
   * Callback function for dropping files on canvas (must be lambda), or a selected file (which is passed
   * as an event).
   * @param dragEvent event.
   */
  private readonly handleDropOnCanvasOrSelectedFile = async (dragEvent: DragEvent) => {
    this.criticalSectionGuard.enterCriticalSection(async () => {
      await this.dataCanvas.handleDrop(
        dragEvent,
        () => {
          this.resetToolsExceptDistanceCalculator()
          this.layersPanel.update()
          this.dataFilesPanel.update()
          this.draw(false, () => {
            this.criticalSectionGuard.leaveCriticalSection()
          }) // Auto-zoom after dropping, if allowed.
        },
        () => {
          this.layersPanel.update()
          this.dataFilesPanel.update()
          this.draw(true, () => {
            this.criticalSectionGuard.leaveCriticalSection()
          }) // Auto-zoom after dropping, if allowed.
        }
      )
    })
  }

  private readonly selectFiles = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = true
    input.accept = ".ttp,.gpx,.json,.geojson,.csv,.txt,.log,.http,.xml,.output,.diff"
    input.addEventListener("change", (event) => {
      const files = (event.target as HTMLInputElement).files
      if (files) {
        const dataTransfer = new DataTransfer()
        for (const element of files) {
          dataTransfer.items.add(element)
        }
        const dragEvent = new DragEvent("drop", {
          dataTransfer: dataTransfer,
          bubbles: true,
          cancelable: true
        })
        // Simulate drop event for the selected files.
        this.handleDropOnCanvasOrSelectedFile(dragEvent).then(() => {})
      }
    })
    // Open file selector dialog with a slight delay (helps mobile browsers).
    setTimeout(() => {
      input.click()
    }, 100)
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private readonly onClick = (event: MapMouseEvent) => {
    const location = event.lngLat
    const point = event.point
    this.toolbox.currentTool.onClick(location, point)
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private readonly onContextMenu = (event: MapMouseEvent) => {
    const location = event.lngLat
    const point = event.point
    this.toolbox.currentTool.onContextMenu(location, point)
  }

  private readonly toggleShowTileGrid = () => {
    this.mapView.toggleShowTileGrid()
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_TILE_GRID, this.mapView.showTileGrid())
  }

  /**
   * Toggle help window. NNeeds to be a lambda, not a regular function, to avoid binding issues.
   */
  private readonly toggleHelpWindow = () => {
    this.helpWindow.setVisible(!this.helpWindow.isVisible())
    if (this.helpWindow.isVisible()) {
      this.releaseNotesWindow.setVisible(false)
    }
  }

  private readonly toggleOptionSkipComments = () => {
    Settings.optionSkipComments = !Settings.optionSkipComments
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SKIP_COMMENTS, Settings.optionSkipComments)
  }

  private readonly toggleOptionUrbanSections = () => {
    Settings.optionUrbanSections = !Settings.optionUrbanSections
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_URBAN_SECTIONS, Settings.optionUrbanSections)
  }

  private readonly toggleOptionGpxTimestamps = () => {
    Settings.optionGpxTimestamps = !Settings.optionGpxTimestamps
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_GPX_TIMESTAMPS, Settings.optionGpxTimestamps)
  }

  private readonly toggleOptionUseLocalTime = () => {
    Settings.optionTimeFormat =
      Settings.optionTimeFormat === DateTimeFormat.UTCTime ? DateTimeFormat.LocalTime : DateTimeFormat.UTCTime
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_LOCAL_TIME, Settings.optionTimeFormat === DateTimeFormat.UTCTime)
    this.draw(true)
  }

  private readonly toggleOptionAutoShowConsole = () => {
    Settings.optionAutoShowConsole = !Settings.optionAutoShowConsole
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW, Settings.optionAutoShowConsole)
    if (Settings.optionAutoShowConsole) {
      Logger.log.show()
    }
  }

  private handleKeyDigit(key: KeyboardEvent) {
    if (key.key < "0" || key.key > "9") {
      return false
    }
    if (key.key >= "0" && key.key <= "7") {
      this.tileLevelFiltersPanel.toggleOption(parseInt(key.key) + 10)
    } else if (key.key >= "8" && key.key <= "9") {
      this.tileLevelFiltersPanel.toggleOption(parseInt(key.key))
    }
    return true
  }

  private setupKeyboardListeners(menuDefinitions: MenuItem[]) {
    document.body.addEventListener("keydown", (keyboardEvent: KeyboardEvent) => {
      keyboardEvent.preventDefault()

      // Avoid listening to keyboard shortcuts when typing in an input field, for example for the API key.
      if (!this.keyboardShortcutsEnabled) {
        return
      }
      if (keyboardEvent.altKey || keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
        return
      }
      // Digits 0-9 are handled separately.
      if (!this.handleKeyDigit(keyboardEvent)) {
        const menuItem = menuDefinitions.find(
          (item) =>
            ("key" in item && keyboardEvent.key === `${item.key}`) ||
            ("shortcut" in item && keyboardEvent.code === `Key${item.shortcut}`)
        )
        if (menuItem && "action" in menuItem) {
          menuItem.action()
        }
      }
    })
  }

  private enterDefaultHttpOverheadSize() {
    const submitDialog = () => {
      const input = Html.getDefinedHtmlElementById(HTML_DATA_INPUT_VALUE) as HTMLInputElement
      const number = parseInt(input.value)
      if (number !== undefined && !isNaN(number) && number >= 0 && number <= 16 * 1024) {
        PersistentStorage.setHttpOverheadSizeInBytes(number)
        document.body.removeEventListener("keydown", handleKeydown)
        dialog.style.display = "none"
        this.keyboardShortcutsEnabled = true
        this.timeline.recalculateSizesAndDraw()
        this.layersPanel.update()
        this.layersPanel.update()
      } else {
        alert("Please enter a valid number between 0-16384.")
      }
    }

    const cancelDialog = () => {
      document.body.removeEventListener("keydown", handleKeydown)
      const dialog = Html.getDefinedHtmlElementById(HTML_DATA_INPUT)
      dialog.style.display = "none"
      this.keyboardShortcutsEnabled = true
    }

    const handleKeydown = (keyboardEvent: KeyboardEvent) => {
      // Do not prevent further handling of keys with keyboardEvent.preventDefault().
      if (keyboardEvent.key === "Enter") {
        submitDialog()
      } else if (keyboardEvent.key === "Escape") {
        cancelDialog()
      }
    }

    this.keyboardShortcutsEnabled = false
    const dialog = Html.getDefinedHtmlElementById(HTML_DATA_INPUT)
    dialog.style.display = "block"
    const value = Html.getDefinedHtmlElementById(HTML_DATA_INPUT_VALUE) as HTMLInputElement
    value.value = PersistentStorage.getHttpOverheadSizeInBytes().toString()
    value.focus()
    value.select()
    document.body.addEventListener("keydown", handleKeydown)
    Html.getDefinedHtmlElementById(HTML_DATA_INPUT_SUBMIT).addEventListener("click", submitDialog)
    Html.getDefinedHtmlElementById(HTML_DATA_INPUT_CANCEL).addEventListener("click", cancelDialog)
  }

  private yesNoDialog(htmlText: string, onYes: () => void, onNo: () => void) {
    this.keyboardShortcutsEnabled = false
    const text = Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_TEXT)
    text.innerHTML = htmlText
    const dialog = Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG)
    dialog.style.display = "block"
    Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_YES).addEventListener("click", () => {
      this.keyboardShortcutsEnabled = true
      dialog.style.display = "none"
      onYes()
    })
    Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_NO).addEventListener("click", () => {
      this.keyboardShortcutsEnabled = true
      dialog.style.display = "none"
      onNo()
    })
  }

  private createGpxFromRoutingResponseFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json, .txt"
    input.addEventListener("change", (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.toolbox.tools.routeCreatorGpxTool.createGpxFromRoutingResponse(file.name, content)
        }
        reader.readAsText(file)
      }
    })
    input.click()
  }

  private createRoutesFromJsonFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json"
    input.addEventListener("change", (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.toolbox.tools.routeCreatorJsonTool.createRoutesFromJson(file.name, content)
        }
        reader.readAsText(file)
      }
    })
    input.click()
  }

  private resetToolsExceptDistanceCalculator() {
    this.toolbox.tools.dataSelectorTool.clearToolData()
    this.toolbox.tools.routeCreatorGpxTool.clearToolData()
    this.toolbox.tools.routeCreatorJsonTool.clearToolData()
    this.toolbox.tools.tileEditorTool.clearToolData()
    this.toolbox.tools.distanceCalculatorTool.prepareNextMeasurement()
    this.helpWindow.setVisible(false)
    this.releaseNotesWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
    this.sourceWindow.setVisible(false)
    this.timeline.resetTimelineTimeRange()
  }

  private readonly clearMap = () => {
    Html.resetHTMLElementPositions()
    this.dataCanvas.removeAll()
    this.toolbox.tools.distanceCalculatorTool.clearToolData()
    this.toolbox.resetCurrentTool()
    this.resetToolsExceptDistanceCalculator()
    this.layersPanel.update()
    this.dataFilesPanel.update()
    this.consoleWindow.setVisible(false)
    this.consoleWindow.clear()
    this.sourceWindow.reset()
    this.draw()
  }

  private undo() {
    Logger.log.info("Undo last action")
    this.toolbox.currentTool.undo && this.toolbox.currentTool.undo()
    this.draw(true)
  }

  /**
   * Callback for (re)drawing the screen. This is an async function, which returns immediately.
   * @param inhibitAutoZoom If true, do not auto-zoom.
   * @param onFinished Called when finished drawing.
   */
  private readonly draw = async (inhibitAutoZoom: boolean = false, onFinished: () => void = () => {}) => {
    try {
      this.progressWindow.show("Analyzing requests for potential duplicates...")
      const features = this.dataCanvas.getActiveFeaturesFromDataStore()
      await this.dataCanvas.highlightPotentialDuplicates(features)
      this.dataCanvas.updateMapDataOverlayFeatures(features)
      this.timeline.replaceAllEvents(this.dataCanvas.getEventsFromFeatures(features), inhibitAutoZoom)
      this.inspectorWindow.update()
      this.sourceWindow.update()
    } finally {
      this.progressWindow.hide()
      onFinished()
    }
  }

  /**
   * Define the view menu.
   */
  private setupViewMenu(): MenuItem[] {
    const viewMenuItems: GenericMenuItem[] = [
      {
        key: "Backspace",
        shortcut: "Backspace",
        name: "Undo last point",
        action: () => this.undo()
      },
      {
        key: "Escape",
        shortcut: "Esc",
        name: "Reset action",
        action: () => this.resetToolsExceptDistanceCalculator()
      },
      {
        shortcut: "C",
        name: "Clear map",
        action: () => this.clearMap()
      },
      {
        key: "",
        shortcut: "+",
        name: "Zoom in (use Shift+ for more)",
        action: () => this.mapView.zoomIn()
      },
      {
        key: "",
        shortcut: "-",
        name: "Zoom out (use Shift- for more)",
        action: () => this.mapView.zoomOut()
      }
    ]

    const toggleMenuItems: GenericMenuItem[] = [
      {
        shortcut: "Z",
        name: "Auto-zoom to extents of data",
        id: "auto-zoom",
        action: () => this.layersPanel.toggleAutoZoom()
      },
      {
        shortcut: "S",
        name: "Auto-show log source window",
        id: "auto-show-source",
        action: () => this.layersPanel.toggleAutoShowSource()
      },
      {
        key: "\\",
        shortcut: "\\",
        name: "Toggle all layers on/off",
        id: "toggle-all-layers",
        action: () => this.layersPanel.toggleAllLayers()
      },
      {
        name: "Show MapVis tile grid",
        id: "tile-grid",
        action: () => this.toggleShowTileGrid()
      },
      {
        name: "Show urban sections in routes",
        id: "urban-sections",
        action: () => this.toggleOptionUrbanSections()
      },
      {
        name: "Write timestamps to GPX files",
        id: "gpx-timestamps",
        action: () => this.toggleOptionGpxTimestamps()
      },
      {
        name: "Use UTC (instead of local time)",
        id: "use-local-time",
        action: () => this.toggleOptionUseLocalTime()
      }
    ]

    const windowMenuItems: GenericMenuItem[] = [
      {
        key: "KeyM",
        shortcut: "M",
        name: "Show inspector window...",
        id: HTML_WINDOW_INSPECTOR,
        action: () => this.inspectorWindow.setVisible(!this.inspectorWindow.isVisible())
      },
      {
        key: "KeyO",
        shortcut: "O",
        name: "Show console window...",
        id: HTML_WINDOW_CONSOLE,
        action: () => this.consoleWindow.setVisible(!this.consoleWindow.isVisible())
      },
      {
        key: "KeyU",
        shortcut: "U",
        name: "Show source window...",
        id: HTML_WINDOW_SOURCE,
        action: () => this.sourceWindow.setVisible(!this.sourceWindow.isVisible())
      }
    ]
    return [viewMenuItems, separatorMenuItem, toggleMenuItems, separatorMenuItem, windowMenuItems].flat()
  }

  /**
   * Define the layers menu.
   */
  private setupLayersMenu(): LayerMenuItem[] {
    // Note: the IDs of these menu items must be the keys of LayerType.
    const layersMenuItems: LayerMenuItem[] = [
      {
        id: "MapVis3D",
        name: "MapVis: 3D",
        shortcut: "D",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVis3D)
      },
      {
        id: "MapVisBasicMap",
        name: "MapVis: basic map",
        shortcut: "B",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisBasicMap)
      },
      {
        id: "MapVisFlow",
        name: "MapVis: flow",
        shortcut: "F",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisFlow)
      },
      {
        id: "MapVisHillshade",
        name: "MapVis: hillshade",
        shortcut: "H",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisHillshade)
      },
      {
        id: "MapVisIncidents",
        name: "MapVis: incidents",
        shortcut: "I",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisIncidents)
      },
      {
        id: "MapVisSatellite",
        name: "MapVis: satellite",
        shortcut: "L",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisSatellite)
      },
      {
        id: "MapVisStyle",
        name: "MapVis: style",
        shortcut: "Y",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisStyle)
      },
      {
        id: "NdsLive",
        name: "NDS.Live",
        shortcut: "N",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsLive)
      },
      {
        id: "NdsClassicRegion",
        name: "NDS.Classic region",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsClassicRegion)
      },
      {
        id: "NdsClassicOther",
        name: "NDS.Classic other",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsClassicOther)
      },
      {
        id: "Nk2NavTiles",
        name: "NK2 NavTiles",
        shortcut: "T",
        action: () => this.layersPanel.toggleLayer(LayerType.Nk2NavTiles)
      },
      {
        id: "Nk2LaneTiles",
        name: "NK2 LaneTiles",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.Nk2LaneTiles)
      },
      {
        id: "ApiAutoComplete",
        name: "API: Auto-complete",
        shortcut: "A",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiAutoComplete)
      },
      {
        id: "ApiEv",
        name: "API: EV",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiEv)
      },
      {
        id: "ApiParking",
        name: "API: Parking",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiParking)
      },
      {
        id: "ApiRevGeocode",
        name: "API: Rev.geocode",
        shortcut: "V",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiRevGeocode)
      },
      {
        id: "ApiRouting",
        name: "API: Routing",
        shortcut: "R",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiRouting)
      },
      {
        id: "ApiSearch",
        name: "API: Search",
        shortcut: "Q",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiSearch)
      },
      {
        id: "ApiTpeg",
        name: "API: TPEG",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiTpeg)
      },
      {
        id: "LogLines",
        name: "Log lines",
        shortcut: "O",
        action: () => this.layersPanel.toggleLayer(LayerType.LogLines)
      },
      {
        id: "JSON",
        name: "JSON",
        shortcut: "J",
        action: () => this.layersPanel.toggleLayer(LayerType.JSON)
      },
      {
        id: "GeoJSON",
        name: "GeoJSON",
        action: () => this.layersPanel.toggleLayer(LayerType.GeoJSON)
      },
      {
        id: "GPX",
        name: "GPX",
        shortcut: "X",
        action: () => this.layersPanel.toggleLayer(LayerType.GPX)
      },
      {
        id: "TTPSlow",
        name: "TTP: 1s data",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPSlow)
      },
      {
        id: "TTPFast",
        name: "TTP: 100ms data",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPFast)
      },
      {
        id: "TTPLocation",
        name: "TTP: GPS/GNSS",
        shortcut: "G",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPLocation)
      }
    ]
    for (const layerType in LayerType) {
      assert(
        !isRealLayer(layerType as LayerType) || layersMenuItems.find((item) => item.id === layerType),
        `Layer type ${layerType} not found in layersMenuItems list`
      )
    }
    layersMenuItems.forEach((menuItem) => {
      ;[
        Html.htmlElementIdForColor(menuItem.id),
        Html.htmlElementIdForText(menuItem.id),
        Html.htmlElementIdForStatus(menuItem.id)
      ].forEach((id) => {
        Html.getDefinedHtmlElementById(id).addEventListener("click", () => menuItem.action?.())
      })
    })
    return layersMenuItems
  }

  /**
   * Define the others  menu.
   */
  private setupOthersMenu(): MenuItem[] {
    const othersMenuItems: MenuItem[] = [
      {
        name: "What's new...",
        id: HTML_WINDOW_RELEASE_NOTES,
        action: () => this.releaseNotesWindow.setVisible(!this.releaseNotesWindow.isVisible())
      },
      {
        name: "Show help...",
        id: HTML_WINDOW_HELP,
        action: () => this.toggleHelpWindow()
      },
      separatorMenuItem,
      {
        name: "Skip '#' comment lines while importing",
        id: "skip-comments",
        action: () => this.toggleOptionSkipComments()
      },
      {
        shortcut: "E",
        name: "Show errors/unprocessed lines during import",
        id: "auto-show-console-window",
        action: () => this.toggleOptionAutoShowConsole()
      },
      separatorMenuItem,
      {
        name: "Enter default HTTP overhead size...",
        action: () => this.enterDefaultHttpOverheadSize()
      },
      {
        name: "Clear local storage (incl. API key)...",
        action: () =>
          this.yesNoDialog(
            "<strong>Clear local storage</strong><br/><br/>This will reload the page, ask for a new API key and reset the default overhead used for calculating the size of HTTP calls...<br/><br/>Are you sure?",
            () => {
              PersistentStorage.clearPersistentStorage()
              window.location.reload()
            },
            () => {}
          )
      },
      {
        name: "Convert routing API response from JSON to GPX...",
        action: () => this.createGpxFromRoutingResponseFile()
      },
      {
        name: "Create routes from JSON file...",
        action: () => this.createRoutesFromJsonFile()
      },
      separatorMenuItem,
      {
        name: "Copy HTML ids to clipboard...",
        action: () => {
          this.consoleWindow.setVisible(true)
          const output = Html.copyAllHtmlIdsFromDocumentToClipboard()
          Logger.log.info(`Copy HTML id's to the clipboard:\n\n${output}`)
          navigator.clipboard
            .writeText(output)
            .then(() => {
              alert("HTML id's from this application are copied to the clipboard as TypeScript declarations...")
            })
            .catch((err) => {
              console.error("Failed to copy text to clipboard: ", err)
            })
        }
      }
    ]
    return othersMenuItems
  }
}

export default App
