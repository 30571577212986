/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import DataSelectorTool from "./dataSelectorTool"
import DistanceCalculatorTool from "./distanceCalculatorTool"
import RouteCreatorJsonTool from "./routeCreatorJsonTool"
import RouteCreatorGpxTool from "./routeCreatorGpxTool"
import TileEditorTool from "./tileEditorTool"
import {
  HTML_TOOL_DATA_SELECTOR,
  HTML_TOOL_DISTANCE_CALCULATOR,
  HTML_TOOL_EXPORT_GPX,
  HTML_TOOL_EXPORT_JSON,
  HTML_TOOL_HELP,
  HTML_TOOL_TILE_EDITOR
} from "../html/htmlElementId"
import {Tool} from "./tool"
import {Html} from "../html/html"
import MapView from "../app/mapView"
import InspectorWindow from "../windows/inspectorWindow"
import {SourceWindow} from "../windows/sourceWindow"

export type Tools = {
  dataSelectorTool: DataSelectorTool
  distanceCalculatorTool: DistanceCalculatorTool
  routeCreatorGpxTool: RouteCreatorGpxTool
  routeCreatorJsonTool: RouteCreatorJsonTool
  tileEditorTool: TileEditorTool
}

type ToolIcon = {
  toolId: keyof Tools
  iconId: string
}

export class Toolbox {
  // Currently selected tool
  public currentTool: Tool

  // All tools in the toolbox.
  public readonly tools: Tools
  private readonly toolIcons: ToolIcon[]

  constructor(
    mapView: MapView,
    inspectorWindow: InspectorWindow,
    sourceWindow: SourceWindow,
    shrinkTimeRange: (dateTime: Date) => void,
    help: () => void
  ) {
    this.tools = {
      dataSelectorTool: new DataSelectorTool(
        mapView,
        inspectorWindow,
        sourceWindow,
        mapView.queryFeatures,
        shrinkTimeRange
      ),
      distanceCalculatorTool: new DistanceCalculatorTool(mapView),
      routeCreatorGpxTool: new RouteCreatorGpxTool(mapView),
      routeCreatorJsonTool: new RouteCreatorJsonTool(mapView),
      tileEditorTool: new TileEditorTool(mapView, mapView.queryTileUrl)
    }

    this.toolIcons = [
      {toolId: "dataSelectorTool", iconId: HTML_TOOL_DATA_SELECTOR},
      {toolId: "distanceCalculatorTool", iconId: HTML_TOOL_DISTANCE_CALCULATOR},
      {toolId: "routeCreatorGpxTool", iconId: HTML_TOOL_EXPORT_GPX},
      {toolId: "routeCreatorJsonTool", iconId: HTML_TOOL_EXPORT_JSON},
      {toolId: "tileEditorTool", iconId: HTML_TOOL_TILE_EDITOR}
    ]

    // Add click event listener to each icon.
    this.toolIcons.forEach((toolIcon) => {
      const image = Html.getDefinedHtmlElementById(toolIcon.iconId) as HTMLImageElement
      image.addEventListener("click", () => {
        this.deselectAllToolIcons()
        this.selectToolIcon(toolIcon.iconId)
        this.currentTool = this.tools[toolIcon.toolId]
      })
    })
    const image = Html.getDefinedHtmlElementById(HTML_TOOL_HELP) as HTMLImageElement
    image.addEventListener("click", () => {
      this.selectToolIcon(HTML_TOOL_HELP)
      setTimeout((): void => {
        this.deselectToolIcon(HTML_TOOL_HELP)
      }, 400)
      help()
    })
    this.currentTool = this.tools.dataSelectorTool
    this.resetCurrentTool()
  }

  public resetCurrentTool() {
    this.deselectAllToolIcons()
    this.currentTool = this.tools.dataSelectorTool
    this.selectToolIcon(HTML_TOOL_DATA_SELECTOR)
  }

  private selectToolIcon(iconId: string) {
    const image = Html.getDefinedHtmlElementById(iconId) as HTMLImageElement
    image.classList.add("selected")
  }

  private deselectToolIcon(iconId: string) {
    const image = Html.getDefinedHtmlElementById(iconId) as HTMLImageElement
    image.classList.remove("selected")
  }

  private deselectAllToolIcons() {
    this.toolIcons.forEach((toolIcon: ToolIcon) => {
      this.deselectToolIcon(toolIcon.iconId)
    })
  }
}

export default Toolbox
