/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import TtpMessageBase from "../ttpMessageBase"
import {TtpMessageType} from "../ttpMessage"

export abstract class TtpMessageSensorBase extends TtpMessageBase {
  type: TtpMessageType
  message: number

  protected constructor(lineNumber: number, type: TtpMessageType, monotonicTime: number, message: number) {
    super(lineNumber, monotonicTime)
    this.type = type
    this.message = message
  }

  // Note: the factory to create a concrete instance of this class cannot be in this class (circular dependency).
}

export default TtpMessageSensorBase
