/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export type LngLat = {
  lng: number
  lat: number
}

/**
 * This is a utility class with static functions only.
 */
export class Wgs84Utils {
  static LngLatToArray(lngLat: LngLat): [number, number] {
    return [lngLat.lng, lngLat.lat]
  }

  static mapToLon(value: number) {
    return ((((value >= 0 ? value : -value) + 180) % 360) - 180) * (value >= 0 ? 1.0 : -1.0)
  }

  static mapToLat(value: number) {
    return Math.max(-90, Math.min(90, value))
  }

  static isValidLng(lon: number) {
    return -180 <= lon && lon <= 180
  }

  static isValidLat(lat: number) {
    return -90 <= lat && lat <= 90
  }

  // Mercator values for latitude are in the range of 0 (north) to 1 (south).
  static mercatorToLatitude(y: number): number {
    if (Number.isNaN(y) || y === Infinity || y === -Infinity) {
      return NaN
    }
    return (180 / Math.PI) * (2.0 * Math.atan(Math.exp(Math.PI - 2.0 * Math.PI * y)) - Math.PI / 2.0)
  }

  // Mercator values for longitude are in the range of 0 (west) to 1 (east).
  static mercatorToLongitude(x: number): number {
    return x * 360 - 180
  }

  static toRadians(degree: number): number {
    return (degree / 180.0) * Math.PI
  }

  static toDegrees(radians: number): number {
    return (radians / Math.PI) * 180.0
  }

  static distanceInMeters(start: LngLat, end: LngLat): number {
    const deltaLat = Wgs84Utils.toRadians(end.lat - start.lat)
    const deltaLon = Wgs84Utils.toRadians(end.lng - start.lng)
    const a =
      Math.sin(deltaLat / 2.0) * Math.sin(deltaLat / 2.0) +
      Math.cos(Wgs84Utils.toRadians(start.lat)) *
        Math.cos(Wgs84Utils.toRadians(end.lat)) *
        Math.sin(deltaLon / 2.0) *
        Math.sin(deltaLon / 2.0)
    const c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a))
    const kEarthRadiusMeters = 6378137
    return kEarthRadiusMeters * c
  }
}

export default Wgs84Utils
