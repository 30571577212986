/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import assert from "../common/assert"

type HtmlElementPosition = {left: number; top: number}

export class Html {
  // Store element positions.
  private static readonly htmlElementPositions: Record<string, HtmlElementPosition> = {}

  static storeHtmlElementPosition(id: string) {
    Html.htmlElementPositions[id] = Html.getHtmlElementPosition(id)
  }

  static storeHtmlClassPositions(className: string) {
    const elements = document.getElementsByClassName(className)
    Array.from(elements).forEach((elm) => (Html.htmlElementPositions[elm.id] = Html.getHtmlElementPosition(elm.id)))
  }

  static resetHTMLElementPositions() {
    Object.entries(Html.htmlElementPositions).forEach(([id, position]) => {
      Html.setHtmlElementPosition(id, position)
    })
  }

  /**
   * Get the HTML element, given an ID, Throw if it does not exist.
   * @param id ID of the HTML element.
   * @returns The HTML element.
   */
  static getDefinedHtmlElementById(id: string): HTMLElement {
    const elm = document.getElementById(id)
    assert(elm, `HTML element '${id}' not found`)
    return elm!
  }

  static getHtmlElementPosition(id: string): HtmlElementPosition {
    const elm = Html.getDefinedHtmlElementById(id)
    return {left: parseInt(elm.style.left), top: parseInt(elm.style.top)}
  }

  static setHtmlElementPosition(id: string, position: HtmlElementPosition) {
    const elm = Html.getDefinedHtmlElementById(id)
    elm.style.left = isNaN(position.left) ? "" : `${position.left}px`
    elm.style.top = isNaN(position.top) ? "" : `${position.top}px`
  }

  static makeHtmlElementDraggable(id: string, classToDrag?: string) {
    let offsetX = 0
    let offsetY = 0
    const elm = Html.getDefinedHtmlElementById(id)
    const parentElm = elm.parentElement
    const parentClass = parentElm?.classList
    const draggableElement = classToDrag && parentElm && parentClass?.contains(classToDrag) ? parentElm : elm

    const mouseDownHandler = (event: MouseEvent) => {
      // Store offset while moving with mouse down.
      offsetX = event.clientX - draggableElement.getBoundingClientRect().left
      offsetY = event.clientY - draggableElement.getBoundingClientRect().top
      document.addEventListener("mousemove", mouseMoveHandler)
      document.addEventListener("mouseup", mouseUpHandler)
    }

    // Update the location of the element while moving.
    const mouseMoveHandler = (e: MouseEvent) => {
      Html.setHtmlElementPosition(draggableElement.id, {left: e.clientX - offsetX, top: e.clientY - offsetY})
    }

    // Remove the event listeners when the mouse is up.
    const mouseUpHandler = (_: MouseEvent) => {
      document.removeEventListener("mousemove", mouseMoveHandler)
      document.removeEventListener("mouseup", mouseUpHandler)
    }

    // Reset the location to its (relative) value defined in the CSS.
    const mouseDoubleClick = () => {
      // Reset to original location.
      Html.setHtmlElementPosition(draggableElement.id, {left: NaN, top: NaN})
    }

    // Register the mouse down and double click events.
    elm.addEventListener("mousedown", mouseDownHandler)
    elm.addEventListener("dblclick", mouseDoubleClick)
  }

  static makeHtmlClassDraggable(className: string, classToDrag?: string) {
    const elements = document.getElementsByClassName(className)
    Array.from(elements).forEach((elm) => Html.makeHtmlElementDraggable(elm.id, classToDrag))
  }

  static makeHtmlClassDroppable(className: string, handleDrop: (dragEvent: DragEvent) => Promise<void>) {
    let elements = document.getElementsByClassName(className)
    Array.from(elements).forEach((elm) => Html.makeHtmlElementDroppable(elm.id, handleDrop))
  }

  static makeHtmlElementDroppable(id: string, handleDrop: (dragEvent: DragEvent) => Promise<void>) {
    const dropArea = Html.getDefinedHtmlElementById(id)
    dropArea.addEventListener("dragover", (dragEvent) => {
      dragEvent.preventDefault()
      dropArea.classList.add("drag-and-drop-border")
    })

    dropArea.addEventListener("dragleave", (dragEvent) => {
      dragEvent.preventDefault()
      dropArea.classList.remove("drag-and-drop-border")
    })

    dropArea.addEventListener("drop", (dragEvent) => {
      dragEvent.preventDefault()
      dropArea.classList.remove("drag-and-drop-border")
      handleDrop(dragEvent).then(() => {})
    })
  }

  static htmlElementIdForColor(value: string) {
    return `color-${value}`
  }

  static htmlElementIdForStatus(value: string) {
    return `status-${value}`
  }

  static htmlElementIdForMenuItem(value: string) {
    return `menu-item-${value}`
  }

  static htmlElementIdForDropdown(value: string) {
    return `dropdown-${value}`
  }

  static htmlElementIdForFilterLevel(value: number) {
    return `filter-level-${value}`
  }

  static htmlElementIdForLayer(value: string) {
    return `layer-${value}`
  }

  static htmlElementIdForText(value: string) {
    return `text-${value}`
  }

  static htmlElementIdForTotalSize(value: string) {
    return `total-size-${value}`
  }

  static htmlElementIdForSize(value: string) {
    return `size-${value}`
  }

  static htmlClassIdForInspectorTableHeader(value: number) {
    return `inspector-table-header-${value}`
  }

  static htmlClassIdForInspectorTableValue(value: number) {
    return `inspector-table-value-${value}`
  }

  static copyAllHtmlIdsFromDocumentToClipboard(): string {
    const elementsWithId = document.querySelectorAll("[id]")
    return (
      Array.from(elementsWithId)
        .map((element) => element.id)
        .filter((id) => /^[a-z][a-z0-9-]+$/.exec(id))
        .map((id) => `export const HTML_${id.replace(/-/g, "_").toUpperCase()} = "${id}"`)
        .sort((a, b) => a.localeCompare(b))
        .join("\n") + "\n"
    )
  }
}

export default Html
