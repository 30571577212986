/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export enum DateTimeFormat {
  LocalTime,
  UTCTime
}

/**
 * This is a utility class with static functions only.
 */
export class DateTimeUtils {
  private static readonly millisYear2000 = new Date(Date.UTC(2000, 0, 1, 0, 0, 0)).getTime()
  private static readonly millisYear2100 = new Date(Date.UTC(2100, 0, 1, 0, 0, 0)).getTime()
  private static readonly secondsYear2000 = DateTimeUtils.millisYear2000 / 1000
  private static readonly secondsYear2100 = DateTimeUtils.millisYear2100 / 1000

  // This regex accepts normal ISO time formats, like "YYYY-MM-DDTHH:MM:SSZ" or "YYYY-MM-DD HH:MM:SS".
  public static readonly regexISODateTime =
    /^\s*(\d{4}-\d{2}-\d{2}(?:\s*T?\s*)\d{2}:\d{2}:\d{2}([.]\d+)?)\s*(Z|(?:[+-]\d+:?\d+))?/

  // This regex accepts (incomplete) Logcat formats, like "MM-DD HH:MM:SS" or "YYYY-MM-DD HH:MM:SS+0200".
  public static readonly regexLogcatDateTime =
    /^\s*(\d{4}-)?(\d{1,2}-\d{1,2}\s+\d{1,2}:\d{2}:\d{2}([.]\d+)?)\s*(Z|(?:([+-]\d+)))?/

  public static readonly regexGoLoggerDateTime =
    /^\s*\[\s*(\d{4}-\d{2}-\d{2}\s+\d{2}:\d{2}:\d{2}):(\d{3})[+-]*([+-]\d+)\s*\]/

  /**
   * Format a full date, incl. time, as a string.
   * @param date The date to format.
   * @param dateTimeFormat The format to use (local, UTC).
   * @param includeMsecs True to include milliseconds.
   */
  static formatDateWithTime(
    date: Date,
    dateTimeFormat: DateTimeFormat = DateTimeFormat.UTCTime,
    includeMsecs = false
  ): string {
    return `${DateTimeUtils.formatDateWithoutTime(date, dateTimeFormat)} ${DateTimeUtils.formatTimeOnly(date, dateTimeFormat, includeMsecs)}`
  }

  /**
   * Format a date, without its time, as a string.
   * @param date The date to format.
   * @param dateTimeFormat The format to use (local, UTC).
   */
  static formatDateWithoutTime(date: Date, dateTimeFormat: DateTimeFormat = DateTimeFormat.UTCTime): string {
    const year = dateTimeFormat === DateTimeFormat.LocalTime ? date.getFullYear() : date.getUTCFullYear()
    const month = String(
      (dateTimeFormat === DateTimeFormat.LocalTime ? date.getMonth() : date.getUTCMonth()) + 1
    ).padStart(2, "0")
    const day = String(dateTimeFormat === DateTimeFormat.LocalTime ? date.getDate() : date.getUTCDate()).padStart(
      2,
      "0"
    )
    return `${year}-${month}-${day}`
  }

  /**
   * Format only the time stamp.
   * @param date The date to format.
   * @param dateTimeFormat The format to use (local, UTC).
   * @param includeMsecs True to include milliseconds.
   */
  static formatTimeOnly(
    date: Date,
    dateTimeFormat: DateTimeFormat = DateTimeFormat.UTCTime,
    includeMsecs = false
  ): string {
    const hours = String(dateTimeFormat === DateTimeFormat.LocalTime ? date.getHours() : date.getUTCHours()).padStart(
      2,
      "0"
    )
    const minutes = String(
      dateTimeFormat === DateTimeFormat.LocalTime ? date.getMinutes() : date.getUTCMinutes()
    ).padStart(2, "0")
    const seconds = String(
      dateTimeFormat === DateTimeFormat.LocalTime ? date.getSeconds() : date.getUTCSeconds()
    ).padStart(2, "0")
    const msecs = String(
      dateTimeFormat === DateTimeFormat.LocalTime ? date.getMilliseconds() : date.getUTCMilliseconds()
    ).padStart(3, "0")
    return `${hours}:${minutes}:${seconds}${includeMsecs ? "." + msecs : ""}${dateTimeFormat === DateTimeFormat.LocalTime ? "" : " UTC"}`
  }

  /**
   * Format a time as a HH:MM:SS string.
   * @param seconds Time in seconds.
   */
  static formatTimeAsDuration(seconds: number): string {
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds - days * 86400) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = Math.round(seconds % 60)
    const paddedHours = String(hours).padStart(2, "0")
    const paddedMinutes = String(minutes).padStart(2, "0")
    const paddedSeconds = String(secs).padStart(2, "0")
    return `${days ? days + "d+" : ""}${paddedHours}:${paddedMinutes}:${paddedSeconds}`
  }

  /**
   * Convert a time in milliseconds or seconds to a Date object. This recognizes if millis or seconds are used and
   * auto-selects the correct conversion.
   * @param millisOrSeconds Time in milliseconds or seconds.
   */
  static convertMillisToDateTime(millisOrSeconds: number): Date | undefined {
    if (DateTimeUtils.secondsYear2000 <= millisOrSeconds && millisOrSeconds < DateTimeUtils.secondsYear2100) {
      return new Date(millisOrSeconds * 1000)
    } else if (DateTimeUtils.millisYear2000 <= millisOrSeconds && millisOrSeconds < DateTimeUtils.millisYear2100) {
      return new Date(millisOrSeconds)
    } else {
      // Date-times outside [2000, 2100] are discarded.
      return undefined
    }
  }

  /**
   * Get a Date object from a string in ISO format.
   * @param line The line to extract the date from.
   */
  static getDateTimeFromISOTimestamp(line: string): Date | undefined {
    let datetime = undefined

    // This matches any time in 'near-ISO' format "YYYY-MM-DD HH:MM:SS". Use UTC.
    let match = DateTimeUtils.regexISODateTime.exec(line)
    if (match) {
      const timestamp = `${match[1].replace(/\s+/, "T")}${match[3]?.replace(":", "") ?? "Z"}`
      datetime = new Date(timestamp)
    }
    return datetime
  }

  /**
   * Get a Date object from a string in Logcat format.
   * @param line The line to extract the date from.
   */
  static getDateTimeFromLogcatTimestamp(line: string): Date | undefined {
    let datetime = undefined

    // This matches any time in 'near-ISO' format "YYYY-MM-DD HH:MM:SS". Use UTC.
    let match = DateTimeUtils.regexLogcatDateTime.exec(line)
    if (match) {
      const year = new Date().getFullYear()
      const timestamp = `${match[1] ?? year + "-"}${match[2]}${match[4] ?? ""}`
      datetime = new Date(timestamp)
    }
    return datetime
  }

  /**
   * Get a Date object from a string in GoLogger format.
   * @param line The line to extract the date from.
   */
  static getDateTimeFromGoLoggerTimestamp(line: string): Date | undefined {
    let datetime = undefined

    // This matches any time in 'near-ISO' format "YYYY-MM-DD HH:MM:SS". Use UTC.
    let match = DateTimeUtils.regexGoLoggerDateTime.exec(line)
    if (match) {
      const timestamp = `${match[1].replace(/\s+/, "T")}.${match[2]}${match[3]}`
      datetime = new Date(timestamp)
    }
    return datetime
  }
}
