/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, FeatureCollection, Geometry} from "geojson"
import {BoundingBox} from "./geo"
import {addAllElements} from "./objects"
import assert from "./assert"

/**
 * Calculate the bounding box for GeoJSON Geometry values.
 */
export function calculateBoundingBox(geometry: Geometry | Feature | FeatureCollection): BoundingBox | undefined {
  let coordinates: number[][] = []
  if (geometry.type === "FeatureCollection") {
    for (const feature of geometry.features) {
      addAllElements(coordinates, getCoordinates(feature.geometry))
    }
  } else if (geometry.type === "Feature") {
    coordinates = getCoordinates(geometry.geometry)
  } else if (geometry.type === "LineString" || geometry.type === "Point" || geometry.type === "Polygon") {
    coordinates = getCoordinates(geometry)
  } else {
    return undefined
  }
  const longitudes = coordinates.map((coord) => coord[0])
  const latitudes = coordinates.map((coord) => coord[1])
  const southWestLon = Math.min(...longitudes)
  const southWestLat = Math.min(...latitudes)
  const northEastLon = Math.max(...longitudes)
  const northEastLat = Math.max(...latitudes)
  return new BoundingBox({lng: southWestLon, lat: southWestLat}, {lng: northEastLon, lat: northEastLat})
}

function getCoordinates(geometry: Geometry): number[][] {
  switch (geometry.type) {
    case "Point":
      return [geometry.coordinates]
    case "LineString":
      return geometry.coordinates
    case "Polygon":
      return geometry.coordinates.flat()
    default:
      assert(false, `Unsupported geometry type: ${geometry.type}`)
      return []
  }
}
