/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {GenericMenuItem} from "../../menu/menuTypes"
import MenuDefinition from "./menuDefinition"
import MapView from "../mapView"

export class MenuDefinitionMaps extends MenuDefinition {
  private readonly mapView: MapView

  constructor(mapView: MapView) {
    super()
    this.mapView = mapView
  }

  public getMenuItems(): GenericMenuItem[] {
    return Object.entries(this.mapView.mapDefinitions)
      .filter(([_, value]) => !value.hideFromMenu)
      .map(([key]) => {
        return {
          id: key,
          name: this.mapView.mapDefinitions[key].name,
          action: () => this.mapView.selectMap(key)
        }
      })
  }
}

export default MenuDefinitionMaps
