/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {APPLICATION_VERSION} from "./version"

export enum Settings {
  AutoZoom = "auto-zoom",
  AutoShowSource = "auto-show-source",
  SkipComments = "skip-comments",
  AutoShowConsole = "auto-show-console",
  UseTimeFormatUTC = "use-time-format-utc",
  AddGpxTimestamps = "add-gpx-timestamps",
  ShowMousePosition = "show-mouse-position",
  ShowMousePositionTileLevel = "show-mouse-position-tile-level",
  DownloadRoutingApiResponse = "download-routing-api-response",
  UseOrbisRoutingApi = "use-orbis-routing-api",
  ShowRouteSectionTypes = "show-route-section-types"
}

export class Storage {
  // The TomTom API key.
  private static readonly KEY_API_KEY_TOMTOM = "api-key-tomtom"

  // The application version.
  private static readonly KEY_PREVIOUS_APPLICATION_VERSION = "previous-application-version"

  // Fixed overhead for HTTP calls, to be used as a default if real overhead is unknown.
  private static readonly KEY_HTTP_OVERHEAD_SIZE_IN_BYTES = "http-overhead-size-in-bytes"

  // Regex for MapVis custom tiles.
  private static readonly KEY_MAPVIS_CUSTOM_REGEX_DEFAULT = "/map-display/tile/custom/{z}/{x}/{y}"
  private static readonly KEY_MAPVIS_CUSTOM_REGEX = "mapvis-custom-regex"

  /**
   * Get global TomTom API key from local storage. Empty string if undefined.
   */
  static getTomTomApiKey() {
    return localStorage.getItem(Storage.KEY_API_KEY_TOMTOM) ?? ""
  }

  /**
   * Set global TomTom API key in local storage.
   * @param apiKey The API key to store.
   */
  static setTomTomApiKey(apiKey: string) {
    localStorage.setItem(Storage.KEY_API_KEY_TOMTOM, apiKey)
  }

  /**
   * Return if the application version was changed since last time.
   */
  static hasApplicationVersionChanged() {
    let previousApplicationVersion = localStorage.getItem(Storage.KEY_PREVIOUS_APPLICATION_VERSION)
    return previousApplicationVersion !== APPLICATION_VERSION
  }

  /**
   * Update the application version in local storage.
   */
  static updateApplicationVersion() {
    localStorage.setItem(Storage.KEY_PREVIOUS_APPLICATION_VERSION, APPLICATION_VERSION)
  }

  /**
   * Get the default HTTP overhead size from local storage, or return a default value.
   * @returns The default HTTP overhead size in bytes.
   */
  static getHttpOverheadSizeInBytes() {
    let overhead = localStorage.getItem(Storage.KEY_HTTP_OVERHEAD_SIZE_IN_BYTES)
    return overhead !== null ? parseInt(overhead) : 1024
  }

  /**
   * Store the default HTTP overhead size in local storage.
   */
  static setHttpOverheadSizeInBytes(sizeInBytes: number) {
    localStorage.setItem(Storage.KEY_HTTP_OVERHEAD_SIZE_IN_BYTES, sizeInBytes.toString())
  }

  /**
   * Get the default regex for custom MapVis tiles from local storage, or return a default value.
   * @returns The default regex for custom MapVis tiles.
   */
  static getMapVisCustomRegex() {
    let regex = localStorage.getItem(Storage.KEY_MAPVIS_CUSTOM_REGEX) ?? Storage.KEY_MAPVIS_CUSTOM_REGEX_DEFAULT
    let isOk = true
    try {
      new RegExp(regex)
    } catch (e) {
      isOk = false
    }
    return isOk ? regex : Storage.KEY_MAPVIS_CUSTOM_REGEX_DEFAULT
  }

  /**
   * Store the regex for custom MapVis tiles.
   */
  static setMapVisCustomRegex(regex: string) {
    localStorage.setItem(Storage.KEY_MAPVIS_CUSTOM_REGEX, regex)
  }

  /**
   * Get the value of a setting.
   * @returns Value.
   */
  static getNumber(setting: Settings, defaultValue = 0) {
    return localStorage.getItem(setting) !== null ? parseInt(localStorage.getItem(setting) as string) : defaultValue
  }

  /**
   * Get the value of a boolean setting.
   * @returns Value.
   */
  static get(setting: Settings, defaultValue = false) {
    let value = localStorage.getItem(setting)
    return value !== null ? value === "true" : defaultValue
  }

  /**
   * Set a boolean setting.
   * @param setting The setting to set.
   * @param value The value to set.
   */
  static set(setting: Settings, value: any) {
    localStorage.setItem(setting, value)
  }

  /**
   * Toggle a boolean setting.
   * @param setting The setting to toggle.
   */
  static toggle(setting: Settings) {
    localStorage.setItem(setting, !Storage.get(setting) ? "true" : "false")
  }

  /**
   * Remove local storage, incl. API key.
   */
  static clear() {
    localStorage.clear()
  }

  /**
   * Set the persistent settings once.
   */
  static initialize() {
    Storage.set(Settings.AutoZoom, true)
    Storage.set(Settings.AutoShowSource, false)
    Storage.set(Settings.SkipComments, true)
    Storage.set(Settings.AutoShowConsole, true)
    Storage.set(Settings.UseTimeFormatUTC, true)
    Storage.set(Settings.AddGpxTimestamps, false)
    Storage.set(Settings.ShowMousePosition, false)
    Storage.set(Settings.ShowMousePositionTileLevel, 13)
    Storage.set(Settings.DownloadRoutingApiResponse, false)
    Storage.set(Settings.UseOrbisRoutingApi, true)
    Storage.set(Settings.ShowRouteSectionTypes, true)
  }
}

export default Storage
