/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import TtpMessageSensorBase from "./messages/ttpMessageSensorBase"
import TtpMessageSensorMapMatcherResult from "./messages/ttpMessageSensorMapMatcherResult"
import TtpMessageSensorLocationPrediction from "./messages/ttpMessageSensorLocationPrediction"
import TtpMessageSensorLaneLevelPrediction from "./messages/ttpMessageSensorLaneLevelPrediction"
import TtpMessageSensorLocationEvent from "./messages/ttpMessageSensorLocationEvent"
import TtpMessageSensorMapMatcherInputLocations from "./messages/ttpMessageSensorMapMatcherInputLocations"
import TtpMessageSensorIncomingLocations from "./messages/ttpMessageSensorIncomingLocations"
import TtpMessageSensorUnknown from "./messages/ttpMessageSensorUnknown"
import TtpMessageParseException from "./ttpMessageParseException"
import {parseNumberMandatory, splitAndTrim, TtpMessageId} from "./ttpMessage"

const REGEX_SENSOR = "^([^,]*),([^,]*),(.*)$"

export class TtpMessageSensorFactory {
  static parse(lineNumber: number, input: string): TtpMessageSensorBase | undefined {
    const match = RegExp(REGEX_SENSOR).exec(input)
    if (!match) {
      return undefined
    }
    if (match.length < 3) {
      throw new TtpMessageParseException(`Invalid sensor (needs at least 3 fields)`)
    }

    const monotonicTime = parseNumberMandatory(match[1].trim())
    const message = parseNumberMandatory(match[2].trim())
    const parts = [monotonicTime.toString(), message.toString(), ...splitAndTrim(match[3], ",")]
    switch (message) {
      case TtpMessageId.MapMatcherResult:
        return TtpMessageSensorMapMatcherResult.parse(lineNumber, parts, monotonicTime, message)
      case TtpMessageId.LocationPrediction:
        return TtpMessageSensorLocationPrediction.parse(lineNumber, parts, monotonicTime, message)
      case TtpMessageId.LaneLevelPrediction:
        return TtpMessageSensorLaneLevelPrediction.parse(lineNumber, parts, monotonicTime, message)
      case TtpMessageId.LocationEvent:
        return TtpMessageSensorLocationEvent.parse(lineNumber, parts, monotonicTime, message)
      case TtpMessageId.MapMatcherInputLocations:
        return TtpMessageSensorMapMatcherInputLocations.parse(lineNumber, parts, monotonicTime, message)
      case TtpMessageId.IncomingLocations:
        return TtpMessageSensorIncomingLocations.parse(lineNumber, parts, monotonicTime, message)
      default:
        return TtpMessageSensorUnknown.parse(lineNumber, monotonicTime, message)
    }
  }
}
