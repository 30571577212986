/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {
  HTML_PANEL_CONTENT_LAYERS,
  HTML_STATUS_AUTO_SHOW_SOURCE,
  HTML_STATUS_AUTO_ZOOM,
  HTML_STATUS_TOGGLE_ALL,
  HTML_STATUS_TOGGLE_SIZE
} from "../html/htmlElementId"
import {
  HTML_CLASS_PANEL_COLOR_SWATCH,
  HTML_CLASS_PANEL_STATUS_BUTTON,
  HTML_CLASS_PANEL_TEXT,
  HTML_CLASS_PANEL_TEXT_SIZE
} from "../html/htmlClassId"
import {
  isParsedPointColor,
  isParsedPolygonColor,
  isRealLayer,
  layerSectionName,
  LayerType,
  layerTypeSupportsItemCountOnly,
  ParsedPointColor,
  ParsedPolygonColor
} from "../parsers/parserTypes"
import {DataStore, LayerSizes} from "../global/dataStore"
import MenuBar from "../menu/menuBar"
import Storage, {Settings} from "../common/storage"
import Html from "../html/html"
import DataCanvas from "../app/dataCanvas"
import Panel from "./panel"
import Timeline from "../app/timeline"
import SourceWindow from "../windows/sourceWindow"
import StringUtils from "../common/utils/stringUtils"
import {Mutex} from "async-mutex"

export class LayersPanel extends Panel {
  private readonly sourceWindow: SourceWindow
  private readonly textAutoZoomOn = "Zoom ON"
  private readonly textAutoZoomOff = "Zoom OFF"
  private readonly textAutoShowSourceOn = "Source ON"
  private readonly textAutoShowSourceOff = "Source OFF"
  private readonly textShowLayerSizeInBytes = "Data size"
  private readonly textShowLayerSizeInCalls = "# Calls"
  private readonly textToggleAllLayers = "Toggle all"

  private readonly excludeLayersFromToggleAll: LayerType[] = [LayerType.TTPPrediction, LayerType.TTPOther]

  private showLayerSizeInBytes = true

  constructor(
    dataCanvas: DataCanvas,
    timeline: Timeline,
    sourceWindow: SourceWindow,
    onDraw: (inhibitAutoZoom?: boolean) => void
  ) {
    super(dataCanvas, timeline, onDraw)

    this.sourceWindow = sourceWindow
    this.create()
    this.setupListeners()
  }

  public update() {
    // This function adds a tiny bit if there's anything to add, so the headers remain visible, even if they show 0.
    const countShown = (show: boolean, count: number) => (show || !count ? count : 1e-10)

    // Update buttons on top.
    this.updateAnyOption("auto-zoom", Storage.get(Settings.AutoZoom), true, this.textAutoZoomOn, this.textAutoZoomOff)
    this.updateAnyOption(
      "auto-show-source",
      Storage.get(Settings.AutoShowSource),
      true,
      this.textAutoShowSourceOn,
      this.textAutoShowSourceOff
    )
    this.updateAnyOption(
      "toggle-size",
      this.showLayerSizeInBytes,
      true,
      this.textShowLayerSizeInBytes,
      this.textShowLayerSizeInCalls
    )

    // Update the layers section.
    let sectionLayerSizes = {} as LayerSizes
    let sectionTotalCount = 0
    let sectionApiCount = 0
    let sectionSizeInBytes = 0
    let currentSection: LayerType | undefined = undefined

    // Determine which layers contains data.
    const dataFileContentIds = DataStore.getDataFileContentIds()
    const layersWithData = dataFileContentIds
      .filter((id) => DataStore.isFileShown(id))
      .map((id) => DataStore.getDataFileContent(id))
      .flatMap((dataFileContent) =>
        Object.entries(dataFileContent.layerSizes)
          .filter(([_, layerSize]) => layerSize.totalCount > 0)
          .map(([layerType, _]) => layerType)
      )

    // Initialize data structure for counting totals per layer.
    const totalLayerSizes = {} as LayerSizes
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      totalLayerSizes[layerType] = {totalCount: 0, apiCount: 0, sizeInBytes: 0}
    }

    // Accumulate total size in bytes for each shown layer type in active files.
    dataFileContentIds
      .map((id) => DataStore.getDataFileContent(id))
      .filter((dataFileContent) => dataFileContent.show)
      .forEach((dataFileContent) => {
        Object.entries(dataFileContent.layerSizes).forEach(([layerType, layerSize]) => {
          totalLayerSizes[layerType as LayerType].totalCount += layerSize.totalCount
          totalLayerSizes[layerType as LayerType].apiCount += layerSize.apiCount
          totalLayerSizes[layerType as LayerType].sizeInBytes += layerSize.sizeInBytes
        })
      })

    let allSectionsTotalCount = 0
    let allSectionsApiCount = 0
    let allSectionsSizeInBytes = 0
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      const showLayer = this.dataCanvas.layerStates[layerType].show
      if (isRealLayer(layerType)) {
        // Show/hide row based on data.
        const row = Html.getDefinedHtmlElementById(Html.htmlElementIdForLayer(layerType))
        row.classList.remove("hide")
        if (!layersWithData.includes(layerType)) {
          row.classList.add("hide")
        }

        // Update status.
        const layerStates = this.dataCanvas.layerStates[layerType]
        MenuBar.updateMenuItemToggle(Html.htmlElementIdForMenuItem(layerType), layerStates.show)
        const parser = this.dataCanvas.lineParsers[layerType] ?? this.dataCanvas.fileParsers[layerType]!
        const columnColor = Html.getDefinedHtmlElementById(Html.htmlElementIdForColor(layerType))
        if (isParsedPointColor(parser.color)) {
          const color = parser.color as ParsedPointColor
          columnColor.style.backgroundColor = color["circle-color"]
          columnColor.style.border = `1px solid ${color["text-color"]}`
        } else if (isParsedPolygonColor(parser.color)) {
          const color = parser.color as ParsedPolygonColor
          columnColor.style.backgroundColor = color["fill-color"]
          columnColor.style.border = `1px solid ${color["fill-outline-color"]}`
        }

        const totalCount = totalLayerSizes[layerType].totalCount
        sectionTotalCount += countShown(showLayer, totalCount)
        allSectionsTotalCount += countShown(showLayer, totalCount)

        const apiCount = totalLayerSizes[layerType].apiCount
        if (!layerTypeSupportsItemCountOnly.includes(layerType)) {
          sectionApiCount += countShown(showLayer, apiCount)
          allSectionsApiCount += countShown(showLayer, apiCount)
        }

        const sizeInBytesWithOverhead = totalLayerSizes[layerType].sizeInBytes
          ? totalLayerSizes[layerType].sizeInBytes +
            totalLayerSizes[layerType].totalCount * Storage.getHttpOverheadSizeInBytes()
          : 0

        sectionSizeInBytes += countShown(showLayer, sizeInBytesWithOverhead)
        allSectionsSizeInBytes += countShown(showLayer, sizeInBytesWithOverhead)

        const columnText = Html.getDefinedHtmlElementById(Html.htmlElementIdForText(layerType))
        if (showLayer) {
          columnText.classList.remove("off")
        } else {
          columnText.classList.add("off")
        }

        const columnSize = Html.getDefinedHtmlElementById(Html.htmlElementIdForSize(layerType))
        if (showLayer) {
          columnSize.classList.remove("off")
        } else {
          columnSize.classList.add("off")
        }
        columnSize.innerText =
          layerTypeSupportsItemCountOnly.includes(layerType) || !this.showLayerSizeInBytes
            ? this.formatLayerCount(apiCount)
            : this.formatLayerSize(sizeInBytesWithOverhead)

        const columnStatus = Html.getDefinedHtmlElementById(Html.htmlElementIdForStatus(layerType))
        columnStatus.innerText = layerStates.show ? "ON" : "OFF"
        columnStatus.classList.remove("off", "on")
        columnStatus.classList.add(layerStates.show ? "on" : "off")
      } else {
        // This is a layer header row.
        if (currentSection) {
          sectionLayerSizes[currentSection] = {
            totalCount: sectionTotalCount,
            apiCount: sectionApiCount,
            sizeInBytes: sectionSizeInBytes
          }
        }
        currentSection = layerType

        // Reset section counters.
        sectionTotalCount = 0
        sectionApiCount = 0
        sectionSizeInBytes = 0
      }
    }
    sectionLayerSizes[LayerType._Other] = {totalCount: 0, apiCount: 0, sizeInBytes: 0}
    sectionLayerSizes[LayerType._Total] = {
      totalCount: allSectionsTotalCount,
      apiCount: allSectionsApiCount,
      sizeInBytes: allSectionsSizeInBytes
    }

    // Update layer header rows (for non-real layer rows).
    for (const layerTypeItem in LayerType) {
      const layerType = layerTypeItem as LayerType
      if (!isRealLayer(layerType)) {
        // Update section header.
        const apiCount = sectionLayerSizes[layerType].apiCount
        const sizeInBytes = sectionLayerSizes[layerType].sizeInBytes
        const row = Html.getDefinedHtmlElementById(Html.htmlElementIdForLayer(layerType))
        row.classList.remove("hide")
        if (sizeInBytes === 0 && apiCount === 0) {
          row.classList.add("hide")
        }
        const size = Html.getDefinedHtmlElementById(Html.htmlElementIdForTotalSize(layerSectionName(layerType)))
        size.innerText = layerTypeSupportsItemCountOnly.includes(layerType)
          ? "items:"
          : this.showLayerSizeInBytes
            ? sizeInBytes
              ? StringUtils.formatSizeInBytes(sizeInBytes, 1, 1)
              : layerType === LayerType._Total
                ? "-"
                : "size:"
            : apiCount
              ? `${StringUtils.formatNumberAsPowers(apiCount, 2, 7)}`
              : layerType === LayerType._Total
                ? "-"
                : "calls:"
      }
    }
    this.resizePanels()
  }

  /**
   * Toggle auto-zoom on/off.
   */
  public readonly toggleAutoZoom = () => {
    Storage.toggle(Settings.AutoZoom)
    this.updateAnyOption("auto-zoom", Storage.get(Settings.AutoZoom), true, this.textAutoZoomOn, this.textAutoZoomOff)
    if (Storage.get(Settings.AutoZoom)) {
      this.dataCanvas.zoomToBounds()
    }
  }

  /**
   * Toggle auto-source on/off.
   */
  public readonly toggleAutoShowSource = () => {
    Storage.toggle(Settings.AutoShowSource)
    this.updateAnyOption(
      "auto-show-source",
      Storage.get(Settings.AutoShowSource),
      true,
      this.textAutoShowSourceOn,
      this.textAutoShowSourceOff
    )
    if (!this.sourceWindow.isEmpty()) {
      this.sourceWindow.setVisible(Storage.get(Settings.AutoShowSource))
    }
  }

  /**
   * Toggle between data consumption and number of API calls.
   */
  public readonly toggleShowLayerSizeInBytes = () => {
    this.showLayerSizeInBytes = !this.showLayerSizeInBytes
    this.updateAnyOption(
      "toggle-size",
      this.showLayerSizeInBytes,
      true,
      this.textShowLayerSizeInBytes,
      this.textShowLayerSizeInCalls
    )
    this.update()
    // No need to redraw entire screen.
  }

  /**
   * Set the option toggle for a layer to ON/OFF.
   * @param layerType Layer.
   * @param value New value for ON/OFF toggle.
   * @param changeOnOffText Modify the text, based on status (requires textOn and textOff).
   * @param textOn Optional ON text, if changeOnOffText is true.
   * @param textOff Optional OFF text, if changeOnOffText is true.
   */
  public readonly updateOption = (
    layerType: LayerType,
    value: boolean,
    changeOnOffText: boolean = true,
    textOn?: string,
    textOff?: string
  ) => this.updateAnyOption(layerType, value, changeOnOffText, textOn, textOff)

  public readonly mutexToggleLayer = new Mutex()
  public readonly toggleLayer = (layerType: LayerType) => {
    this.mutexToggleLayer.runExclusive(() => {
      const layerStateAndSize = this.dataCanvas.layerStates[layerType]
      layerStateAndSize.show = !layerStateAndSize.show
      this.updateOption(layerType, layerStateAndSize.show)
      this.onDraw(true, () => {})
    })
  }

  /**
   * Toggle all layers on/off. If any layer is switched on, they wll first go to all off.
   */
  public readonly toggleAllLayers = () => {
    // Blink the toggle button.
    const elm = Html.getDefinedHtmlElementById(HTML_STATUS_TOGGLE_ALL)
    elm.classList.add("highlight")
    setTimeout(() => {
      elm.classList.remove("highlight")
    }, 250)

    // Determine if any layer is shown.
    const show = !Object.entries(this.dataCanvas.layerStates)
      .filter(([key, _]) => !this.excludeLayersFromToggleAll.includes(key as LayerType))
      .map(([_, layerState]) => layerState.show)
      .reduce((acc, show) => acc || show, false)
    this.showAllLayersExceptExcluded(show)
    if (show) {
      this.excludeLayersFromToggleAll.forEach((layerType) => {
        if (!this.dataCanvas.layerStates[layerType].show) {
          const columnStatus = Html.getDefinedHtmlElementById(Html.htmlElementIdForStatus(layerType))
          columnStatus.classList.add("highlight")
          setTimeout(() => {
            columnStatus.classList.remove("highlight")
          }, 250)
        }
      })
    }
  }

  /**
   * Switch all layers to show or hide. Some layers are not automatically shown by this feature (because
   * they would slow down the application too much).
   * @param show Show all layers if true, hide all layers if false.
   */
  private showAllLayersExceptExcluded(show: boolean) {
    Object.entries(this.dataCanvas.layerStates)
      .filter(([key, _]) => !show || !this.excludeLayersFromToggleAll.includes(key as LayerType))
      .forEach(([_, layerState]) => {
        layerState.show = show
      })
    this.update()
    this.onDraw(true)
  }

  private setupListeners() {
    Html.getDefinedHtmlElementById(HTML_STATUS_AUTO_ZOOM).addEventListener("click", () => this.toggleAutoZoom())
    Html.getDefinedHtmlElementById(HTML_STATUS_AUTO_SHOW_SOURCE).addEventListener("click", () =>
      this.toggleAutoShowSource()
    )
    Html.getDefinedHtmlElementById(HTML_STATUS_TOGGLE_SIZE).addEventListener("click", () =>
      this.toggleShowLayerSizeInBytes()
    )
    Html.getDefinedHtmlElementById(HTML_STATUS_TOGGLE_ALL).addEventListener("click", () => this.toggleAllLayers())
  }

  private formatLayerCount(count: number) {
    return count <= 0 ? "-" : StringUtils.formatNumberAsPowers(count, 2, 7)
  }

  private formatLayerSize(sizeInBytes: number) {
    return sizeInBytes <= 0 ? "-" : StringUtils.formatSizeInBytes(sizeInBytes, 1, 1)
  }

  private create() {
    const panelContentLayers = Html.getDefinedHtmlElementById(HTML_PANEL_CONTENT_LAYERS)
    panelContentLayers.innerHTML = ""

    const buttons = document.createElement("div")
    buttons.style.display = "flex"
    buttons.style.justifyContent = "space-between"
    buttons.style.gap = "10px"

    // Create "zoom", "source", "data usage", and "toggle all" buttons.
    let button = document.createElement("span")
    button.id = HTML_STATUS_AUTO_ZOOM
    button.textContent = ""
    button.className = `${HTML_CLASS_PANEL_STATUS_BUTTON} top`
    buttons.appendChild(button)

    button = document.createElement("span")
    button.id = HTML_STATUS_AUTO_SHOW_SOURCE
    button.textContent = ""
    button.className = `${HTML_CLASS_PANEL_STATUS_BUTTON} top`
    buttons.appendChild(button)

    button = document.createElement("span")
    button.id = HTML_STATUS_TOGGLE_SIZE
    button.textContent = ""
    button.className = `${HTML_CLASS_PANEL_STATUS_BUTTON} top`
    buttons.appendChild(button)

    button = document.createElement("span")
    button.id = HTML_STATUS_TOGGLE_ALL
    button.textContent = this.textToggleAllLayers
    button.className = `${HTML_CLASS_PANEL_STATUS_BUTTON} top`
    buttons.appendChild(button)
    panelContentLayers.appendChild(buttons)

    // Create table with layer rows.
    const table = document.createElement("table")
    for (const layerTypeItem in LayerType) {
      // Create a row for each layer.
      const layerType = layerTypeItem as LayerType
      let row = document.createElement("tr")
      row.id = Html.htmlElementIdForLayer(layerType)
      let column = document.createElement("td")

      // Create layer info or section header for layer types.
      if (isRealLayer(layerType)) {
        // Create real layer row.
        const layerState = this.dataCanvas.layerStates[layerType]
        const parser = this.dataCanvas.lineParsers[layerType] ?? this.dataCanvas.fileParsers[layerType]!
        column = document.createElement("td")

        column.id = Html.htmlElementIdForColor(layerType)
        column.className = HTML_CLASS_PANEL_COLOR_SWATCH
        if (!parser.color) {
          column.innerText = "-"
        }
        // Properties for backgroundColor and border will be added later.
        row.appendChild(column)

        column = document.createElement("td")
        column.id = Html.htmlElementIdForText(layerType)
        column.className = HTML_CLASS_PANEL_TEXT
        column.textContent = parser.name
        row.appendChild(column)

        column = document.createElement("td")
        column.id = Html.htmlElementIdForSize(layerType)
        column.className = HTML_CLASS_PANEL_TEXT_SIZE
        column.textContent = this.formatLayerSize(0)
        row.appendChild(column)

        column = document.createElement("td")
        column.id = Html.htmlElementIdForStatus(layerType)
        column.className = HTML_CLASS_PANEL_STATUS_BUTTON
        // Properties for innerText and on/off will be added later.

        column.innerText = layerState.show ? "ON" : "OFF"
        column.classList.remove("off", "on")
        column.classList.add(layerState.show ? "on" : "off")
      } else {
        // Create section header for layer types.
        column.className = HTML_CLASS_PANEL_COLOR_SWATCH
        column.innerText = ""
        column.classList.add("none")
        row.appendChild(column)

        column = document.createElement("td")
        column.className = HTML_CLASS_PANEL_TEXT
        column.textContent = `${layerSectionName(layerType)}:`
        column.classList.add(layerType === LayerType._Total ? "total" : "subtotal")
        row.appendChild(column)

        column = document.createElement("td")
        column.textContent = ""
        column.id = Html.htmlElementIdForTotalSize(layerSectionName(layerType))
        column.className = HTML_CLASS_PANEL_TEXT_SIZE
        column.classList.add(layerType === LayerType._Total ? "total" : "subtotal")
        row.appendChild(column)

        column = document.createElement("td")
        column.className = HTML_CLASS_PANEL_STATUS_BUTTON

        column.innerText = ""
        column.classList.add("none")
      }
      row.appendChild(column)
      table.appendChild(row)
    }
    panelContentLayers.appendChild(table)
  }
}

export default LayersPanel
