/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {APPLICATION_VERSION} from "../common/version"

export class PersistentStorage {
  // The TomTom API key.
  private static readonly KEY_API_KEY_TOMTOM = "api-key-tomtom"

  // The application version.
  private static readonly KEY_PREVIOUS_APPLICATION_VERSION = "previous-application-version"

  // Fixed overhead for HTTP calls, to be used as a default if real overhead is unknown.
  private static readonly KEY_HTTP_OVERHEAD_SIZE_IN_BYTES = "http-overhead-size-in-bytes"

  /**
   * Get global TomTom API key from local storage. Empty string if undefined.
   */
  static getTomTomApiKey() {
    return localStorage.getItem(PersistentStorage.KEY_API_KEY_TOMTOM) ?? ""
  }

  /**
   * Set global TomTom API key in local storage.
   * @param apiKey The API key to store.
   */
  static setTomTomApiKey(apiKey: string) {
    localStorage.setItem(PersistentStorage.KEY_API_KEY_TOMTOM, apiKey)
  }

  /**
   * Return if the application version was changed since last time.
   */
  static hasApplicationVersionChanged() {
    let previousApplicationVersion = localStorage.getItem(PersistentStorage.KEY_PREVIOUS_APPLICATION_VERSION)
    return previousApplicationVersion !== APPLICATION_VERSION
  }

  /**
   * Update the application version in local storage.
   */
  static updateApplicationVersion() {
    localStorage.setItem(PersistentStorage.KEY_PREVIOUS_APPLICATION_VERSION, APPLICATION_VERSION)
  }

  /**
   * Get the default HTTP overhead size from local storage, or return a default value.
   * @returns The default HTTP overhead size in bytes.
   */
  static getHttpOverheadSizeInBytes() {
    let overhead = localStorage.getItem(PersistentStorage.KEY_HTTP_OVERHEAD_SIZE_IN_BYTES)
    return overhead !== null ? parseInt(overhead) : 1024
  }

  /**
   * Store the default HTTP overhead size in local storage.
   */
  static setHttpOverheadSizeInBytes(sizeInBytes: number) {
    localStorage.setItem(PersistentStorage.KEY_HTTP_OVERHEAD_SIZE_IN_BYTES, sizeInBytes.toString())
  }

  /**
   * Remove local storage, incl. API key. Needs to be a lambda, not a regular function, to avoid
   * binding issues for 'this'.
   */
  static clearPersistentStorage = () => {
    localStorage.removeItem(PersistentStorage.KEY_API_KEY_TOMTOM)
    localStorage.removeItem(PersistentStorage.KEY_PREVIOUS_APPLICATION_VERSION)
    localStorage.removeItem(PersistentStorage.KEY_HTTP_OVERHEAD_SIZE_IN_BYTES)
  }
}

export default PersistentStorage
