/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import MenuBar from "../menu/menuBar"
import Html from "../html/html"
import {
  HTML_CLASS_CLOSE_BUTTON,
  HTML_CLASS_TITLE,
  HTML_CLASS_WINDOW,
  HTML_CLASS_WINDOW_CONTENT,
  HTML_CLASS_WINDOW_TITLE_BAR
} from "../html/htmlClassId"

/**
 * This class defines a window that can be shown or hidden and that displays HTML text.
 */
export abstract class HtmlWindow {
  protected readonly htmlWindowElement: HTMLElement
  protected readonly htmlWindowTitleElement: HTMLElement
  protected readonly htmlWindowContentElement: HTMLElement

  private readonly title: string
  private readonly displayShow: string
  private readonly displayHide = "none"

  /**
   * Create a new HtmlWindow.
   * @param title The title of the window.
   * @param htmlBaseId The base name of the HTML ids.
   * @param valueForShow The value to set the display style to when the window is shown.
   */
  protected constructor(title: string, htmlBaseId: string, valueForShow = "block") {
    // Create HTML window.
    this.htmlWindowElement = document.createElement("div")
    this.htmlWindowElement.classList.add(HTML_CLASS_WINDOW)
    this.htmlWindowElement.id = HtmlWindow.htmlElementIdForWindow(htmlBaseId)

    const titleBarElm = document.createElement("div")
    titleBarElm.classList.add(HTML_CLASS_WINDOW_TITLE_BAR)
    titleBarElm.id = HtmlWindow.htmlElementIdForWindowTitleBar(htmlBaseId)

    const button = document.createElement("button")
    button.classList.add(HTML_CLASS_CLOSE_BUTTON)
    button.innerText = "X"
    titleBarElm.appendChild(button)

    this.htmlWindowTitleElement = document.createElement("span")
    this.htmlWindowTitleElement.classList.add(HTML_CLASS_TITLE)
    this.htmlWindowTitleElement.id = HtmlWindow.htmlElementIdForWindowTitle(htmlBaseId)
    titleBarElm.appendChild(this.htmlWindowTitleElement)
    this.htmlWindowElement.appendChild(titleBarElm)

    this.htmlWindowContentElement = document.createElement("div")
    this.htmlWindowContentElement.classList.add(HTML_CLASS_WINDOW_CONTENT)
    this.htmlWindowContentElement.id = HtmlWindow.htmlElementIdForWindowContent(htmlBaseId)
    this.htmlWindowElement.appendChild(this.htmlWindowContentElement)
    document.body.appendChild(this.htmlWindowElement)

    this.title = title
    this.setTitle()
    this.displayShow = valueForShow
    this.setupCloseWindowListeners()
  }

  /**
   * Set the postfix for the title of the window.
   * @param titlePostfix The postfix to add to the title of the window.
   */
  public setTitle(titlePostfix: string = "") {
    this.htmlWindowTitleElement.textContent = this.title + (titlePostfix.length > 0 ? ` - ${titlePostfix}` : "")
  }

  /**
   * Set the visibility of the window.
   * @param show True to show the window, false to hide it.
   */
  public setVisible(show: boolean = true) {
    this.htmlWindowElement.style.display = show ? this.displayShow : this.displayHide

    // !! TODO [techdebt]: Refactor this to not directly use MenuBar.
    MenuBar.updateMenuItemToggle(Html.htmlElementIdForMenuItem(this.htmlWindowElement.id), show)
  }

  /**
   * Check if the window is visible.
   * @returns True if the window is visible, false if it is hidden.
   */
  isVisible() {
    return this.htmlWindowElement.style.display !== this.displayHide
  }

  /**
   * Register listeners for the close window buttons.
   * This function can safely be called multiple times.
   */
  private setupCloseWindowListeners() {
    const closeButtons = document.querySelectorAll(".close-button")
    closeButtons.forEach((closeButton) => {
      closeButton.removeEventListener("click", () => this.setVisible(false))
      closeButton.addEventListener("click", () => this.setVisible(false))
    })
  }

  private static htmlElementIdForWindow(value: string) {
    return `window-${value}`
  }

  private static htmlElementIdForWindowTitle(value: string) {
    return `window-title-${value}`
  }

  private static htmlElementIdForWindowTitleBar(value: string) {
    return `window-title-bar-${value}`
  }

  private static htmlElementIdForWindowContent(value: string) {
    return `window-content-${value}`
  }
}

export default HtmlWindow
