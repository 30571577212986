/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import TtpMessageParseException from "../ttpMessageParseException"
import {parseKeyValues, parseNumberMandatory, TtpMessageType} from "../ttpMessage"
import TtpMessageBase from "../ttpMessageBase"

const REGEX_SENSOR_CONFIGURATION = "^([^,]*),([^,]*),([^,]*),\\s*SENSOR\\s*=([^,]*)(.*)$"

export class TtpMessageSensorConfiguration extends TtpMessageBase {
  type: TtpMessageType = TtpMessageType.SensorConfiguration
  message: number
  channel: number
  sensor: string
  config: Record<string, string>

  constructor(
    lineNumber: number,
    monotonicTime: number,
    message: number,
    channel: number,
    sensor: string,
    config: Record<string, string>
  ) {
    super(lineNumber, monotonicTime)
    this.message = message
    this.channel = channel
    this.sensor = sensor
    this.config = config
  }

  static parse(lineNumber: number, input: string): TtpMessageSensorConfiguration | undefined {
    const match = RegExp(REGEX_SENSOR_CONFIGURATION).exec(input)
    if (!match) {
      return undefined
    }
    if (match.length < 5) {
      throw new TtpMessageParseException(`Invalid sensor configuration (needs at least 5 fields)`)
    }
    const monotonicTime = parseNumberMandatory(match[1].trim())
    const message = parseNumberMandatory(match[2].trim())
    const channel = parseNumberMandatory(match[3].trim())
    const sensor = match[4].trim()
    const config = match.length > 5 ? parseKeyValues(match[5].split(",").slice(1)) : {}
    return new TtpMessageSensorConfiguration(lineNumber, monotonicTime, message, channel, sensor, config)
  }
}

export default TtpMessageSensorConfiguration
