/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * This is a utility class with static functions only.
 */
export class UrlUtils {
  /**
   * Convert a URL to base-64.
   * @param url URL to convert.
   */
  static urlToBase64(url: string): string {
    return btoa(url).replace(/[=_/-]/g, "")
  }
}

export default UrlUtils
