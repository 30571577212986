/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import MenuBar from "../menu/menuBar"
import Html from "../html/html"

/**
 * This class defines a window that can be shown or hidden and that displays HTML text.
 */
export abstract class HtmlWindow {
  protected readonly htmlWindowElement: HTMLElement
  protected readonly htmlWindowTitleElement: HTMLElement
  private readonly title: string
  private readonly displayShow: string
  private readonly displayHide = "none"

  /**
   * Create a new HtmlWindow.
   * @param htmlWindowElementId The HTML element ID of the element that will hold the HTML text in the window.
   * @param htmlWindowTitleElementId The HTML element ID of the element that will hold the title of the window.
   * @param title The title of the window.
   * @param valueForShow The value to set the display style to when the window is shown.
   */
  constructor(title: string, htmlWindowElementId: string, htmlWindowTitleElementId: string, valueForShow = "block") {
    this.title = title
    this.htmlWindowElement = Html.getDefinedHtmlElementById(htmlWindowElementId)
    this.htmlWindowTitleElement = Html.getDefinedHtmlElementById(htmlWindowTitleElementId)
    this.setTitle()
    this.displayShow = valueForShow

    this.setupCloseWindowListeners()
  }

  /**
   * Set the postfix for the title of the window.
   * @param titlePostfix The postfix to add to the title of the window.
   */
  public setTitle(titlePostfix: string = "") {
    this.htmlWindowTitleElement.textContent = this.title + (titlePostfix.length > 0 ? ` - ${titlePostfix}` : "")
  }

  /**
   * Set the visibility of the window.
   * @param show True to show the window, false to hide it.
   */
  public setVisible(show: boolean = true) {
    this.htmlWindowElement.style.display = show ? this.displayShow : this.displayHide

    // !! TODO [techdebt]: Refactor this to not directly use MenuBar.
    MenuBar.updateMenuItemToggle(Html.htmlElementIdForMenuItem(this.htmlWindowElement.id), show)
  }

  /**
   * Check if the window is visible.
   * @returns True if the window is visible, false if it is hidden.
   */
  isVisible() {
    return this.htmlWindowElement.style.display !== this.displayHide
  }

  /**
   * Register listeners for the close window buttons.
   * This function can safely be called multiple times.
   */
  private setupCloseWindowListeners() {
    const closeButtons = document.querySelectorAll(".close-button")
    closeButtons.forEach((closeButton) => {
      closeButton.removeEventListener("click", () => this.setVisible(false))
      closeButton.addEventListener("click", () => this.setVisible(false))
    })
  }
}

export default HtmlWindow
