/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import MemoryUtils from "../common/utils/memoryUtils"

/**
 * A simple key/value store for metadata.
 */
export class MetadataStore {
  private static metadataStore: {[key: string]: any} = {}
  private static metadataKey = 0

  /**
   * Store metadata and return a key to retrieve it later.
   * @param metadata Any metadata.
   * @returns A key to retrieve the metadata later.
   */
  static store(metadata: any): string {
    const key = MetadataStore.generateNextKey()
    MetadataStore.metadataStore[key] = {...metadata}
    return key
  }

  /**
   * Update metadata by key.
   * @param key The key returned by store().
   * @param metadata The new metadata.
   */
  static update(key: string, metadata: any): void {
    MetadataStore.metadataStore[key] = {...metadata}
  }

  /**
   * Retrieve metadata by key.
   * @param key The key returned by store().
   * @returns The metadata, or undefined if the key is not found.
   */
  static retrieve(key?: string): any {
    return key ? MetadataStore.metadataStore[key] : undefined
  }

  /**
   * Remove metadata by key.
   * @param key The key returned by store().
   */
  static remove(key: string): void {
    delete MetadataStore.metadataStore[key]
  }

  /**
   * Remove all metadata.
   */
  static removeAll(): void {
    MetadataStore.metadataStore = {}
    MetadataStore.metadataKey = 0
  }

  /**
   * Get the total size of all metadata in bytes.
   * @returns The total size in bytes.
   */
  static totalSizeInBytes(): number {
    return MemoryUtils.approximateMemorySizeInBytesOfObject(MetadataStore.metadataStore)
  }

  private static generateNextKey() {
    return `#${++MetadataStore.metadataKey}` // No worries, this effectively never overflows in a lifetime.
  }
}
