/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {isParsedPolygonColor, LayerLineParser, LayerType, ParsedPolygonColor} from "../parserTypes"
import {wktToGeoJSON} from "@terraformer/wkt"
import {Feature, GeoJSON, Geometry} from "geojson"
import LineParser from "./lineParser"
import {FileUtils} from "../../common/utils/fileUtils"
import {MetadataStore} from "../../global/metadataStore"
import {HttpUtils} from "../../common/utils/httpUtils"
import GeoJsonUtils from "../../common/utils/geoJsonUtils"
import {BugReport} from "../../global/bugReport"

export class LineParserNdsClassic extends LineParser {
  private static readonly ndsClassicUpdateRegionsConfigFile = "./config/ndsclassic_update_regions.json"
  private static readonly ndsClassicUpdateRegions: Record<number, GeoJSON> = {}

  private static readonly parsers: Partial<Record<LayerType, LayerLineParser>> = {
    [LayerType.NdsClassicRegion]: {
      name: "NDS.Classic region",
      layerType: LayerType.NdsClassicRegion,
      color: {
        "fill-opacity": 0.01,
        "fill-color": "rgb(0,255,157)",
        "fill-outline-color": "rgb(19,135,90)"
      },
      regexWithLocation: "/+nds-updates.tomtom.com(?::[0-9]+)?/+staging/+(?:[0-9]+/+)+UR_{n}/BB_[0-9]+[.]NDS"
    },
    [LayerType.NdsClassicOther]: {
      name: "NDS.Classic other",
      layerType: LayerType.NdsClassicOther,
      color: {
        "fill-opacity": 0.05,
        "fill-color": "rgb(255,234,0)",
        "fill-outline-color": "rgb(135,125,19)"
      },
      regexWithLocation: "/+nds-updates.tomtom.com(?::[0-9]+)?/+staging/+(?:[0-9]+/+)+UR_{n}[.]BXZ",
      regexWithoutLocation:
        "" +
        /* variant 1 */ "(/+nds-updates.tomtom.com(?::[0-9]+)?/+staging/+([0-9]+/+)+[0-9]+/+[0-9]+/+UR_[0-9]+/+[A-Z][A-Z]_[0-9]+[.][A-Z]{3})" +
        /* variant 2 */ "|(/+nds-test/+updates/+[0-9]+/+fetch)" +
        /* variant 3 */ "|(/+nds-updates.tomtom.com(?::[0-9]+)?/+staging/+([0-9]+/+)*" +
        /* variant 3, suffix */ "(" +
        /* variant 3, suffix 1 */ "(PGROUPS([.][A-Z]{3})?)" +
        /* variant 3, suffix 2 */ "|(PRODUCT[.][A-Z]{3})" +
        /* variant 3, suffix 3 */ "|([0-9]+[.][A-Z]{3})" +
        /* variant 3, suffix */ ")" +
        /* variant 3 */ ")"
    }
  }

  constructor(layerType: LayerType) {
    super(
      layerType,
      LineParserNdsClassic.parsers[layerType]!.name,
      LineParserNdsClassic.parsers[layerType]!.color,
      LineParserNdsClassic.parsers[layerType]!.regexWithLocation,
      LineParserNdsClassic.parsers[layerType]!.regexWithoutLocation
    )
    LineParserNdsClassic.loadNdsClassicRegions()
  }

  private static loadNdsClassicRegions() {
    if (Object.entries(LineParserNdsClassic.ndsClassicUpdateRegions).length) {
      console.log(
        `Skipping loading NDS.Classic update region polygons, already loaded: ${LineParserNdsClassic.ndsClassicUpdateRegionsConfigFile}`
      )
    } else {
      console.log(
        `Loading NDS.Classic update region polygons from: ${LineParserNdsClassic.ndsClassicUpdateRegionsConfigFile}`
      )
      FileUtils.requestJsonFile(
        LineParserNdsClassic.ndsClassicUpdateRegionsConfigFile,
        (json) => {
          Object.entries(json).forEach(([key, value]) => {
            const updateRegionId = parseInt(key)
            LineParserNdsClassic.ndsClassicUpdateRegions[updateRegionId] = wktToGeoJSON(value as string)
          })
          console.log(`Loaded ${Object.entries(json).length} NDS.Classic update region polygons`)
        },
        () => {
          console.log(
            `Cannot load NDS.Classic update regions (not authorized): ${LineParserNdsClassic.ndsClassicUpdateRegionsConfigFile}`
          )
          BugReport.logBugReport()
        },
        (httpStatus) => {
          console.log(
            `Cannot load NDS.Classic update regions: ${LineParserNdsClassic.ndsClassicUpdateRegionsConfigFile}, HTTP status: ${httpStatus}`
          )
          BugReport.logBugReport()
        }
      )
    }
  }

  parseLine(fileId: string, lineNumber: number, line: string): Feature[] {
    line = this.decodeIfLineIsURI(line)
    let features: Feature[] = []
    const time = this.getDateTimeFromAnyString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const usesCdn = LineParser.isLineCdnUrl(line)
    const metadata = {
      file: fileId,
      lineNumber: lineNumber,
      line: line,
      ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
      ...(usesCdn && {usesCdn: true})
    }
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation.replace("{n}", "([0-9]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const updateRegionId = parseInt(match[1])
        const metadataWithTile = {
          ...metadata,
          tileId: updateRegionId
        }
        const polygon = this.createPolygonFromUpdateRegionId(updateRegionId, metadataWithTile, time, sizeInBytes)
        if (!polygon) {
          return features
        }
        features.push(polygon)
        from = from + match.index + match[0].length
      }
    }
    if (features.length === 0 && this.regexWithoutLocation) {
      const match = RegExp(new RegExp(this.regexWithoutLocation)).exec(line)
      if (match) {
        const featureWithoutTile = this.createFeatureWithSizeWithoutCoordinates(metadata, time, sizeInBytes)
        features.push(featureWithoutTile)
      }
    }
    return features
  }

  private createPolygonFromUpdateRegionId(
    updateRegionId: number,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature {
    let color = this.color
    if (isParsedPolygonColor(color) && !HttpUtils.isOk(metadata.httpStatusCode) && metadata.httpStatusCode) {
      color = this.modifyPolygonColorToErrorState(
        color as ParsedPolygonColor,
        HttpUtils.isError(metadata.httpStatusCode)
      )
    }

    // Find the corresponding polygon for this update region.
    const geoJSON = LineParserNdsClassic.ndsClassicUpdateRegions[updateRegionId]
    if (!geoJSON) {
      return this.createFeatureWithSizeWithoutCoordinates(metadata, time, sizeInBytes)
    }

    const bounds = GeoJsonUtils.calculateBoundingBox(geoJSON)
    const extendedMetadata = {
      ...metadata,
      ...(time !== undefined && {time: time.getTime()}),
      ...(sizeInBytes !== undefined && {sizeInBytes: sizeInBytes}),
      layer: this.layerType,
      bounds: bounds,
      geoHash: bounds.toGeoHash()
    }
    const metadataKey = MetadataStore.store(extendedMetadata)
    return {
      type: "Feature",
      geometry: geoJSON as Geometry,
      properties: {
        metadata: metadataKey,
        ...(time !== undefined && {time: time.getTime()}),
        layer: this.layerType,
        geoHash: bounds.toGeoHash(),
        ...color
      }
    }
  }
}

export default LineParserNdsClassic
