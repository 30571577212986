/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * Returns whether the HTTP result code indicated success.
 * @param code The HTTP result code.
 */
type HttpCodeName = {
  code: number
  name: string
}

/**
 * This is a utility class with static functions only.
 */
export class HttpUtils {
  private static readonly names: HttpCodeName[] = [
    {code: 100, name: "Continue"},
    {code: 101, name: "Protocol Switching"},
    {code: 102, name: "Processing"},
    {code: 199, name: "Miscellaneous Warning"},
    {code: 200, name: "OK"},
    {code: 201, name: "Created"},
    {code: 202, name: "Accepted"},
    {code: 203, name: "Non-Authoritative Information"},
    {code: 204, name: "No Content"},
    {code: 205, name: "Reset Content"},
    {code: 206, name: "Partial Content"},
    {code: 207, name: "Multi-Status"},
    {code: 208, name: "Already Reported"},
    {code: 226, name: "IM Used"},
    {code: 300, name: "Multiple Choices"},
    {code: 301, name: "Moved Permanently"},
    {code: 302, name: "Found"},
    {code: 303, name: "See Other"},
    {code: 304, name: "Not Modified"},
    {code: 305, name: "Use Proxy"},
    {code: 306, name: "Switch Proxy"},
    {code: 307, name: "Temporary Redirect"},
    {code: 308, name: "Permanent Redirect"},
    {code: 400, name: "Bad Request"},
    {code: 401, name: "Unauthorized"},
    {code: 402, name: "Payment Required"},
    {code: 403, name: "Forbidden"},
    {code: 404, name: "Not Found"},
    {code: 405, name: "Method Not Allowed"},
    {code: 406, name: "Not Acceptable"},
    {code: 407, name: "Proxy Authentication Required"},
    {code: 408, name: "Request Timeout"},
    {code: 409, name: "Conflict"},
    {code: 410, name: "Gone"},
    {code: 411, name: "Length Required"},
    {code: 412, name: "Precondition Failed"},
    {code: 413, name: "Payload Too Large"},
    {code: 414, name: "URI Too Long"},
    {code: 415, name: "Unsupported Media Type"},
    {code: 416, name: "Range Not Satisfiable"},
    {code: 417, name: "Expectation Failed"},
    {code: 418, name: "I'm a teapot"},
    {code: 421, name: "Misdirected Request"},
    {code: 422, name: "Unprocessable Entity"},
    {code: 423, name: "Locked"},
    {code: 424, name: "Failed Dependency"},
    {code: 425, name: "Too Early"},
    {code: 426, name: "Upgrade Required"},
    {code: 428, name: "Precondition Required"},
    {code: 429, name: "Too Many Requests"},
    {code: 431, name: "Request Header Fields Too Large"},
    {code: 451, name: "Unavailable For Legal Reasons"},
    {code: 500, name: "Internal Server Error"},
    {code: 501, name: "Not Implemented"},
    {code: 502, name: "Bad Gateway"},
    {code: 503, name: "Service Unavailable"},
    {code: 504, name: "Gateway Timeout"},
    {code: 505, name: "HTTP Version Not Supported"},
    {code: 506, name: "Variant Also Negotiates"},
    {code: 507, name: "Insufficient Storage"},
    {code: 508, name: "Loop Detected"},
    {code: 510, name: "Not Extended"},
    {code: 511, name: "Network Authentication Required"}
  ]

  static getName(code: number): string | undefined {
    const httpCode = HttpUtils.names.find((httpCode) => httpCode.code === code)
    return httpCode ? httpCode.name : undefined
  }

  static isOk(code: number): boolean {
    return !HttpUtils.isWarning(code) && !HttpUtils.isError(code)
  }

  static isWarning(code: number): boolean {
    return code === 199 || (500 <= code && code < 600)
  }

  static isError(code: number): boolean {
    return 400 <= code && code < 500
  }

  static isNoContent(code: number): boolean {
    return code === 204
  }

  /**
   * Returns whether the HTTP result code indicated an authorization failure.
   * @param code The HTTP result code.
   */
  static isNotAuthorized(code: number): boolean {
    return code === 401 || code === 403
  }
}
