/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LayerMenuItem} from "../../menu/menuTypes"
import MenuDefinition from "./menuDefinition"
import LayersPanel from "../../panels/layersPanel"
import {isRealLayer, LayerType} from "../../parsers/parserTypes"
import assert from "../../common/assert"
import Html from "../../html/html"

export class MenuDefinitionLayers extends MenuDefinition {
  private readonly layersPanel: LayersPanel

  constructor(layersPanel: LayersPanel) {
    super()
    this.layersPanel = layersPanel
  }

  /**
   * Define the layers menu.
   */
  public getMenuItems(): LayerMenuItem[] {
    // Note: the IDs of these menu items must be the keys of LayerType.
    const layersMenuItems: LayerMenuItem[] = [
      {
        id: "MapVis3D",
        name: "MapVis: 3D",
        shortcut: "D",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVis3D)
      },
      {
        id: "MapVisBasicMap",
        name: "MapVis: basic map",
        shortcut: "B",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisBasicMap)
      },
      {
        id: "MapVisFlow",
        name: "MapVis: flow",
        shortcut: "F",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisFlow)
      },
      {
        id: "MapVisHillshade",
        name: "MapVis: hillshade",
        shortcut: "H",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisHillshade)
      },
      {
        id: "MapVisIncidents",
        name: "MapVis: incidents",
        shortcut: "I",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisIncidents)
      },
      {
        id: "MapVisSatellite",
        name: "MapVis: satellite",
        shortcut: "L",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisSatellite)
      },
      {
        id: "MapVisStyle",
        name: "MapVis: style",
        shortcut: "Y",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisStyle)
      },
      {
        id: "MapVisCustom",
        name: "MapVis: custom",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisCustom)
      },
      {
        id: "NdsLive",
        name: "NDS.Live",
        shortcut: "N",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsLive)
      },
      {
        id: "NdsClassicRegion",
        name: "NDS.Classic region",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsClassicRegion)
      },
      {
        id: "NdsClassicOther",
        name: "NDS.Classic other",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsClassicOther)
      },
      {
        id: "Nk2NavTiles",
        name: "NK2 NavTiles",
        shortcut: "T",
        action: () => this.layersPanel.toggleLayer(LayerType.Nk2NavTiles)
      },
      {
        id: "Nk2LaneTiles",
        name: "NK2 LaneTiles",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.Nk2LaneTiles)
      },
      {
        id: "ApiAutoComplete",
        name: "API: Auto-complete",
        shortcut: "A",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiAutoComplete)
      },
      {
        id: "ApiEv",
        name: "API: EV",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiEv)
      },
      {
        id: "ApiParking",
        name: "API: Parking",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiParking)
      },
      {
        id: "ApiRevGeocode",
        name: "API: Rev.geocode",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiRevGeocode)
      },
      {
        id: "ApiRouting",
        name: "API: Routing",
        shortcut: "R",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiRouting)
      },
      {
        id: "ApiSearch",
        name: "API: Search",
        shortcut: "Q",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiSearch)
      },
      {
        id: "ApiTpeg",
        name: "API: TPEG",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiTpeg)
      },
      {
        id: "LogLines",
        name: "Log lines",
        shortcut: "O",
        action: () => this.layersPanel.toggleLayer(LayerType.LogLines)
      },
      {
        id: "JSON",
        name: "JSON",
        shortcut: "J",
        action: () => this.layersPanel.toggleLayer(LayerType.JSON)
      },
      {
        id: "GeoJSON",
        name: "GeoJSON",
        action: () => this.layersPanel.toggleLayer(LayerType.GeoJSON)
      },
      {
        id: "GPX",
        name: "GPX",
        shortcut: "X",
        action: () => this.layersPanel.toggleLayer(LayerType.GPX)
      },
      {
        id: "TTPOther",
        name: "TTP: other data (1/sec)",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPOther)
      },
      {
        id: "TTPPrediction",
        name: "TTP: other data (100/sec)",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPPrediction)
      },
      {
        id: "TTPLocation",
        name: "TTP: GPS/GNSS",
        shortcut: "G",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPLocation)
      }
    ]
    for (const layerType in LayerType) {
      assert(
        !isRealLayer(layerType as LayerType) || layersMenuItems.find((item) => item.id === layerType),
        `Layer type ${layerType} not found in layersMenuItems list`
      )
    }
    layersMenuItems.forEach((menuItem) => {
      ;[
        Html.htmlElementIdForColor(menuItem.id),
        Html.htmlElementIdForText(menuItem.id),
        Html.htmlElementIdForStatus(menuItem.id)
      ].forEach((id) => {
        Html.getDefinedHtmlElementById(id).addEventListener("click", () => menuItem.action?.())
      })
    })
    return layersMenuItems
  }
}

export default MenuDefinitionLayers
