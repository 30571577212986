/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import TtpMessageBase from "../ttpMessageBase"
import {TtpMessageType} from "../ttpMessage"

const REGEX_FOOTER = "^\\s*END\\s*$"

export class TtpMessageFooter extends TtpMessageBase {
  type: TtpMessageType = TtpMessageType.Footer

  constructor(lineNumber: number, monotonicTime?: number) {
    super(lineNumber, monotonicTime)
    this.lineNumber = lineNumber
  }

  static parse(lineNumber: number, input: string): TtpMessageFooter | undefined {
    const match = RegExp(REGEX_FOOTER).exec(input)
    if (!match) {
      return undefined
    }
    return new TtpMessageFooter(lineNumber)
  }
}

export default TtpMessageFooter
