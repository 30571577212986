/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {BoundingBox} from "../common/geo"
import {createTimeBasedFilename, requestJsonFile, triggerDownloadFileInBrowser} from "../common/files"
import {LngLat} from "../common/wgs84"
import {LayerWithoutId} from "../common/mapLibreLayer"
import {Feature, LineString, Point, Position} from "geojson"
import {Tool} from "./tool"
import {Point as MapLibrePoint} from "maplibre-gl"
import {MetadataStore} from "../common/metadata"
import InvalidApiKey from "../exceptions/invalidApiKey"
import MapView from "../app/mapView"
import InspectorWindow from "../app/inspectorWindow"
import LogWindow from "../app/logWindow"

export class RouteCreatorJsonTool extends Tool {
  static readonly ID = "json"
  private readonly apiKey: string = ""
  private points: [number, number][] = []

  constructor(
    map: MapView,
    inspectorWindow: InspectorWindow,
    logWindow: LogWindow,
    metadataStore: MetadataStore,
    apiKey: string
  ) {
    super(map, inspectorWindow, logWindow, metadataStore, RouteCreatorJsonTool.ID, "Create/export route as JSON")
    this.apiKey = apiKey
  }

  onClick(location: LngLat, _: MapLibrePoint) {
    this.points.push([location.lng, location.lat])
    this.refresh()
  }

  undo() {
    if (this.points.length > 0) {
      this.points.pop()
      this.refresh()
    }
  }

  clearToolData() {
    this.points = []
    this.refresh()
  }

  createRoutesFromJson(fileName: string, contents: string): BoundingBox | undefined {
    try {
      const json = JSON.parse(contents)
      const features: Feature[] = []
      const _this = this
      const requestCalculateRoute = (
        name: string,
        points: [number, number][],
        callback: (points: [number, number][]) => void
      ) => _this.requestCalculateRoute(name, points, callback)

      for (let i = 0; i < json.cities.length; ++i) {
        let city = json.cities[i]
        let points: Position[] = [city.from, city.to]
        const point: Feature<Point> = {
          type: "Feature",
          geometry: {type: "Point", coordinates: city.from},
          properties: {}
        }
        features.push(point)

        setTimeout(requestCalculateRoute, i * 200, city.name, points, (points: Position[]) => {
          const lineString: Feature<LineString> = {
            type: "Feature",
            geometry: {type: "LineString", coordinates: points},
            properties: {}
          }
          features.push(lineString)
          this.draw(features)
        })
      }
      this.draw(features)
    } catch (error) {
      this.logWindow.error(`Syntax error in ${fileName}\n${error}`)
    }
    return undefined
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "rgb(255,255,255)",
          "line-width": 6
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "rgb(75,120,203)",
          "line-width": 3
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": 5,
          "circle-color": "rgb(75,120,203)"
        },
        filter: ["in", "$type", "Point"]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": 3,
          "circle-color": "rgb(255,255,255)"
        },
        filter: ["in", "$type", "Point"]
      }
    ]
  }

  refresh() {
    let features: Feature[] = []
    for (const element of this.points) {
      const point: Feature<Point> = {
        type: "Feature",
        geometry: {type: "Point", coordinates: element},
        properties: {}
      }
      features.push(point)
    }
    this.draw(features)
    if (this.points.length > 1) {
      this.requestCalculateRoute("", this.points, (points) => {
        const lineString: Feature<LineString> = {
          type: "Feature",
          geometry: {type: "LineString", coordinates: points},
          properties: {}
        }
        features.push(lineString)
        this.draw(features)
      })
    }
  }

  private requestCalculateRoute(
    name: string,
    points: [number, number][],
    drawPoints: (points: [number, number][]) => void
  ) {
    let url = "https://api.tomtom.com/routing/1/calculateRoute/"
    for (let i = 0; i < points.length; ++i) {
      url += (i === 0 ? "" : ":") + points[i][1] + "," + points[i][0]
    }
    url += `/json?key=${this.apiKey}&sectionType=urban`
    requestJsonFile(
      url,
      (response) => {
        if (response.routes.length > 0) {
          let route = response.routes[0]
          this.inspectorWindow.show(`Calculate route${name ? ": " + name : ""}`, [route.summary])
          let points: [number, number][] = []
          for (const leg of route.legs) {
            for (const point of leg.points) {
              points.push([point.longitude, point.latitude])
            }
          }
          drawPoints(points)
          this.logWindow.info(`Created JSON route, ${points.length} points`)
          triggerDownloadFileInBrowser(createTimeBasedFilename("route", "json"), JSON.stringify(response))
        }
      },
      () => {
        this.logWindow.error(`Authorization problem - clearing API keys.`)
        throw new InvalidApiKey()
      },
      () => this.logWindow.warning(`Cannot create route file: ${url}`)
    )
  }
}

export default RouteCreatorJsonTool
