/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * This is a utility class with static functions only.
 */
export class StringUtils {
  /**
   * Format a size in bytes like a human-readable string.
   * @param bytes Size in bytes.
   * @param fractionDigits Number of digits after the decimal point.
   * @param minPowerOf10 Minimum power of 10 to start from (0=bytes, 1=Kb, ...). This defines the minimum unit to use.
   */
  static formatSizeInBytes(bytes: number, fractionDigits = 1, minPowerOf10 = 0): string {
    const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    if (bytes === 0) {
      return `${parseFloat("0").toFixed(minPowerOf10 ? fractionDigits : 0)} ${sizes[minPowerOf10]}`
    }
    const k = 1024
    const unit = Math.max(minPowerOf10, Math.floor(Math.log(bytes) / Math.log(k)))
    return (bytes / Math.pow(k, unit)).toFixed(unit ? fractionDigits : 0) + " " + sizes[unit]
  }

  /**
   * Format a number like a human-readable string.
   * @param number Number of items.
   * @param digits Number of digits after the decimal point.
   * @param minPower Minimum power of 10 to start 'e' notation.
   */
  static formatNumberAsPowers(number: number, digits = 2, minPower: number = 7): string {
    const pow = Math.log(number) / Math.log(10)
    if (pow < minPower) {
      return number.toFixed(0)
    } else {
      return (number / Math.pow(10, Math.floor(pow))).toFixed(digits) + "e" + Math.floor(pow)
    }
  }

  /**
   * Truncate a string to a maximum length, or cut it with "...".
   * @param input
   * @param maxLength
   * @param truncatePostfix Default is "...".
   */
  static truncateString(input: string, maxLength: number, truncatePostfix = "..."): string {
    if (input.length <= maxLength) {
      return input
    }
    return input.slice(0, Math.max(0, maxLength - truncatePostfix.length)) + truncatePostfix
  }

  /**
   * Convert an object to a string, redacting sensitive fields.
   * @param input Any string.
   */
  static redactedString(input: string) {
    return input.replace(/(key|secret|password|pwd|token|credentials)\s*=\s*[^&?;,:|]*/gi, "$1=xxx")
  }

  /**
   * Capitalize the first letter of a string.
   * @param input Any string.
   * @returns The input string with the first letter capitalized.
   */
  static capitalizeFirstLetter(input: string) {
    return input.length > 0 ? input.charAt(0).toUpperCase() + input.slice(1) : input
  }
}

export default StringUtils
