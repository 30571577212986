/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageParseException} from "../ttpMessageParseException"
import TtpMessageBase from "../ttpMessageBase"
import {parseNumberMandatory, TtpMessageType} from "../ttpMessage"

const REGEX_POSITIONING_XML = "^([^,]*),\\s*16\\s*,(.*)$"

export class TtpMessagePositioningXml extends TtpMessageBase {
  type: TtpMessageType = TtpMessageType.PositioningXML
  xmlLine: string

  constructor(lineNumber: number, monotonicTime: number, xmlLine: string) {
    super(lineNumber, monotonicTime)
    this.xmlLine = xmlLine
  }

  static parse(lineNumber: number, input: string): TtpMessagePositioningXml | undefined {
    const match = RegExp(REGEX_POSITIONING_XML).exec(input)
    if (!match) {
      return undefined
    }
    if (match.length < 3) {
      throw new TtpMessageParseException(`Invalid positioning XML (needs at least 3 fields)`)
    }

    const monotonicTime = parseNumberMandatory(match[1].trim())
    const xmlLine = match[2].trim()
    return new TtpMessagePositioningXml(lineNumber, monotonicTime, xmlLine)
  }
}

export default TtpMessagePositioningXml
