/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export function assert(expression: any, message?: string): void {
  if (!expression) {
    const stack = new Error().stack
    const lines = stack?.split("\n")
    const parts = lines && lines.length > 2 ? lines[2].trim().split(" ") : undefined
    const fn = parts && parts.length > 1 ? parts[1] : undefined

    const logMessage = `Assertion failed${fn ? " (" + fn + ")" : ""}${message ? ": " + message : ""}`
    throw new Error(logMessage)
  }
}

export default assert
