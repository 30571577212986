/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export enum DateTimeFormat {
  LocalTime,
  UTCTime
}

/**
 * This is a utility class with static functions only.
 */
export class DateTimeUtils {
  private static readonly millisYear2000 = new Date(Date.UTC(2000, 0, 1, 0, 0, 0)).getTime()
  private static readonly millisYear2100 = new Date(Date.UTC(2100, 0, 1, 0, 0, 0)).getTime()
  private static readonly secondsYear2000 = DateTimeUtils.millisYear2000 / 1000
  private static readonly secondsYear2100 = DateTimeUtils.millisYear2100 / 1000

  // This regex accepts normal ISO time formats, like "YYYY-MM-DDTHH:MM:SSZ" or "YYYY-MM-DD HH:MM:SS".
  public static readonly regexISODateTime = /^\s*(\d{4}-\d{2}-\d{2}[T|\s+]\d{2}:\d{2}:\d{2}([.]\d+)?)(Z|[+-]\d+)?/

  // This regex accepts (incomplete) Logcat formats, like "MM-DD HH:MM:SS" or "YYYY-MM-DD HH:MM:SS+0200".
  public static readonly regexLogcatDateTime =
    /^\s*(\d{4}-)?(\d{1,2}-\d{1,2}\s+\d{1,2}:\d{2}:\d{2}([.]\d+)?)\s*((?:([+-]\d+)|(?:Z)))?/

  static formatDateWithTime(date: Date, timeFormat: DateTimeFormat = DateTimeFormat.UTCTime): string {
    return `${DateTimeUtils.formatDateWithoutTime(date, timeFormat)} ${DateTimeUtils.formatTimeOnly(date, timeFormat)}`
  }

  static formatDateWithoutTime(date: Date, timeFormat: DateTimeFormat = DateTimeFormat.UTCTime): string {
    const year = timeFormat === DateTimeFormat.LocalTime ? date.getFullYear() : date.getUTCFullYear()
    const month = String((timeFormat === DateTimeFormat.LocalTime ? date.getMonth() : date.getUTCMonth()) + 1).padStart(
      2,
      "0"
    )
    const day = String(timeFormat === DateTimeFormat.LocalTime ? date.getDate() : date.getUTCDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  static formatTimeOnly(date: Date, timeFormat: DateTimeFormat = DateTimeFormat.UTCTime): string {
    const hours = String(timeFormat === DateTimeFormat.LocalTime ? date.getHours() : date.getUTCHours()).padStart(
      2,
      "0"
    )
    const minutes = String(timeFormat === DateTimeFormat.LocalTime ? date.getMinutes() : date.getUTCMinutes()).padStart(
      2,
      "0"
    )
    const seconds = String(timeFormat === DateTimeFormat.LocalTime ? date.getSeconds() : date.getUTCSeconds()).padStart(
      2,
      "0"
    )
    return `${hours}:${minutes}:${seconds}${timeFormat === DateTimeFormat.LocalTime ? "" : " UTC"}`
  }

  /**
   * Format a time as a HH:MM:SS string.
   * @param seconds Time in seconds.
   */
  static formatTimeAsDuration(seconds: number): string {
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds - days * 86400) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = Math.round(seconds % 60)
    const paddedHours = String(hours).padStart(2, "0")
    const paddedMinutes = String(minutes).padStart(2, "0")
    const paddedSeconds = String(secs).padStart(2, "0")
    return `${days ? days + "d+" : ""}${paddedHours}:${paddedMinutes}:${paddedSeconds}`
  }

  static convertMillisToDateTime(millisOrSeconds: number): Date | undefined {
    if (DateTimeUtils.secondsYear2000 <= millisOrSeconds && millisOrSeconds < DateTimeUtils.secondsYear2100) {
      return new Date(millisOrSeconds * 1000)
    } else if (DateTimeUtils.millisYear2000 <= millisOrSeconds && millisOrSeconds < DateTimeUtils.millisYear2100) {
      return new Date(millisOrSeconds)
    } else {
      // Date-times outside [2000, 2100] are discarded.
      return undefined
    }
  }

  static getDateTimeFromISOTimestamp(line: string): Date | undefined {
    let datetime = undefined

    // This matches any time in 'near-ISO' format "YYYY-MM-DD HH:MM:SS". Use UTC.
    let match = DateTimeUtils.regexISODateTime.exec(line)
    if (match) {
      const timestamp = `${match[1].replace(/\s+/, "T")}${match[3] ?? "Z"}`
      datetime = new Date(timestamp)
    }
    return datetime
  }

  static getDateTimeFromLogcatTimestamp(line: string): Date | undefined {
    let datetime = undefined

    // This matches any time in 'near-ISO' format "YYYY-MM-DD HH:MM:SS". Use UTC.
    let match = DateTimeUtils.regexLogcatDateTime.exec(line)
    if (match) {
      const year = new Date().getFullYear()
      const timestamp = `${match[1] ?? year + "-"}${match[2]}${match[4] ?? "Z"}`
      datetime = new Date(timestamp)
    }
    return datetime
  }
}
