/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import InvalidApiKeyException from "../exceptions/invalidApiKeyException"
import Storage from "../common/storage"
import Logger from "../common/logger"

export class ExceptionHandler {
  /**
   * Exception handler.
   *
   * IMPORTANT: This needs to be a lambda and not a regular function to avoid
   * ---------- binding issues.
   * @param message Message.
   * @param source Source.
   * @param line Line.
   * @param column Column.
   * @param error Error.
   */
  static readonly handleException = (
    message: string | Event,
    source: string | undefined,
    line: number | undefined,
    column: number | undefined,
    error: Error | undefined
  ): boolean => {
    Logger.log.error(
      `Exception: ${JSON.stringify(message)} in: ${source}, line ${line}, column ${column}\nerror: ${JSON.stringify(error)}`
    )
    if (error instanceof InvalidApiKeyException) {
      Storage.clear()
      window.location.reload()
      return true
    }
    // Return a boolean that indicates whether or not the browser should still handle this exception.
    return false
  }
}
