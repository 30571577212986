/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {formatDateWithoutTime, formatDateWithTime, formatTimeOnly} from "../common/datetime"
import {PointXY} from "../common/geo"
import {formatSizeInBytes, limitValue} from "../common/objects"
import Parser from "../parsers/parser"
import {getDefaultHttpOverheadSizeInBytes} from "../common/httpCodes"
import {GlobalSettings} from "./globalSettings"
import InspectorWindow from "./inspectorWindow"
import {MetadataStore} from "../common/metadata"

export type TimeRange = {
  // Range of time in millis; used for the timeline.
  minMillis: number
  maxMillis: number
}

type SelectionTimeRange = {
  // Range of time, used for selection.
  minMillis: number
  maxMillis: number
  startDragMillis: number // Used while extending the selection time range.
  endDragMillis: number
}

export type Event = {
  // Data for any single event.
  timeMillis: number
  sizeInBytes?: number
  duplicates?: number
  hasLocation?: boolean
  hasError?: boolean
  metadata?: string
}

type Bucket = {
  // Bucket containing multiple events; the timeline is divided into buckets.
  timeRange: TimeRange
  nrOfEvents: number
  nrOfEventsWithoutLocation: number
  nrOfEventsWithError: number
  sizeInBytes: number
}

type EventStats = {
  // Statistics for events; used for all events and the selected events.
  nrOfEvents: number
  nrOfEventsWithSize: number
  sizeInBytes: number
  duplicatesSizeInBytes: number
}

type Rectangle = {
  x: number
  y: number
  width: number
  height: number
}

/**
 * This class is a cache for the buckets calculation, to avoid recalculating them every time,
 * which is an expensive operation.
 */
class BucketCache {
  private static previousTimelineRange = {minMillis: 0, maxMillis: 0}
  private static previousEventsLength = 0
  private static bucketsCache: Bucket[] = []

  static readonly bucketSizeInPixels = 5 // More pixels implies less buckets and more events in a bucket.

  static invalidateCache() {
    BucketCache.previousTimelineRange = {minMillis: 0, maxMillis: 0}
    BucketCache.previousEventsLength = 0
  }

  static createBuckets(events: Event[], timelineRange: TimeRange, graphWidth: number): Bucket[] {
    // Cache the previous buckets (expensive to recalculate a lot).
    if (
      events.length === this.previousEventsLength &&
      this.previousTimelineRange.minMillis !== 0 &&
      this.previousTimelineRange.maxMillis !== 0 &&
      timelineRange.minMillis === BucketCache.previousTimelineRange.minMillis &&
      timelineRange.maxMillis === BucketCache.previousTimelineRange.maxMillis
    ) {
      return this.bucketsCache
    }
    this.previousTimelineRange = {minMillis: timelineRange.minMillis, maxMillis: timelineRange.maxMillis}
    this.previousEventsLength = events.length

    const totalNrOfBuckets = Math.floor(graphWidth / BucketCache.bucketSizeInPixels)
    this.bucketsCache = []

    const rangeMillis = timelineRange.maxMillis - timelineRange.minMillis
    for (let i = 0; i < totalNrOfBuckets; ++i) {
      const minMillis = timelineRange.minMillis + i * (rangeMillis / totalNrOfBuckets)
      const maxMillis = minMillis + rangeMillis / totalNrOfBuckets
      this.bucketsCache.push({
        timeRange: {
          minMillis: minMillis,
          maxMillis: maxMillis
        },
        nrOfEvents: 0,
        nrOfEventsWithoutLocation: 0,
        nrOfEventsWithError: 0,
        sizeInBytes: 0
      })
    }
    for (const event of events) {
      if (timelineRange.minMillis <= event.timeMillis && event.timeMillis <= timelineRange.maxMillis) {
        let bucketIndex = limitValue(
          Math.floor(
            timelineRange.maxMillis === timelineRange.minMillis
              ? 0
              : ((event.timeMillis - timelineRange.minMillis) / (timelineRange.maxMillis - timelineRange.minMillis)) *
                  totalNrOfBuckets
          ),
          0,
          totalNrOfBuckets - 1
        )
        this.bucketsCache[bucketIndex].nrOfEvents++
        this.bucketsCache[bucketIndex].nrOfEventsWithoutLocation += event.hasLocation ? 0 : 1
        this.bucketsCache[bucketIndex].nrOfEventsWithError += event.hasError ? 1 : 0
        this.bucketsCache[bucketIndex].sizeInBytes += event.sizeInBytes ?? 0
      }
    }
    return this.bucketsCache
  }
}

/**
 * This is the timeline class, which is responsible for drawing the timeline.
 */
export class Timeline {
  private readonly container: HTMLElement
  private readonly globalSettings: GlobalSettings
  private readonly metadataStore: MetadataStore
  private readonly inspectorWindow: InspectorWindow
  private readonly onUpdateTimeRange: (timeRange: TimeRange) => void

  private readonly canvas = document.createElement("canvas")
  private readonly context = this.canvas.getContext("2d")!
  private readonly style = {
    font: "12px Roboto, Arial, Helvetica, sans-serif",
    fontAxis: "10px Roboto, Arial, Helvetica, sans-serif",
    fontSize: "11px Roboto, Arial, Helvetica, sans-serif",
    fontArrow: "12px Roboto, Arial, Helvetica, sans-serif",
    padding: 5,
    axisWidthY: 40,
    axisHeightX: 20,
    infoHeight: 20,
    backgroundColor: "rgb(31,49,71)", // Backdrop of timeline.
    textColorStats: "rgb(255,255,255)", // Stats text color.
    textColorHelp: "rgb(154,154,154)", // Subtitle help line.
    textColorAxis: "rgb(255,242,148)", // Color for X/Y axis text.
    textColorSize: "rgb(255,236,217)", // Color for size indicator in selection.
    textColorArrow: "rgb(255,243,224)", // Color of selection arrows.
    axisColor: "rgb(169,195,243)", // Color of X/Y axis.
    selectionColor: "rgba(255,109,126,0.40)", // Color of selection rectangle.
    bucketColorEvents: "rgb(87,112,168)", // Color of buckets, for events with locations.
    bucketColorEventsWithoutLocation: "rgb(189,158,35)", // Color of buckets, for events without locations.
    bucketColorEventsWithError: "rgb(255,0,0)", // Color of buckets, for events without errors.
    pointerColor: "rgb(255,255,255)" // Mouse hover pointer.
  }
  private readonly textHeight = this.getTextHeight()
  private readonly minBucketHeight = 4 // Minimum height of buckets with >0 elements.
  private readonly paddingAxisX = 5 // Padding around graph.

  private readonly minMouseMoveUpdateIntervalMillis = 300 // Avoid to many redraws while dragging.

  private timelineTimeRange: TimeRange // Range of visible timeline.
  private fullTimeRange: TimeRange // Range of all events.
  private selectionTimeRange: SelectionTimeRange // Range of selection in timeline.

  private cursorMillis: number // Current mouse position in millis.
  private isMouseDown = false // True while dragging/extending the selection.

  private previousMouseMoveMillis: number // Avoid redrawing the selection too often while dragging.
  private previousTimeRange: TimeRange // Avoid redrawing the selection when range wasn't changed.

  private events: Event[] // All events.
  private buckets: Bucket[] = [] // Bucketized events for displaying in timeline.

  // Used for displaying statistics.
  private timelineStats: EventStats
  private selectionStats: EventStats

  constructor(
    container: HTMLElement,
    globalSettings: GlobalSettings,
    metadataStore: MetadataStore,
    inspectorWindow: InspectorWindow,
    onUpdateTimeRange: (timeRange: TimeRange) => void
  ) {
    this.container = container
    this.globalSettings = globalSettings
    this.metadataStore = metadataStore
    this.inspectorWindow = inspectorWindow
    this.container.appendChild(this.canvas)
    this.onUpdateTimeRange = onUpdateTimeRange
    this.timelineTimeRange = {minMillis: 0, maxMillis: 0}
    this.fullTimeRange = {minMillis: 0, maxMillis: 0}
    this.selectionTimeRange = {minMillis: 0, maxMillis: 0, startDragMillis: 0, endDragMillis: 0}

    this.previousMouseMoveMillis = 0
    this.previousTimeRange = {minMillis: 0, maxMillis: 0}
    this.events = []
    this.cursorMillis = 0

    this.timelineStats = {
      nrOfEvents: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }
    this.selectionStats = {
      nrOfEvents: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }

    this.canvas.style.cursor = "pointer"

    this.updateCanvasSize()
    this.canvas.addEventListener(
      "mousedown",
      (event: MouseEvent) => {
        let point = this.convertMouseXYToCanvasXY(event)
        this.onMouseDown(point.x)
      },
      false
    )
    this.canvas.addEventListener(
      "mousemove",
      (event: MouseEvent) => {
        let point = this.convertMouseXYToCanvasXY(event)
        this.onMouseMove(point.x)
      },
      false
    )
    this.canvas.addEventListener(
      "mouseleave",
      () => {
        this.onMouseLeave()
      },
      false
    )

    // Note: these need to be a window listeners, not canvas listeners.
    window.addEventListener("resize", () => this.updateCanvasSize())
    window.addEventListener(
      "mouseup",
      (event: MouseEvent) => {
        let point = this.convertMouseXYToCanvasXY(event)
        this.onMouseUp(point.x, point.y, event.shiftKey)
      },
      false
    )
  }

  resetTimelineTimeRange() {
    BucketCache.invalidateCache()
    this.timelineTimeRange = {minMillis: this.fullTimeRange.minMillis, maxMillis: this.fullTimeRange.maxMillis}
    this.selectionTimeRange = {minMillis: 0, maxMillis: 0, startDragMillis: 0, endDragMillis: 0}
    this.isMouseDown = false
    this.setEventsTimeRangeIfChanged(this.getEffectiveEventsTimeRange(), true)
    this.draw()
  }

  getEffectiveEventsTimeRange(): TimeRange {
    // This return the effective time range, which is the selection time range if it is not empty,
    // otherwise it returns the full timeline time range.
    const selectionRange = this.getCurrentSelectionTimeRange()
    const minMills =
      this.selectionTimeRange.minMillis === this.selectionTimeRange.maxMillis
        ? this.timelineTimeRange.minMillis
        : selectionRange.minMillis
    const maxMillis =
      this.selectionTimeRange.minMillis === this.selectionTimeRange.maxMillis
        ? this.timelineTimeRange.maxMillis
        : selectionRange.maxMillis
    return {minMillis: minMills, maxMillis: maxMillis}
  }

  setEventsTimeRangeIfChanged(timeRange: TimeRange, forceUpdate: boolean = false) {
    const nowMillis = new Date().getTime()
    if (
      forceUpdate ||
      (timeRange.minMillis === 0 && timeRange.maxMillis === 0) ||
      (nowMillis - this.previousMouseMoveMillis >= this.minMouseMoveUpdateIntervalMillis &&
        (this.previousTimeRange.minMillis !== timeRange.minMillis ||
          this.previousTimeRange.maxMillis !== timeRange.maxMillis))
    ) {
      this.previousMouseMoveMillis = nowMillis
      this.previousTimeRange = {minMillis: timeRange.minMillis, maxMillis: timeRange.maxMillis}

      const stats = this.calculateEventStats(
        this.getCurrentSelectionTimeRange(),
        this.timelineTimeRange,
        getDefaultHttpOverheadSizeInBytes()
      )
      this.selectionStats = stats.stats1
      this.timelineStats = stats.stats2
      this.onUpdateTimeRange(timeRange)
    }
  }

  replaceAllEvents(allEvents: Event[]) {
    BucketCache.invalidateCache()
    this.events = []
    this.cursorMillis = 0
    this.fullTimeRange = {minMillis: 0, maxMillis: 0}
    this.previousTimeRange = {minMillis: 0, maxMillis: 0}
    allEvents.forEach((event) => this.addEventWithoutRedrawAndUpdateFullTimeRange(event))
    this.timelineTimeRange = {minMillis: this.fullTimeRange.minMillis, maxMillis: this.fullTimeRange.maxMillis}
    this.setEventsTimeRangeIfChanged(this.getEffectiveEventsTimeRange(), true)
    this.draw()
  }

  public recalculateSizesAndDraw() {
    this.setEventsTimeRangeIfChanged(this.getEffectiveEventsTimeRange(), true)
    this.draw()
  }

  public draw() {
    const graphRect = this.getGraphRect()
    const axisRectY = this.getAxisRectY()
    const axisRectX = this.getAxisRectX()
    const infoRect = this.getInfoRect()
    this.buckets = BucketCache.createBuckets(this.events, this.timelineTimeRange, graphRect.width)

    this.context.fillStyle = this.style.backgroundColor
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
    this.drawAxisX(axisRectX)
    this.drawAxisY(axisRectY, this.buckets)
    this.drawBars(graphRect, this.buckets)
    this.drawSelection()
    this.drawCursor()
    this.drawInfo(infoRect)
  }

  private onMouseDown(pointX: number) {
    // Return if there's no timeline.
    if (this.timelineTimeRange.maxMillis === this.timelineTimeRange.minMillis) {
      return
    }

    this.isMouseDown = true

    const rect = this.getGraphRect()
    pointX -= rect.x
    const pointInMillis =
      this.timelineTimeRange.minMillis +
      (pointX / rect.width) * (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)

    // Convert millis to pixels.
    const selectionTimeRange = this.getCurrentSelectionTimeRange()
    const edgeInPixels = 15

    const minEdgeX =
      ((selectionTimeRange.minMillis - this.timelineTimeRange.minMillis) /
        (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)) *
      rect.width
    const maxEdgeX =
      ((selectionTimeRange.maxMillis - this.timelineTimeRange.minMillis) /
        (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)) *
      rect.width
    const isNearMinEdge = Math.abs(pointX - minEdgeX) < edgeInPixels
    const isNearMaxEdge = Math.abs(pointX - maxEdgeX) < edgeInPixels

    if (isNearMinEdge || isNearMaxEdge) {
      this.selectionTimeRange.startDragMillis = 0
      this.selectionTimeRange.endDragMillis = 0
      // Extend selection if near existing time range edges.
      if (isNearMinEdge) {
        this.selectionTimeRange.minMillis = pointInMillis
      } else {
        this.selectionTimeRange.maxMillis = pointInMillis
      }
    }
    // Move time range if clicked inside the selection.
    else if (selectionTimeRange.minMillis <= pointInMillis && pointInMillis <= selectionTimeRange.maxMillis) {
      this.selectionTimeRange.startDragMillis = pointInMillis
      this.selectionTimeRange.endDragMillis = pointInMillis
    } else {
      // Create new time range if clicked outside.
      this.selectionTimeRange.startDragMillis = 0
      this.selectionTimeRange.endDragMillis = 0
      this.selectionTimeRange.minMillis = pointInMillis
      this.selectionTimeRange.maxMillis = pointInMillis
    }
    this.draw()
  }

  private onMouseMove(pointX: number) {
    const rect = this.getGraphRect()
    pointX -= rect.x

    const selectionTimeRange = this.getCurrentSelectionTimeRange()
    const pointInMillis =
      this.timelineTimeRange.minMillis +
      (pointX / rect.width) * (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)
    const extendLeft = pointInMillis < (selectionTimeRange.maxMillis + selectionTimeRange.minMillis) / 2

    if (this.isMouseDown) {
      if (this.selectionTimeRange.startDragMillis !== 0) {
        this.selectionTimeRange.endDragMillis = pointInMillis
      } else if (extendLeft) {
        this.selectionTimeRange.minMillis = pointInMillis
      } else {
        this.selectionTimeRange.maxMillis = pointInMillis
      }
      // Update the range only every second to avoid a lot of redraws.
      if (this.selectionTimeRange.minMillis !== this.selectionTimeRange.maxMillis) {
        this.setEventsTimeRangeIfChanged(this.getEffectiveEventsTimeRange())
      }
      this.cursorMillis = 0
    } else {
      this.cursorMillis = pointInMillis
    }
    this.draw()
  }

  private onMouseUp(pointX: number, pointY: number, shiftKey: boolean) {
    if (!this.isMouseDown) {
      return
    }
    this.isMouseDown = false
    let shiftMillis = this.selectionTimeRange.endDragMillis - this.selectionTimeRange.startDragMillis
    this.selectionTimeRange.minMillis = Math.max(
      this.timelineTimeRange.minMillis,
      this.selectionTimeRange.minMillis + shiftMillis
    )
    this.selectionTimeRange.maxMillis = Math.min(
      this.timelineTimeRange.maxMillis,
      this.selectionTimeRange.maxMillis + shiftMillis
    )
    this.selectionTimeRange.startDragMillis = 0
    this.selectionTimeRange.endDragMillis = 0

    // If shift was pressed, the display range of the timeline needs to zoom in/out.
    const selectionRange = this.getCurrentSelectionTimeRange()
    if (shiftKey) {
      // Check if the mouse actually selected a range, or it was a single click.
      if (selectionRange.minMillis === selectionRange.maxMillis) {
        // Single click; reset timeline range.
        this.timelineTimeRange = {minMillis: this.fullTimeRange.minMillis, maxMillis: this.fullTimeRange.maxMillis}
      } else {
        // Selected a range; set new timeline range.
        this.timelineTimeRange = {minMillis: selectionRange.minMillis, maxMillis: selectionRange.maxMillis}
      }

      // Reset selection.
      this.selectionTimeRange = {minMillis: 0, maxMillis: 0, startDragMillis: 0, endDragMillis: 0}
    } else if (shiftMillis === 0 && selectionRange.minMillis === selectionRange.maxMillis) {
      // Only show the inspector if no range was shown.
      const rect = this.getGraphRect()
      pointX -= rect.x
      const pointInMillis =
        this.timelineTimeRange.minMillis +
        (pointX / rect.width) * (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)
      const totalNrOfBuckets = Math.floor(rect.width / BucketCache.bucketSizeInPixels)
      const bucketIndex = limitValue(
        Math.floor(
          ((pointInMillis - this.timelineTimeRange.minMillis) /
            (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)) *
            totalNrOfBuckets
        ),
        0,
        totalNrOfBuckets - 1
      )
      const bucket = this.buckets[bucketIndex]
      const eventsInBucket = bucket.nrOfEvents
      if (eventsInBucket) {
        const title = `Time: ${formatTimeOnly(new Date(bucket.timeRange.minMillis), this.globalSettings.optionTimeFormat)} to ${formatTimeOnly(new Date(bucket.timeRange.maxMillis), this.globalSettings.optionTimeFormat)}`
        const selectedEvents = []
        for (const event of this.events) {
          if (bucket.timeRange.minMillis <= event.timeMillis && event.timeMillis <= bucket.timeRange.maxMillis) {
            const metadata = event.metadata ? this.metadataStore.retrieve(event.metadata) : undefined
            if (metadata) {
              selectedEvents.push(metadata)
            }
          }
        }
        this.inspectorWindow.show(title, selectedEvents, (feature: any) => feature)
      }
    }
    this.setEventsTimeRangeIfChanged(this.getEffectiveEventsTimeRange(), true)
    this.draw()
  }

  private onMouseLeave() {
    this.cursorMillis = 0
    this.setEventsTimeRangeIfChanged(this.getEffectiveEventsTimeRange())
    this.draw()
  }

  private calculateEventStats(
    range1: TimeRange,
    range2: TimeRange,
    httpOverheadSizeInBytes: number
  ): {
    stats1: EventStats
    stats2: EventStats
  } {
    let stats1: EventStats = {
      nrOfEvents: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }
    let stats2: EventStats = {
      nrOfEvents: 0,
      nrOfEventsWithSize: 0,
      sizeInBytes: 0,
      duplicatesSizeInBytes: 0
    }

    /**
     * Calculate the total number of items and the total size in bytes for the given ranges.
     * If 'size' is not defined for an event, the event does not have a size in its definition,
     * so it does not mark the range as incomplete. If it is '-1', it was missing where it
     * was supposed to be found, so it marks the range as incomplete.
     */
    for (const event of this.events) {
      if (range1.minMillis <= event.timeMillis && event.timeMillis <= range1.maxMillis) {
        ++stats1.nrOfEvents
        if (event.sizeInBytes !== Parser.SIZE_EXPECTED_BUT_NOT_FOUND) {
          // Size was found (value) or not expected at all (undefined).
          ++stats1.nrOfEventsWithSize
          if (event.sizeInBytes !== undefined) {
            stats1.sizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            if (event.duplicates && event.duplicates > 1) {
              stats1.duplicatesSizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            }
          }
        }
      }
      if (range2.minMillis <= event.timeMillis && event.timeMillis <= range2.maxMillis) {
        ++stats2.nrOfEvents
        if (event.sizeInBytes !== Parser.SIZE_EXPECTED_BUT_NOT_FOUND) {
          // Size was found (value) or not expected at all (undefined).
          ++stats2.nrOfEventsWithSize
          if (event.sizeInBytes !== undefined) {
            stats2.sizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            if (event.duplicates && event.duplicates > 1) {
              stats2.duplicatesSizeInBytes += event.sizeInBytes + httpOverheadSizeInBytes
            }
          }
        }
      }
    }
    return {
      stats1: stats1,
      stats2: stats2
    }
  }

  private addEventWithoutRedrawAndUpdateFullTimeRange(event: Event) {
    this.fullTimeRange.minMillis =
      this.fullTimeRange.minMillis === 0 ? event.timeMillis : Math.min(this.fullTimeRange.minMillis, event.timeMillis)

    this.fullTimeRange.maxMillis =
      this.fullTimeRange.maxMillis === 0 ? event.timeMillis : Math.max(this.fullTimeRange.maxMillis, event.timeMillis)
    this.events.push(event)
  }

  private convertMouseXYToCanvasXY(event: MouseEvent): PointXY {
    let rect = this.canvas.getBoundingClientRect()
    let x = event.clientX - rect.left
    let y = event.clientY - rect.top
    return {x: x, y: y}
  }

  private updateCanvasSize() {
    this.canvas.width = this.container.clientWidth
    this.canvas.height = this.container.clientHeight
    this.draw()
  }

  private getTextHeight() {
    let element = document.createElement("div")
    element.style.font = this.style.font
    element.textContent = "a"
    document.body.appendChild(element)
    let height = element.offsetHeight
    document.body.removeChild(element)
    return height
  }

  private getTextWidth(text: string) {
    this.context.font = this.style.font
    let metrics = this.context.measureText(text)
    return metrics.width
  }

  private getGraphRect(): Rectangle {
    return {
      x: this.style.padding + this.style.axisWidthY,
      y: this.style.padding,
      width: this.canvas.width - this.style.padding * 8 - this.style.axisWidthY,
      height: this.canvas.height - this.style.padding * 2 - this.style.axisHeightX
    }
  }

  private getAxisRectY(): Rectangle {
    return {
      x: this.style.padding,
      y: this.style.padding,
      width: this.style.axisWidthY,
      height: this.canvas.height - this.style.padding * 2 - this.style.axisHeightX
    }
  }

  private getAxisRectX(): Rectangle {
    return {
      x: this.style.padding + this.style.axisWidthY,
      y: this.canvas.height - this.style.padding - this.style.axisHeightX,
      width: this.canvas.width - this.style.padding * 8 - this.style.axisWidthY,
      height: this.style.axisHeightX
    }
  }

  private getInfoRect(): Rectangle {
    return {
      x: this.style.padding + this.style.axisWidthY,
      y: this.style.padding,
      width: this.canvas.width - this.style.padding * 2 - this.style.axisWidthY,
      height: this.style.infoHeight
    }
  }

  private getCurrentSelectionTimeRange(): TimeRange {
    // This return the selected time, updated while dragging the selection.
    const shift = this.selectionTimeRange.endDragMillis - this.selectionTimeRange.startDragMillis
    return {
      minMillis: Math.min(this.selectionTimeRange.minMillis, this.selectionTimeRange.maxMillis) + shift,
      maxMillis: Math.max(this.selectionTimeRange.minMillis, this.selectionTimeRange.maxMillis) + shift
    }
  }

  private isTimelineRangeInSingleDay(): boolean {
    const date1 = new Date(this.timelineTimeRange.minMillis)
    const date2 = new Date(this.timelineTimeRange.maxMillis)
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  private formatTimeMillis(timeMillis: number) {
    if (this.isTimelineRangeInSingleDay()) {
      return formatTimeOnly(new Date(timeMillis), this.globalSettings.optionTimeFormat)
    } else {
      return formatDateWithTime(new Date(timeMillis), this.globalSettings.optionTimeFormat)
    }
  }

  private drawAxisX(rect: Rectangle) {
    this.drawLine({x: rect.x, y: rect.y}, {x: rect.x + rect.width, y: rect.y}, this.style.axisColor, 1)
    const range = this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis
    if (range < 0) {
      return
    }
    const textWidth = this.getTextWidth(this.formatTimeMillis(this.timelineTimeRange.maxMillis))
    const count = Math.max(1, Math.min(range / 1000, Math.floor(rect.width / (2 * textWidth))))

    for (let i = 0; i <= count; ++i) {
      const label = this.formatTimeMillis(this.timelineTimeRange.minMillis + (range * i) / count)
      const midX = rect.x + (i * rect.width) / count
      this.drawLine({x: midX, y: rect.y}, {x: midX, y: rect.y + 7}, this.style.axisColor, 1)
      this.drawTextAxis(label, {x: midX, y: rect.y + 10}, "center", "top")
    }
  }

  private drawAxisY(rect: Rectangle, buckets: Bucket[]) {
    this.drawLine(
      {x: rect.x + rect.width, y: rect.y},
      {x: rect.x + rect.width, y: rect.y + rect.height},
      this.style.axisColor,
      1
    )
    let maxValue = 0
    for (const element of buckets) {
      maxValue = Math.max(maxValue, element.nrOfEvents)
    }
    let count = Math.max(1, Math.min(maxValue, Math.floor(rect.height / this.textHeight)))
    let verticalSpacing = rect.height / count

    for (let i = 0; i <= count; ++i) {
      const midY = rect.y + i * verticalSpacing
      this.drawLine({x: rect.x + rect.width - 5, y: midY}, {x: rect.x + rect.width, y: midY}, this.style.axisColor, 1)
      const value = maxValue * (1 - i / count)
      this.drawTextAxis(
        `${i === count || value ? value.toFixed(0) : ""}`,
        {x: rect.x + rect.width - 8, y: midY},
        "right",
        "middle"
      )
    }
  }

  private drawInfo(rect: Rectangle) {
    if (this.timelineTimeRange.minMillis === this.timelineTimeRange.maxMillis) {
      return
    }

    let line = ""
    if (this.cursorMillis !== 0) {
      line += `CURSOR:   ${this.formatTimeMillis(this.cursorMillis)}   |   `
    }

    if (this.isTimelineRangeInSingleDay()) {
      line += `DAY:  ${formatDateWithoutTime(new Date(this.timelineTimeRange.minMillis), this.globalSettings.optionTimeFormat)}   |   `
    }

    if (this.selectionTimeRange.minMillis !== this.selectionTimeRange.maxMillis) {
      const selectionRange = this.getCurrentSelectionTimeRange()
      line += `SELECTION:   ${this.formatTimeMillis(selectionRange.minMillis)} to ${this.formatTimeMillis(
        selectionRange.maxMillis
      )}${this.selectionStats.sizeInBytes ? "   " + formatSizeInBytes(this.selectionStats.sizeInBytes) : ""}${
        this.selectionStats.duplicatesSizeInBytes
          ? " (" + formatSizeInBytes(this.selectionStats.duplicatesSizeInBytes) + " in duplicates)"
          : ""
      }   ${this.selectionStats.nrOfEvents} items${
        this.selectionStats.nrOfEvents === this.selectionStats.nrOfEventsWithSize
          ? ""
          : " (" + (this.selectionStats.nrOfEvents - this.selectionStats.nrOfEventsWithSize) + " w/o size)"
      }   |   `
    }

    line += `TIMELINE:   ${this.formatTimeMillis(this.timelineTimeRange.minMillis)} to ${this.formatTimeMillis(
      this.timelineTimeRange.maxMillis
    )}${this.timelineStats.sizeInBytes ? "   " + formatSizeInBytes(this.timelineStats.sizeInBytes) : ""}${
      this.timelineStats.duplicatesSizeInBytes
        ? " (" + formatSizeInBytes(this.timelineStats.duplicatesSizeInBytes) + " in duplicates)"
        : ""
    }   ${this.timelineStats.nrOfEvents} items${
      this.timelineStats.nrOfEvents === this.timelineStats.nrOfEventsWithSize
        ? ""
        : " (" + (this.timelineStats.nrOfEvents - this.timelineStats.nrOfEventsWithSize) + " w/o size)"
    }`
    let point = {x: rect.x + rect.width, y: rect.y}
    this.drawText(line, point, "right", "top")

    line = "Click & drag = select range  |  Shift-click & drag = zoom in on selection |  Escape = reset timeline"
    point = {x: rect.x + rect.width, y: rect.y + this.textHeight}
    this.drawText(line, point, "right", "top", this.style.textColorHelp)
  }

  private drawSelection() {
    if (this.selectionTimeRange.minMillis !== this.selectionTimeRange.maxMillis) {
      const selectionRange = this.getCurrentSelectionTimeRange()
      this.context.fillStyle = this.style.selectionColor
      const rect = this.getGraphRect()
      let startX =
        rect.x +
        ((selectionRange.minMillis - this.timelineTimeRange.minMillis) /
          (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)) *
          rect.width
      let endX =
        rect.x +
        ((selectionRange.maxMillis - this.timelineTimeRange.minMillis) /
          (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)) *
          rect.width
      startX = Math.min(Math.max(startX, rect.x), rect.x + rect.width)
      endX = Math.min(Math.max(endX, rect.x), rect.x + rect.width)
      this.context.fillRect(startX, rect.y, endX - startX, rect.height)
      const graphRect = this.getGraphRect()
      const sizeText = this.selectionStats.sizeInBytes
        ? formatSizeInBytes(this.selectionStats.sizeInBytes) +
          (this.selectionStats.nrOfEvents === this.selectionStats.nrOfEventsWithSize
            ? ""
            : " (" + (this.selectionStats.nrOfEvents - this.selectionStats.nrOfEventsWithSize) + " incomplete)")
        : ""
      this.drawTextSize(sizeText, {x: (startX + endX) / 2, y: graphRect.y + graphRect.height / 1.5}, "center", "top")
      if (endX - startX > 15) {
        this.drawTextArrow("<", {x: startX + 2, y: graphRect.y + graphRect.height / 2}, "left", "middle")
        this.drawTextArrow(">", {x: endX - 2, y: graphRect.y + graphRect.height / 2}, "right", "middle")
      }
    }
  }

  private drawCursor() {
    if (this.cursorMillis !== 0) {
      this.context.fillStyle = this.style.axisColor
      const rect = this.getGraphRect()
      let x =
        rect.x +
        ((this.cursorMillis - this.timelineTimeRange.minMillis) /
          (this.timelineTimeRange.maxMillis - this.timelineTimeRange.minMillis)) *
          rect.width
      if (rect.x - this.paddingAxisX <= x && x <= rect.x + rect.width + this.paddingAxisX) {
        this.drawLine({x: x, y: rect.y}, {x: x, y: rect.y + rect.height}, this.style.pointerColor, 1)
      }
    }
  }

  private drawBars(rect: Rectangle, buckets: Bucket[]) {
    let maxValue = 0
    for (const element of buckets) {
      maxValue = Math.max(maxValue, element.nrOfEvents)
    }

    const barWidth = rect.width / buckets.length
    for (let i = 0; i < buckets.length; ++i) {
      const bucket = buckets[i]
      if (bucket.nrOfEvents === 0) {
        continue
      }

      const posX = rect.x + i * barWidth + 0.5
      const heightEvents = Math.max(
        bucket.nrOfEvents ? this.minBucketHeight : 0,
        (rect.height * bucket.nrOfEvents) / maxValue
      )
      const heightEventsWithoutLocation = Math.max(
        bucket.nrOfEventsWithoutLocation ? this.minBucketHeight : 0,
        (rect.height * bucket.nrOfEventsWithoutLocation) / maxValue
      )
      const heightEventsWithError = Math.max(
        bucket.nrOfEventsWithError ? this.minBucketHeight : 0,
        (rect.height * bucket.nrOfEventsWithError) / maxValue
      )

      this.context.fillStyle = this.style.bucketColorEvents
      this.context.fillRect(posX, rect.y + rect.height, barWidth - 1, -heightEvents)
      this.context.fillStyle = this.style.bucketColorEventsWithoutLocation
      this.context.fillRect(posX, rect.y + rect.height, barWidth - 1, -heightEventsWithoutLocation)
      this.context.fillStyle = this.style.bucketColorEventsWithError
      this.context.fillRect(posX, rect.y + rect.height, barWidth - 1, -heightEventsWithError)
    }
  }

  private drawLine(from: PointXY, to: PointXY, color: string, width: number) {
    this.context.strokeStyle = color
    this.context.lineWidth = width
    this.context.beginPath()
    this.context.moveTo(from.x, from.y)
    this.context.lineTo(to.x, to.y)
    this.context.stroke()
  }

  private drawText(
    text: string,
    point: PointXY,
    textAlign: CanvasTextAlign,
    textBaseline: CanvasTextBaseline,
    color: string = this.style.textColorStats
  ) {
    this.context.fillStyle = color
    this.context.font = this.style.font
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextAxis(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorAxis
    this.context.font = this.style.fontAxis
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextSize(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorSize
    this.context.font = this.style.fontSize
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }

  private drawTextArrow(text: string, point: PointXY, textAlign: CanvasTextAlign, textBaseline: CanvasTextBaseline) {
    this.context.fillStyle = this.style.textColorArrow
    this.context.font = this.style.fontArrow
    this.context.textAlign = textAlign
    this.context.textBaseline = textBaseline
    this.context.fillText(text, point.x, point.y)
  }
}

export default Timeline
