/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import HtmlWindow from "./htmlWindow"

import {getDefinedHtmlElementById} from "../common/html"

export class LogWindow extends HtmlWindow {
  private logMessages: {text: string; style: string}[] = []
  private logMessageRepeatCount = 1

  private readonly minRepeatCount = 3
  private readonly maxLogLines = 250

  public static readonly color = (color: string) => `color: ${color}`

  public static readonly colorBlack = LogWindow.color("black")
  public static readonly colorWhite = LogWindow.color("white")
  public static readonly colorOK = "color: green"
  public static readonly colorInfo = "color: black"
  public static readonly colorWarning = "color: darkorange"
  public static readonly colorError = "color: red"

  constructor(htmlElementId: string) {
    super(htmlElementId)
  }

  ok = (text: string) => this.log(text, LogWindow.colorOK)
  info = (text: string) => this.log(text, LogWindow.colorInfo)
  warning = (text: string) => this.log(text, LogWindow.colorWarning)
  error = (text: string) => this.log(text, LogWindow.colorError)

  log(text: string, style: string) {
    if (this.logMessages.length > 0 && text === this.logMessages[this.logMessages.length - 1].text) {
      this.logMessageRepeatCount++
      if (this.logMessageRepeatCount > this.minRepeatCount) {
        this.logMessages.pop()
        text += ` [<-- repeated ${this.logMessageRepeatCount - this.minRepeatCount + 1}x]`
      }
    } else {
      this.logMessageRepeatCount = 1
    }
    this.logMessages.push({text, style})
    if (this.logMessages.length > this.maxLogLines) {
      this.logMessages.shift()
    }

    const logWindowContent = getDefinedHtmlElementById("log-window-content")
    logWindowContent.innerHTML = this.logMessages
      .map((msg) => `<span style="${msg.style}">${msg.text}</span><br/>`)
      .join("")
    const logWindow = getDefinedHtmlElementById("log-window")
    logWindow.scrollTo({top: logWindowContent.scrollHeight})
  }

  clear() {
    this.logMessages = []
    this.logMessageRepeatCount = 1
    const logWindowContent = getDefinedHtmlElementById("log-window-content")
    logWindowContent.innerHTML = ""
  }

  setVisible(show: boolean) {
    super.setVisible(show)
    if (show) {
      const logWindow = getDefinedHtmlElementById("log-window")
      const logWindowContent = getDefinedHtmlElementById("log-window-content")
      logWindow.scrollTo({top: logWindowContent.scrollHeight})
    }
  }
}

export default LogWindow
