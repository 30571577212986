/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */
import Html from "../html/html"
import MathUtils from "../common/utils/mathUtils"

export class ProgressWindow {
  private static readonly minimumUpdateTimeMsec = 500
  private readonly htmlWindowElementId: HTMLElement
  private readonly htmlContentElementId: HTMLElement
  private readonly progressBar: HTMLElement
  private lastUpdateTimeMsec: number = 0
  private lastUpdatePercentage: number = 0
  private nrOfShows: number = 0
  private message: string = ""

  constructor(htmlWindowElementId: string, htmlContentElementId: string, htmlElementIdProgressBar: string) {
    this.htmlWindowElementId = Html.getDefinedHtmlElementById(htmlWindowElementId)
    this.htmlContentElementId = Html.getDefinedHtmlElementById(htmlContentElementId)
    this.progressBar = Html.getDefinedHtmlElementById(htmlElementIdProgressBar)
  }

  show(message: string) {
    this.nrOfShows++
    this.message = message
    this.htmlContentElementId.innerText = this.message
    this.htmlContentElementId.classList.add("blink")
    this.htmlWindowElementId.style.display = "block"
    this.lastUpdateTimeMsec = Date.now()
    this.updatePercentage(0)
  }

  updateMessage(message: string) {
    const now = Date.now()
    if (this.lastUpdateTimeMsec + ProgressWindow.minimumUpdateTimeMsec < now || this.message !== message) {
      this.message = message
      this.htmlContentElementId.innerText = message
      this.lastUpdateTimeMsec = now
    }
  }

  updatePercentage(percentage: number) {
    const now = Date.now()
    const limitedPercentage = MathUtils.limitValue(Math.round(percentage * 100 + 0.5), 0, 100)
    if (
      this.lastUpdateTimeMsec + ProgressWindow.minimumUpdateTimeMsec < now ||
      this.lastUpdatePercentage !== limitedPercentage
    ) {
      this.progressBar.style.width = `${limitedPercentage}%`
      this.lastUpdateTimeMsec = now
      this.lastUpdatePercentage = limitedPercentage
    }
  }

  hide() {
    if (this.nrOfShows > 0) {
      this.nrOfShows--
    }
    if (this.nrOfShows === 0) {
      this.htmlWindowElementId.style.display = "none"
      this.htmlContentElementId.classList.remove("blink")
    }
  }
}

export default ProgressWindow
