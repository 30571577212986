/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LngLat} from "../common/wgs84"
import {LayerWithoutId} from "../common/mapLibreLayer"
import {Feature} from "geojson"
import {Tool} from "./tool"
import {MapGeoJSONFeature, Point as MapLibrePoint} from "maplibre-gl"
import {MetadataStore} from "../common/metadata"
import MapView from "../app/mapView"
import InspectorWindow from "../app/inspectorWindow"
import {LogWindow} from "../app/logWindow"

/**
 * This tool allows the user to select map data on the map and get metadata info in the
 * metadata window.
 */
export class DataSelectorTool extends Tool {
  static readonly ID = "select"
  private readonly queryFeatures: (point: MapLibrePoint) => MapGeoJSONFeature[]
  private selectedFeatures: Feature[] = []

  constructor(
    map: MapView,
    inspectorWindow: InspectorWindow,
    logWindow: LogWindow,
    metadataStore: MetadataStore,
    queryFeatures: (point: MapLibrePoint) => MapGeoJSONFeature[]
  ) {
    super(map, inspectorWindow, logWindow, metadataStore, DataSelectorTool.ID, "Select data on map")
    this.queryFeatures = queryFeatures
  }

  onClick(location: LngLat, point: MapLibrePoint) {
    this.selectedFeatures = this.queryFeatures(point) ?? []
    this.refresh()
  }

  clearToolData() {
    this.selectedFeatures = []
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "symbol",
        paint: {
          "text-color": "white"
        },
        filter: ["all", ["has", "text"], ["==", ["geometry-type"], "Point"]]
      },
      {
        type: "circle",
        paint: {
          "circle-radius": 7,
          "circle-color": ["case", ["has", "circle-color"], ["get", "circle-color"], "rgb(255,0,0)"]
        },
        filter: ["==", ["geometry-type"], "Point"]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(0,0,0)"],
          "line-width": 10,
          "line-dasharray": [0.1, 1]
        },
        filter: ["any", ["==", ["geometry-type"], "Polygon"], ["==", ["geometry-type"], "LineString"]]
      },
      {
        type: "fill",
        paint: {
          "fill-color": ["case", ["has", "fill-color"], ["get", "fill-color"], "rgb(255,0,0)"],
          "fill-opacity": ["case", ["has", "fill-opacity"], ["get", "fill-opacity"], 0.2]
        },
        filter: ["==", ["geometry-type"], "Polygon"]
      }
    ]
  }

  refresh() {
    this.inspectorWindow.show(
      "",
      this.selectedFeatures,
      (feature: any) => this.metadataStore.retrieve(feature?.properties?.metadata),
      (feature: Feature) => {
        this.draw([feature ?? []])
      }
    )
    if (this.selectedFeatures.length < 1) {
      this.inspectorWindow.setVisible(false)
    }
  }
}

export default DataSelectorTool
