/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import TtpMessageBase from "./ttpMessageBase"
import {TtpMessageType} from "./ttpMessage"

export class TtpMessageComment extends TtpMessageBase {
  type: TtpMessageType = TtpMessageType.Comment
  comment: string

  constructor(lineNumber: number, comment: string) {
    super(lineNumber)
    this.comment = comment
  }

  static parse(lineNumber: number, input: string): TtpMessageComment | undefined {
    const line = input.trim()
    if (line.length === 0) {
      return new TtpMessageComment(lineNumber, "")
    } else if (line.startsWith("#")) {
      return new TtpMessageComment(lineNumber, line.substring(1).trim())
    }
    return undefined
  }
}
