/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {HTML_LAYERS_OVERHEAD, HTML_PANEL_CONTENT_DATA_FILES, HTML_PANEL_HEADER_DATA_FILES} from "../html/htmlElementId"
import {DataFileInfo, DataStore} from "../global/dataStore"
import {HTML_CLASS_PANEL_STATUS_BUTTON, HTML_CLASS_PANEL_TEXT} from "../html/htmlClassId"
import PersistentStorage from "../persistence/persistentStorage"
import Panel from "./panel"
import DataCanvas from "../app/dataCanvas"
import Timeline from "../app/timeline"
import Html from "../html/html"
import StringUtils from "../common/utils/stringUtils"

export class DataFilesPanel extends Panel {
  constructor(dataCanvas: DataCanvas, timeline: Timeline, onDraw: (inhibitAutoZoom?: boolean) => void) {
    super(dataCanvas, timeline, onDraw)

    this.create()
  }

  public update() {
    this.create()
  }

  /**
   * Set the option toggle for a layer to ON/OFF.
   * @param dataFileId Data file ID.
   * @param value New value for ON/OFF toggle.
   * @param changeOnOffText Modify the text, based on status (requires textOn and textOff).
   * @param textOn Optional ON text, if changeOnOffText is true.
   * @param textOff Optional OFF text, if changeOnOffText is true.
   */
  public updateOption = (
    dataFileId: string,
    value: boolean,
    changeOnOffText: boolean = true,
    textOn?: string,
    textOff?: string
  ) => this.updateAnyOption(dataFileId, value, changeOnOffText, textOn, textOff)

  private totalSizeInBytes() {
    return DataStore.totalSizeInBytes()
  }

  private readonly toggleDataFile = (dataFileInfo: DataFileInfo) => {
    this.criticalSection.enterCriticalSection(() => {
      const newValue = !this.dataCanvas.isFileShown(dataFileInfo.id)
      this.dataCanvas.setFileIsShown(dataFileInfo.id, newValue)
      this.updateOption(dataFileInfo.id, newValue)
      this.update()
      // Zooming is done when setting the time range.
      this.onDraw(true, () => {
        this.criticalSection.leaveCriticalSection()
      })
    })
  }

  private create() {
    const panelHeaderDataFiles = Html.getDefinedHtmlElementById(HTML_PANEL_HEADER_DATA_FILES)
    const title = document.createElement("tr")
    const sizeInBytes = this.totalSizeInBytes()
    title.textContent = `Data files${sizeInBytes > 999 ? " (uses " + StringUtils.formatSizeInBytes(sizeInBytes) + " of memory)" : ""}:`
    title.style.lineHeight = "20px"
    title.style.fontWeight = "bold"
    panelHeaderDataFiles.innerHTML = ""
    panelHeaderDataFiles.appendChild(title)

    const panelContentDataFiles = Html.getDefinedHtmlElementById(HTML_PANEL_CONTENT_DATA_FILES)
    panelContentDataFiles.innerHTML = ""

    const dataFileInfoList: DataFileInfo[] = DataStore.getDataFileInfoList()
    const table = document.createElement("table")

    dataFileInfoList.forEach((dataFileInfo) => {
      const row = document.createElement("tr")
      const columnStatus = document.createElement("td")
      columnStatus.className = HTML_CLASS_PANEL_STATUS_BUTTON
      columnStatus.id = Html.htmlElementIdForStatus(dataFileInfo.id)
      columnStatus.addEventListener("click", () => this.toggleDataFile(dataFileInfo))
      row.appendChild(columnStatus)
      table.appendChild(row)

      const columnName = document.createElement("td")
      columnName.className = `${HTML_CLASS_PANEL_TEXT} longer`
      columnName.textContent = dataFileInfo.name
      columnName.addEventListener("click", () => this.toggleDataFile(dataFileInfo))
      row.appendChild(columnName)
    })

    // Show the "drop log files" messages or the HTTP overhead.
    const row = document.createElement("tr")
    const empty = document.createElement("td")
    const overhead = document.createElement("td")
    overhead.id = HTML_LAYERS_OVERHEAD
    overhead.style.lineHeight = "20px"
    overhead.style.fontWeight = "regular"
    if (dataFileInfoList.length === 0) {
      overhead.textContent = "Start by dropping log files on the map..."
    } else {
      // Add an empty column to avoid wrapping the text.
      empty.innerText = ""
      row.appendChild(empty)
      overhead.textContent = `HTTP overhead: ${StringUtils.formatSizeInBytes(PersistentStorage.getHttpOverheadSizeInBytes(), 1)}/request`
    }
    row.appendChild(overhead)
    table.appendChild(row)

    panelContentDataFiles.appendChild(table)
    dataFileInfoList.forEach((layerInfo) =>
      this.updateAnyOption(layerInfo.id, this.dataCanvas.isFileShown(layerInfo.id))
    )
  }
}

export default DataFilesPanel
